import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import FunnelSimple from "../../../src/Assets/FunnelSimple.svg";
import filterGreyFill from "../../../src/Assets/filterGreyFill.svg";
import greenTick from "../../../src/Assets/greenTick.svg";
import desquoContext from "../../picontext/DashBoard/desquoContext";
import MagnifyingGlass from "../../../src/Assets/MagnifyingGlass.svg";
import "./version2.css";
import { Dropdown, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  PiChatTeardropDots,
  PiBellFill,
  PiCalendar,
  PiChatText,
  PiChatTextFill,
} from "react-icons/pi";
import LeadManagementOffcanvas from "./LeadManagementOffcanvas";
import styles from "./ModuleCssFiles/lms.module.css";
import { addDays } from "date-fns";
import { DateRangePicker } from "react-date-range";
import { enUS } from "date-fns/locale";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { RxCross1 } from "react-icons/rx";
import { DatePicker } from "antd";
import axios from "axios";
import { constants } from "../../piservices/constants";
import { List, ListItem, styled, tooltipClasses } from "@mui/material";
import dayjs from "dayjs";
import { setStoreLeadCount } from "../../Redux/Slices/lmsSlice";
import { useDispatch } from "react-redux";

const LeadTableV2 = ({
  tableOptions,
  showEmailModalHandler,
  setSelectedLeadsProp,
  selectAll,
  setSelectAll,
  init,
}) => {
  const WTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  const TagList = ({ data }) => {
    return (
      <List>
        {data?.map((item) => (
          <ListItem
            style={{
              backgroundColor: `${item?.tagColorCode}20`,
              color: `${item?.tagColorCode}`,
              width: "fit-content",
              padding: "6px 10px",
              fontWeight: "500",
              fontSize: "12px",
              borderRadius: "4px",
              margin: "6px",
            }}
          >
            {item?.tagName}
          </ListItem>
        ))}
      </List>
    );
  };

  const context = useContext(desquoContext);
  const dispatch = useDispatch();

  const { leads, setLeads, activeLead } = context;
  const [selectedPhoneNumbers, setSelectedPhoneNumbers] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [selectedBudget, setSelectedBudget] = useState([]);
  const [selectedStage, setSelectedStage] = useState([]);
  const [selectedSourceName, setSelectedSourceName] = useState([]);
  const [selectedFollowUp, setSelectedFollowUp] = useState([]);
  const [assignTo, setAssignTo] = useState(null);
  const [selectedLastCall, setSelectedLastCall] = useState([]);
  const [selectedExpClosure, setSelectedExpClosure] = useState([]);
  const [selectedAssignedTo, setSelectedAssignedTo] = useState([]);
  const [selectedRequirements, setSelectedRequirements] = useState([]);
  const [remarkModal, setRemarkModal] = useState(false);
  const [remarkData, setRemarkData] = useState("");
  const [selectedUpdatedAt, setSelectedUpdatedAt] = useState([]);
  const [selectedNames, setSelectedNames] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedLeadSourceNameType, setSelectedLeadSourceNameType] = useState(
    []
  );
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [active, setActive] = useState("updateStatus");
  const [allLeadsSelected, setAllLeadsSelected] = useState(false);

  const [openOffcanvas, setOpenOffcanvas] = useState(false);
  const [show, setShow] = useState(false);
  const [datePickerField, setDatePickerField] = useState("");
  const [userId, setUserId] = useState(null);

  const [lastCallFilter, setLastCallFilter] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);
  const [followUpFilter, setFollowUpFilter] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);
  const [expClosureFilter, setExpClosureFilter] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);
  const [openPicker, setOpenPicker] = useState(null);
  const [focusComment, setFocusComment] = useState(false);

  const handleContainerClick = (id) => {
    // Toggle visibility of the DatePicker
    setOpenPicker(openPicker === id ? null : id);
  };

  function formateDate(date, type) {
    let today = "";
    if (date) {
      today = new Date(date);
    }
    // else{
    //     today = new Date();
    // }
    if (!today) {
      return undefined;
    }
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    if (type === "number") {
      return dd + "-" + mm + "-" + yyyy;
    }
    return dd + "-" + mm + "-" + yyyy;
    // return dd + '-' + monthNames[mm - 1]?.slice(0, 3) + '-' + yyyy;
  }
  const [selectedStatuses, setSelectedStatuses] = useState([]); // Initialize state for selected statuses

  const handleStatusSelect = (statusName) => {
    setSelectedStatuses((prev) =>
      prev.includes(statusName)
        ? prev.filter((name) => name !== statusName) // Remove if already selected
        : [...prev, statusName] // Add if not selected
    );
  };

  const handleClearStatuses = () => {
    setSelectedStatuses([]); // Clear all selections
  };
  useEffect(() => {
    const followup = [];
    for (let i of leads?.data) {
      if (i?.leadUpdates[i?.leadUpdates?.length - 1]?.updateDate) {
        if (
          isDateInRange(
            i?.leadUpdates[i?.leadUpdates?.length - 1]?.updateDate,
            followUpFilter[0].startDate,
            followUpFilter[0].endDate
          )
        ) {
          followup.push(i?.leadUpdates[i?.leadUpdates?.length - 1]?.updateDate);
        }
      }
    }
    setSelectedFollowUp(followup);
  }, [followUpFilter]);
  useEffect(() => {
    const lastCall = [];
    for (let i of leads?.data) {
      if (i?.updatedAt) {
        if (
          isDateInRange(
            formateDate(i?.updatedAt),
            lastCallFilter[0].startDate,
            lastCallFilter[0].endDate
          )
        ) {
          lastCall.push(i?.updatedAt);
        }
      }
    }
    setSelectedLastCall(lastCall);
  }, [lastCallFilter]);

  useEffect(() => {
    const expClosure = [];
    for (let i of leads?.data) {
      if (i?.expClosureDate) {
        if (
          isDateInRange(
            formateDate(i?.expClosureDate),
            expClosureFilter[0].startDate,
            expClosureFilter[0].endDate
          )
        ) {
          expClosure.push(i?.expClosureDate);
        }
      }
    }
    setSelectedExpClosure(expClosure);
  }, [expClosureFilter]);

  useEffect(() => {
    setSelectedLeadsProp(selectedLeads);
    if (selectedLeads.length === filteredLeadsNew?.length) {
      setAllLeadsSelected(true);
      setSelectAll(true);
    } else {
      setAllLeadsSelected(false);
      setSelectAll(false);
    }
  }, [selectedLeads]);


  const isDateInRange = (dateStr, startDate, endDate) => {
    // Parse the date string (dd-mm-yyyy) into a Date object
    const [day, month, year] = dateStr.split("-");
    const parsedDate = new Date(year, month - 1, day);

    // Check if the parsed date is between the start and end dates
    return parsedDate >= startDate && parsedDate <= endDate;
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const handleNameSelect = (name) => {
    setSelectedNames((prevSelected) =>
      prevSelected.includes(name)
        ? prevSelected.filter((selected) => selected !== name)
        : [...prevSelected, name]
    );
  };
  const handlePhoneNumberSelect = (phoneNumber) => {
    setSelectedPhoneNumbers((prevSelected) =>
      prevSelected.includes(phoneNumber)
        ? prevSelected.filter((number) => number !== phoneNumber)
        : [...prevSelected, phoneNumber]
    );
  };

  const handleEmailSelect = (email) => {
    setSelectedEmails((prevSelected) =>
      prevSelected.includes(email)
        ? prevSelected.filter((selected) => selected !== email)
        : [...prevSelected, email]
    );
  };
  const handleCitySelect = (city) => {
    setSelectedCities((prevSelected) =>
      prevSelected.includes(city)
        ? prevSelected.filter((selected) => selected !== city)
        : [...prevSelected, city]
    );
  };
  const handleAddressSelect = (address) => {
    setSelectedAddress((prevSelected) =>
      prevSelected.includes(address)
        ? prevSelected.filter((selected) => selected !== address)
        : [...prevSelected, address]
    );
  };
  const handleAreaSelect = (area) => {
    setSelectedAreas((prevSelected) =>
      prevSelected.includes(area)
        ? prevSelected.filter((selected) => selected !== area)
        : [...prevSelected, area]
    );
  };
  const handleBudgetSelect = (budget) => {
    setSelectedBudget((prevSelected) =>
      prevSelected.includes(budget)
        ? prevSelected.filter((selected) => selected !== budget)
        : [...prevSelected, budget]
    );
  };
  const handleStageSelect = (stage) => {
    setSelectedStage((prevSelected) =>
      prevSelected.includes(stage)
        ? prevSelected.filter((selected) => selected !== stage)
        : [...prevSelected, stage]
    );
  };
  const handleSourceNameSelect = (name) => {
    setSelectedSourceName((prevSelected) =>
      prevSelected.includes(name)
        ? prevSelected.filter((selected) => selected !== name)
        : [...prevSelected, name]
    );
  };
  const handleBudgetRequirement = (requirements) => {
    setSelectedRequirements((prevSelected) =>
      prevSelected.includes(requirements)
        ? prevSelected.filter((selected) => selected !== requirements)
        : [...prevSelected, requirements]
    );
  };
  const handleUpdatedAtSelect = (updatedAt) => {
    setSelectedUpdatedAt((prevSelected) =>
      prevSelected.includes(updatedAt)
        ? prevSelected.filter((date) => date !== updatedAt)
        : [...prevSelected, updatedAt]
    );
  };
  const handleAssignedToSelect = (fullName) => {
    setSelectedAssignedTo((prevSelected) =>
      prevSelected.includes(fullName)
        ? prevSelected.filter((item) => item !== fullName)
        : [...prevSelected, fullName]
    );
  };
  const handleClearPhoneNumbers = () => {
    setSelectedPhoneNumbers([]);
  };

  const handleClearEmails = () => {
    setSelectedEmails([]);
  };
  const handleClearNames = () => {
    setSelectedNames([]);
  };
  const handleClearCities = () => {
    setSelectedCities([]);
  };
  const handleClearAddress = () => {
    setSelectedAddress([]);
  };
  const handleClearAreas = () => {
    setSelectedAreas([]);
  };
  const handleClearBudget = () => {
    setSelectedBudget([]);
  };
  const handleClearStage = () => {
    setSelectedStage([]);
  };
  const handleClearSourceName = () => {
    setSelectedSourceName([]);
  };
  const handleClearRequirements = () => {
    setSelectedRequirements([]);
  };
  const handleClearUpdatedAt = () => setSelectedUpdatedAt([]);
  const handleClearAssignTo = () => setSelectedAssignedTo([]);
  const handleClearLeadSources = () => setSelectedLeadSourceNameType([]);
  const handleLeadSourceSelect = (source) => {
    setSelectedLeadSourceNameType((prevSelected) =>
      prevSelected.includes(source)
        ? prevSelected.filter((item) => item !== source)
        : [...prevSelected, source]
    );
  };
  window.addEventListener('load', function () {
    const navigationType = performance.getEntriesByType('navigation')[0].type;

    if (navigationType === 'reload') {
      localStorage.removeItem('projectId');
    }
  });
  const [selectedFollowUpFilter, setSelectedFollowUpFilter] = useState("All Time");

  const handleFollowUpFilter = (filterType) => {
    setSelectedFollowUpFilter(filterType);
  };
  const checkFollowUpDateInRange = (lastFollowUpDate) => {
    if (selectedFollowUpFilter === "All Time") {
      return true; // Include all leads
    }
    if (!lastFollowUpDate) return false;
    const [day, month, year] = lastFollowUpDate.split("-");
    const followUpDate = new Date(year, month - 1, day);
    const today = new Date(); // Current date (local time)
    let startDate, endDate;

    switch (selectedFollowUpFilter) {
      case "Today":
        startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0);
        endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59, 999);
        break;

      case "Yesterday":
        startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1, 0, 0, 0, 0);
        endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1, 23, 59, 59, 999);
        break;

      case "Tomorrow":
        startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1, 0, 0, 0, 0);
        endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1, 23, 59, 59, 999);
        break;

      case "This Week":
        startDate = new Date(today.setDate(today.getDate() - today.getDay() + 1)).setHours(0, 0, 0, 0);
        endDate = new Date(today.setDate(today.getDate() - today.getDay() + 7)).setHours(23, 59, 59, 999);
        break;

      case "lastWeek":
        startDate = new Date(today.setDate(today.getDate() - today.getDay() - 6)).setHours(0, 0, 0, 0);
        endDate = new Date(today.setDate(today.getDate() - today.getDay())).setHours(23, 59, 59, 999);
        break;

      case "nextWeek":
        startDate = new Date(today.setDate(today.getDate() - today.getDay() + 8)).setHours(0, 0, 0, 0);
        endDate = new Date(today.setDate(today.getDate() - today.getDay() + 14)).setHours(23, 59, 59, 999);
        break;

      case "thisMonth":
        startDate = new Date(today.getFullYear(), today.getMonth(), 1).setHours(0, 0, 0, 0);
        endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0).setHours(23, 59, 59, 999);
        break;

      case "lastMonth":
        startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1).setHours(0, 0, 0, 0);
        endDate = new Date(today.getFullYear(), today.getMonth(), 0).setHours(23, 59, 59, 999);
        break;

      case "nextMonth":
        startDate = new Date(today.getFullYear(), today.getMonth() + 1, 1).setHours(0, 0, 0, 0);
        endDate = new Date(today.getFullYear(), today.getMonth() + 2, 0).setHours(23, 59, 59, 999);
        break;

      case "thisYear":
        startDate = new Date(today.getFullYear(), 0, 1).setHours(0, 0, 0, 0);
        endDate = new Date(today.getFullYear() + 1, 0, 0).setHours(23, 59, 59, 999);
        break;

      case "lastYear":
        startDate = new Date(today.getFullYear() - 1, 0, 1).setHours(0, 0, 0, 0);
        endDate = new Date(today.getFullYear(), 0, 0).setHours(23, 59, 59, 999);
        break;

      case "last7Days":
        startDate = new Date(today.setDate(today.getDate() - 7)).setHours(0, 0, 0, 0);
        endDate = new Date().setHours(23, 59, 59, 999);
        break;

      case "last30Days":
        startDate = new Date(today.setDate(today.getDate() - 30)).setHours(0, 0, 0, 0);
        endDate = new Date().setHours(23, 59, 59, 999);
        break;
      default:
        return true; // Default to "All Time"
    }
    const isInRange = followUpDate >= startDate && followUpDate <= endDate;
    return isInRange;
  };



  const applyFollowUpFilter = (startDate, endDate) => {
    const filteredLeads = leads?.data?.filter((lead) => {
      const lastFollowUpDate = lead?.leadUpdates[lead?.leadUpdates?.length - 1]?.updateDate;
      if (lastFollowUpDate) {
        const [day, month, year] = lastFollowUpDate.split("-");
        const parsedDate = new Date(year, month - 1, day);

        // Check if the parsed date is between the startDate and endDate
        return parsedDate >= startDate && parsedDate <= endDate;
      }
      return false;
    });

    setSelectedFollowUp(filteredLeads);
  };

  const filteredLeads = leads?.data?.filter((data) => {
    const phoneNumber = data.phoneNumber ? data?.phoneNumber?.toLowerCase() : "";
    const email = data.email ? data?.email?.toLowerCase() : "";
    const name = data.name ? data?.name?.toLowerCase() : "";
    const address = data.address ? data?.address?.toLowerCase() : "";
    const city = data.city ? data?.city?.toLowerCase() : "";
    const area = data.area ? data?.area?.toLowerCase() : "";
    const budget = data.budget ? data.budget?.toLowerCase() : "";
    const requirements = data?.requirements
      ? data?.requirements?.toLowerCase()
      : "";
    const updatedAt = data?.updatedAt
      ? formateDate(data?.updatedAt)?.toLowerCase()
      : "";
    const assignedTo = data?.assignedTo?.fullName
      ? data?.assignedTo?.fullName?.toLowerCase()
      : "";
    const leadSourceNameType = typeof data?.leadSource === 'string'
      ? data.leadSource.toLowerCase()
      : "";

    const stage = data?.leadStage?.stageName
      ? data?.leadStage?.stageName?.toLowerCase()
      : "";
    const sourceName = data?.sourceName
      ? data?.sourceName?.toLowerCase()
      : ""

    return (
      phoneNumber?.includes(searchQuery.toLowerCase()) ||
      email?.includes(searchQuery.toLowerCase()) ||
      name?.includes(searchQuery.toLowerCase()) ||
      address?.includes(searchQuery.toLowerCase()) ||
      city?.includes(searchQuery.toLowerCase()) ||
      area?.includes(searchQuery.toLowerCase()) ||
      budget?.includes(searchQuery.toLowerCase()) ||
      requirements?.includes(searchQuery.toLowerCase()) ||
      updatedAt?.includes(searchQuery.toLowerCase()) ||
      assignedTo?.includes(searchQuery.toLowerCase()) ||
      leadSourceNameType?.includes(searchQuery.toLowerCase()) ||
      stage?.includes(searchQuery.toLowerCase()) ||
      sourceName?.includes(searchQuery.toLowerCase())
    );
  });
  const getFormattedDate = (dateString) => {
    const updateDate = dayjs(dateString, 'DD-MM-YYYY'); // Parse the date with the given format
    const today = dayjs(); // Get today's date
    const yesterday = today.subtract(1, 'day'); // Get yesterday's date
    const tomorrow = today.add(1, 'day'); // Get tomorrow's date

    if (updateDate.isSame(today, 'day')) {
      return 'Today';
    } else if (updateDate.isSame(yesterday, 'day')) {
      return 'Yesterday';
    } else if (updateDate.isSame(tomorrow, 'day')) {
      return 'Tomorrow';
    } else {
      return updateDate.format('D MMM'); // Return the formatted date as "22 Sept"
    }
  };
  const getTextColor = (dateString) => {
    const updateDate = dayjs(dateString, 'DD-MM-YYYY');
    const today = dayjs();
    const yesterday = today.subtract(1, 'day');
    const tomorrow = today.add(1, 'day');

    if (updateDate.isSame(today, 'day')) {
      return '#FF9900'; // Color for today
    } else if (updateDate.isBefore(today, 'day')) {
      return '#FF5349'; // Color for yesterday and previous dates
    } else if (updateDate.isSame(tomorrow, 'day') || updateDate.isAfter(tomorrow, 'day')) {
      return '#41424A'; // Color for tomorrow and future dates
    }
  };
  const uniqueLeadSources = Array.from(
    new Set(
      filteredLeads
        ?.map((data) => data.leadSource)
        .filter((source) => source)
    )
  );
  const uniqueCities = Array.from(
    new Set(filteredLeads?.map((data) => data.city))
  ).filter(Boolean);

  const uniqueAddress = Array.from(
    new Set(filteredLeads?.map((data) => data.address))
  ).filter(Boolean);

  const filteredLeadsNew = leads?.data?.filter((data) => {
    const lastFollowUpDate = data?.leadUpdates[data?.leadUpdates?.length - 1]?.updateDate;
    const isFollowUpInRange = checkFollowUpDateInRange(lastFollowUpDate);
    const statusName = data?.leadStage?.status.find(
      (status) => status.id === data?.leadStatus
    )?.statusName;
    return (
      isFollowUpInRange &&
      (selectedPhoneNumbers.length === 0 || selectedPhoneNumbers.includes(data.phoneNumber)) &&
      (selectedEmails.length === 0 || selectedEmails.includes(data.email)) &&
      (selectedNames.length === 0 || selectedNames.includes(data.name)) &&
      (selectedCities.length === 0 || selectedCities.includes(data.city)) &&
      (selectedAddress.length === 0 || selectedAddress.includes(data.address)) &&
      (selectedAreas.length === 0 || selectedAreas.includes(data.area)) &&
      (selectedBudget.length === 0 || selectedBudget.includes(data.budget)) &&
      (selectedRequirements.length === 0 || selectedRequirements.includes(data.requirements)) &&
      (selectedUpdatedAt.length === 0 || selectedUpdatedAt.includes(formateDate(data.updatedAt))) &&
      (selectedAssignedTo.length === 0 || selectedAssignedTo.includes(data.assignedTo?.fullName)) &&
      (selectedLeadSourceNameType.length === 0 || selectedLeadSourceNameType.includes(data?.leadSource)) &&
      (selectedStage.length === 0 || selectedStage.includes(data?.leadStage?.stageName)) &&
      (selectedSourceName.length === 0 || selectedSourceName.includes(data?.sourceName)) &&
      (selectedFollowUp.length === 0 || selectedFollowUp.includes(data?.leadUpdates?.[data?.leadUpdates.length - 1]?.updateDate)) &&
      (selectedLastCall.length === 0 || selectedLastCall.includes(data?.updatedAt)) &&
      (selectedExpClosure.length === 0 || selectedExpClosure.includes(data?.expClosureDate)) &&
      (selectedStatuses.length === 0 || selectedStatuses.includes(statusName))
    );
  });
  const uniqueRequirements = Array.from(
    new Set(filteredLeads?.map((data) => data.requirements).filter(Boolean))
  );
  const uniqueStage = Array.from(new Set(filteredLeads?.map((data) => data?.leadStage?.stageName).filter(Boolean)));

  const uniqueAssignedTo = Array.from(
    new Set(
      filteredLeads
        ?.map((data) => data.assignedTo?.fullName)
        .filter((name) => name)
    )
  );
  dispatch(setStoreLeadCount(filteredLeadsNew?.length));

  const uniqueSourceName = Array.from(
    new Set(
      filteredLeads?.map((data) => data.sourceName).filter((name) => name)
    )
  );

  const showOffcanvas = () => {
    setAssignTo(null);
    setOpenOffcanvas(true);
  };

  // closes offcanvas
  const hideOffcanvas = () => {
    setOpenOffcanvas(false);
    // init();
  };
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const handleDropdownOpen = () => {
    setIsDropdownOpen(true);
  };

  const handleDropdownClose = () => {
    setIsDropdownOpen(false);
  };

  const showOffcanvasHandler = (id, data) => {
    if (isDropdownOpen) {
      return;
    }
    setUserId(id);
    if (data?.leadStage?.stageName !== "Won") {
      setActive("updateStatus");
      showOffcanvas();
    }
  };

  const openCommentHandler = (e, id) => {
    e.stopPropagation();
    setActive("logHistory");
    setUserId(id);
    setFocusComment(true);
    showOffcanvas();
  };

  // select a lead
  const selectLeadHandler = (checked, id) => {
    if (checked) {
      if (selectedLeads.indexOf(id) === -1) {
        setSelectedLeads((prev) => [...prev, id]);
      }
    } else {
      if (selectedLeads.indexOf(id) !== -1) {
        const index = selectedLeads.indexOf(id);
        const leads = [...selectedLeads];
        leads.splice(index, 1);
        setSelectedLeads(leads);
      }
    }
  };
  console.log(filteredLeadsNew, leads, "opiop")
  //select all leads
  const selectAllLeadHandler = (e) => {
    const checked = e.target.checked;
    if (checked) {
      const arr = [];
      for (let i of filteredLeadsNew) {
        arr.push(i._id);
      }
      console.log(arr, "Verververv")
      setSelectedLeads(arr);
      setAllLeadsSelected(true);
    } else {
      setSelectedLeads([]);
      setAllLeadsSelected(false);
    }
  };
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      maximumFractionDigits: 0
    }).format(value);
  };

  const onChange = (id, date, dateString) => {
    async function expClosure() {
      try {
        const obj = { leadId: id, expClosureDate: date["$d"] };
        await axios.post(
          `${constants.CRM_API
          }/user/add-exp-closure?userId=${localStorage.getItem("userId")}`,
          obj
        );
        init();
      } catch (err) {
      }
    }
    expClosure();
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    // Get the day of the month
    const day = String(date.getDate()).padStart(2, "0");

    // Get the month name
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getMonth()];

    // Get the year and take only the last two digits
    const year = String(date.getFullYear()).slice(-2);

    return `${day} ${month} ${year}`;
  };

  const colors = [
    "#26355D",
    "#AF47D2",
    "#E65C19",
    "#FF8F00",
    "#028391",
    "#667BC6",
    "#E0A75E",
    "#1679AB",
    "#365E32",
  ];

  const colorMap = {};
  let colorIndex = 0;

  const hexToRgba = (hex, alpha) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };
  const capitalizeFirstLetter = (name) => {
    if (!name) return "";
    return name
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(" ");
  };

  const assignColor = (stageName) => {
    if (!colorMap[stageName]) {
      colorMap[stageName] = colors[colorIndex % colors.length];
      colorIndex++;
    }
    const textColor = colorMap[stageName];
    const backgroundColor = hexToRgba(textColor, 0.2);
    return { textColor, backgroundColor };
  };
  const uniqueNames = Array.from(new Set(filteredLeads?.map((data) => data.name).filter(Boolean)));
  const uniqueBudgets = Array.from(new Set(filteredLeads?.map((data) => data.budget).filter(Boolean)));
  const wrapperRef = useRef(null);

  const handleDocumentClick = useCallback((event) => {
    const offcanvas = document.querySelector('.offcanvas');
    const datePicker = document.querySelector('.MuiPickersPopper-root');
    if (
      offcanvas &&
      !offcanvas.contains(event.target) &&
      (!datePicker || !datePicker.contains(event.target))
    ) {
      hideOffcanvas();
    }
  }, [hideOffcanvas]);

  useEffect(() => {
    if (selectAll) {
      const arr = filteredLeadsNew.map((lead) => lead._id) || [];
      setSelectedLeads(arr);
      setAllLeadsSelected(true);
    } else {
      setSelectedLeads([]); // Clear the selected leads when unselecting
      setAllLeadsSelected(false);
    }
  }, [selectAll]);
  useEffect(() => {
    if (openOffcanvas) {
      document.addEventListener('mousedown', handleDocumentClick);
    } else {
      document.removeEventListener('mousedown', handleDocumentClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleDocumentClick);
    };
  }, [openOffcanvas, handleDocumentClick]);


  return (
    <div>
      <div >
        <LeadManagementOffcanvas
          show={openOffcanvas}
          hide={hideOffcanvas}
          init={init}
          id={userId}
          assignTo={assignTo}
          setAssignTo={setAssignTo}
          setActive={setActive}
          active={active}
          focusComment={focusComment}
          setFocusComment={setFocusComment}
          assignColor={assignColor}
        />
      </div>
      <div
        className="offcanvasBodyyy2"
        style={{
          marginLeft: "2.1rem",
          marginRight: "2.1rem",
          marginTop: "3.3rem",
          display: "flex",
          height: "72vh",
          gap: "8px",
          width: "95vw",
          // overflowX: "auto",
        }}
      >
        {/* <Scrollbar style={{width: "100%", height: "600px"}} permanentTrackY={true}> */}
        <table>
          <div>
            <tr
              style={{
                backgroundColor: "#F2F5F8",
                borderRadius: "8px",
                height: "45px",
                alignItems: "center",
                display: "flex"
              }}
            >
              <th style={{ height: "25px" }}>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    width: "50px",
                    padding: "8px 12px",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      color: "#717789",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={allLeadsSelected && true}
                      style={{ height: "12px", width: "12px" }}
                      onChange={selectAllLeadHandler}
                    />
                  </div>
                </div>{" "}
              </th>
              <th style={{ height: "25px" }}>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    width: "132px",
                    padding: "8px 12px",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      color: "#717789",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                  >
                    Lead Name
                  </div>
                  <Dropdown autoClose="outside" onToggle={isOpen => (isOpen ? handleDropdownOpen() : handleDropdownClose())}>
                    <Dropdown.Toggle
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <img
                        onClick={() => {
                          setSearchQuery("");
                        }}
                        src={selectedNames?.length > 0 ? filterGreyFill : FunnelSimple}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className="frstttry"
                      style={{
                        overflow: "scroll",
                        maxHeight: "300px",
                        top: "13px",
                        left: "-115px",
                        position: "absolute",
                        inset: "13px auto auto -115px",
                        width: "194px",
                      }}
                    >
                      <Dropdown.Item
                        className="togyy"
                        style={{ position: "sticky", top: 0 }}
                      >
                        <div className="maininput">
                          <input
                            className="inputs"
                            placeholder="Search"
                            value={searchQuery}
                            onChange={handleSearchChange}
                          />
                          <img src={MagnifyingGlass} />
                        </div>
                      </Dropdown.Item>
                      <div style={{ maxHeight: "140px", overflowY: "scroll" }}>
                        {uniqueNames.map((name, index) => (
                          <Dropdown.Item
                            key={index}
                            style={{ paddingTop: "3px", paddingBottom: "3px" }}
                            onClick={() => handleNameSelect(name)}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                className={
                                  selectedNames.includes(name)
                                    ? "mainnameGreen"
                                    : "mainname"
                                }
                              >
                                {name}
                              </div>
                              {selectedNames.includes(name) && (
                                <img src={greenTick} alt="Selected" />
                              )}
                            </div>
                          </Dropdown.Item>
                        ))}
                        <Dropdown.Item
                          onClick={handleClearNames}
                          style={{
                            background: "white",
                            position: "sticky",
                            bottom: 0,
                            textAlignLast: "end",
                          }}
                        >
                          <div className="xlearrr">Clear Selection</div>
                        </Dropdown.Item>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </th>

              {tableOptions["Last Call Status"] && (
                <th style={{ height: "25px" }}>
                  <div
                    style={{
                      display: "flex",
                      width: "137px",
                      gap: "24px",
                      width: "127px",
                      padding: "8px 12px",
                    }}
                  >
                    <div
                      style={{
                        color: "#717789",
                        fontSize: "12px",
                        fontWeight: "500",
                        display: "flex",
                        gap: "15px",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ whiteSpace: "nowrap" }}>Last Call Status</div>
                    </div>
                    <Dropdown autoClose="outside">
                      <Dropdown.Toggle className="dropdownss">
                        <img
                          onClick={() => {
                            setSearchQuery("");
                          }}
                          src={FunnelSimple}
                          alt="Toggle Dropdown"
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        className="toggyy2"
                      >
                        <Dropdown.Item className="togyy">
                          <div className="maininput">
                            <input
                              className="inputs"
                              placeholder="Search"
                              value={searchQuery}
                              onChange={handleSearchChange}
                            />
                            <img src={MagnifyingGlass} alt="Search" />
                          </div>
                        </Dropdown.Item>
                        {(() => {
                          const uniqueStatusNames = new Set(); // Store unique status names
                          return filteredLeads
                            ?.filter((data) => {
                              const statusName = data?.leadStage?.status.find(
                                (status) => status.id === data?.leadStatus
                              )?.statusName;
                              if (statusName && !uniqueStatusNames.has(statusName)) {
                                uniqueStatusNames.add(statusName);
                                return true;
                              }
                              return false;
                            })
                            .map((data, index) => {
                              const statusName = data?.leadStage?.status.find(
                                (status) => status.id === data?.leadStatus
                              )?.statusName;

                              return (
                                <Dropdown.Item
                                  key={index}
                                  style={{ paddingTop: "3px", paddingBottom: "3px" }}
                                  onClick={() => handleStatusSelect(statusName)} // Replace logic here
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div
                                      className={
                                        selectedStatuses.includes(statusName)
                                          ? "mainnameGreen"
                                          : "mainname"
                                      }
                                    >
                                      {statusName}
                                    </div>
                                    {selectedStatuses.includes(statusName) && (
                                      <img src={greenTick} alt="Selected" />
                                    )}
                                  </div>
                                </Dropdown.Item>
                              );
                            });
                        })()}
                        <Dropdown.Item
                          onClick={handleClearStatuses}
                          style={{ textAlignLast: "end" }}
                        >
                          <div className="xlearrr">Clear Selection</div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>

                  </div>
                </th>
              )}
              {tableOptions["Follow up"] && (
                <th style={{ height: "25px" }}>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      width: "200px",
                      padding: "8px 21px",
                    }}
                  >
                    <div
                      style={{
                        color: "#717789",
                        fontSize: "12px",
                        fontWeight: "500",
                        display: "flex",
                        alignItems: "center",
                        gap: "31px",
                      }}
                    >
                      Follow up on
                      <Dropdown autoClose="outside" onToggle={isOpen => (isOpen ? handleDropdownOpen() : handleDropdownClose())}>
                        <Dropdown.Toggle
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <img
                            className={styles.followUpFilter}
                            src={(selectedFollowUpFilter?.length > 0 && selectedFollowUpFilter !== "All Time") ? filterGreyFill : FunnelSimple}
                          />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className={styles.followScroll} style={{ height: "350px", overflowY: "scroll" }}>
                          <Dropdown.Item
                            style={{ paddingTop: "3px", paddingBottom: "3px" }}
                            onClick={() => handleFollowUpFilter("All Time")}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                className={
                                  selectedFollowUpFilter.includes("All Time")
                                    ? "mainnameGreen"
                                    : "mainname"
                                }
                              >
                                All Time
                              </div>
                              {selectedFollowUpFilter.includes("All Time") && (
                                <img src={greenTick} alt="Selected" />
                              )}
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => handleFollowUpFilter("Yesterday")}> <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              className={
                                selectedFollowUpFilter.includes("Yesterday")
                                  ? "mainnameGreen"
                                  : "mainname"
                              }
                            >
                              Yesterday
                            </div>
                            {selectedFollowUpFilter.includes("Yesterday") && (
                              <img src={greenTick} alt="Selected" />
                            )}
                          </div></Dropdown.Item>
                          <Dropdown.Item onClick={() => handleFollowUpFilter("Today")}> <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              className={
                                selectedFollowUpFilter.includes("Today")
                                  ? "mainnameGreen"
                                  : "mainname"
                              }
                            >
                              Today
                            </div>
                            {selectedFollowUpFilter.includes("Today") && (
                              <img src={greenTick} alt="Selected" />
                            )}
                          </div></Dropdown.Item>
                          <Dropdown.Item onClick={() => handleFollowUpFilter("Tomorrow")}> <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              className={
                                selectedFollowUpFilter.includes("Tomorrow")
                                  ? "mainnameGreen"
                                  : "mainname"
                              }
                            >
                              Tomorrow
                            </div>
                            {selectedFollowUpFilter.includes("Tomorrow") && (
                              <img src={greenTick} alt="Selected" />
                            )}
                          </div></Dropdown.Item>
                          <Dropdown.Item onClick={() => handleFollowUpFilter("lastWeek")}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                className={
                                  selectedFollowUpFilter.includes("lastWeek")
                                    ? "mainnameGreen"
                                    : "mainname"
                                }
                              >
                                Last Week
                              </div>
                              {selectedFollowUpFilter.includes("lastWeek") && (
                                <img src={greenTick} alt="Selected" />
                              )}
                            </div></Dropdown.Item>
                          <Dropdown.Item onClick={() => handleFollowUpFilter("This Week")}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                className={
                                  selectedFollowUpFilter.includes("This Week")
                                    ? "mainnameGreen"
                                    : "mainname"
                                }
                              >
                                This Week
                              </div>
                              {selectedFollowUpFilter.includes("This Week") && (
                                <img src={greenTick} alt="Selected" />
                              )}
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => handleFollowUpFilter("nextWeek")}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                className={
                                  selectedFollowUpFilter.includes("nextWeek")
                                    ? "mainnameGreen"
                                    : "mainname"
                                }
                              >
                                Next Week
                              </div>
                              {selectedFollowUpFilter.includes("nextWeek") && (
                                <img src={greenTick} alt="Selected" />
                              )}
                            </div></Dropdown.Item>
                          <Dropdown.Item onClick={() => handleFollowUpFilter("lastMonth")}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                className={
                                  selectedFollowUpFilter.includes("lastMonth")
                                    ? "mainnameGreen"
                                    : "mainname"
                                }
                              >
                                Last Month
                              </div>
                              {selectedFollowUpFilter.includes("lastMonth") && (
                                <img src={greenTick} alt="Selected" />
                              )}
                            </div></Dropdown.Item>
                          <Dropdown.Item onClick={() => handleFollowUpFilter("thisMonth")}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                className={
                                  selectedFollowUpFilter.includes("thisMonth")
                                    ? "mainnameGreen"
                                    : "mainname"
                                }
                              >
                                This Month
                              </div>
                              {selectedFollowUpFilter.includes("thisMonth") && (
                                <img src={greenTick} alt="Selected" />
                              )}
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => handleFollowUpFilter("nextMonth")}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                className={
                                  selectedFollowUpFilter.includes("nextMonth")
                                    ? "mainnameGreen"
                                    : "mainname"
                                }
                              >
                                Next Month
                              </div>
                              {selectedFollowUpFilter.includes("nextMonth") && (
                                <img src={greenTick} alt="Selected" />
                              )}
                            </div></Dropdown.Item>
                          <Dropdown.Item onClick={() => handleFollowUpFilter("lastYear")}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                className={
                                  selectedFollowUpFilter.includes("lastYear")
                                    ? "mainnameGreen"
                                    : "mainname"
                                }
                              >
                                Last Year
                              </div>
                              {selectedFollowUpFilter.includes("lastYear") && (
                                <img src={greenTick} alt="Selected" />
                              )}
                            </div></Dropdown.Item>
                          <Dropdown.Item onClick={() => handleFollowUpFilter("thisYear")}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                className={
                                  selectedFollowUpFilter.includes("thisYear")
                                    ? "mainnameGreen"
                                    : "mainname"
                                }
                              >
                                This Year
                              </div>
                              {selectedFollowUpFilter.includes("thisYear") && (
                                <img src={greenTick} alt="Selected" />
                              )}
                            </div></Dropdown.Item>
                          <Dropdown.Item onClick={() => handleFollowUpFilter("last7Days")}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                className={
                                  selectedFollowUpFilter.includes("last7Days")
                                    ? "mainnameGreen"
                                    : "mainname"
                                }
                              >
                                Last 7 Days
                              </div>
                              {selectedFollowUpFilter.includes("last7Days") && (
                                <img src={greenTick} alt="Selected" />
                              )}
                            </div></Dropdown.Item>
                          <Dropdown.Item onClick={() => handleFollowUpFilter("last30Days")}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                className={
                                  selectedFollowUpFilter.includes("last30Days")
                                    ? "mainnameGreen"
                                    : "mainname"
                                }
                              >
                                Last 30 Days
                              </div>
                              {selectedFollowUpFilter.includes("last30Days") && (
                                <img src={greenTick} alt="Selected" />
                              )}
                            </div></Dropdown.Item>
                        </Dropdown.Menu>

                        {/* <Dropdown.Menu>
                          <Dropdown.Item>
                            <div className={styles.datePickerHeading}>
                              Follow up
                            </div>
                            <DateRangePicker
                              locale={enUS}
                              onChange={(item) =>
                                setFollowUpFilter([item.selection])
                              }
                              showSelectionPreview={true}
                              moveRangeOnFirstSelection={false}
                              months={2}
                              ranges={followUpFilter}
                              direction="horizontal"
                              inputRanges={[]}
                              staticRanges={[]}
                            />
                          </Dropdown.Item>
                        </Dropdown.Menu> */}
                      </Dropdown>
                    </div>

                  </div>
                </th>
              )}
              {tableOptions["Last Call"] && (
                <th style={{ height: "25px", paddingLeft: "7px" }}>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      width: "191px",
                      padding: "8px 12px",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        color: "#717789",
                        fontSize: "12px",
                        fontWeight: "500",
                        display: "flex",
                        alignItems: "center",
                        gap: "15px",
                      }}
                    >
                      Last Call
                    </div>
                    <Dropdown autoClose="outside" onToggle={isOpen => (isOpen ? handleDropdownOpen() : handleDropdownClose())} style={{ paddingRight: "47px" }}>
                      <Dropdown.Toggle
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <img
                          className={styles.followUpFilter}
                          src={FunnelSimple}
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <div className={styles.datePickerHeading}>
                            Last Call
                          </div>
                          <DateRangePicker
                            locale={enUS}
                            onChange={(item) =>
                              setLastCallFilter([item.selection])
                            }
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            months={2}
                            ranges={lastCallFilter}
                            direction="horizontal"
                            inputRanges={[]}
                            staticRanges={[]}
                          />
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </th>
              )}

              <th style={{ height: "25px" }}>
                <div
                  style={{
                    display: "flex",
                    width: "202px",
                    padding: "8px 12px",
                    alignItems: "center",
                    gap: "36px",
                    // justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      color: "#717789",
                      paddingLeft: "3px",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                  >
                    Mobile No.
                  </div>
                  <Dropdown autoClose="outside" onToggle={isOpen => (isOpen ? handleDropdownOpen() : handleDropdownClose())}>
                    <Dropdown.Toggle
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <img
                        onClick={() => {
                          setSearchQuery("");
                        }}
                        src={selectedPhoneNumbers?.length > 0 ? filterGreyFill : FunnelSimple}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className="frstttry2"
                      style={{
                        overflow: "scroll",
                        maxHeight: "300px",
                        width: "194px",
                      }}
                    >
                      <Dropdown.Item
                        className="togyy"
                        style={{ position: "sticky", top: 0 }}
                      >
                        <div className="maininput">
                          <input
                            className="inputs"
                            placeholder="Search"
                            value={searchQuery}
                            onChange={handleSearchChange}
                          />
                          <img src={MagnifyingGlass} />
                        </div>
                      </Dropdown.Item>
                      <div style={{ maxHeight: "140px", overflowY: "scroll" }}>
                        {filteredLeads
                          ?.filter((data) => data.phoneNumber)
                          ?.map((data, index) => (
                            <Dropdown.Item
                              key={index}
                              style={{ paddingTop: "3px", paddingBottom: "3px" }}
                              onClick={() =>
                                handlePhoneNumberSelect(data.phoneNumber)
                              }
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div
                                  className={
                                    selectedPhoneNumbers.includes(
                                      data.phoneNumber
                                    )
                                      ? "mainnameGreen"
                                      : "mainname"
                                  }
                                >
                                  {data.phoneNumber}
                                </div>
                                {selectedPhoneNumbers.includes(
                                  data.phoneNumber
                                ) && <img src={greenTick} alt="Selected" />}
                              </div>
                            </Dropdown.Item>
                          ))}
                      </div>
                      <Dropdown.Item
                        onClick={handleClearPhoneNumbers}
                        style={{
                          background: "white",
                          position: "sticky",
                          bottom: 0,
                          textAlignLast: "end",
                        }}
                      >
                        <div className="xlearrr">Clear Selection</div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </th>
              <th style={{ height: "25px" }}>
                <div
                  style={{
                    display: "flex",
                    width: "150px",
                    padding: "8px 12px",
                    alignItems: "center",
                    gap: "36px",
                    // justifyContent: "space-between",

                  }}
                >
                  <div
                    style={{
                      color: "#717789",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                  >
                    E-mail
                  </div>
                  <Dropdown autoClose="outside" onToggle={isOpen => (isOpen ? handleDropdownOpen() : handleDropdownClose())}>
                    <Dropdown.Toggle
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <img
                        onClick={() => {
                          setSearchQuery("");
                        }}
                        src={selectedEmails?.length > 0 ? filterGreyFill : FunnelSimple}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className="frstttry2"
                      style={{
                        overflow: "scroll",
                        maxHeight: "300px",
                        width: "194px",
                      }}
                    >
                      <Dropdown.Item
                        className="togyy"
                        style={{ position: "sticky", top: 0 }}
                      >
                        <div className="maininput">
                          <input
                            className="inputs"
                            placeholder="Search"
                            value={searchQuery}
                            onChange={handleSearchChange}
                          />
                          <img src={MagnifyingGlass} />
                        </div>
                      </Dropdown.Item>
                      <div style={{ maxHeight: "140px", overflowY: "scroll" }}>
                        {filteredLeads
                          ?.filter((data) => data.email)
                          ?.map((data, index) => (
                            <Dropdown.Item
                              key={index}
                              style={{ paddingTop: "3px", paddingBottom: "3px" }}
                              onClick={() => handleEmailSelect(data.email)}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div
                                  className={
                                    selectedEmails.includes(data.email)
                                      ? "mainnameGreen"
                                      : "mainname"
                                  }
                                >
                                  {data.email}
                                </div>
                                {selectedEmails.includes(data.email) && (
                                  <img src={greenTick} alt="Selected" />
                                )}
                              </div>
                            </Dropdown.Item>
                          ))}
                      </div>
                      <Dropdown.Item
                        onClick={handleClearEmails}
                        style={{
                          background: "white",
                          position: "sticky",
                          bottom: 0,
                          textAlignLast: "end",
                        }}
                      >
                        <div className="xlearrr">Clear Selection</div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </th>
              {tableOptions["City"] && (<th style={{ height: "25px" }}>
                <div
                  style={{
                    display: "flex",
                    gap: "25px",
                    width: "106px",
                    padding: "8px 12px",
                    alignItems: "center",
                    // justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      color: "#717789",
                      fontSize: "12px",
                      paddingLeft: "3px",
                      fontWeight: "500",
                    }}
                  >
                    City
                  </div>
                  <Dropdown autoClose="outside" onToggle={isOpen => (isOpen ? handleDropdownOpen() : handleDropdownClose())}>
                    <Dropdown.Toggle
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <img
                        onClick={() => {
                          setSearchQuery("");
                        }}
                        src={selectedCities?.length > 0 ? filterGreyFill : FunnelSimple}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className="frstttry2"
                      style={{
                        overflow: "scroll",
                        maxHeight: "220px",
                        width: "194px",
                      }}
                    >
                      <Dropdown.Item
                        className="togyy"
                        style={{ position: "sticky", top: 0 }}
                      >
                        <div className="maininput">
                          <input
                            className="inputs"
                            placeholder="Search"
                            value={searchQuery}
                            onChange={handleSearchChange}
                          />
                          <img src={MagnifyingGlass} />
                        </div>
                      </Dropdown.Item>
                      <div style={{ maxHeight: "140px", overflowY: "scroll" }}>
                        {uniqueCities.map((city, index) => (
                          <Dropdown.Item
                            key={index}
                            style={{ paddingTop: "3px", paddingBottom: "3px" }}
                            onClick={() => handleCitySelect(city)}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                className={
                                  selectedCities.includes(city)
                                    ? "mainnameGreen"
                                    : "mainname"
                                }
                              >
                                {city}
                              </div>
                              {selectedCities.includes(city) && (
                                <img src={greenTick} alt="Selected" />
                              )}
                            </div>
                          </Dropdown.Item>
                        ))}
                      </div>
                      <Dropdown.Item
                        onClick={handleClearCities}
                        style={{
                          background: "white",
                          position: "sticky",
                          bottom: 0,
                          textAlignLast: "end",
                        }}
                      >
                        <div className="xlearrr">Clear Selection</div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </th>)}
              {tableOptions["Address"] && (
                <th style={{ height: "25px" }}>
                  <div
                    style={{
                      display: "flex",
                      gap: "25px",
                      width: "106px",
                      padding: "8px 12px",
                      alignItems: "center",
                      // justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        color: "#717789",
                        fontSize: "12px",
                        paddingLeft: "3px",
                        fontWeight: "500",
                      }}
                    >
                      Address
                    </div>
                    <Dropdown autoClose="outside" onToggle={isOpen => (isOpen ? handleDropdownOpen() : handleDropdownClose())}>
                      <Dropdown.Toggle
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <img
                          onClick={() => {
                            setSearchQuery("");
                          }}
                          src={selectedAddress?.length > 0 ? filterGreyFill : FunnelSimple}
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        className="frstttry2"
                        style={{
                          overflow: "scroll",
                          maxHeight: "220px",
                          width: "194px",
                        }}
                      >
                        <Dropdown.Item
                          className="togyy"
                          style={{ position: "sticky", top: 0 }}
                        >
                          <div className="maininput">
                            <input
                              className="inputs"
                              placeholder="Search"
                              value={searchQuery}
                              onChange={handleSearchChange}
                            />
                            <img src={MagnifyingGlass} />
                          </div>
                        </Dropdown.Item>
                        <div style={{ maxHeight: "140px", overflowY: "scroll" }}>
                          {uniqueAddress.map((address, index) => (
                            <Dropdown.Item
                              key={index}
                              style={{ paddingTop: "3px", paddingBottom: "3px" }}
                              onClick={() => handleAddressSelect(address)}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div
                                  className={
                                    selectedAddress.includes(address)
                                      ? "mainnameGreen"
                                      : "mainname"
                                  }
                                >
                                  {address}
                                </div>
                                {selectedAddress.includes(address) && (
                                  <img src={greenTick} alt="Selected" />
                                )}
                              </div>
                            </Dropdown.Item>
                          ))}
                        </div>
                        <Dropdown.Item
                          onClick={handleClearAddress}
                          style={{
                            background: "white",
                            position: "sticky",
                            bottom: 0,
                            textAlignLast: "end",
                          }}
                        >
                          <div className="xlearrr">Clear Selection</div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </th>
              )}
              {tableOptions["Budget"] && (<th style={{ height: "25px" }}>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    width: "107px",
                    padding: "8px 12px",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      color: "#717789",
                      fontSize: "12px",
                      fontWeight: "500",
                      paddingLeft: "3px",
                    }}
                  >
                    Budget
                  </div>
                  <Dropdown autoClose="outside" onToggle={isOpen => (isOpen ? handleDropdownOpen() : handleDropdownClose())}>
                    <Dropdown.Toggle
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <img
                        onClick={() => {
                          setSearchQuery("");
                        }}
                        src={selectedBudget?.length > 0 ? filterGreyFill : FunnelSimple}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className="frstttry2"
                      style={{
                        overflow: "scroll",
                        maxHeight: "300px",
                        width: "194px",
                      }}
                    >
                      <Dropdown.Item
                        className="togyy"
                        style={{ position: "sticky", top: 0 }}
                      >
                        <div className="maininput">
                          <input
                            className="inputs"
                            placeholder="Search"
                            value={searchQuery}
                            onChange={handleSearchChange}
                          />
                          <img src={MagnifyingGlass} />
                        </div>
                      </Dropdown.Item>
                      <div style={{ maxHeight: "140px", overflowY: "scroll" }}>
                        {uniqueBudgets.map((budget, index) => (
                          <Dropdown.Item
                            key={index}
                            style={{ paddingTop: "3px", paddingBottom: "3px" }}
                            onClick={() => handleBudgetSelect(budget)}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                className={
                                  selectedBudget.includes(budget)
                                    ? "mainnameGreen"
                                    : "mainname"
                                }
                              >
                                {budget}
                              </div>
                              {selectedBudget.includes(budget) && (
                                <img src={greenTick} alt="Selected" />
                              )}
                            </div>
                          </Dropdown.Item>
                        ))}
                      </div>
                      <Dropdown.Item
                        onClick={handleClearBudget}
                        style={{
                          background: "white",
                          position: "sticky",
                          bottom: 0,
                          textAlignLast: "end",
                        }}
                      >
                        <div className="xlearrr">Clear Selection</div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </th>
              )}
              {tableOptions["Work Scope"] && (<th style={{ height: "25px" }}>
                <div
                  style={{
                    display: "flex",
                    gap: "21px",
                    width: "170px",
                    padding: "8px 12px",
                    // justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      color: "#717789",
                      fontSize: "12px",
                      fontWeight: "500",
                      paddingLeft: "9px",
                    }}
                  >
                    Work Scope
                  </div>
                  <Dropdown autoClose="outside" onToggle={isOpen => (isOpen ? handleDropdownOpen() : handleDropdownClose())}>
                    <Dropdown.Toggle
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <img
                        onClick={() => {
                          setSearchQuery("");
                        }}
                        src={selectedRequirements?.length > 0 ? filterGreyFill : FunnelSimple}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className="frstttry2"
                      style={{
                        overflow: "scroll",
                        maxHeight: "300px",
                        width: "194px",
                      }}
                    >
                      <Dropdown.Item
                        className="togyy"
                        style={{ position: "sticky", top: 0 }}
                      >
                        <div className="maininput">
                          <input
                            className="inputs"
                            placeholder="Search"
                            value={searchQuery}
                            onChange={handleSearchChange}
                          />
                          <img src={MagnifyingGlass} />
                        </div>
                      </Dropdown.Item>
                      <div style={{ maxHeight: "140px", overflowY: "scroll" }}>
                        {uniqueRequirements.map((requirement, index) => (
                          <Dropdown.Item
                            key={index}
                            style={{ paddingTop: "3px", paddingBottom: "3px" }}
                            onClick={() => handleBudgetRequirement(requirement)}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                className={
                                  selectedRequirements.includes(requirement)
                                    ? "mainnameGreen"
                                    : "mainname"
                                }
                              >
                                {requirement}
                              </div>
                              {selectedRequirements.includes(requirement) && (
                                <img src={greenTick} alt="Selected" />
                              )}
                            </div>
                          </Dropdown.Item>
                        ))}
                      </div>
                      <Dropdown.Item
                        onClick={handleClearRequirements}
                        style={{
                          background: "white",
                          position: "sticky",
                          bottom: 0,
                          textAlignLast: "end",
                        }}
                      >
                        <div className="xlearrr">Clear Selection</div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </th>
              )}
              {tableOptions["Area"] && (
                <th style={{ height: "25px" }}>
                  <div
                    style={{
                      display: "flex",
                      gap: "19px",
                      width: "112px",
                      padding: "8px 12px 8px 3px",
                      alignItems: "center",
                      // justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        color: "#717789",
                        fontSize: "12px",
                        fontWeight: "500",
                      }}
                    >
                      Area
                    </div>
                    <Dropdown autoClose="outside" onToggle={isOpen => (isOpen ? handleDropdownOpen() : handleDropdownClose())}>
                      <Dropdown.Toggle
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <img
                          onClick={() => {
                            setSearchQuery("");
                          }}
                          src={selectedAreas?.length > 0 ? filterGreyFill : FunnelSimple}
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        className="frstttry2"
                        style={{
                          overflow: "scroll",
                          maxHeight: "300px",
                          width: "194px",
                        }}
                      >
                        <Dropdown.Item
                          className="togyy"
                          style={{ position: "sticky", top: 0 }}
                        >
                          <div className="maininput">
                            <input
                              className="inputs"
                              placeholder="Search"
                              value={searchQuery}
                              onChange={handleSearchChange}
                            />
                            <img src={MagnifyingGlass} />
                          </div>
                        </Dropdown.Item>
                        <div style={{ maxHeight: "140px", overflowY: "scroll" }}>
                          {filteredLeads
                            ?.filter((data) => data.area)
                            ?.map((data, index) => (
                              <Dropdown.Item
                                key={index}
                                style={{ paddingTop: "3px", paddingBottom: "3px" }}
                                onClick={() => handleAreaSelect(data.area)}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div
                                    className={
                                      selectedAreas.includes(data.area)
                                        ? "mainnameGreen"
                                        : "mainname"
                                    }
                                  >
                                    {data.area}
                                  </div>
                                  {selectedAreas.includes(data.area) && (
                                    <img src={greenTick} alt="Selected" />
                                  )}
                                </div>
                              </Dropdown.Item>
                            ))}
                        </div>
                        <Dropdown.Item
                          onClick={handleClearAreas}
                          style={{
                            background: "white",
                            position: "sticky",
                            bottom: 0,
                            textAlignLast: "end",
                          }}
                        >
                          <div className="xlearrr">Clear Selection</div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </th>)}
              <th style={{ height: "25px" }}>
                <div
                  style={{
                    display: "flex",
                    gap: "31px",
                    width: "200px",
                    padding: "8px 12px",
                    // justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      color: "#717789",
                      fontSize: "12px",
                      fontWeight: "500",
                      paddingLeft: "9px",
                    }}
                  >
                    Stage
                  </div>
                  {/* <Dropdown autoClose="outside" >
                                    <Dropdown.Toggle style={{ display: "flex", alignItems: "center" }}> */}
                  <Dropdown autoClose="outside" onToggle={isOpen => (isOpen ? handleDropdownOpen() : handleDropdownClose())}>
                    <Dropdown.Toggle
                      style={{ display: "flex", alignItems: "center", paddingRight: "31px" }}
                    >
                      <img
                        onClick={() => {
                          setSearchQuery("");
                        }}
                        src={selectedStage?.length > 0 ? filterGreyFill : FunnelSimple}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className="frstttry2"
                      style={{
                        overflow: "scroll",
                        maxHeight: "300px",
                        width: "194px",
                      }}
                    >
                      <Dropdown.Item
                        className="togyy"
                        style={{ position: "sticky", top: 0 }}
                      >
                        <div className="maininput">
                          <input
                            className="inputs"
                            placeholder="Search"
                            value={searchQuery}
                            onChange={handleSearchChange}
                          />
                          <img src={MagnifyingGlass} />
                        </div>
                      </Dropdown.Item>
                      <div style={{ maxHeight: "140px", overflowY: "scroll" }}>
                        {uniqueStage.map((stageName, index) => (
                          <Dropdown.Item
                            key={index}
                            style={{ paddingTop: "3px", paddingBottom: "3px" }}
                            onClick={() => handleStageSelect(stageName)}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                className={
                                  selectedStage.includes(stageName)
                                    ? "mainnameGreen"
                                    : "mainname"
                                }
                              >
                                {stageName}
                              </div>
                              {selectedStage.includes(stageName) && (
                                <img src={greenTick} alt="Selected" />
                              )}
                            </div>
                          </Dropdown.Item>
                        ))}
                      </div>
                      <Dropdown.Item
                        onClick={handleClearStage}
                        style={{
                          background: "white",
                          position: "sticky",
                          bottom: 0,
                          textAlignLast: "end",
                        }}
                      >
                        <div className="xlearrr">Clear Selection</div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  {/* </Dropdown.Toggle>
                                    <Dropdown.Menu style={{ overflow: "scroll", height: "300px", width: "194px" }}>
                                        <Dropdown.Item className='togyy'>
                                            <div className='maininput'>
                                                <input
                                                    className='inputs'
                                                    placeholder='Search'
                                                    value={searchQuery}
                                                    onChange={handleSearchChange} />
                                                <img src={MagnifyingGlass} />
                                            </div>
                                        </Dropdown.Item>
                                        {filteredLeads?.filter(data => data.requirements)?.map((data, index) => (
                                            <Dropdown.Item
                                                key={index}
                                                style={{ paddingTop: "3px", paddingBottom: "3px" }}
                                                onClick={() => handleBudgetRequirement(data.requirements)}
                                            >
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <div className={selectedRequirements.includes(data.requirements) ? 'mainnameGreen' : 'mainname'}>
                                                        {data.requirements}
                                                    </div>
                                                    {selectedRequirements.includes(data.requirements) && (
                                                        <img src={greenTick} alt="Selected" />
                                                    )}
                                                </div>
                                            </Dropdown.Item>
                                        ))}
                                        <Dropdown.Item onClick={handleClearRequirements} style={{ textAlignLast: "end" }}>
                                            <div className='xlearrr'>
                                                Clear Selection
                                            </div>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown> */}
                </div>
              </th>


              {tableOptions["Exp. Closure"] && (
                <th style={{ height: "25px" }}>
                  <div
                    style={{
                      display: "flex",
                      gap: "19px",
                      width: "160px",
                      padding: "8px 12px",
                      // justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        color: "#717789",
                        fontSize: "12px",
                        fontWeight: "500",
                      }}
                    >
                      Exp. Closure
                    </div>
                    <Dropdown autoClose="outside" onToggle={isOpen => (isOpen ? handleDropdownOpen() : handleDropdownClose())}>
                      <Dropdown.Toggle
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <img
                          src={FunnelSimple}
                          className={styles.followUpFilter}
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <div className={styles.datePickerHeading}>
                            Exp. Closure
                          </div>
                          <DateRangePicker
                            locale={enUS}
                            onChange={(item) =>
                              setExpClosureFilter([item.selection])
                            }
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            months={2}
                            ranges={expClosureFilter}
                            direction="horizontal"
                            inputRanges={[]}
                            staticRanges={[]}
                          />
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </th>
              )}

              {tableOptions["Assigned To"] && (
                <th style={{ height: "25px" }}>
                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                      width: "154px",
                      padding: "8px 12px",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        color: "#717789",
                        fontSize: "12px",
                        fontWeight: "500",
                        paddingLeft: "14px",
                      }}
                    >
                      Assigned To
                    </div>
                    <Dropdown autoClose="outside" onToggle={isOpen => (isOpen ? handleDropdownOpen() : handleDropdownClose())}>
                      <Dropdown.Toggle
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <img
                          onClick={() => {
                            setSearchQuery("");
                          }}
                          src={selectedAssignedTo?.length > 0 ? filterGreyFill : FunnelSimple}
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        className="frstttry2"
                        style={{
                          overflow: "scroll",
                          maxHeight: "300px",
                          width: "194px",
                        }}
                      >
                        <Dropdown.Item
                          className="togyy"
                          style={{ position: "sticky", top: 0 }}
                        >
                          <div className="maininput">
                            <input
                              className="inputs"
                              placeholder="Search"
                              value={searchQuery}
                              onChange={handleSearchChange}
                            />
                            <img src={MagnifyingGlass} />
                          </div>
                        </Dropdown.Item>
                        <div style={{ maxHeight: "140px", overflowY: "scroll" }}>
                          {uniqueAssignedTo.map((fullName, index) => (
                            <Dropdown.Item
                              key={index}
                              style={{ paddingTop: "3px", paddingBottom: "3px" }}
                              onClick={() => handleAssignedToSelect(fullName)}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div
                                  className={
                                    selectedAssignedTo.includes(fullName)
                                      ? "mainnameGreen"
                                      : "mainname"
                                  }
                                >
                                  {fullName}
                                </div>
                                {selectedAssignedTo.includes(fullName) && (
                                  <img src={greenTick} alt="Selected" />
                                )}
                              </div>
                            </Dropdown.Item>
                          ))}
                        </div>
                        <Dropdown.Item
                          onClick={handleClearAssignTo}
                          style={{
                            background: "white",
                            position: "sticky",
                            bottom: 0,
                            textAlignLast: "end",
                          }}
                        >
                          <div className="xlearrr">Clear Selection</div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </th>
              )}

              {tableOptions["Lead Source"] && (
                <th style={{ height: "25px" }}>
                  <div
                    style={{
                      display: "flex",
                      gap: "23px",
                      width: "150px",
                      padding: "8px 12px",
                      alignItems: "center",
                      // justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        color: "#717789",
                        fontSize: "12px",
                        paddingLeft: "10px",
                        fontWeight: "500",
                      }}
                    >
                      Lead Source
                    </div>
                    <Dropdown autoClose="outside" onToggle={isOpen => (isOpen ? handleDropdownOpen() : handleDropdownClose())}>
                      <Dropdown.Toggle
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <img
                          onClick={() => {
                            setSearchQuery("");
                          }}
                          src={selectedLeadSourceNameType?.length > 0 ? filterGreyFill : FunnelSimple}
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        className="frstttry2"
                        style={{
                          overflow: "scroll",
                          maxHeight: "300px",
                          width: "194px",
                        }}
                      >
                        <Dropdown.Item
                          className="togyy"
                          style={{ position: "sticky", top: 0 }}
                        >
                          <div className="maininput">
                            <input
                              className="inputs"
                              placeholder="Search"
                              value={searchQuery}
                              onChange={handleSearchChange}
                            />
                            <img src={MagnifyingGlass} />
                          </div>
                        </Dropdown.Item>
                        <div style={{ maxHeight: "140px", overflowY: "scroll" }}>
                          {uniqueLeadSources.map((source, index) => (
                            <Dropdown.Item
                              key={index}
                              style={{ paddingTop: "3px", paddingBottom: "3px" }}
                              onClick={() => handleLeadSourceSelect(source)}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div
                                  className={
                                    selectedLeadSourceNameType.includes(source)
                                      ? "mainnameGreen"
                                      : "mainname"
                                  }
                                >
                                  {source}
                                </div>
                                {selectedLeadSourceNameType.includes(source) && (
                                  <img src={greenTick} alt="Selected" />
                                )}
                              </div>
                            </Dropdown.Item>
                          ))}
                        </div>
                        <Dropdown.Item
                          onClick={handleClearLeadSources}
                          style={{
                            background: "white",
                            position: "sticky",
                            bottom: 0,
                            textAlignLast: "end",
                          }}
                        >
                          <div className="xlearrr">Clear Selection</div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </th>
              )}

              {tableOptions["Source Name"] && (
                <th style={{ height: "25px" }}>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      width: "150px",
                      padding: "8px 12px",
                      // justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        color: "#717789",
                        fontSize: "12px",
                        fontWeight: "500",
                        paddingLeft: "8px",
                      }}
                    >
                      Source Name
                    </div>
                    <Dropdown autoClose="outside" onToggle={isOpen => (isOpen ? handleDropdownOpen() : handleDropdownClose())}>
                      <Dropdown.Toggle
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <img
                          onClick={() => {
                            setSearchQuery("");
                          }}
                          src={selectedSourceName?.length > 0 ? filterGreyFill : FunnelSimple}
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        className="frstttry2"
                        style={{
                          overflow: "scroll",
                          maxHeight: "300px",
                          width: "194px",
                        }}
                      >
                        <Dropdown.Item
                          className="togyy"
                          style={{ position: "sticky", top: 0 }}
                        >
                          <div className="maininput">
                            <input
                              className="inputs"
                              placeholder="Search"
                              value={searchQuery}
                              onChange={handleSearchChange}
                            />
                            <img src={MagnifyingGlass} />
                          </div>
                        </Dropdown.Item>
                        <div style={{ maxHeight: "140px", overflowY: "scroll" }}>
                          {uniqueSourceName?.map((data, index) => (
                            <Dropdown.Item
                              key={index}
                              style={{ paddingTop: "3px", paddingBottom: "3px" }}
                              onClick={() => handleSourceNameSelect(data)}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div
                                  className={
                                    selectedSourceName.includes(data)
                                      ? "mainnameGreen"
                                      : "mainname"
                                  }
                                >
                                  {data}
                                </div>
                                {selectedSourceName.includes(data) && (
                                  <img src={greenTick} alt="Selected" />
                                )}
                              </div>
                            </Dropdown.Item>
                          ))}
                        </div>
                        <Dropdown.Item
                          onClick={handleClearSourceName}
                          style={{
                            background: "white",
                            position: "sticky",
                            bottom: 0,
                            textAlignLast: "end",
                          }}
                        >
                          <div className="xlearrr">Clear Selection</div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </th>
              )}
              <th style={{ width: "20px" }}></th>
              {/* {tableOptions["Tags"] && (
              <th>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    width: "115px",
                    padding: "8px 12px",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      color: "#717789",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                  >
                    Tags
                  </div>
                  <img src={FunnelSimple} />
                </div>
              </th>
            )} */}
            </tr>
          </div>
          {/* className="offcanvasBodyyy"  */}
          <div style={{
            height: "63vh",
            overflow: "scroll"
          }}>
            {console.log(filteredLeadsNew?.length, "okaish")}
            {filteredLeadsNew.length > 0 ? (
              filteredLeadsNew?.map((data, i) => {
                const { textColor, backgroundColor } = assignColor(data?.leadStage?.stageName);
                return (
                  <tr
                    key={i}
                    className="tablekirow"
                    onClick={showOffcanvasHandler.bind(null, data._id, data)}
                    style={{ cursor: (data?.leadStage?.stageName == "Won") ? "default" : "pointer" }}
                  >

                    <td className="tableKaPart">
                      <div
                        className="inner_checkbox"
                        style={{
                          display: "flex",
                          gap: "10px",
                          width: "65px",
                          padding: "8px 10px",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <input
                          id="selectLead"
                          type="checkbox"
                          style={{ height: "12px", width: "12px" }}
                          checked={selectedLeads.indexOf(data._id) !== -1 && true}
                          onChange={(e) => {
                            e.stopPropagation();
                            selectLeadHandler(e.target.checked, data._id);
                            setOpenOffcanvas(false);
                          }}
                        />
                      </div>
                    </td>
                    <td className="tableKaPart">
                      {((data?.type == 1 || data?.type == undefined) && data?.otherContactDetails?.[0]?.otherContactPhoneNumber?.[0]) ?
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id="button-tooltip">
                              <span> {`${capitalizeFirstLetter(data?.otherContactDetails?.[0]?.otherContactFirstName)} ${capitalizeFirstLetter(data?.otherContactDetails?.[0]?.otherContactLastName)}`}</span>
                            </Tooltip>
                          }
                        >
                          <div className="innerdiv" style={{ width: "132px", whiteSpace: "nowrap" }}>
                            <div>
                              {data?.otherContactDetails[0]?.otherContactFirstName && data?.otherContactDetails[0]?.otherContactLastName
                                ? `${capitalizeFirstLetter(data?.otherContactDetails[0]?.otherContactFirstName)} ${capitalizeFirstLetter(data?.otherContactDetails[0]?.otherContactLastName)} -`
                                : data?.otherContactDetails[0]?.otherContactFirstName ? `${capitalizeFirstLetter(data?.otherContactDetails[0]?.otherContactFirstName)}` : "-"}
                            </div>
                            <div>{data?.teamDetails?.name}</div>
                          </div>
                        </OverlayTrigger>
                        :
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id="button-tooltip">
                              <span> {`${capitalizeFirstLetter(data?.firstName)} ${capitalizeFirstLetter(data?.lastName)}`}</span>
                            </Tooltip>
                          }
                        >
                          <div className="innerdiv" style={{ width: "132px", whiteSpace: "nowrap" }}>
                            <div>
                              {data?.firstName && data?.lastName
                                ? `${capitalizeFirstLetter(data?.firstName)} ${capitalizeFirstLetter(data?.lastName)}`
                                : data?.firstName ? `${capitalizeFirstLetter(data?.firstName)}` : "-"}
                            </div>

                            <div>{data?.teamDetails?.name}</div>
                          </div>
                        </OverlayTrigger>
                      }
                    </td>

                    {tableOptions["Last Call Status"] && (
                      <td className="tableKaPart">
                        <div className="innerdiv" style={{ width: "135px" }}>
                          {data?.leadStatus && data?.leadStage?.status ? (
                            // Find the matching status based on leadStatus
                            data?.leadStage?.status.find(status => status.id === data?.leadStatus)?.statusName || "-"
                          ) : (
                            "-"
                          )}
                        </div>
                      </td>
                    )}
                    {tableOptions["Follow up"] && (
                      <td className="tableKaPart" style={{ width: "215px" }}>
                        {data?.leadUpdates &&
                          data?.leadUpdates[data?.leadUpdates?.length - 1]
                            ?.updateDate ? (
                          <div
                            className="innerdiv"
                            style={{ textAlign: "center", width: "125px", justifyContent: "space-between", display: "flex" }}
                          >
                            <div style={{ textAlign: "left" }}>
                              <div style={{
                                fontWeight: "600",
                                color: getTextColor(data?.leadUpdates[data?.leadUpdates?.length - 1]?.updateDate),
                              }}>
                                {
                                  data?.latestFollowUpDate && dayjs(data?.latestFollowUpDate, "DD-MM-YYYY").isValid()
                                    ? (() => {
                                      const followUpDate = dayjs(data?.latestFollowUpDate, "DD-MM-YYYY");
                                      const today = dayjs();
                                      if (followUpDate.isSame(today, "day")) {
                                        return "Today";
                                      } else if (followUpDate.isSame(today.subtract(1, "day"), "day")) {
                                        return "Yesterday";
                                      } else if (followUpDate.isSame(today.add(1, "day"), "day")) {
                                        return "Tomorrow";
                                      } else {
                                        return followUpDate.format("DD/MM/YYYY");
                                      }
                                    })()
                                    : "-"
                                }
                              </div>
                              <div style={{
                                fontSize: "10px", fontWeight: "400", color: getTextColor(data?.leadUpdates[data?.leadUpdates?.length - 1]?.updateDate),

                              }}>{
                                  data?.latestFollowUpTime &&
                                    /^([0-9]|0[0-9]|1[0-2]):[0-5][0-9] (AM|PM)$/i.test(data.latestFollowUpTime)
                                    ? data.latestFollowUpTime
                                    : '-'
                                }

                              </div>
                            </div>
                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip id="button-tooltip" className={styles.customtooltip}>
                                  <span>Send Alert</span>
                                </Tooltip>
                              }
                            >
                              <div className={styles.sendAlert}>
                                <PiBellFill />
                                {/* <div className={styles.sendAlertWords}>
                                  Send Alert
                                </div> */}
                              </div>
                            </OverlayTrigger>
                          </div>
                        ) : (
                          <div
                            className={`innerdiv ${styles.followUp}`}
                            onClick={(event) => (
                              event.stopPropagation(), showEmailModalHandler(data)
                            )}
                            style={{ display: "flex", width: "147px", gap: "64px", textAlign: "center" }}
                          >
                            <div>-</div>
                            <div>
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                  <Tooltip id="button-tooltip">
                                    <span>Send Alert</span>
                                  </Tooltip>
                                }
                              >
                                <div className={styles.sendAlert}>
                                  <PiBellFill />
                                  {/* <div className={styles.sendAlertWords}>
                                  Send Alert
                                </div> */}
                                </div>
                              </OverlayTrigger>
                            </div>
                          </div>
                        )}
                      </td>
                    )}
                    {tableOptions["Last Call"] && (
                      <td className="tableKaPart">
                        <div
                          className="innerdiv"
                          style={{
                            width: "195px",
                            display: "flex",
                            gap: "4px",
                            paddingLeft: "28px",
                            alignItems: "center",
                            // justifyContent: "center",
                          }}
                        >
                          {data?.updatedAt ? formateDate(data?.updatedAt) : "-"}
                          <div
                            className={styles.viewRemark}
                            onClick={(e) => (
                              e.stopPropagation(),
                              setRemarkData(data),
                              setRemarkModal(true)
                            )}
                          >
                            <PiChatText className={styles.viewRemarkIcon} />
                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip id="button-tooltip">
                                  <span>View remark</span>
                                </Tooltip>
                              }
                            >
                              <div className={styles.viewRemarkWords}>
                                <PiChatTextFill />
                                {/* View remark */}
                              </div>
                            </OverlayTrigger>
                          </div>
                        </div>
                      </td>
                    )}
                    <td className="tableKaPart">
                      {((data?.type == 1 || data?.type == undefined) && data?.otherContactDetails?.[0]?.otherContactPhoneNumber?.[0]) ?
                        <div className="innerdiv" style={{ paddingLeft: "28px", width: "201px" }}>
                          {data?.otherContactDetails[0]?.otherContactPhoneNumber[0] ? data?.otherContactDetails[0]?.otherContactPhoneNumber[0] : "-"}
                        </div>
                        : <div className="innerdiv" style={{ paddingLeft: "28px", width: "201px" }}>
                          {data?.phoneNumber ? data?.phoneNumber : "-"}
                        </div>}
                    </td>
                    <td className="tableKaPart">
                      {((data?.type == 1 || data?.type == undefined) && data?.otherContactDetails?.[0]?.otherContactPhoneNumber?.[0]) ?
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id="button-tooltip">
                              <span> {data?.otherContactDetails[0]?.otherContactEmailId ? data?.otherContactDetails[0]?.otherContactEmailId : "-"}</span>
                            </Tooltip>
                          }
                        >
                          <div className="innerdiv" style={{ paddingLeft: "28px", width: "150px", paddingRight: "12px" }} >
                            {data?.otherContactDetails[0]?.otherContactEmailId ? data?.otherContactDetails[0]?.otherContactEmailId : "-"}
                          </div>
                        </OverlayTrigger>
                        :
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id="button-tooltip">
                              <span> {data?.email ? data?.email : "-"}</span>
                            </Tooltip>
                          }
                        >
                          <div className="innerdiv" style={{ paddingLeft: "28px", width: "150px", paddingRight: "12px" }} >
                            {data?.email ? data?.email : "-"}
                          </div>
                        </OverlayTrigger>}
                    </td>
                    {tableOptions["City"] && (
                      <td className="tableKaPart">
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id="button-tooltip">
                              <span> {data?.city ? data?.city : "-"}</span>
                            </Tooltip>
                          }
                        >
                          <div className="innerdiv" style={{ paddingLeft: "32px", width: "110px", paddingRight: "5px" }}>
                            {data?.city ? data?.city : "-"}
                          </div>
                        </OverlayTrigger>
                      </td>)}
                    {tableOptions["Address"] && (
                      <td className="tableKaPart">
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id="button-tooltip">
                              <span> {data?.address ? data?.address : "-"}</span>
                            </Tooltip>
                          }
                        >
                          <div className="innerdiv" style={{ paddingLeft: "28px", width: "110px", paddingRight: "5px" }}>
                            {data?.address ? data?.address : "-"}
                          </div>
                        </OverlayTrigger>
                      </td>
                    )}
                    {tableOptions["Budget"] && (
                      <td className="tableKaPart">
                        <div className="innerdiv" style={{ paddingLeft: "28px", width: "112px" }}>
                          {typeof data?.budget === "number" ? formatCurrency(data.budget) : data?.budget || "-"}
                        </div>

                      </td>
                    )}
                    {tableOptions["Work Scope"] && (<td className="tableKaPart">
                      {/* {((data?.type == 1 || data?.type == undefined) && data?.otherContactDetails?.[0]?.otherContactPhoneNumber?.[0]) ? */}
                      <div className="innerdiv" style={{ paddingLeft: "28px", width: "140px" }}>
                        {data?.workType ? data?.workType : data?.requirements ? data?.requirements : "-"}
                      </div>
                      {/* : */}
                      {/* <div className="innerdiv" style={{ width: "140px" }}>
                          {data?.requirements ? data?.requirements : "-"}
                        </div> */}
                      {/* } */}
                    </td>
                    )}
                    {tableOptions["Area"] && (
                      <td className="tableKaPart">
                        <div className="innerdiv" style={{ paddingLeft: "28px", width: "128px", paddingLeft: "28px" }}>
                          <span>
                            {data?.area && /\d/.test(data?.area) ? data?.area : "-"}
                          </span>

                        </div>
                      </td>
                    )}
                    <td className="tableKaPart" style={{ width: "200px" }}>
                      {/* {data?.leadStatus === 1 ? (
                      <div className="bluechipss">New Lead</div>
                    ) : data?.leadStatus === 3 ? (
                      <div className="intersted">Interested</div>
                    ) : data?.leadStatus === 2 ? (
                      <div className="FollowUp">Follow Up</div>
                    ) : data?.leadStatus === 5 ? (
                      <div className="notintersted">Not Interested</div>
                    ) : data?.leadStatus === 6 ? (
                      <div className="nores">No Response</div>
                    ) : (
                      <div className="OnHold">On Hold</div>
                    )} */}
                      <div
                        style={{
                          textAlign: "center",
                          display: "flex",
                          width: "202px",
                          alignItems: "center",
                          justifyContent: "left",
                          gap: "8px",
                          padding: "16px 14px",
                        }}
                      >
                        {data?.leadStage?.stageName ? (
                          <div
                            style={{
                              color: textColor,
                              backgroundColor: backgroundColor,
                              padding: "6px 8px",
                              borderRadius: "4px",
                            }}
                          >
                            {data?.leadStage?.stageName}
                          </div>
                        ) : (
                          <div>-</div>
                        )}

                        {data?.leadTag?.length > 0 && (
                          <div
                            style={{
                              display: "flex",
                              gap: "2px",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <span
                              style={{
                                color: data?.leadTag[0].tagColorCode,
                                padding: "4px 4px",
                              }}
                            >
                              {data?.leadTag[0].tagName}
                            </span>
                            <WTooltip
                              title={<TagList data={data?.leadTag} />}
                              placement="bottom-end"
                            >
                              {data?.leadTag?.length > 1 && (
                                <span style={{ color: "#0084EF" }}>
                                  +{data?.leadTag?.length - 1}
                                </span>
                              )}
                            </WTooltip>
                          </div>
                        )}
                      </div>
                    </td>



                    {tableOptions["Exp. Closure"] && (
                      <td className="tableKaPart">
                        <div
                          className="innerdiv"
                          style={{ width: "185px", textAlign: "center" }}
                        >
                          {data?.expClosureDate ? (
                            formatDate(data?.expClosureDate)
                          ) : (
                            <div
                              className={styles.addClosure}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleContainerClick(data?._id);
                              }}
                            >
                              <PiCalendar className={styles.addClosureIcon} />
                              <div className={styles.addClosureWords}>
                                Select date
                              </div>
                            </div>
                          )}
                          <div onClick={(e) => e.stopPropagation()} style={{ position: 'relative' }}>
                            {/* <DatePickerWrapper
                            id={data?._id}
                            value={addExpClosure[data?._id]}
                            onChange={handleDateChange}
                            open={openPicker === data?._id} // Open if it matches the id
                            onOpenChange={(status) => {
                              if (!status) {
                                setOpenPicker(null);
                              }
                            }}
                          /> */}
                            <DatePicker
                              className={styles.datePicker}
                              open={openPicker === data?._id}
                              onOpenChange={(status) => {
                                if (!status) {
                                  setOpenPicker(null);
                                }
                              }}
                              // value={value}
                              onChange={(date, dateString) => {
                                onChange(data?._id, date, dateString);
                              }}
                              style={{ position: 'absolute', top: '100%', left: '0' }} // Adjust as needed
                            />
                          </div>
                        </div>
                      </td>
                    )}
                    {tableOptions["Assigned To"] && (
                      <td className="tableKaPart">
                        <div className="innerdiv" style={{ width: "125px", textAlign: "left", paddingLeft: "13px" }}>
                          {data?.assignedTo?.fullName
                            ? data?.assignedTo?.fullName
                            : "-"}
                        </div>
                      </td>
                    )}
                    {tableOptions["Lead Source"] && (
                      <td className="tableKaPart">
                        <div className="innerdiv" style={{ width: "160px", textAlign: "left", paddingLeft: "37px" }}>
                          {data?.leadSource ? data?.leadSource : "-"}
                          {/* <span>{data?.leadSource && data?.leadSource}</span> */}
                          {/* <span>{data?.sourceName && data?.sourceName}</span> */}
                          {/* <span>
                          {data?.sourceName == undefined &&
                            data?.leadSource == undefined &&
                            "-"}
                        </span> */}
                        </div>
                      </td>
                    )}
                    {tableOptions["Source Name"] && (
                      <td className="tableKaPart">
                        <div className="innerdiv" style={{ width: "130px", display: "flex", justifyContent: "left", paddingLeft: "25px" }}>
                          {data?.sourceName ? data?.sourceName : "-"}
                        </div>
                      </td>
                    )}
                    {/* {tableOptions["Tags"] && (
                    <td className="tableKaPart">
                      <div className="innerdiv" style={{ width: "115px" }}>
                        tagssss
                      </div>
                    </td>
                  )} */}
                    <td>
                      <div
                        style={{
                          padding: "8px 12px",
                          cursor: "pointer",
                          display: "flex", alignItems: "baseline"
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setActive("logHistory");
                          openCommentHandler(e, data?._id);
                        }}
                      >
                        <PiChatTeardropDots />
                        <span style={{ fontSize: "10px" }}>
                          {data?.leadUpdates?.filter(item => item.text)?.length != 0 && data?.leadUpdates?.filter(item => item.text)?.length}
                        </span>
                      </div>
                    </td>
                  </tr>
                )
              })
            ) : (
              <tr>
                <td colSpan="6" style={{ textAlign: "center", padding: "20px" }}>
                  <div style={{ background: "#F5FAFF", marginLeft: "30rem", marginTop: "3rem", width: "185px", height: "149px", padding: "16px", gap: "15px", display: "flex", flexDirection: "column" }}>
                    <div></div>
                    <div style={{ fontSize: "14px", fontWeight: "500" }}>Leads for the selected <br />
                      time, do not exist</div>
                    <div onClick={() => handleFollowUpFilter("All Time")}
                      style={{ fontSize: "12px", fontWeight: "400", color: "#0084EF", textDecoration: "underline", cursor: "pointer" }}
                    >
                      Change Filter to Default
                    </div>
                  </div>
                </td>
              </tr>
            )}
          </div>

        </table>
        {/* </Scrollbar> */}
      </div>
      <Modal
        show={remarkModal}
        onHide={() => setRemarkModal(false)}
        animation={false}
        backdropClassName="appModal"
        size="md"
        id="em"
        centered
      >
        <Modal.Header
          style={{
            width: "100",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span className="remarkModalHeader">Last remark</span>
          <RxCross1
            style={{ cursor: "pointer" }}
            onClick={() => setRemarkModal(false)}
          />
        </Modal.Header>
        <Modal.Body
          style={{
            display: "flex",
            padding: "4%",
            flexDirection: "column",
          }}
        >
          <div>
            {remarkData?.leadUpdates?.length > 0
              ? <div style={{ display: "flex", gap: "12px", alignItems: "center" }}>
                <span className="lastRemarkDate">
                  {remarkData?.leadUpdates?.length > 0
                    ? new Date(
                      remarkData.leadUpdates[
                        remarkData.leadUpdates?.length - 1
                      ].createdDate
                    ).toLocaleDateString("en-GB")
                    : "-"}
                </span>
                <div className="lastRemarkStatus">
                  {remarkData?.leadUpdates?.length > 0
                    ? remarkData.leadUpdates[remarkData.leadUpdates.length - 1]
                      .callLeadStatus === 1
                      ? "New Lead"
                      : remarkData.leadUpdates[remarkData.leadUpdates.length - 1]
                        .callLeadStatus === 3
                        ? "Interested"
                        : remarkData.leadUpdates[remarkData.leadUpdates.length - 1]
                          .callLeadStatus === 2
                          ? "Follow Up"
                          : remarkData.leadUpdates[remarkData.leadUpdates.length - 1]
                            .callLeadStatus === 5
                            ? "Not Interested"
                            : remarkData.leadUpdates[remarkData.leadUpdates.length - 1]
                              .callLeadStatus === 6
                              ? "No Response"
                              : "On Hold"
                    : "On Hold"}
                </div>
              </div>
              : <div>No Remarks yet</div>}
          </div>

          <div className="remarkData">
            {remarkData?.leadUpdates?.length > 0
              ? remarkData?.leadUpdates[remarkData?.leadUpdates?.length - 1]
                .text
                ? remarkData?.leadUpdates[remarkData?.leadUpdates?.length - 1]
                  .text
                : "No text available"
              : "-"}
          </div>
        </Modal.Body>
        {/* <Modal.Footer style={{ borderTop: "none", marginTop: "-1%" }}>
                  <button
                    className={styles.cancelTimelineButton}
                    onClick={() => setRemarkModal(false)}
                  >
                   Cancel
                  </button>
                  
                 
                </Modal.Footer> */}
      </Modal>
    </div>
  );
};

export default LeadTableV2;
