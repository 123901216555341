import React from "react";
import styles from "./AdminPersonalData.module.css";
// import icon from "../footerImg/file/Vector.svg";
// import icon1 from "../footerImg/task/Vector.png";
// import icon2 from "../footerImg/reports/Vector.png";
import { FaArrowLeft } from "react-icons/fa6";
import { useState } from "react";
import {
  TextField,
  useMediaQuery,
  FormControl,
  InputLabel,
  MenuItem,
  InputAdornment,
  Autocomplete,
} from "@mui/material";
import { getReq, postTReq } from "../../piservices/apis";
import { apiEndpoints } from "../../piservices/enums";
import { Carousel } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import {
  checkIfHasOnlyNumbers,
  validationFunction,
} from "../../piservices/commonService";
import expf, {
  constants,
} from "../../piservices/constants";
import idesign from "../../Assets/logo.png";
import MuiPhoneNumber from "material-ui-phone-number";
import { useEffect } from "react";
import { getToken } from "../../piservices/authService";
import axios from "axios";
import { Country, State, City } from "country-state-city";
import { useLocation } from "react-router-dom";
import { countryFlags } from "../MockDataCountries";
import Select from "react-select";
import { AiOutlineArrowLeft } from "react-icons/ai";

const AdminPersonalData = () => {
  const [countryDialCode, setCountryDialCode] = useState("");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryString = window.location.search;
  const hasQueryParams = queryString !== "";
  const adminPersonalDataValObj = {
    fname: {
      required: true,
    },
    lName: {
      required: true,
    },
    address: {
      required: true,
    },
    city: {
      required: true,
    },
    zipCode: {
      required: true,
      minLength: 6,
      maxLength: 6,
    },
    birth: {
      required: true,
    },
    number: {
      required: true,
    },
  };
  
  if (hasQueryParams) {
    localStorage.setItem("token", queryParams.get("token"));
    localStorage.setItem("userId", queryParams.get("userId"));
    localStorage.setItem("hasQueryParams", "yes");
  }

  useEffect(() => {
    const url = window.location.href;
    const urlParts = url.split("/");
    const lastSegment = urlParts[urlParts.length - 1];
    localStorage.setItem("currentPageUrl", lastSegment);
  }, []);
  const numberArray = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

  const [selectedCountry, setSelectedCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [currency, setCurrency] = useState("");
  const [particularCountryData, setParticularCountryData] = useState({});
  const [phoneCode, setPhoneCode] = useState("us");

  const today = new Date().toISOString().split("T")[0];
  const isMobile = useMediaQuery("(max-width:767px)");
  const navigateTo = useNavigate();
  // const [error, setError] = useState({});
  const [error, setError] = useState({
    fname: false,
    lName: false,
    address: false,
    city: false,
    zipCode: false,
    birth: false,
    number: false,
    email: false,
  });

  const [input, setinput] = useState({
    fname: "",
    lName: "",
    address: "",
    city: "",
    zipCode: "",
    birth: "",
    number: "",
    email: "",
    countryCode: "",
  });
  const [errormsg, setErrorMsg] = useState("");
  const [cityForMap, setCityForMap] = useState("Delhi");
  const [profileData, setProfileData] = useState({});
  const [phoneNumberErr, setPhoneNumberErr] = useState("err");

  const handleSetCountryFromDropdown = (countryName) => {
    // Find the entry corresponding to the provided country name
    const countryEntry = Object.entries(countryFlags).find(
      ([country, _]) => country?.toLowerCase() === countryName?.toLowerCase()
    );
    if (countryEntry) {
      const [_, { code, countrycode, currency }] = countryEntry;
      // Update the state with the code, countrycode, and currency
      setCountryFromDropdown((prevState) => ({
        ...prevState,
        value: {
          ...prevState.value,
          country: countryEntry[0],
          code: countryEntry[1].code,
          countrycode: countryEntry[1].countrycode,
          currency: countryEntry[1].currency,
        },
      }));
      setCountryDialCode(countryEntry[1].code);
    } else {
      // Handle case where country name is not found
      console.log(`Country '${countryName}' not found`);
    }
  };

  // useEffect(() => {
  //   handleSetCountryFromDropdown(localStorage.getItem("NameCountry"))
  // }, [])

  // useEffect(() => {
  //   const fetchCountries = async () => {
  //     const countryData = await Country.getAllCountries();
  //     setCountries(countryData);
  //     // const initialCountryIsoCode = "IN";
  //     // setSelectedCountry(initialCountryIsoCode);
  //   };
  //   fetchCountries();
  // }, []);

  const [countryFromDropdown, setCountryFromDropdown] = useState({
    value: {
      country: "",
      code: "",
      countrycode: "",
      currency: "",
    },
  });

  const handleCountryDropdown = (selectedOption) => {
    localStorage.setItem("NameCountry", selectedOption?.value?.country);

    setCountryFromDropdown(selectedOption);
    setCountryDialCode(selectedOption?.value?.code);
    setCurrency(selectedOption?.value?.currency);
    setinput((prev) => {
      return {
        ...prev,
        country: selectedOption?.value?.country,
      };
    });
  };

  const defaultCountry = {
    label: countryFromDropdown.value.country,
    value: {
      country: countryFromDropdown.value.country,
      code: countryFromDropdown.value.code,
      countrycode: countryFromDropdown.value.countrycode,
      currency: countryFromDropdown.value.currency,
    },
  };

  useEffect(() => {
    localStorage.setItem("countryCode", "US");
    localStorage.setItem("city", cityForMap);
  }, []);

  const getParticularCountryData = async (countryCode) => {
    const data = await Country.getCountryByCode(countryCode);
    setinput((prev) => {
      return {
        ...prev,
        country: data?.name,
      };
    });
    setCurrency(data?.currency);
    setCountryDialCode(data?.phonecode);
    setPhoneCode(data?.isoCode?.toLowerCase());
    setParticularCountryData(data);
  };
  const handleCountryChange = async (countryCode) => {
    setSelectedCountry(countryCode);
    localStorage.setItem("countryCode", countryCode);
  };

  const getProfileData = async () => {
    const res = await getReq(
      `${constants.CRM_API}/user/profile`,
      {},
      { Authorization: `Bearer ${getToken()}` }
    );
    if (res && !res.error) {
      setProfileData({ ...res.data.data });
    } else {
      console.log(res.error);
    }
  };

  useEffect(() => {
    getProfileData();
  }, []);

  function formatDate(dateString) {
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 to month index to make it 1-based
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    //   if(profileData?.countryCode){
    //     if (profileData?.countryCode !== "") {
    //       setCountryDialCode(profileData?.countryCode)
    //     } else if (
    //       profileData?.countryCode == ""
    //     ) {
    //       setCountryDialCode("+1")
    //     }
    //   }
    // else{
    //     setCountryDialCode("+1")
    //   }

    if (profileData) {
      if (profileData?.CompanySettingDetails) {
        if (profileData?.CompanySettingDetails?.countryCode !== "") {
          setCountryDialCode(profileData?.CompanySettingDetails?.countryCode);
        }
      }
      if (profileData?.fullName) {
        const fullNameParts = profileData?.fullName.split(" ");
        const first = fullNameParts[0];
        const last = fullNameParts.slice(1).join(" ");
        setinput((prev) => ({
          ...prev,
          // fname: first,
          // lName:last,
          fname: first.charAt(0).toUpperCase() + first.slice(1),
          lName: last.charAt(0).toUpperCase() + last.slice(1) || "",
        }));
        // setinput((prev) => {
        //   return {
        //     ...prev,
        //     fname: profileData?.fullName.split(" ")[0],
        //     lName: profileData?.fullName.split(" ")[1],
        //   };
        // });
      }

      if (profileData?.phoneNumber) {
        setinput((prev) => {
          return {
            ...prev,
            number: profileData?.phoneNumber,
          };
        });
      }

      if (profileData?.country) {
        localStorage.setItem(
          "NameCountry",
          profileData?.CompanySettingDetails?.country
        );
        setinput((prev) => {
          return {
            ...prev,
            country: profileData?.country,
          };
        });
      }
      if (profileData?.dateOfBirth) {
        setinput((prev) => {
          return {
            ...prev,
            birth: formatDate(profileData?.dateOfBirth),
          };
        });
      }
      if (profileData?.email) {
        localStorage.setItem("adminEmail", profileData?.email);
        setinput((prev) => {
          return {
            ...prev,
            email: profileData?.email,
          };
        });
      }
      if (profileData?.address) {
        setinput((prev) => {
          return {
            ...prev,
            address: profileData?.address,
          };
        });
      }
      if (profileData?.city) {
        setinput((prev) => {
          return {
            ...prev,
            city: profileData?.city,
          };
        });
      }
      if (profileData?.pinCode) {
        setinput((prev) => {
          return {
            ...prev,
            zipCode: profileData?.pinCode,
          };
        });
      }
    }
  }, [profileData]);

  const handleOnboarding = async () => {
    const res = await axios
      .post(
        `${constants.CRM_API}/user/edit-user-details?userId=${localStorage.getItem(
          "userId"
        )}`,
        {
          isOnboardingDone: true,
        }
      )
      .then((res) => {
        localStorage.removeItem("emailId");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function removeSpecialCharacters(inputString) {
    const specialCharsRegex = /[()\-_\W]/g;
    const cleanedString = inputString.replace(specialCharsRegex, "");
    return cleanedString;
  }

  const [firstnameErr, setFirstnameErr] = useState(null);
  const [lastnameErr, setLastnameErr] = useState(null);

  const handleInput = (event) => {
    const { name, value } = event.target;
    const numValue = value?.replace(/[^0-9]/g, "");
    const valText = validationFunction(adminPersonalDataValObj[name], value);
    const alphaValue = value?.replace(/[^a-zA-Z]/g, "");
    const alphaValueSpace = value.replace(/[^a-zA-Z\s]/g, "");

    if (valText) {
      setError((prev) => {
        return {
          ...prev,
          [name]: true,
        };
      });
    } else {
      setError((prev) => {
        return {
          ...prev,
          [name]: false,
        };
      });
    }

    // if (name === "zipCode") {
    //   if(checkIfHasOnlyNumbers(value)){
    //     if (value.length < 7) {
    //       setinput((prev) => {
    //         return {
    //           ...prev,
    //           zipCode: value,
    //         };
    //       });
    //     }
    //   }

    // }
    if (name === "zipCode") {
      if (input.country == "India") {
        if (value.length < 7) {
          setinput((prev) => {
            return {
              ...prev,
              zipCode: numValue,
            };
          });
          setError((prev) => {
            return {
              ...prev,
              zipCode: false,
            };
          });
        }
      } else {
        if (value.length < 10) {
          setinput((prev) => {
            return {
              ...prev,
              zipCode: numValue,
            };
          });
          setError((prev) => {
            return {
              ...prev,
              zipCode: false,
            };
          });
        }
      }
    }

    if (name === "fname") {
      if (value.length < 25) {
        setinput((prev) => {
          return {
            ...prev,
            fname: alphaValue.charAt(0).toUpperCase() + alphaValue.slice(1),
          };
        });
      }
      // error shown
      if (value.length === 24 || value.length === 25) {
        setFirstnameErr("Max character limit has been reached");
      } else {
        setFirstnameErr(null);
      }
    }
    if (name === "lName") {
      if (value.length < 25) {
        setinput((prev) => {
          return {
            ...prev,
            lName: alphaValue.charAt(0).toUpperCase() + alphaValue.slice(1),
          };
        });
      }
      // error shown
      if (value.length === 24 || value.length === 25) {
        setLastnameErr("Max character limit has been reached");
      } else {
        setLastnameErr(null);
      }
    }

    if (name === "address") {
      if (value.length < 151) {
        setinput((prev) => {
          return {
            ...prev,
            address: value.charAt(0).toUpperCase() + value.slice(1),
          };
        });
      }
    }

    if (name === "city") {
      let check = numberArray.some((curelem) => {
        return value.includes(curelem);
      });
      if (!check) {
        if (value.length < 33) {
          setinput((prev) => {
            return {
              ...prev,
              city: value.charAt(0).toUpperCase() + value.slice(1),
            };
          });
        }
      }
    }
    setCityForMap(input.city);

    if (name === "birth") {
      setinput((prev) => {
        return {
          ...prev,
          birth: value,
        };
      });
    }

    //   if (selectedCountry === 'IN') {
    //     if (value.length < 10) {
    //         setPhoneNumberErr('yes err');
    //         setError((prev) => {
    //           return {
    //             ...prev,
    //             number: true,
    //           };
    //         });
    //     } else {
    //         setPhoneNumberErr('');
    //         setError((prev) => {
    //           return {
    //             ...prev,
    //             number: false,
    //           };
    //         });
    //     }
    // } else{
    //   if (value.length < 7) {
    //     setPhoneNumberErr('yes err');
    //     setError((prev) => {
    //       return {
    //         ...prev,
    //         number: true,
    //       };
    //     });
    // } else {
    //     setPhoneNumberErr('');
    //     setError((prev) => {
    //       return {
    //         ...prev,
    //         number: false,
    //       };
    //     });
    // }
    // }

    // if (name === "number") {
    //   const dialCode = "+" + event.target.countryCode.dialCode;
    //   setinput((prev) => {
    //     return {
    //       ...prev,
    //       countryCode: dialCode,
    //     };
    //   });
    //   const trimmedValue = value.replace(dialCode, '');
    //     setinput((prev) => {
    //       return {
    //         ...prev,
    //         // number:trimmedValue,
    //         number:value
    //       };
    //     });
    // }

    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,100}$/;

    if (name === "email") {
      let x = emailRegex.test(value);
      if (!x) {
        setErrorMsg("Invalid Email");
      } else {
        setErrorMsg("");
      }
      setinput((prev) => {
        return {
          ...prev,
          email: value,
        };
      });
      setError((prev) => {
        return {
          ...prev,
          email: false,
        };
      });
    }
  };

  // update profile
  const upDateProfile = async () => {
    const payload = new FormData();
    payload.append("firstName", (input?.fname).trim());
    payload.append("lastName", (input?.lName).trim());
    payload.append("address", input?.address);
    payload.append("city", input?.city);
    payload.append("pinCode", input?.zipCode);
    payload.append("dateOfBirth", input?.birth);
    payload.append("phoneNumber", input?.number);
    // payload.append("phoneNumber", removeSpecialCharacters(input?.number).replace(input?.countryCode.replace('+', ''), ''))
    payload.append("isSuperAdmin", true);
    payload.append("email", input?.email);
    // payload.append("countryCode", input?.countryCode);
    payload.append(
      "countryCode",
      countryDialCode?.startsWith("+") ? countryDialCode : "+" + countryDialCode
    );

    const res = await postTReq(`${constants.CRM_API}/user/updateProfile`, payload);
    if (res && !res.error) {
      // navigateTo("/company-details");
      navigateTo("/team-onboard");
      // setinput({
      //   fname: "",
      //   lName: "",
      //   address: "",
      //   city: "",
      //   zipCode: "",
      //   birth: "",
      //   number: "",
      //   email:""
      // });
    } else {
      console.error(res.error);
    }
  };

  useEffect(() => {
    // localStorage.setItem("countryCode", "US");
    localStorage.setItem("city", cityForMap);
  }, []);

  useEffect(() => {
    localStorage.setItem("currentPageURL", location.pathname);
  }, []);

  const handlePhoneNumberChange = (event) => {
    const inputValue = event.target.value.replace(/[^0-9]/g, "");
    const { country } = input;
    if (inputValue.length <= 15) {
      setinput((prev) => {
        return {
          ...prev,
          number: inputValue,
        };
      });
      if (/^\d+$/.test(inputValue)) {
        if (input.number.length < 5) {
          // if ((countryDialCode === "+91" && inputValue.length !== 10) || (countryDialCode !== "+91" && inputValue.length > 0)) {
          setPhoneNumberErr("yes err");
          setError((prev) => ({
            ...prev,
            number: true,
          }));
        } else {
          setPhoneNumberErr("");
          setError((prev) => ({
            ...prev,
            number: false,
          }));
        }
        setinput((prev) => {
          return {
            ...prev,
            number: inputValue,
          };
        });
      }
      if (input.number.length >= 4) {
        setError((prev) => {
          return {
            ...prev,
            number: false,
          };
        });
      }
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.first_contain}>
          {/* <img src={idesign} alt="" style={{ height: "2rem", position: "absolute", top: "2rem", left: "4rem" }} /> */}
          <div className={styles.logoImgContainer}>
            <img src={idesign} alt="" className={styles.logoImg} />
          </div>
          {/* <Carousel
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "85%",
              margin:"auto auto",
              transform:"scale(0.9)"
            }}
            nextIcon=""
            nextLabel=""
            prevIcon=""
            prevLabel=""
          >
            <Carousel.Item className={styles.CarouselItem}>
            <div className={styles.flexForCarousel}>
            <div style={{ backgroundColor: "#E9EFFB", paddingLeft: "1rem", display: "flex", flexDirection: "column" }}>
                <div className={styles.insideCaraouselItem}>
                  <div style={{display:"flex", flexDirection:"row", alignItems:"center", gap:"0.75rem"}}><img src="https://idesign-office.s3.ap-south-1.amazonaws.com/Group.svg" alt="" className={styles.miniImg} />
                  <div className={styles.heading}>FILE MANAGER</div></div>
                  <div className={styles.para}>Seamlessly upload files and glide through a structured file hierarchy with ease, ensuring your documents are always organised and accessible.</div>
                </div>
              </div>
              <div className={styles.ImgDiv}>
                <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Edit%20location%20%26%20description.png" alt="First slide" className={styles.imgg} />
              </div>
              </div>
              <div className={styles.dotsContainer}>
                   <div className={styles.activeDots} ></div>
                  <div className={styles.notActiveDots}  ></div>
                  <div className={styles.notActiveDots}  ></div>
                  <div className={styles.notActiveDots}  ></div>
                </div>
            </Carousel.Item>

            <Carousel.Item className={styles.CarouselItem}>
            <div className={styles.flexForCarousel}>
            <div style={{ backgroundColor: "#E9EFFB", paddingLeft: "1rem", display: "flex", flexDirection: "column" }}>
                <div className={styles.insideCaraouselItem}>
                <div style={{display:"flex", flexDirection:"row", alignItems:"center", gap:"0.75rem"}}>
                <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/Group1.svg" alt="" className={styles.miniImg} />
                  <div className={styles.heading}>TASK MANAGEMENT</div>
                  </div>
                  <div className={styles.para}>
                  Monitor team progress with Task Manager screen and effortlessly create, assign and update tasks. Also generate detailed reports for informed decision-making.
                  </div>
                </div>
              </div>
              <div className={styles.ImgDiv}>
                <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Table%20v2-1.png" alt="Second slide" className={styles.imgg} />
              </div>
              </div>
              <div className={styles.dotsContainer}>
                  <div className={styles.notActiveDots}  ></div>
                  <div className={styles.activeDots} ></div>
                  <div className={styles.notActiveDots}  ></div>
                  <div className={styles.notActiveDots}  ></div>
                </div>
            </Carousel.Item>
            <Carousel.Item className={styles.CarouselItem}>
            <div className={styles.flexForCarousel}>
            <div style={{ backgroundColor: "#E9EFFB", paddingLeft: "1rem" }}>
                <div className={styles.insideCaraouselItem}>
                <div style={{display:"flex", flexDirection:"row", alignItems:"center", gap:"0.75rem"}}>
                <img
                  src="https://idesign-office.s3.ap-south-1.amazonaws.com/CurrencyDollarSimple.svg"
                  alt="" className={styles.miniImg}
                />
                  <div className={styles.heading}>Quotation</div>
                  </div>
                  <div className={styles.para}>
                  Effortlessly create quotations from an item library. Compare and manage quotations efficiently. Streamline budget creation and purchase orders.
                  </div>
                </div>
              </div>
              <div className={styles.ImgDiv}>
                <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Create%20Quotation.png" alt="Third slide" className={styles.imgg} />
              </div>
              </div>
              <div className={styles.dotsContainer}>
                  <div className={styles.notActiveDots}  ></div>
                  <div className={styles.notActiveDots}  ></div>
                  <div className={styles.activeDots} ></div>
                  <div className={styles.notActiveDots}  ></div>
                </div>
            </Carousel.Item>

            <Carousel.Item className={styles.CarouselItem}>
            <div className={styles.flexForCarousel}>
            <div style={{ backgroundColor: "#E9EFFB", paddingLeft: "1rem", display: "flex", flexDirection: "column" }}>
                <div className={styles.insideCaraouselItem}>
                <div style={{display:"flex", flexDirection:"row", alignItems:"center", gap:"0.75rem"}}>
                <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/LineSegments.svg" alt="" className={styles.miniImg} />
                  <div className={styles.heading}>Timeline</div>
                  </div>
                  <div className={styles.para}>
                  Easily create and allocate hours with Timesheets. Track project progress with multiple views. Create detailed timelines with predecessors, subtasks, and checklists.
                  </div>
                </div>
              </div>
              <div className={styles.ImgDiv}>
                <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/For%20onb%201.png" alt="Third slide" className={styles.imgg} />
              </div>
              </div>
              <div className={styles.dotsContainer}>
                  <div className={styles.notActiveDots}  ></div>
                  <div className={styles.notActiveDots}  ></div>
                  <div className={styles.notActiveDots}  ></div>
                  <div className={styles.activeDots} ></div>
                </div>
            </Carousel.Item>
          </Carousel>   */}
          <Carousel
            className={styles.carouselMainContainer}
            nextIcon=""
            nextLabel=""
            prevIcon=""
            prevLabel=""
          >
            <Carousel.Item className={styles.CarouselItem}>
              <div className={styles.flexForCarousel}>
                <div
                  style={{
                    backgroundColor: "#E9EFFB",
                    paddingLeft: "1rem",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div className={styles.insideCaraouselItem}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "0.75rem",
                      }}
                    >
                      <img
                        src="https://idesign-office.s3.ap-south-1.amazonaws.com/Group.svg"
                        alt=""
                        className={styles.miniImg}
                      />
                      <div className={styles.heading}>FILE MANAGER</div>
                    </div>
                    <div className={styles.para}>
                      Seamlessly upload files and glide through a structured
                      file hierarchy with ease, ensuring your documents are
                      always organised and accessible.
                    </div>
                  </div>
                </div>
                <div className={styles.ImgDiv}>
                  <img
                    src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Edit%20location%20%26%20description.png"
                    alt="First slide"
                    className={styles.imgg}
                  />
                </div>
              </div>
              <div className={styles.dotsContainer}>
                <div className={styles.activeDots}></div>
                <div className={styles.notActiveDots}></div>
                <div className={styles.notActiveDots}></div>
                <div className={styles.notActiveDots}></div>
              </div>
            </Carousel.Item>

            <Carousel.Item className={styles.CarouselItem}>
              <div className={styles.flexForCarousel}>
                <div
                  style={{
                    backgroundColor: "#E9EFFB",
                    paddingLeft: "1rem",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div className={styles.insideCaraouselItem}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "0.75rem",
                      }}
                    >
                      <img
                        src="https://idesign-office.s3.ap-south-1.amazonaws.com/Group1.svg"
                        alt=""
                        className={styles.miniImg}
                      />
                      <div className={styles.heading}>TASK MANAGEMENT</div>
                    </div>
                    <div className={styles.para}>
                      Monitor team progress with Task Manager screen and
                      effortlessly create, assign and update tasks. Also
                      generate detailed reports for informed decision-making.
                    </div>
                  </div>
                </div>
                <div className={styles.ImgDiv}>
                  <img
                    src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Table%20v2-1.png"
                    alt="Second slide"
                    className={styles.imgg}
                  />
                </div>
              </div>
              <div className={styles.dotsContainer}>
                <div className={styles.notActiveDots}></div>
                <div className={styles.activeDots}></div>
                <div className={styles.notActiveDots}></div>
                <div className={styles.notActiveDots}></div>
              </div>
            </Carousel.Item>
            <Carousel.Item className={styles.CarouselItem}>
              <div className={styles.flexForCarousel}>
                <div
                  style={{ backgroundColor: "#E9EFFB", paddingLeft: "1rem" }}
                >
                  <div className={styles.insideCaraouselItem}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "0.75rem",
                      }}
                    >
                      <img
                        src="https://idesign-office.s3.ap-south-1.amazonaws.com/CurrencyDollarSimple.svg"
                        alt=""
                        className={styles.miniImg}
                      />
                      <div className={styles.heading}>Quotation</div>
                    </div>
                    <div className={styles.para}>
                      Effortlessly create quotations from an item library.
                      Compare and manage quotations efficiently. Streamline
                      budget creation and purchase orders.
                    </div>
                  </div>
                </div>
                <div className={styles.ImgDiv}>
                  <img
                    src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Create%20Quotation.png"
                    alt="Third slide"
                    className={styles.imgg}
                  />
                </div>
              </div>
              <div className={styles.dotsContainer}>
                <div className={styles.notActiveDots}></div>
                <div className={styles.notActiveDots}></div>
                <div className={styles.activeDots}></div>
                <div className={styles.notActiveDots}></div>
              </div>
            </Carousel.Item>

            <Carousel.Item className={styles.CarouselItem}>
              <div className={styles.flexForCarousel}>
                <div
                  style={{
                    backgroundColor: "#E9EFFB",
                    paddingLeft: "1rem",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div className={styles.insideCaraouselItem}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "0.75rem",
                      }}
                    >
                      <img
                        src="https://idesign-office.s3.ap-south-1.amazonaws.com/LineSegments.svg"
                        alt=""
                        className={styles.miniImg}
                      />
                      <div className={styles.heading}>Timeline</div>
                    </div>
                    <div className={styles.para}>
                      Easily create and allocate hours with Timesheets. Track
                      project progress with multiple views. Create detailed
                      timelines with predecessors, subtasks, and checklists.
                    </div>
                  </div>
                </div>
                <div className={styles.ImgDiv}>
                  <img
                    src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/For%20onb%201.png"
                    alt="Third slide"
                    className={styles.imgg}
                  />
                </div>
              </div>
              <div className={styles.dotsContainer}>
                <div className={styles.notActiveDots}></div>
                <div className={styles.notActiveDots}></div>
                <div className={styles.notActiveDots}></div>
                <div className={styles.activeDots}></div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>

        {/* second div */}
        <div className={styles.second_contain}>
          <div className={styles.PersonalContent_div}>
            {/* <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            > */}
            <div className={styles.personalDetailsDiv}>
              <div className={styles.personal_head}>Personal Details</div>
              <button
                className={styles.backBtn}
                onClick={() => {
                  // navigateTo("/personal-data")
                  // localStorage.removeItem("firstTimeMap")
                  localStorage.setItem("editCompany",true)
                  navigateTo("/company-details");
                }}
              >
                <AiOutlineArrowLeft className={styles.backArrow} />
                Go back
              </button>
              {/* <button className={styles.backBtn} onClick={() => navigateTo("/sign-up")}>
                <FaArrowLeft />
                Go Back
              </button> */}
              {/* <div className={styles.skipShowHide}>
              <a
               href={'/company-details'}
               //  href={`${constants. BASE_URL}/admin/`}
               className={styles.skip} style={{ textDecoration: "none" }}
               onClick={()=>handleOnboarding()}>
              Skip
            </a>
            </div> */}
            </div>

            {/* all inputs div */}
            <div
              style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
            >
              {/* <div style={{ display: "flex", gap: "1rem" }}> */}
              <div className={styles.nameWrapper}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <TextField
                    id="outlined-basic"
                    error={error.fname}
                    label="First Name*"
                    variant="outlined"
                    className="saas-form"
                    type="text"
                    name="fname"
                    value={input.fname}
                    onChange={handleInput}
                    // style={{ width: "50%" }}
                    // sx={{
                    //   width: "200%",
                    //   "@media (max-width: 600px)": { width: "100%" },
                    // }}
                    // style={{ borderColor: input.fname === "" ? "1px solid red" : "" }}
                    InputProps={{ style: { borderRadius: "12px" } }}
                    inputProps={{
                      style: { fontSize: isMobile ? "14px" : "18px" },
                    }}
                  />
                  {firstnameErr && (
                    <span
                      style={{
                        color: "red",
                        fontSize: "10px",
                        marginLeft: "5px",
                      }}
                    >
                      {firstnameErr}
                    </span>
                  )}
                </div>

                <div style={{ display: "flex", flexDirection: "column" }}>
                  <TextField
                    id="outlined-basic"
                    error={error.lName}
                    label="Last Name*"
                    variant="outlined"
                    className="saas-form"
                    type="text"
                    name="lName"
                    value={input.lName}
                    onChange={handleInput}
                    // style={{ width: "50%" }}
                    // sx={{
                    //   width: "200%",
                    //   "@media (max-width: 600px)": { width: "100%" },
                    // }}
                    inputProps={{
                      style: { fontSize: isMobile ? "14px" : "18px" },
                    }}
                    InputProps={{ style: { borderRadius: "12px" } }}
                  />
                  {lastnameErr && (
                    <span
                      style={{
                        color: "red",
                        fontSize: "10px",
                        marginLeft: "5px",
                      }}
                    >
                      {lastnameErr}
                    </span>
                  )}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <TextField
                  id="outlined-basic"
                  error={error.email}
                  label="Email*"
                  variant="outlined"
                  className="saas-form"
                  type="text"
                  name="email"
                  value={input.email}
                  // value={profileData.email}
                  onChange={handleInput}
                  fullWidth
                  disabled
                  inputProps={{
                    style: { fontSize: isMobile ? "14px" : "18px" },
                  }}
                  style={{ borderRadius: "12px" }}
                  InputProps={{
                    style: { borderRadius: "12px" },
                    inputMode: "text",
                  }}
                />
                {errormsg.length > 0 ? (
                  <div
                    style={{
                      color: "red",
                      fontSize: "10px",
                      marginTop: "-15px",
                      marginLeft: "10px",
                    }}
                  >
                    {errormsg}
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div>
                <TextField
                  id="outlined-basic"
                  // error={error.address}
                  label="Address"
                  variant="outlined"
                  className="saas-form"
                  type="text"
                  name="address"
                  value={input.address}
                  onChange={handleInput}
                  style={{ borderRadius: "12px", width: "100%" }}
                  InputProps={{ style: { borderRadius: "12px" } }}
                  inputProps={{
                    style: { fontSize: isMobile ? "14px" : "18px" },
                  }}
                />
              </div>

              {/* <div style={{ display: "flex", gap: "1rem", position: "relative" }}> */}
              {/* <div className={styles.cityContainer}>
              <FormControl sx={{borderRadius: "12px", width:"33%",'@media (max-width: 600px)': { width: '100%'} }} >
                          <InputLabel id="demo-simple-select-helper-label" >Country</InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={selectedCountry} 
                            label="Country"
                             onChange={(e) => {
                                      handleCountryChange(e.target.value);
                                      getParticularCountryData(e.target.value);
                                    }}
                            sx={{borderRadius: "12px",'@media (max-width: 600px)': { width: '100%'} }}
                          >
                                        {countries.map((country) => (
                                  <MenuItem key={country.isoCode} value={country.isoCode}>
                                    {country.name}
                                  </MenuItem>
                                     ))}
                          </Select>                        
                        </FormControl>
                <TextField
                  id="outlined-basic"
                  // error={error.city}
                  label="City"
                  variant="outlined"
                  className="saas-form"
                  type="text"
                  name="city"
                  value={input.city}
                  onChange={handleInput}
                  sx={{ width: "33%",  '@media (max-width: 600px)': { width: '100%', }}}
                  InputProps={{ style: { borderRadius: "12px" } }}
                  inputProps={{style:{ padding:isMobile ? "18px 20px":"18px 24px", fontSize:isMobile?"14px":"18px"}}}
                />
                  
                <TextField
                  id="outlined-basic"
                  // error={error.zipCode}
                  label= {input.country=="India" ? "Pin Code" : "Zip Code"}
                  variant="outlined"
                  className="saas-form"
                  type="text"
                  name="zipCode"
                  value={input.zipCode}
                  onChange={handleInput}
                  sx={{ width: "33%",  '@media (max-width: 600px)': { width: '100%', }}}
                  InputProps={{ style: { borderRadius: "12px" } }}
                  inputProps={{style:{ padding:isMobile ? "18px 20px":"18px 24px", fontSize:isMobile?"14px":"18px"}}}
                />
              </div> */}

              {/* <FormControl sx={{ borderRadius: "12px", width: "33%", '@media (max-width: 600px)': { width: '100%' } }}>
  <Autocomplete
    id="country-autocomplete"
    options={countries}
    autoHighlight
    getOptionLabel={(option) => option.name}
    onChange={(e, newValue) => {
      handleCountryChange(newValue ? newValue.isoCode : ""); // Pass isoCode to handleCountryChange
      getParticularCountryData(newValue ? newValue.isoCode : ""); // Pass isoCode to getParticularCountryData
    }}
    renderInput={(params) => (
      <TextField
      {...params}
      label="Country"
      InputLabelProps={{ ...params.InputLabelProps,
          shrink: true,}}
          InputProps={{
            ...params.InputProps,
            endAdornment: null, // Remove the endAdornment (clear button)
            sx: {
              borderRadius: "12px", // Adjust the border radius here
              '&.MuiOutlinedInput-root': {
                borderRadius: "12px", // Adjust the border radius for the Autocomplete input
              },
            },
          }}
    />
    )}
    PaperProps={{
      style: {
        maxHeight: 300,
      },
    }}
  />
</FormControl> */}
              {/* <div className={styles.cityContainer}>
<div style={{width:"33%"}}>
<Select
                  placeholder=""
                    defaultValue={defaultCountry}
                    value={{
                      label: countryFromDropdown?.value?.country,
                      value: countryFromDropdown?.value?.country,
                    }}
                    options={
                      Object.entries(countryFlags).map(
                        ([country, { code, countrycode, currency }]) => ({
                          label: ` ${country}`,
                          value: { country, code, countrycode, currency },
                        })
                      )
                    }
                    onChange={handleCountryDropdown}
                    required={false}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        border: "1px solid #999999",
                        borderRadius: "12px",
                        padding: "8px",
                        width:"100%"
                      }),
                      menu: (provided, state) => ({
                        ...provided,
                        zIndex: 9999,
                        maxHeight: '225px' ,
                        overflowY: 'auto' 
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state?.isSelected ? '#1976d2' : 'transparent', // Similar to Material-UI TextField selected option color
                        color: state?.isSelected ? '#ffffff' : '#333333', // Similar to Material-UI TextField selected option text color
                      }),
                    }}
                  />
</div>
                <TextField
                  id="outlined-basic"
                  label="City"
                  variant="outlined"
                  className="saas-form"
                  type="text"
                  name="city"
                  value={input.city}
                  onChange={handleInput}
                  // style={{ borderRadius: "12px", width: "50%" }}
                  sx={{ width: "33%", '@media (max-width: 600px)': { width: '100%'}}}
                  InputProps={{ style: { borderRadius: "12px" } }}
                  // inputProps={{style:{ padding:isMobile ? "18px 20px":"18px 24px", fontSize:isMobile?"14px":"18px"}}}
                />

                <TextField
                  id="outlined-basic"
                  // label="Pin Code"
                  label = {input.country=="India" ? "Pin Code" : "Zip Code"}
                  variant="outlined"
                  className="saas-form"
                  type="text"
                  name="zipCode"
                  value={input.zipCode}
                  onChange={handleInput}
                  // style={{ width: "50%" }}
                  sx={{ width: "33%", '@media (max-width: 600px)': { width: '100%'}}}
                  // onKeyDown={handleNumberInput}
                  InputProps={{ style: { borderRadius: "12px" } }}
                />
              </div> */}

              <div style={{ display: "flex", gap: "1rem" }}>
                <TextField
                  id="outlined-basic"
                  // error={error.birth}
                  label="Date of Birth"
                  variant="outlined"
                  className="saas-form"
                  type="date"
                  name="birth"
                  value={input.birth}
                  onChange={handleInput}
                  style={{ borderRadius: "12px", width: "100%" }}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    placeholder: "",
                    style: { borderRadius: "12px" },
                  }}
                  inputProps={{
                    max: today,
                    style: { fontSize: isMobile ? "14px" : "18px" },
                  }}
                />
              </div>

              {/* <div>
                <MuiPhoneNumber
                 error={error.number}
                  fullWidth
                  variant="outlined"
                  label="Contact*"
                  value={input.number}
                  onChange={(newVal, countryCode, formattedValue) => handleInput({ target: { name: "number", value: newVal, countryCode } })}
                  // defaultCountry={"in"}
                  defaultCountry={phoneCode}
                  InputProps={{ placeholder: "", style: { borderRadius: "12px" } }}
                  inputProps={{style:{ padding:isMobile ? "18px 20px":"18px 24px", fontSize:isMobile?"14px":"18px"}}}
                />
              </div> */}
              <TextField
                style={{ borderRadius: "12px" }}
                id="outlined-basic"
                label="Phone number*"
                variant="outlined"
                value={input.number}
                onChange={(e) => handlePhoneNumberChange(e)}
                // error={input.country === "India" ? input.number.length !==10 : input.number.length <1}
                // error={input.country === "India" ? input.number.length !==10 : countryDialCode?.substring(1) === "91" ?  input.number.length !==10 :  input.number.length <1}
                error={error.number}
                // {input.number.length > 0 && input.number.length < 10}
                InputProps={{
                  style: { borderRadius: "12px" },
                  inputMode: "numeric",
                  startAdornment: (
                    <InputAdornment position="start">
                      {countryDialCode?.startsWith("+")
                        ? countryDialCode
                        : "+" + countryDialCode}
                    </InputAdornment>
                  ),
                  inputProps: {
                    style: { fontSize: isMobile ? "14px" : "18px" },
                    maxLength: 15,
                    minLength: 5,
                    // maxLength: countryDialCode=="+91" ? 10 : 15
                    // maxLength: input?.country === "India" ? 10 : countryDialCode?.substring(1) === "91" ? 10 : 15
                    // input?.country === "India"? 10 : 15 ,
                  },
                }}
                fullWidth
              />
            </div>

            <div>
              <button
                className={styles.nextBtn}
                onClick={() => {
                  Object.keys(input).forEach((curElem) => {
                    if (input[curElem]) {
                      setError((prev) => {
                        return {
                          ...prev,
                          [curElem]: false,
                        };
                      });
                    } else {
                      setError((prev) => {
                        return {
                          ...prev,
                          [curElem]: true,
                        };
                      });
                    }
                  });
                  if (
                    input.fname !== "" &&
                    input.lName !== "" &&
                    // && input.address !== ""
                    //  && input.city !== ""
                    //  && input.zipCode !== ""
                    //   && input.birth !== ""
                    input.number !== "" &&
                    input.email !== ""
                  ) {
                    if (input.city !== "") {
                      localStorage.setItem("cityName", input.city);
                    }
                    localStorage.setItem("pincodeValue", input.zipCode);

                    // if(countryDialCode.substring(1)=="91" && input.number.length!==10 ){
                    if (input.number.length < 5) {
                      setError((prev) => {
                        return {
                          ...prev,
                          number: true,
                        };
                      });
                    } else {
                      setError((prev) => {
                        return {
                          ...prev,
                          number: false,
                        };
                      });

                      handleOnboarding();
                      upDateProfile();
                    }
                  }
                }}
              >
                Next
              </button>
            </div>
            {/* <div style={{marginBottom:isMobile?"10px":"0px"}}></div> */}
            {/* <div className={styles.skipBtnInLast}>
            <a 
           href={'/company-details'}
            // href={`${constants. BASE_URL}/admin/`} 
            className={styles.skip} style={{ textDecoration: "none" }}
            onClick={()=>handleOnboarding()}>
              Skip Personal Profile
            </a>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminPersonalData;
