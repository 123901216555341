import React, { useEffect, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { PiCaretDown } from "react-icons/pi";
import "../leadManagementSettings.css";
import axios from "axios";
import { constants } from "../../../../piservices/constants";
import toast from "react-hot-toast";

const AddStatusModal = ({ show, hide, stages }) => {
  const [stage, setStage] = useState({ stageName: "", id: null });
  const [openStages, setOpenStages] = useState(false);
  const [status, setStatus] = useState("");

  useEffect(() => {
    if (show) {
      setStage({ stageName: "", id: null })
      setStatus("");
    }
  }, [show])

  // toggle stage options
  const toggleStages = () => {
    setOpenStages((prev) => !prev);
  };

  // change stage
  const changeStage = (index) => {
    setStage(stages[index]);
    setOpenStages(false);
  };

  // change tag name
  const statusHandler = (e) => {
    const value = e.target.value;
    setStatus(value);
  };

  // save status
  const saveHandler = async () => {
    const obj = { stageId: stage.id, statusName: status };
    if (stage.id && status) {
      try {
        await axios.post(`${constants.CRM_API}/user/add-lead-status?userId=${localStorage.getItem("userId")}`, obj);
        toast.success("Status added successfully!");
        getData();
        hide();
      } catch (err) {
        console.log(err);
      }
    }
  };
  const [stageData,setStageData] = useState();
  const getData = async () => {
    const response = await axios.get(
      `${constants.CRM_API}/user/get-pipeline?userId=${localStorage.getItem(
        "userId"
      )}`
    );
    const data = response.data.data.stages;
    setStageData(data);
  };
  useEffect(() => {
    getData();
  }, [])
  
  return (
    <Modal show={show} onHide={hide} centered>
      {console.log(stageData,"dwgiyda")}
      <Modal.Header className="addStatusHeader">
        <div style={{ fontWeight: "600", fontSize: "18px", color: "#212121" }}>
          Add Status
        </div>
        <div style={{ display: "flex", gap: "12px", alignItems: "center" }}>
          <button onClick={hide} className="addStatusCancel">
            Cancel
          </button>
          <button onClick={saveHandler} className="addStatusSave">
            Save
          </button>
        </div>
      </Modal.Header>
      <Modal.Body
        style={{ display: "flex", flexDirection: "column", gap: "18px" }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <label
            style={{ color: "#191D23", fontWeight: "500", fontSize: "14px" }}
          >
            Select Stage
          </label>
          {console.log(stages,"dwouqdxq")}
          <Dropdown style={{ display: "flex", flexDirection: "column" }}>
            <Dropdown.Toggle className="addStatusStage">
              <div>{stage.stageName ? stage.stageName : " " }</div>
              <PiCaretDown />
            </Dropdown.Toggle>
            {/* {openStages && ( */}
              <Dropdown.Menu className="addStatusStages">
                {stageData?.map((item, index) => (
                  <Dropdown.Item
                    key={index}
                    className="addStatusStageOption"
                    onClick={changeStage.bind(null, index)}
                    disabled={item.status.length >= 8}
                  >
                    {item?.stageName}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            {/* )} */}
          </Dropdown>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <label
            style={{ color: "#191D23", fontWeight: "500", fontSize: "14px" }}
          >
            Status Name
          </label>
          <input
            placeholder="Type here"
            className="addStatusTag"
            value={status}
            onChange={statusHandler}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddStatusModal;
