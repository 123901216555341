import axios from "axios";
import React, { useContext, useRef, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { GrDocumentExcel } from "react-icons/gr";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { IoMdClose } from "react-icons/io";
import expf, { constants } from "../../../piservices/constants";
import importt from "../../Lms/../../Assets/importt.png"
import styles from "../ModuleCssFiles/lms.module.css";
import { notification } from "antd";
import { setFileImportedData, setShowImport } from "../../../Redux/Slices/newLeadSlice";
import { postReq } from "../../../piservices/apis";
const ImportFromCSVModal = ({ show, handleClose ,showImport}) => {
  const addImageRef = useRef();
  // const context = useContext(desquoContext);
  // const {
  //     updateCatsAndInitialDataXLS,
  //     setTabs,
  //     newCats
  // } = context;
  const [leadFirstName, setLeadFirstName] = useState("");
  const [leadLastName, setLeadLastName] = useState("");
  const [leadEmail, setLeadEmail] = useState("");
  const [phoneNumber, setPhonenumber] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [filesobj, setFilesobj] = useState("");
  const [budget, setBudget] = useState();
  const [workScope, setWorkScope] = useState("");
  const [leadAddress, setLeadAddress] = useState("");
  const [leadCity, setLeadCity] = useState("");
  const [leadArea, setLeadArea] = useState("");
  const [leadCityCode, setLeadCityCode] = useState("");
  const [errorPin, setErrorPin] = useState(false);
  const [leadSource, setLeadSource] = useState([]);
  const [selectedSourceName, setSelectedSourceName] = useState("");
  const [screenToShhow,setScreenToShhow]=useState(1)

  const dispatch = useDispatch();
  const [filesobjFromdata, setFilesobjFromdata] = useState("");
  const [loading, setLoading] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);

  const handleFileChange = async (e) => {
   if (e.target.files[0]) {
      const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB
      if (e.target.files[0].size > maxSizeInBytes) {
        alert('File size exceeds the limit of 2 MB. Please choose a smaller file.');
        e.target.value = ''; // Reset the file input to clear the selected file
      } else {

        const files = new FormData();
        files.append("files", e.target.files[0]);
        setFilesobjFromdata(e.target.files[0]);

        setScreenToShhow(2)
        const res = await axios
          .put(`${constants.S3_API}/api/upload`, files)
          .then(function (response) {
            setFilesobj(response?.data?.locations[0]);
            setTimeout(() => {
              setScreenToShhow(3)
          }, 2000);
          })
          .catch(function (error) {
            // console.log(error);
          });
      }


    }
  };

  const handleFullclose = () => {
    setFilesobj("");
    setFilesobjFromdata("");
    dispatch(setShowImport(false));
    setScreenToShhow(1)
    // handleClose();
  };


  const validationRules = {
    "First Name": function (fname) {
      if (fname?.length > 0 && fname?.length <= 24) {
        if (/^[a-zA-Z\s]*$/.test(fname)) {
          setLeadFirstName(fname);
          setFirstNameError(false);
        }
      } else {
        setFirstNameError(true);
        return "First Name is not valid";
      }
    },
    "Last Name": function (lname) {
      if (lname?.length > 0 && lname?.length <= 24) {
        if (/^[a-zA-Z\s]*$/.test(lname)) {
          setLeadLastName(lname);
          setLastNameError(false);
        }
      } else {
        setLastNameError(true);
        return "Last Name is not valid";
      }
    },
    "Email": function (email) {
      const inputValue = email;
      const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i;
      // console.log('regexemail',emailPattern.test(inputValue))
      if (inputValue.length > 0 && inputValue.length < 64 && emailPattern.test(inputValue)) {
        setLeadEmail(inputValue);
        setEmailError(false);
      } else {
        // console.log("Emailllllli", email)
        return "Email is not valid";
      }
    },
    "Phone Number": function (phone) {
      if (phone.toString()?.length == 10 && /^\d+$/.test(phone)) {
        setPhonenumber(phone);

      } else {
        if(phone.toString()?.length > 0){
          return "Phone Number is not valid";
        }
       
      }
    },
    "Budget": function (budget) {
      console.log('budegettt', budget)
      if (budget?.toString()?.length <= 10 && /^\d*$/.test(budget)) {
        setBudget(budget);
      } else {
        return "Budget is not valid";
      }
    },
    "Scope": function (scope) {
      setWorkScope(scope);
    },
    "Address": function (address) {
      setLeadAddress(address);
    },
    "City": function (city) {
      if (city?.length <= 23 && /^[a-zA-Z\s]*$/.test(city)) {
        setLeadCity(city);
      } else {
        return "City Name is not valid";
      }
    },
    "Area": function (area) {
      if (area?.toString()?.length <= 6 && /^\d*$/.test(area)) {
        setLeadArea(area);
      } else {
        return "Area is not valid";
      }
    },
    "Pin Code": function (pinCode) {
      if (pinCode?.toString()?.length > 0 &&pinCode?.toString()?.length <= 6 && /^\d+$/.test(pinCode)) {
        setLeadCityCode(pinCode);

        // if (
        //   /^\d+$/.test(inputValue) &&
        //   inputValue.length >= 6 &&
        //   inputValue.length <= 6
        // ) {
        //   setLeadCityCode(inputValue);
        //   setErrorPin(false);
        // } 
      }
      else {
        if(pinCode?.toString()?.length > 0){
          setErrorPin(true);
          return "Pin Code is not valid";
        }
        
      }
    },
    "Lead Source": function (leadSource) {
      setLeadSource(leadSource);
    },
    "Source Name": function (sourceName) {
      setSelectedSourceName(sourceName);
    },
  };

  const showError = (error) => {
    // console.log(error)
    if (error.response && error.response.data.message) {
      toast.error(error.response.data.message);
      // clg
      // alert(error.response.data.message)
      return;
    }
    // else {
    //   window.location.reload();
    // }
  };

  const saveAllData = async (data) => {
    try {
     await axios
        .post(
          `${constants.CRM_API}/user/save-leads?userId=${localStorage.getItem(
            "userId"
          )}`,
          data
        )
        .then((response) => {
          toast.success("File uploaded successfully!");
          setTimeout(() => window.location.reload(), 1500)
        })
        .catch((error) => toast.error(error));
    } catch (error) {
      showError(error);
      // showError(error);
    }
  };
  const checkValidations = (data, currentIndex) => {
    try {
      for (const [key, value] of Object.entries(data)) {
        if (!(key === "Lead status" || key === "Expected Closure")) {
          const Errormsg = validationRules[key](value);
          if (Errormsg) {
            // console.log(`validation failure message at ${currentIndex}`, Errormsg);
            return `${Errormsg} for ${data["First Name"]} ${data["Last Name"]}`
          }

        }
      }
    } catch (error) {
      throw new Error(
        `Validation failed for index ${currentIndex}: ${error.message}`
      );
    }
  };

  const uploadImportedData = (importedDataFromFile) => {
    let trackIndex = 0;
    for (let i = 0; i < importedDataFromFile.length; i++) {
      try {
        const ErrorMsg = checkValidations(importedDataFromFile[i], i);
        // console.log('ErrorMsg in uploadimporteddata function', ErrorMsg)
        trackIndex = i;
        if (ErrorMsg) {
          notification.error({
            message: ErrorMsg,
            duration: 10,
          });
          break;
        }

      } catch (error) {
        // console.log(`Validation failed for index ${i}: ${error.message}`)
        throw new Error(`Validation failed for index ${i}: ${error.message}`);
      }
    }

    if (trackIndex === importedDataFromFile.length - 1) {
      const formattedData = importedDataFromFile?.map((item) => ({
        name: `${item["First Name"]} ${item["Last Name"]}`,
        firstName: item["First Name"],
        lastName: item["Last Name"],
        email: item["Email"],
        address: item["Address"],
        city: item["City"],
        pinCode: item["Pin Code"],
        phoneNumber: item["Phone Number"],
        budget: item["Budget"],
        requirements: item["Scope"],
        leadSource: item["Lead Source"],
        sourceName: item["Source Name"],
        area: item["Area"],
      }));
      saveAllData(formattedData);
    }
  };
console.log(screenToShhow,"sdfrtg")
  const uploadFile = async () => {
    const file = new FormData();
    setLoading(true);
    // files.append("bucketName", "idesign-quotation")
    file.append("file", filesobjFromdata);
    setScreenToShhow(2)
    await axios
      .post(`${constants.PMT_API}/api/lms/import-lead-csv`, file)
      .then(function (response) {
        uploadImportedData(response.data);
        setLoading(false);
        handleFullclose(); 
         setTimeout(() => {
          setScreenToShhow(3)
      }, 2000);

      })
      .catch(function (error) {
        console.log('errorrr', error)
        toast.error(error.response.data);
        setFilesobj("");
        setFilesobjFromdata("");
        setLoading(false);
        // console.log(error);
      });
  };

  // console.log(newCats)

  return (
    <div>
      <Modal show={showImport} onHide={handleFullclose} centered>
        <Modal.Header closeButton>
          <Modal.Title
            style={{ color: "#40454A", fontSize: "18px", fontWeight: "700" }}
          >
            Import XLS
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className="d-flex w-100 align-items-center justify-content-center flex-column"
          style={{ height: "200px" }}
        >
          <div className="d-flex w-100 align-items-center justify-content-center flex-column h-100 ">
            <input
              type="file"
              className="d-none"
              onClick={(event) => {
                event.target.value = null;
              }}
              accept=".xls, .xlsx, .csv"
              ref={addImageRef}
              onChange={handleFileChange}
            />
          {
    screenToShhow === 1 ? (
        <div className="d-flex w-100 align-items-center justify-content-center flex-column h-100">
            <input
                type="file"
                className="d-none"
                onClick={(event) => {
                    event.target.value = null;
                }}
                accept=".xls, .xlsx, .csv"
                ref={addImageRef}
                onChange={handleFileChange}
            />
            {filesobj ? (
                <div className="d-flex align-items-center">
                    <div
                        className="d-flex align-items-center cursor-pointer"
                        onClick={() => {
                            addImageRef.current.click();
                        }}
                    >
                        <GrDocumentExcel color="green" className="me-1" />
                        <div
                            className="xls-line"
                            style={{ wordBreak: "break-all", color: "#BEBEBE" }}
                        >
                            {filesobj?.split("/")[4]?.slice(0, 40)}
                        </div>
                    </div>
                    <IoMdClose
                        className="ms-1"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            setFilesobj("");
                            setFilesobjFromdata("");
                        }}
                    />
                </div>
            ) : (
                <div style={{ height: "352px" }} className="commmnnn">
                    <div
                        onClick={() => {
                            addImageRef.current.click();
                        }}
                        style={{ cursor: "pointer" ,display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}
                    >
                        <div className="importf">
                            <img src={importt} alt="Import File" />
                        </div>
                        <div className="dragdrop">Drag & drop to upload</div>
                        <div className="browsee">or browse</div>
                    </div>
                </div>
            )}
        </div>
    ) : screenToShhow === 2 ? (
        <div className="d-flex w-100 align-items-center justify-content-center flex-column h-100">
            {console.log(filesobjFromdata, "edwdx")}
            <div
                style={{
                    width: "95%",
                    padding: "1rem",
                    borderRadius: "0.5rem",
                    border: "1px solid #F0F0F0",
                    background: "#FFF",
                }}
            >
                <div
                    className="d-flex align-items-center justify-content-start"
                    style={{ width: "100%" }}
                >
                    {filesobjFromdata?.name || "-"}
                </div>
                <div
                    className="d-flex align-items-center justify-content-between"
                    style={{ width: "100%" }}
                >
                    <p>{filesobjFromdata?.size / 2} bytes</p>
                    <p>Uploading 50%</p>
                </div>
                <div
                    style={{ background: "#99D1FF", width: "100%", borderRadius: "0.25rem" }}
                >
                    <div
                        style={{
                            height: "100%",
                            width: "50%",
                            padding: "0.25rem",
                            background: "#0084EF",
                            borderRadius: "0.25rem",
                        }}
                    ></div>
                </div>
            </div>
        </div>
    ) : screenToShhow === 3 ? (
        <div className="d-flex w-100 align-items-center justify-content-center flex-column h-100">
            <div
                style={{
                    width: "95%",
                    padding: "1rem",
                    borderRadius: "0.5rem",
                    border: "1px solid #F0F0F0",
                    background: "#FFF",
                }}
            >
                <div
                    className="d-flex align-items-center justify-content-between"
                    style={{ width: "100%" }}
                >
                    <p>{filesobjFromdata?.name || "-"}</p>
                    <IoMdClose
                        className="ms-1"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            setFilesobj("");
                            setFilesobjFromdata("");
                            setScreenToShhow(1);
                        }}
                    />
                </div>
                <div
                    className="d-flex align-items-center justify-content-between"
                    style={{ width: "100%" }}
                >
                    <p>{filesobjFromdata?.size} bytes</p>
                    <p>File Uploaded</p>
                </div>
            </div>
        </div>
    ) : null
}

          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex w-100 justify-content-between align-items-center">
          <button className={styles.xls_download}>
            <a
           href="https://idesign-quotation.s3.ap-south-1.amazonaws.com/0/my-file%2520%252819%2529%2520%25286%2529.csv"
           // href="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/my-file%20%2819%29%20%286%29.csv"
              download="File format"
              style={{ color: "#40454A", marginRight: "0.3rem" }}
            >
              {" "}
              Download Sample
            </a>
          </button>
          <button
            className={styles.xls_import}
            onClick={uploadFile}
            style={{ width: "6rem" }}
          >
            {!loading ? (
              "Import"
            ) : (
              <Spinner
                animation="border"
                role="status"
                style={{ height: "1.25rem", width: "1.25rem" }}
              >
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ImportFromCSVModal;
