import axios from "axios";
import React, { useEffect, useState } from "react";
import styles from "./companyDetails.module.css";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { Modal } from "react-bootstrap";
import { TextField } from "@mui/material";
import { postReq } from "../../piservices/apis";
// import expf from "../../piservices/constants";
import { constants } from "../../piservices/constants";
import { saveMapData } from "../../Redux/Slices/onboardingSlice";
const libraries = ["places"];
const mapContainerStyle = {
  // width: "46.5rem",
  height: "367px",
  marginTop: "1.5%",
  // marginLeft: "1.5%",
};

const center = {
  lat: 28.69680310102602,
  lng: 77.22127332285639,
};

const options = {
  disableDefaultUI: true,
  zoomControl: true,
};
const apiKey = "AIzaSyAdUtzsZsgXw6tacKy2WqbPAWreWOZM-pk";

const Map = ({ open,close, placeLocation, sendValueToParent }) => {
  // console.log("placeLocation", placeLocation)
  const dispatch=useDispatch();
  const [address2, setAddress2] = useState(placeLocation);
  const [lan, setLan] = useState("");
  const [lag, setLag] = useState("");
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    setAddress2(placeLocation);
    localStorage.setItem("location",placeLocation)
  }, []);
  useEffect(() => {
    if (open) {
      handleSubmitIntial();
    }
  }, [open]);

  const handleChange2 = (value) => {
    setAddress2(value);
    localStorage.setItem("location",value)
    setIsChanged(true);
  };

useEffect(()=>{
  setAddress2("Delhi, India")
  localStorage.setItem("firstTimeMap", true)
},[])

  const handleSelect2 = async (value) => {
    try {
      const results = await geocodeByAddress(value);
      const latLng = await getLatLng(results[0]);

      setAddress2(results[0].formatted_address);
      localStorage.setItem("location",results[0].formatted_address)
      setLan(latLng.lat);

      setLag(latLng.lng);
      setIsChanged(true);
    } catch (error) {
      // console.error('Error:', error);
    }
  };
  useEffect(() => {
    handleSubmit();
  }, [lan, lag]);
  const handleSubmitIntial = async () => {
    const res = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${address2}&key=AIzaSyAdUtzsZsgXw6tacKy2WqbPAWreWOZM-pk`);
    if(res?.data?.results[0]?.geometry?.location){
      const { lat, lng } = res?.data?.results[0]?.geometry?.location;
    setMarkerPosition({ lat, lng });
    localStorage.setItem("address", res?.data?.results[0]?.geometry.location);
    localStorage.setItem("Long & Lat", { lat, lng });
    localStorage.setItem("longitude", markerPosition?.lng)
    localStorage.setItem("latitude", markerPosition?.lat)
  }
  };
  const handleSubmit = async () => {
    const res = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${address2}&key=AIzaSyAdUtzsZsgXw6tacKy2WqbPAWreWOZM-pk`);
   if(res?.data?.results[0]?.geometry?.location){
    const { lat, lng } = res?.data?.results[0]?.geometry?.location;
    setMarkerPosition({ lat, lng });
    localStorage.setItem("address", res?.data?.results[0]?.geometry.location);
    localStorage.setItem("Long & Lat", { lat, lng });
    localStorage.setItem("longitude", markerPosition?.lng)
    localStorage.setItem("latitude", markerPosition?.lat)
   }
   
  };

  const [city, setCity] = useState("");

  const [markerPosition, setMarkerPosition] = useState(center);

  const handleMarkerDrag = async (pinLocation) => {
    // const newPosition = {
    //   lat: e.latLng.lat(),
    //   lng: e.latLng.lng(),
    // };
   
    const newPosition=pinLocation;
    setMarkerPosition(newPosition);
    // getLocationFromLatLng();
    const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${pinLocation?.lat},${pinLocation?.lng}&key=AIzaSyAdUtzsZsgXw6tacKy2WqbPAWreWOZM-pk`);

    if (response.data.results.length > 0) {
      const location = response.data.results[0].formatted_address;
      setAddress2(location);
      localStorage.setItem("location",location)
      sendValueToParent(location)
      localStorage.setItem("longitude", markerPosition?.lng)
      localStorage.setItem("latitude", markerPosition?.lat)

    }
  };

  const changeAddress = async () => {
    const res = await postReq(`${constants.PMT_API}/api/projects/edit-location?projectId=${localStorage.getItem("projectId")}`, {
      location: address2,
      longitude: markerPosition?.lng,
      latitude: markerPosition?.lat,
    });
  };
  
  const getLocationFromLatLng = async () => {
    const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${markerPosition?.lat},${markerPosition?.lng}&key=AIzaSyAdUtzsZsgXw6tacKy2WqbPAWreWOZM-pk`);

    if (response.data.results.length > 0) {
      const location = response.data.results[0].formatted_address;
      setAddress2(location);
      localStorage.setItem("location",location)
      sendValueToParent(location)
      localStorage.setItem("longitude", markerPosition?.lng)
      localStorage.setItem("latitude", markerPosition?.lat)

    }
  };
 
 // Load the Google Maps API
 const { isLoaded, loadError } = useLoadScript({
  googleMapsApiKey: apiKey,
  libraries,
});

  return (
    <div>
      <Modal show={open} size="lg" backdrop="static" keyboard={false} centered>
        <Modal.Header style={{ borderBottom: "none" }}>
          <Modal.Title className={styles.createNewProjectHeader} style={{ width: "100%" }}>
            <div className={styles.createProjectModalHeader} style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
              <span style={{ fontFamily: "Inter", fontSize: "22px", fontWeight: 600 }}>Company location on Google Map</span>
              <div className={styles.closeBtnLocation} style={{ display: "flex" }}>
                <button className={styles.createModalClose} style={{ background: "none", border: "none" }}  onClick={close}>
                  <span
                    style={{
                      color: "#C9C9C9",
                      fontWeight: "600",
                      fontSize: "1rem",
                      color: "#C9C9C9",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      border: "none",
                      background: "none",
                    }}
                  >
                    Cancel
                  </span>
                </button>
                <button  style={{ display:"flex", alignItems:"center",
                   borderRadius: "8px", background: "#0084EF",border:'none',padding: "4px 24px",  marginLeft:"15px"}} onClick={() => {
                  // changeAddress(); 
                  localStorage.removeItem("firstTimeMap")
                  sendValueToParent(address2)
                  dispatch(saveMapData(address2)); close() }}>
                  <span
                    style={{
                      color: "#C9C9C9",
                      fontWeight: "600",
                      fontSize: "1rem",
                      color: "#ffffff",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      padding:"0",
                    }}
                  >
                    Submit{" "}
                  </span>
                </button>
              </div>
            </div>
            <div style={{color:"#888888", fontSize:"14px"}}>Google Map location can be used for marking attendance from Office</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <PlacesAutocomplete value={address2} onChange={handleChange2} onSelect={handleSelect2}>
              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div>
                  <div style={{ display: "flex" }}>
                    <div className={styles.formName} style={{width:"100%"}}>
                      <TextField
                      sx={{width:"100%"}}
                        style={{ width: "100%" }}
                        id="outlined-basic"
                        label="Search for Location"
                        variant="outlined"
                        isRequired={true}
                        value={address2}
                        {...getInputProps({
                          placeholder: "Search your location",
                        })}
                      />
                    </div>
                    {/* <img src={scanIcon} alt="" style={{ marginLeft: "-5%", height: "100%", paddingTop: "2.5%", zIndex: "99", color: "#888888", cursor: "pointer" }} onClick={handleSubmit} /> */}
                  </div>
                  <div
                    style={{
                      marginTop: "0%",
                      marginLeft: "1%",
                      position: "fixed",
                      zIndex: "999",
                      width:"45%"
                    }}
                  >
                    {loading ? <div>Loading...</div> : null}
                    {suggestions.map((suggestion) => {
                      const style = {
                        backgroundColor: suggestion.active ? "#FAFAFA" : "#fff",
                        cursor: "pointer",
                        padding: "2%",
                        borderBottom: "1px solid #DFDFDF",
                      };
                      return (
                        <div key={suggestion.placeId} {...getSuggestionItemProps(suggestion, { style })}>
                          {suggestion.description}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
            <GoogleMap mapContainerStyle={mapContainerStyle} center={markerPosition} zoom={10} options={options}>
              <Marker position={markerPosition} draggable={true} onDragEnd={(e)=>{
                  localStorage.setItem("latitude", e.latLng.lat())
                  localStorage.setItem("longitude", e.latLng.lng())
                  // setLatitude(e.latLng.lat())
                  // setLongitude(e.latLng.lng())

                handleMarkerDrag({
                  lat: e.latLng.lat(),
                  lng: e.latLng.lng(),
                })}} />
            </GoogleMap>
          </>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Map;
