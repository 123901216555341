import React, { useEffect, useState } from "react";
import { PiQuestionLight } from "react-icons/pi";
import { PiNotePencilLight } from "react-icons/pi";
import { PiTrashLight } from "react-icons/pi";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { leadManagementSettingsActions } from "../../../Redux/Slices/leadManagementSettingsSlice";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import axios from "axios";
import { constants } from "../../../piservices/constants";
import "./leadManagementSettings.css";
import ConfirmDelete from "../Modals/ConfirmDelete";

const CustomField = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [fields, setFields] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(12);
  const [maxPages, setMaxPages] = useState(1);
  const [totalFields, setTotalFields] = useState(0);
  const [currPage, setCurrPage] = useState(1);
  const [deleted, setDeleted] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);

  // useEffect(() => {
  //   dispatch(leadManagementSettingsActions.addAllFields(fields));
  // }, [fields]);

  useEffect(() => {
    fetchFields();
  }, [page, limit]);

  useEffect(() => {
    if (deleted) {
      fetchFields();
      setDeleted(false);
    }
  }, [deleted]);

  // console.log(fields, "fields!")

  const closeDeleteModal = () => {
    setShowDelete(false);
  };

  async function fetchFields() {
    try {
      const response = await axios.get(
        `${
          constants.CRM_API
        }/api/get-custom-fields?userId=${localStorage.getItem(
          "userId"
        )}&page=${page}&limit=${limit}`
      );
      const data = response.data.data;
      // console.log(data, "data!");
      setFields(data?.data);
      setMaxPages(data?.pagination?.pages);
      setCurrPage(data?.pagination?.page);
      setTotalFields(data?.pagination?.total);
    } catch (err) {
      console.log(err);
    }
  }

  // delete the field
  const deleteFieldHandler = async (field) => {
    // console.log(field, "field!");
    if (!field.isDefault) {
      try {
        await axios.post(
          `${constants.CRM_API}/api/edit-custom-field?id=${field._id}`,
          {
            ...field,
            isDeleted: true,
          }
        );
        setDeleted(true);
      } catch (err) {
        console.log(err);
      }
    }
  };

  // convert section number to string
  const sectionToString = (data) => {
    const strArr = [];
    for (let i of data) {
      if (i === 0) {
        strArr.push("Individual");
      } else {
        strArr.push("Corporate");
      }
    }
    if (strArr.length === 0) {
      return "";
    } else {
      let str = strArr[0];
      for (let i of strArr.slice(1)) {
        str += ", " + i;
      }
      return str;
    }
  };

  // set previous page
  const previousPageHandler = () => {
    setPage((prev) => prev - 1);
  };

  // set next page
  const nextPageHandler = () => {
    setPage((prev) => prev + 1);
  };

  // set last page
  const lastPageHandler = () => {
    setPage(maxPages);
  };

  // to create new field
  const createFieldHandler = () => {
    navigate("/leads/lead-management-settings/create-new-field");
  };

  // to edit a field
  const editFieldHandler = (index) => {
    navigate(`/leads/lead-management-settings/edit-field/${index}`);
  };

  return (
    <>
      <ConfirmDelete
        show={showDelete}
        hide={closeDeleteModal}
        name="Custom Field"
        action={deleteFieldHandler.bind(null, deleteItem)}
      />
      <div
        style={{
          position: "fixed",
          left: "15%",
          width: "85%",
          paddingTop: "3rem",
          border: "1px solid #EEEEEF",
          height: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginLeft: "2rem",
            marginRight: "4rem",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "5px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h3
              style={{
                fontWeight: "600",
                fontSize: "24px",
                color: "#212529",
                marginTop: "auto",
                marginBottom: "auto",
              }}
            >
              Manage Custom Fields
            </h3>
            <Tooltip
              id="manage-custom-fields-tooltip"
              style={{ width: "350px", fontWeight: "600", fontSize: "12px" }}
            />
            <PiQuestionLight
              style={{ height: "22px", width: "22px", color: "#212529" }}
              data-tooltip-id="manage-custom-fields-tooltip"
              data-tooltip-content="Customize lead fields effortlessly: Create, edit, and delete fields to tailor your data entry experience."
              data-tooltip-place="top"
            />
          </div>
          <button
            style={{
              border: "1px solid #0084EF",
              borderRadius: "8px",
              padding: "8px 24px 8px 24px",
              backgroundColor: "#FFFFFF",
              fontWeight: "500",
              fontSize: "14px",
              color: "#0084EF",
            }}
            onClick={createFieldHandler}
          >
            Create Custom Field
          </button>
        </div>
        <div className="CFTable">
          <table style={{ width: "100%" }}>
            <thead
              style={{ color: "#64748B", fontWeight: "600", fontSize: "12px" }}
            >
              <tr>
                <th
                  style={{ borderBottom: "1px solid #E7EAEE", padding: "8px" }}
                >
                  S no.
                </th>
                <th
                  style={{ borderBottom: "1px solid #E7EAEE", padding: "8px" }}
                >
                  Display Name
                </th>
                <th
                  style={{
                    borderBottom: "1px solid #E7EAEE",
                    padding: "8px 0px 8px 8px",
                  }}
                >
                  Section Name
                </th>
                <th
                  style={{
                    borderBottom: "1px solid #E7EAEE",
                    padding: "8px 0px 8px 8px",
                  }}
                >
                  Field Type
                </th>
                <th
                  style={{
                    borderBottom: "1px solid #E7EAEE",
                    padding: "8px 0px 8px 8px",
                  }}
                >
                  Mandatory
                </th>
                <th
                  style={{
                    borderBottom: "1px solid #E7EAEE",
                    padding: "8px 0px 8px 8px",
                    textAlign: "center",
                  }}
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody
              style={{ color: "#191D23", fontWeight: "400", fontSize: "12px" }}
            >
              {fields &&
                fields.map((item, index) => (
                  <tr>
                    <td
                      style={{
                        borderBottom: "1px solid #E7EAEE",
                        padding: "8px",
                      }}
                    >
                      {(currPage - 1) * limit + (index + 1)}.
                    </td>
                    <td
                      style={{
                        borderBottom: "1px solid #E7EAEE",
                        padding: "8px",
                      }}
                    >
                      {item.fieldName}
                    </td>
                    <td
                      style={{
                        borderBottom: "1px solid #E7EAEE",
                        padding: "8px 0px 8px 8px",
                      }}
                    >
                      {sectionToString(item.section)}
                    </td>
                    <td
                      style={{
                        borderBottom: "1px solid #E7EAEE",
                        padding: "8px 0px 8px 8px",
                      }}
                    >
                      {item.fieldType}
                    </td>
                    <td
                      style={{
                        borderBottom: "1px solid #E7EAEE",
                        padding: "8px 0px 8px 8px",
                      }}
                    >
                      {item.isRequired ? "Yes" : "No"}
                    </td>
                    <td
                      style={{
                        borderBottom: "1px solid #E7EAEE",
                        padding: "8px 0px 8px 8px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          justifyContent: "center",
                        }}
                      >
                        <button
                          style={{ backgroundColor: "white", border: "none" }}
                          onClick={editFieldHandler.bind(null, item._id)}
                        >
                          <PiNotePencilLight
                            style={{ height: "20px", width: "20px" }}
                          />
                        </button>
                        <Tooltip
                          id="deleteButton"
                          events={["click"]}
                          style={{
                            width: "186px",
                            fontWeight: "600",
                            fontSize: "12px",
                          }}
                        />
                        <button
                          style={{
                            backgroundColor: "white",
                            border: "none",
                          }}
                          onClick={() => {
                            setShowDelete(true);
                            setDeleteItem(item);
                          }}
                          data-tooltip-id={item.isDefault && "deleteButton"}
                          data-tooltip-content="This field can’t be deleted"
                          data-tooltip-place="top"
                        >
                          <PiTrashLight
                            style={{
                              height: "20px",
                              width: "20px",
                              color: item.isDefault ? "#CCCCCC" : "#343330",
                            }}
                          />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="CFpaginationMain">
          <div>
            {totalFields === 0 ? 0 : (currPage - 1) * limit + 1}-
            {totalFields < currPage * limit ? totalFields : currPage * limit} of{" "}
            {totalFields}
          </div>
          <div className="CFChangePages">
            <button
              disabled={currPage === 1 && true}
              className="CFPrevNext"
              onClick={previousPageHandler}
            >
              Previous
            </button>
            <span className="CFCurr">{currPage}</span>
            {currPage < maxPages - 1 && (
              <button onClick={nextPageHandler} className="CFPageButton">
                {currPage + 1}
              </button>
            )}
            {currPage !== maxPages && (
              <>
                {maxPages > 2 && <span className="CFPageButton">...</span>}
                <button onClick={lastPageHandler} className="CFPageButton">
                  {maxPages}
                </button>
              </>
            )}
            <button
              disabled={currPage === maxPages && true}
              className="CFPrevNext"
              onClick={nextPageHandler}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomField;
