import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    addLeadModalOpen: false,
    newLeadObj:{},
    headerTab: localStorage.getItem("headerTab") || "Leads Management",
    fileImportedData:[],
    showImport : false,
}
export const newLeadSlice = createSlice({
    name: 'newLead',
    initialState,
    reducers:{
        setAddLeadModalOpen:(state, action)=>{
            state.addLeadModalOpen = action.payload;
        },
        newLeadObj:(state, action)=>{
            state.newLeadObj = action.payload;
        },
        setHeaderTab:(state, action)=>{
            state.headerTab = action.payload;
        },
        setShowImport:(state, action)=>{
            state.showImport = action.payload;
        },
        setFileImportedData:(state, action)=>{
            state.fileImportedData = action.payload;
        }
    }
})

export const {setFileImportedData,setShowImport, setAddLeadModalOpen, newLeadObj,setHeaderTab} = newLeadSlice.actions;
export default newLeadSlice.reducer;
