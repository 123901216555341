import React, { useEffect, useState } from "react";
import { PiCaretDown } from "react-icons/pi";
import { Dropdown, Modal } from "react-bootstrap";
import "../leadManagementSettings.css";
import axios from "axios";
import { constants } from "../../../../piservices/constants";
import toast from "react-hot-toast";

const EditStatusModal = ({ show, hide, stages, data, defaultId }) => {
  const [stage, setStage] = useState({ stageName: "", id: null });
  const [prevStage, setPrevStage] = useState({ stageName: "", id: null });
  const [openStages, setOpenStages] = useState(false);
  const [status, setStatus] = useState("");

  useEffect(() => {
    if (data !== null) {
      setStatus(data?.statusName);
      setStage({
        stageName: data?.stageName,
        id: stages[
          stages.findIndex((item) => item.stageName === data?.stageName)
        ].id,
      });
      setPrevStage({
        stageName: data?.stageName,
        id: stages[
          stages.findIndex((item) => item.stageName === data?.stageName)
        ].id,
      });
    }
  }, [data]);

  // toggle stage options
  const toggleStages = () => {
    setOpenStages((prev) => !prev);
  };

  // change stage
  const changeStage = (index) => {
    setStage(stages[index]);
    setOpenStages(false);
  };

  // change tag name
  const statusHandler = (e) => {
    const value = e.target.value;
    setStatus(value);
  };

  const saveHandler = async () => {
    try {
      const obj = {
        oldStatusId: data?.statusId,
        oldStageId: prevStage.id,
        newStageId: stage.id,
        newStatus: status,
      };
      console.log(obj, "obj!");
      await axios.put(
        `${
          constants.CRM_API
        }/user/edit-lead-status?userId=${localStorage.getItem("userId")}`,
        obj
      );
      toast.success("Status edited successfully!");
      hide();
    } catch (err) {
      console.log(err);
    }
  };

  console.log(data, "data!");

  return (
    <Modal show={show} onHide={hide} centered>
      <Modal.Header className="addStatusHeader">
        <div style={{ fontWeight: "600", fontSize: "18px", color: "#212121" }}>
          Edit Status
        </div>
        <div style={{ display: "flex", gap: "12px", alignItems: "center" }}>
          <button onClick={hide} className="addStatusCancel">
            Cancel
          </button>
          <button onClick={saveHandler} className="addStatusSave">
            Save
          </button>
        </div>
      </Modal.Header>
      <Modal.Body
        style={{ display: "flex", flexDirection: "column", gap: "18px" }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <label
            style={{ color: "#191D23", fontWeight: "500", fontSize: "14px" }}
          >
            Select Stage
          </label>
          <Dropdown style={{ display: "flex", flexDirection: "column", pointerEvents: defaultId && "none" }}>
            <Dropdown.Toggle className="addStatusStage">
              <div>{stage.stageName ? stage.stageName : " "}</div>
              <PiCaretDown />
            </Dropdown.Toggle>
            {/* {openStages && ( */}
              <Dropdown.Menu className="addStatusStages">
                {stages.map((item, index) => (
                  <Dropdown.Item
                    key={index}
                    className="addStatusStageOption"
                    onClick={changeStage.bind(null, index)}
                  >
                    {item?.stageName}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            {/* )} */}
          </Dropdown>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <label
            style={{ color: "#191D23", fontWeight: "500", fontSize: "14px" }}
          >
            Status Name
          </label>
          <input
            placeholder="Type here"
            className="addStatusTag"
            value={status}
            onChange={statusHandler}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditStatusModal;
