import React, { useEffect, useState } from 'react'
import './leadManagementSettings.css'
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { AiOutlineDelete, AiOutlineSearch } from 'react-icons/ai'
import { constants } from '../../../piservices/constants'
import TeamZero from '../../../Assets/TeamZero.svg'
import axios from 'axios'
import toast from 'react-hot-toast'
import { PiNotePencilLight, PiTrashLight } from 'react-icons/pi'
import store from '../../../Redux/store'
import { TextField } from '@mui/material'
import { getReq } from '../../../piservices/apis'

const Teams = () => {
  const [showTeamsModal, setShowTeamsModal] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [isSelectAll, setIsSelectAll] = useState(false)
  const [selectedMemberIds, setSelectedMemberIds] = useState([])
  const [assignedMembers, setAssignedMembers] = useState([])
  const [isAssignedSelectAll, setIsAssignedSelectAll] = useState(false)
  const [editLeadBoolean, setEditLeadBoolean] = useState(false)
  const [storeTeamData, setStoreTeamData] = useState()
  const [openViewModal, setOpenViewModal] = useState()
  const [storeAvailMem, setStoreAvailMem] = useState()
  const [storeTeams, setStoreTeams] = useState()
  const [editTeamData, setEditTeamData] = useState()
  const [teamId, setTeamId] = useState()
  const [teamName, setTeamName] = useState('')
  const handleInputChange = (e) => {
    setTeamName(e.target.value)
  }

  const [searchQuery, setSearchQuery] = useState('')
  const newAssignedMembers = storeTeamData?.members?.filter((member) =>
    member?.fullName?.toLowerCase()?.includes(searchQuery?.toLowerCase())
  )
  const getUserbyId = async (id) => {
    const res = await getReq(
      `${constants.CRM_API}/user/getUserById?userId=${localStorage.getItem('userId')}`
    )
    if (res && !res.error) {
      console.log(res?.data?.data, 'res!')
      setEditLeadBoolean(res?.data?.data?.rolesAssigned?.crm?.edit)
    } else {
      console.log(res.error)
    }
  }
  useEffect(() => {
    getUserbyId()
  }, [])

  const getAvailMember = async () => {
    await axios
      .get(`${constants.CRM_API}/team/available-members?userId=${localStorage.getItem('userId')}`)
      .then(function (response) {
        setStoreAvailMem(response?.data)
      })
      .catch(function (error) {})
  }
  const getTeams = async () => {
    await axios
      .get(`${constants.CRM_API}/teams?userId=${localStorage.getItem('userId')}`)
      .then(function (response) {
        setStoreTeams(response?.data)
        console.log(response?.data, 'dwedwessssss')
      })
      .catch(function (error) {})
  }
  const deleteTeams = async (deleteId) => {
    await axios
      .delete(`${constants.CRM_API}/team/${deleteId}?userId=${localStorage.getItem('userId')}`)
      .then(function (response) {
        toast.success('delete sucessfully')
        console.log(response?.data, 'dwedwessssss')
        getTeams()
      })
      .catch(function (error) {})
  }
  console.log(storeAvailMem, 'wedkfwe')
  const [searchQuery2, setSearchQuery2] = useState('')

  const filteredMembers = storeAvailMem?.filter((member) => {
    if (searchTerm === '') return true
    return member?.fullName?.toLowerCase()?.includes(searchTerm?.toLowerCase())
  })
  const filteredAssignedMembers = assignedMembers.filter((member) =>
    member?.fullName?.toLowerCase().includes(searchQuery2.toLowerCase())
  )
  const handleSearchChange2 = (e) => {
    setSearchQuery2(e.target.value)
  }

  const handleSelectMember = (memberId) => {
    if (selectedMemberIds.includes(memberId)) {
      // Deselect the member
      setSelectedMemberIds(selectedMemberIds.filter((id) => id !== memberId))
      setAssignedMembers((prevMembers) => prevMembers.filter((member) => member._id !== memberId))
      setIsSelectAll(false) // Uncheck Select All if any member is deselected
      setIsAssignedSelectAll(false)
    } else {
      // Select the member
      setSelectedMemberIds([...selectedMemberIds, memberId])
      const memberToAdd = storeAvailMem.find((member) => member._id === memberId)
      if (memberToAdd && !assignedMembers.some((member) => member._id === memberToAdd._id)) {
        setAssignedMembers([...assignedMembers, memberToAdd])
      }

      // Check if all available members are selected
      if (selectedMemberIds.length + 1 === filteredMembers.length) {
        setIsSelectAll(true) // Check Select All if all members are selected
        setIsAssignedSelectAll(true)
      }
    }
  }

  const handleToggleAssignedMember = (memberId) => {
    if (selectedMemberIds.includes(memberId)) {
      // If the member is already selected, remove them from the selected IDs and assigned members
      setAssignedMembers((prevMembers) => prevMembers.filter((member) => member._id !== memberId))
      setSelectedMemberIds((prevIds) => prevIds.filter((id) => id !== memberId))
    } else {
      // If the member is not selected, add them to the selected IDs and assigned members
      setSelectedMemberIds((prevIds) => [...prevIds, memberId])
      const memberToAdd = assignedMembers.find((member) => member._id === memberId)
      if (memberToAdd) {
        setAssignedMembers((prevMembers) => [...prevMembers, memberToAdd])
      }
    }
  }

  const handleSelectAll = () => {
    if (isSelectAll || filteredMembers.length === selectedMemberIds.length) {
      setSelectedMemberIds([])
      setAssignedMembers([])
    } else {
      const allMemberIds = filteredMembers.map((member) => member._id)
      setSelectedMemberIds(allMemberIds)
      const allMembersToAdd = filteredMembers.filter(
        (member) => !assignedMembers.some((assigned) => assigned._id === member._id)
      )
      setAssignedMembers([...assignedMembers, ...allMembersToAdd])
    }
    setIsSelectAll(!isSelectAll)
    setIsAssignedSelectAll(!isAssignedSelectAll)
  }
  const handleAssignedSelectAll = () => {
    if (isAssignedSelectAll || assignedMembers.length === selectedMemberIds.length) {
      setSelectedMemberIds([])
    } else {
      const allAssignedIds = assignedMembers.map((member) => member._id)
      setSelectedMemberIds(allAssignedIds)
    }
    setIsAssignedSelectAll(!isAssignedSelectAll)
    setIsSelectAll(!isSelectAll)
  }
  useEffect(() => {
    // If the number of selected members is equal to the assigned members, check Select All
    setIsAssignedSelectAll(selectedMemberIds.length === assignedMembers.length)
  }, [selectedMemberIds, assignedMembers])

  const handleRemoveMember = (memberId) => {
    setAssignedMembers((prevMembers) => {
      const updatedMembers = prevMembers.filter((member) => member._id !== memberId)
      setSelectedMemberIds((prevIds) => prevIds.filter((id) => id !== memberId))
      return updatedMembers
    })
    setIsSelectAll(false)
    // If there are no assigned members left, uncheck the select all checkbox
    if (assignedMembers.length === 1) {
      setIsAssignedSelectAll(false)
    }
  }

  useEffect(() => {
    if (editTeamData) {
      setTeamName(editTeamData.teamName)
      const assignedMemberIds = editTeamData?.members?.map((member) => member._id) || []
      setSelectedMemberIds(assignedMemberIds)
      setAssignedMembers(editTeamData?.members || [])
    }
  }, [editTeamData])
  const createTeam = async () => {
    const userId = localStorage.getItem('userId')

    if (editTeamData) {
      await axios
        .put(`${constants.CRM_API}/team/${editTeamData?._id}?userId=${userId}`, {
          name: teamName,
          members: selectedMemberIds,
        })
        .then(function (response) {
          setSelectedMemberIds([])
          setAssignedMembers([])
          setTeamName('')
          getTeams()
          setEditTeamData()
          setShowTeamsModal(false)
          getAvailMember()
          toast.success('Team updated successfully')
        })
        .catch(function (error) {
          console.error('Error updating team:', error)
          toast.error('Error updating team')
        })
    } else {
      await axios
        .post(`${constants.CRM_API}/team?userId=${userId}`, {
          name: teamName,
          members: selectedMemberIds,
        })
        .then(function (response) {
          setSelectedMemberIds([])
          setAssignedMembers([])
          setTeamName('')
          getAvailMember()
          getTeams()
          setShowTeamsModal(false)
          toast.success('Team created successfully')
        })
        .catch(function (error) {
          console.error('Error creating team:', error)
          toast.error('Error creating team')
        })
    }
  }

  useEffect(() => {
    getAvailMember()
    getTeams()
  }, [])
  return (
    <div>
      <div
        style={{
          position: 'fixed',
          left: '15%',
          width: '85%',
          paddingTop: '1.3rem',
          border: '1px solid #EEEEEF',
          height: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginLeft: '2rem',
            marginRight: '4rem',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: '5px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <h3
              style={{
                fontWeight: '600',
                fontSize: '24px',
                color: '#212529',
                marginTop: 'auto',
                marginBottom: 'auto',
              }}
            >
              Manage your Team(s)
            </h3>
          </div>
          {editLeadBoolean == true && (
            <button
              style={{
                border: '1px solid #0084EF',
                borderRadius: '8px',
                padding: '8px 24px 8px 24px',
                backgroundColor: '#FFFFFF',
                fontWeight: '500',
                fontSize: '14px',
                color: '#0084EF',
              }}
              onClick={() => {
                editLeadBoolean ? setShowTeamsModal(true) : toast.error('Access disabled by Admin')
              }}
            >
              Create New Team
            </button>
          )}
        </div>
        {storeTeams?.length <= 0 ? (
          <div style={{ marginTop: '4rem', textAlign: 'center' }}>
            <img src={TeamZero} alt="" style={{ marginRight: '5rem' }} />
            <div className="zeroHead">No Team(s) Created</div>
            <div className="zeroSmall">Click on “Create New Team” on top right corner</div>
          </div>
        ) : (
          <div
            style={{
              marginTop: '2.5rem',
              marginLeft: '2rem',
              marginRight: '2rem',
              overflowY: 'auto',
              maxHeight: '63%',
              position: 'relative',
            }}
          >
            <table style={{ width: '100%' }}>
              <thead style={{ color: '#64748B', fontWeight: '600', fontSize: '12px' }}>
                <tr>
                  <th style={{ borderBottom: '1px solid #E7EAEE', padding: '8px', width: '50px' }}>
                    S no.
                  </th>
                  <th style={{ borderBottom: '1px solid #E7EAEE', padding: '8px', width: '270px' }}>
                    Team Name
                  </th>
                  <th style={{ borderBottom: '1px solid #E7EAEE', padding: '8px', width: '250px' }}>
                    Members
                  </th>
                  <th style={{ borderBottom: '1px solid #E7EAEE', padding: '8px', width: '250px' }}>
                    Created By
                  </th>
                  <th style={{ borderBottom: '1px solid #E7EAEE', padding: '8px', width: '250px' }}>
                    Created On
                  </th>
                  <th
                    style={{
                      borderBottom: '1px solid #E7EAEE',
                      padding: '8px 0px 8px 8px',
                      textAlign: 'center',
                      width: '250px',
                    }}
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody style={{ color: '#191D23', fontWeight: '400', fontSize: '12px' }}>
                {storeTeams?.map((newData, index) => {
                  return (
                    <tr
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.borderLeft = '3.5px solid #0084EF')
                      } // Show blue border on hover
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.borderLeft = '3.5px solid transparent')
                      } // Hide border on hover out
                    >
                      <td
                        style={{
                          borderBottom: '1px solid #E7EAEE',
                          padding: '8px',
                        }}
                      >
                        {index + 1}.
                      </td>
                      <td
                        style={{
                          borderBottom: '1px solid #E7EAEE',
                          padding: '8px',
                        }}
                        onClick={() => {
                         if( editLeadBoolean) {
                          setStoreTeamData(newData)
                          setOpenViewModal(true)
                        }}}
                        onMouseEnter={(e) => (e.currentTarget.style.color = '#0084EF')} // Change color to blue on hover
                        onMouseLeave={(e) => (e.currentTarget.style.color = '#191D23')}
                      >
                        {newData.name}
                      </td>
                      <td
                        style={{
                          borderBottom: '1px solid #E7EAEE',
                          padding: '8px 0px 8px 8px',
                        }}
                      >
                        {newData?.members?.length}
                      </td>
                      <td
                        style={{
                          borderBottom: '1px solid #E7EAEE',
                          padding: '8px 0px 8px 8px',
                        }}
                      >
                        {newData?.createdBy?.fullName}
                      </td>
                      <td
                        style={{
                          borderBottom: '1px solid #E7EAEE',
                          padding: '8px 0px 8px 8px',
                        }}
                      >
                        {newData?.createdAt
                          ? new Date(newData.createdAt)
                              .toLocaleDateString('en-GB', {
                                day: '2-digit',
                                month: 'short',
                                year: '2-digit',
                              })
                              .replace('.', '')
                          : '-'}
                      </td>
                      <td
                        style={{
                          borderBottom: '1px solid #E7EAEE',
                          padding: '8px 0px 8px 8px',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            gap: '5px',
                            justifyContent: 'center',
                          }}
                        >
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip id="button-tooltip">
                                <span>Edit</span>
                              </Tooltip>
                            }
                          >
                            <button
                              style={{ backgroundColor: 'white', border: 'none' }}
                              onClick={() => {
                                if (editLeadBoolean) {
                                  setStoreTeamData(newData)
                                  setTeamName(newData?.name)
                                  setEditTeamData(newData)
                                  setShowTeamsModal(true)
                                } else toast.error('Access disabled by Admin')
                              }}
                            >
                              <PiNotePencilLight style={{ height: '20px', width: '20px' }} />
                            </button>
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip id="button-tooltip">
                                <span>Delete</span>
                              </Tooltip>
                            }
                          >
                            <button
                              style={{
                                backgroundColor: 'white',
                                border: 'none',
                              }}
                              data-tooltip-id={'deleteButton'}
                              data-tooltip-content="This field can’t be deleted"
                              data-tooltip-place="top"
                              onClick={() => {
                                editLeadBoolean
                                  ? deleteTeams(newData?._id)
                                  : toast.error('Access disabled by Admin')
                              }}
                            >
                              <PiTrashLight
                                style={{
                                  height: '20px',
                                  width: '20px',
                                }}
                              />
                            </button>
                          </OverlayTrigger>
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
      {console.log(editTeamData, 'dfghj')}
      <Modal
        centered
        backdrop="static"
        show={showTeamsModal}
        animation={false}
        dialogClassName="newWidth"
        backdropClassName="appModal"
      >
        <Modal.Body style={{ padding: '26px 30px' }}>
          <div>
            <div
              style={{
                paddingBottom: '16px',
                justifyContent: 'space-between',
                borderBottom: '1px solid #EDEDED',
                display: 'flex',
              }}
            >
              <div className="crestee">{editTeamData ? 'Edit Team' : 'Create Team'}</div>
              <div style={{ display: 'flex' }}>
                <div
                  onClick={() => {
                    setSelectedMemberIds([])
                    setEditTeamData()
                    setAssignedMembers([])
                    setTeamName('')
                    setShowTeamsModal(false)
                  }}
                  style={{
                    color: '#C9C9C9',
                    cursor: 'pointer',
                    fontSize: '16px',
                    fontWeight: '600',
                    padding: '8px 24px',
                  }}
                >
                  Cancel
                </div>
                <div
                  onClick={() => {
                    selectedMemberIds?.length > 0 && teamName != ''
                      ? createTeam()
                      : teamName == ''
                        ? toast.error('Please fill the Team Name')
                        : toast.error('Please select the Team Members.')
                  }}
                  className={selectedMemberIds?.length > 0 && teamName != '' ? 'saveblue' : 'save'}
                >
                  Save
                </div>
              </div>
            </div>
            <TextField
              label="Team name"
              type="text"
              required={true}
              value={teamName} // The value controls the input
              onChange={handleInputChange} // Handles the input changes
              variant="outlined" // Ensure variant is set correctly
              fullWidth
              InputLabelProps={{
                shrink: teamName !== '', // Ensures label shrinks when there is a value
              }}
              style={{ height: '48px', borderRadius: '8px' }}
              className="borderteam"
            />

            {/* <input className='borderteam' placeholder='Team name' value={teamName}
                            onChange={handleInputChange} style={{ width: "735px", height: "48px", borderRadius: "8px" }} /> */}
            <div style={{ display: 'flex', gap: '28px', marginTop: '15px' }}>
              {console.log(storeAvailMem, 'Ewfcwef')}
              <div className="frstBox">
                <div style={{ fontSize: '16px', fontWeight: '600', color: '#212121' }}>
                  Available Team Member
                </div>
                <div className={storeAvailMem?.length <= 0 ? 'zeroDiv' : 'mainDivv'}>
                  {storeAvailMem?.length > 0 ? (
                    <>
                      <div className="searchBox">
                        <input
                          className="teamMember"
                          placeholder="Search Team Member"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <AiOutlineSearch color="#6F6E6E" />
                      </div>
                      {filteredMembers?.length === 0 ? (
                        <div
                          className="zeroStateeText"
                          style={{ paddingLeft: '50px', paddingTop: '30px' }}
                        >
                          No matching members found
                        </div>
                      ) : (
                        <div>
                          {/* <div style={{ display: "flex", gap: "8px", padding: "12px 16px" }}>
                                                    <input type="checkbox" checked={isSelectAll} onChange={handleSelectAll} />
                                                        <div style={{ fontSize: "12px", fontWeight: "400" }}>Select All</div>
                                                    </div> */}
                          <div style={{ height: '334px', overflowY: 'scroll' }}>
                            {filteredMembers?.map((data, index) => {
                              return (
                                <div
                                  style={{
                                    display: 'flex',
                                    gap: '8px',
                                    backgroundColor: selectedMemberIds?.includes(data?._id)
                                      ? '#F9FAFB'
                                      : 'unset',
                                    padding: '8px 16px',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  {console.log(data, selectedMemberIds, 'referf')}
                                  <div style={{ display: 'flex', gap: '8px' }}>
                                    <input
                                      type="checkbox"
                                      checked={selectedMemberIds.includes(data._id)}
                                      onChange={() => handleSelectMember(data._id)}
                                    />
                                    <div style={{ fontSize: '12px' }}>{data?.fullName}</div>
                                  </div>
                                  <div className="chip">
                                    <div
                                      style={{
                                        width: '56px',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        textWrap: 'nowrap',
                                        display: 'flex',
                                        textWrap: 'nowrap',
                                      }}
                                    >
                                      {data?.roleName}
                                    </div>
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="zeroStateeText">No Member has been added Yet </div>
                  )}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                  borderRadius: '8px',
                  padding: '8px',
                }}
              >
                <div style={{ fontSize: '16px', fontWeight: '600', color: '#212121' }}>
                  Assigned Team Member
                </div>
                <div className={storeAvailMem?.length <= 0 ? 'zeroDiv' : 'mainDivv'}>
                  {assignedMembers?.length > 0 ? (
                    <>
                      <div className="searchBox">
                        <input
                          className="teamMember"
                          value={searchQuery2} // bind the searchQuery state to the input field
                          onChange={handleSearchChange2}
                          placeholder="Search Team Member"
                        />
                        <AiOutlineSearch color="#6F6E6E" />
                      </div>
                      {filteredAssignedMembers?.length === 0 ? (
                        <div
                          className="zeroStateeText"
                          style={{ paddingLeft: '50px', paddingTop: '30px' }}
                        >
                          No matching members found
                        </div>
                      ) : (
                        <div>
                          {/* <div style={{ display: "flex", gap: "8px", padding: "12px 16px" }}>
                                                <input type="checkbox" checked={isAssignedSelectAll || assignedMembers?.length === selectedMemberIds?.length}
                                    onChange={handleAssignedSelectAll} />
                                                    <div style={{ fontSize: "12px", fontWeight: "400" }}>Select All</div>
                                                </div> */}
                          <div style={{ height: '334px', overflowY: 'scroll' }}>
                            {filteredAssignedMembers?.map((data) => (
                              <div
                                key={data._id}
                                style={{
                                  display: 'flex',
                                  gap: '8px',
                                  padding: '8px 16px',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <div style={{ display: 'flex', gap: '8px' }}>
                                  <input
                                    type="checkbox"
                                    checked={selectedMemberIds.includes(data._id)}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        // If the checkbox is checked, add the member ID to selectedMemberIds
                                        setSelectedMemberIds((prevIds) => [...prevIds, data._id])
                                      } else {
                                        // If the checkbox is unchecked, call the handleRemoveMember function
                                        handleRemoveMember(data._id)
                                      }
                                    }}
                                    // onChange={() => handleRemoveMember(data._id)}
                                  />
                                  <div style={{ fontSize: '12px' }}>{data?.fullName}</div>
                                </div>
                                <div style={{ display: 'flex', gap: '6px', alignItems: 'center' }}>
                                  <div className="chip">
                                    <div
                                      style={{
                                        width: '56px',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        display: 'flex',
                                      }}
                                    >
                                      {data?.roleName}
                                    </div>
                                  </div>
                                  {/* <div onClick={() => handleRemoveMember(data._id)} style={{ cursor: "pointer" }}>
                                                                    <AiOutlineDelete />
                                                                </div> */}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <div
                      className="zeroStateeText"
                      style={{ display: 'flex', justifyContent: 'center', paddingTop: '3rem' }}
                    >
                      No Member has been added Yet{' '}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        centered
        backdrop="static"
        show={openViewModal}
        animation={false}
        dialogClassName="newWidth"
        backdropClassName="appModal"
      >
        <Modal.Body style={{ padding: '26px 30px' }}>
          <div>
            <div
              style={{
                paddingBottom: '16px',
                justifyContent: 'space-between',
                borderBottom: '1px solid #EDEDED',
                display: 'flex',
              }}
            >
              <div className="crestee" style={{ fontSize: '27px' }}>
                View Team
              </div>
              <div style={{ display: 'flex' }}>
                <div
                  onClick={() => {
                    setOpenViewModal(false)
                    setSelectedMemberIds([])
                    setAssignedMembers([])
                    setTeamName('')
                    setShowTeamsModal(false)
                  }}
                  style={{
                    color: '#C9C9C9',
                    cursor: 'pointer',
                    fontSize: '16px',
                    fontWeight: '600',
                    padding: '8px 24px',
                  }}
                >
                  Close
                </div>
                <div
                  onClick={() => {
                    setOpenViewModal(false)
                    setTeamName(storeTeamData?.name)
                    setEditTeamData(storeTeamData)
                    setShowTeamsModal(true)
                  }}
                  className="saveblue"
                >
                  Edit
                </div>
              </div>
            </div>
            <div>
              <div
                style={{
                  paddingTop: '10px',
                  fontSize: '20px',
                  fontWeight: '400',
                  color: '#4D4D4D',
                }}
              >
                Team - <span style={{ fontWeight: '600' }}>{storeTeamData?.name}</span>
              </div>
              <div
                style={{
                  paddingTop: '10px',
                  fontSize: '16px',
                  fontWeight: '600',
                  color: '#212121',
                }}
              >
                Assigned Team Member
              </div>
              <div
                style={{
                  height: '400px',
                  border: '1px solid #999999',
                  borderRadius: '8px',
                  padding: '8px',
                  marginTop: '15px',
                }}
              >
                <div className="searchBox" style={{ width: 'unset' }}>
                  <input
                    className="teamMember"
                    placeholder="Search Team Member"
                    onChange={(e) => setSearchQuery(e.target.value)}
                    style={{ width: '690px' }}
                    value={searchQuery} // Update search query on input change
                  />
                  <AiOutlineSearch color="#6F6E6E" />
                </div>
                {newAssignedMembers?.length === 0 ? (
                  <div
                    className="zeroStateeText"
                    style={{ paddingLeft: '250px', paddingTop: '30px' }}
                  >
                    No member is available is to be shown
                  </div>
                ) : (
                  <div style={{ height: '329px', overflow: 'scroll' }}>
                    {newAssignedMembers?.length > 0 ? (
                      newAssignedMembers?.map((datas, index) => (
                        <div
                          style={{
                            display: 'flex',
                            height: '48px',
                            paddingLeft: '16px',
                            paddingRight: '16px',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <div style={{ display: 'flex', gap: '5px' }}>
                            <div>{index + 1}. </div>
                            <div>{datas?.fullName}</div>
                          </div>
                          {datas?.roleName && (
                            <div className="chip">
                              <div
                                style={{
                                  width: '56px',
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                  textWrap: 'nowrap',
                                  display: 'flex',
                                  textWrap: 'nowrap',
                                }}
                              >
                                {datas?.roleName}
                              </div>
                            </div>
                          )}
                        </div>
                      ))
                    ) : (
                      <div
                        style={{ display: 'flex', justifyContent: 'center', marginTop: '6rem' }}
                        className="zeroStateeText"
                      >
                        No members found
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default Teams
