import React, { useContext, useEffect, useRef, useState } from "react";
import desquoContext from "../../../picontext/DashBoard/desquoContext";
import { getReq, postReq, putReq } from "../../../piservices/apis";
import { convertTo24Hour, formateDate, isMd } from "../../../piservices/compCommon";
import  { constants } from "../../../piservices/constants";
import attachedFile from "../../../Assets/attach_file_add.svg";
import parse from "html-react-parser";
import "./index.css";
import "./Lms.css";
import { useParams } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import uuid from "react-uuid";
import Select from "react-select";
import { Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setShowEmailModal } from "../../../Redux/Slices/lmsSlice";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import useApiFetchCustomHookForEmailService from "../../../piservices/ApiFetchCustomHookForEmailService";
import { ReactComponent as Pdf } from "./pdf.svg"
import { ReactComponent as Exe } from "./exe.svg"
import { ReactComponent as Jpg } from "./jpg.svg"
import { ReactComponent as Dwg } from "./dwg.svg"


//  react quill tool bar 
const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // toggled buttons


  [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],

  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  // [{ direction: "rtl" }], // text direction

  // [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ font: [] }],
  // [{ align: [] }],
];
const LmsMail = () => {
  const dispatch = useDispatch();
  const [mails, setMails] = useState([]);
  const showEmailModal = useSelector((state) => state.lmsSlice.showEmailModal);
  const userData = useSelector((state) => state.lmsSlice.userData);
  const followUpDate = useSelector((state) => state.lmsSlice.followUpDate);
  const profileData = useSelector((state) => state.lmsSlice.profileData);
  // const [fileName, setFileName] = useState([]);
  // const [fileLinks, setFileLinks] = useState([]);
  const [mailFiles, setMailFiles] = useState([]);
  const [emailError, setEmailError] = useState(false);
  const context = useContext(desquoContext);
  const [tempmail, setTempmail] = useState("");
  const myRef = useRef(null);
  const desceditRef = useRef(null);
  const [buttonClickedOnce, setButtonClickedOnce] = useState(false)

  const descRef = useRef(null);
  const inputFocusRef = useRef(null);
  const emailTypes = {
    followUp: 1,
    not_interested: 5,
    no_response: 4,
    meeting_schedule: 6,
  };
  let commonCss = {
    color: "#454545",
    fontSize: "0.875rem",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
    whiteSpace: "nowrap",
    // marginLeft: "1rem",
    cursor: "pointer",
  };
  const {
    activeLead,
    leadstatus,
    setLeadstatus,
    mailStatus,
    setMailStatus,
    getActive,
    activeTem,
    mailForm,
    setMailForm,
    isnewlead,
    setMyLeadStatus,
    setActiveLeadState,
    dateTime,
    myLeadStatus,
    showAlert,
    leads,
    setLeads,
  } = context;

// bug fix Date - 19-06-2024
  console.log(mailForm,"mailformdata")

  const selectedLeadsData = useSelector(
    (state) => state.selectedLeadSlice.selectedLeadData
  );
  const { sendEmailPushBellNotifications } = useApiFetchCustomHookForEmailService()
  let isLead = window.location.href?.includes("lead-details");
  const uploadFileRef = useRef(null);

  console.log('leaddattuusss', leadstatus)

  const getActiveTemplate =
    getActive &&
    leadstatus[getActive]?.templates &&
    Object.keys(leadstatus[getActive]?.templates)?.filter(
      (f) => leadstatus[getActive]?.templates[f]?.active
    )?.length > 0 &&
    Object.keys(leadstatus[getActive]?.templates)?.filter(
      (f) => leadstatus[getActive]?.templates[f]?.active
    )[0];

  const setTemplate = async (e = 0) => {
    try {
      getActiveTemplate &&
        (await setLeadstatus({
          ...leadstatus,
          [getActive]: {
            ...leadstatus[getActive],
            templates: {
              ...leadstatus[getActive]?.templates,
              [getActiveTemplate]: {
                ...leadstatus[getActive]?.templates[getActiveTemplate],
                active: false,
              },
              [e]: {
                ...leadstatus[getActive]?.templates[e],
                active: true,
              },
            },
          },
        }));
    } catch (error) {
      console.log(error);
    }
  };
  console.log()
  function formatTemplateData(data) {
    const tempData = { ...data };
    for (const key in data) {
      tempData[key] = {
        ...data[key],
        active: false,
        body: { newText2: data[key]?.text, subject: data[key]?.subject },
      };
    }

    tempData[0] = { ...tempData[0], active: true };
    return tempData;
  }

  const init = async () => {


    if (!activeLead?.name) {
      return;
    }
    let mailT = "";

    if (leadstatus && leadstatus[getActive]?.templates[activeTem]?.body) {
      // alert("adf " + getActive )
      setMailForm(leadstatus[getActive]?.templates[activeTem]?.body);
      if (desceditRef.current) {
        desceditRef.current.innerHTML =
          leadstatus[getActive]?.templates[activeTem]?.body?.newText2 || "";
      }

      return;
    }
    if (
      leadstatus &&
      Object.keys(leadstatus[getActive]?.templates)?.length > 1
    ) {
      mailT = getActive + "_Template" + (parseInt(activeTem) + 1);
    } else {
      mailT = getActive;
    }

    if (mailT === "interested") {
      mailT = "meeting_schedule";
    }
    // const res = await getReq(
    //   constants.CRM_API +
    //   `/user/email-template?templateName=${mailT || "no_response"
    //   }&homeownerName=${activeLead?.name}&date=${new Date()}&time=10:27`
    // );

    const res = await getReq(
      constants.CRM_API +
      `/api/get-all-emailTemplate?type=${emailTypes[getActive] ? emailTypes[getActive] : 1
      }&userId=${localStorage.getItem("userId")}`
    );
    if (res && !res.error) {
      let x = leadstatus;
      x[getActive].templates = formatTemplateData(res?.data?.data);
      // if (Object.keys(leadstatus[getActive]?.templates)?.length > 1) {
      //   x[getActive].templates[activeTem].body = res?.data?.data;
      // } else {
      //   x[getActive].templates[0].body = res?.data?.data;
      // }
      setLeadstatus(x);
      setMailForm(x[getActive].templates[activeTem]?.body);
      if (desceditRef.current) {
        desceditRef.current.innerHTML =
          x[getActive].templates[activeTem]?.body?.newText2 || "";
      }
    }
  };
  useEffect(() => {
    if (isLead) {
      setActiveLeadState(selectedLeadsData)
    }
  }, [selectedLeadsData])

  useEffect(() => {
    setMails([activeLead?.email]);
    if (myRef?.current) {
      myRef.current.scrollLeft = myRef.current?.scrollWidth;
    }

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadstatus]);

  useEffect(() => {
    // setTemplate(leadstatus[getActive]?.templates[0])
    if (inputFocusRef.current) {
      inputFocusRef.current.focus()
    }

    setMails([activeLead?.email]);
    init();
  }, []);

  const createLogText = async (logText) => {
    const res = await postReq(
      `${constants.CRM_API}/user/edit-lead?id=${activeLead?._id
      }&userId=${localStorage.getItem("userId")}`,
      {
        // leadId: activeLead?._id,
        logText: [logText]
      }
    );

  }

  console.log("jatin",leadstatus,Object.keys(leadstatus)?.map((e,i)=>{
      return e
  }))


  // console.log('converted date', new Date(`${followUpDate[getActive]?.updateDate} ${followUpDate[getActive]?.updateTime}`)?.toISOString())

  function convertDateFormat(inputDate) {
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    var [day, monthIndex, year] = inputDate?.split('-');
    var monthName = months[parseInt(monthIndex) - 1];

    return `${day}-${monthName}-${year}`;
  }

  // console.log('datetimeee',new Date(`${convertDateFormat(followUpDate[getActive]?.updateDate)} ${followUpDate[getActive]?.updateTime}`)?.toLocaleString("en-US", { timeZone: "Asia/Kolkata" }))
  const handleSendEmail = async () => {
    setButtonClickedOnce(true)
    let sendEmailURL = ''

    if (Boolean(followUpDate[getActive])) {
      sendEmailURL = `${constants?.CRM_API}/user/lead-email?type=${getActive}&dateTime=${new Date(`${convertDateFormat(followUpDate[getActive]?.updateDate)} ${followUpDate[getActive]?.updateTime}`)?.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })}&emailId=${userData?.email}`
    } else {
      sendEmailURL = `${constants?.CRM_API}/user/lead-email`
    }
    try {
      const res0 = await postReq(sendEmailURL, {
        to: mails,
        // "replyTo": "testing4200@gmmail.com",
        subject: mailForm?.subject,
        emailBody: mailForm?.newText2,
        attachments: mailFiles,
      });


      if (res0) {
          sendEmailPushBellNotifications("Send Email", {
            "@leadName's": activeLead?.name,
            "@leadName":activeLead?.name,
            "@username": profileData.fullName,
            "@Status":getActive,
            '@Receiver': activeLead?.assignTo?.fullName,
            '@Sender':profileData.fullName,
            '@receiver':  activeLead?.assignTo?.fullName,
            '@sender':profileData.fullName,
            "@leadOwnerName":profileData.fullName,

          });
          createLogText(`Email has been sent to ${mails.join(",")} by ${ profileData.fullName}`)
        setButtonClickedOnce(false)
        showAlert(
          "You have successfully sent mail <br><br> Want to update the call status? ",
          "Yes",
          true,
          () => {
            handleSendCallEmail();
          },
          () => {
            handleSendEmailUpdates()
            showAlert("Mail Sent successfully ", "Okay");
          }
        );
        setMails([activeLead?.email]);
        setMailFiles([]);
        dispatch(setShowEmailModal(false));
      }
    } catch (error) {
      setButtonClickedOnce(false)
      console.log(error);
    }
  };

  const handleSendEmailUpdates = async () => {
    // await handleSendEmail();

    console.log('calling email send updates')

    const res = await postReq(constants.CRM_API + "/user/newleadActions", {
      leadId: activeLead?._id,
      leadStatus: activeLead?.leadStatus,
      leadUpdates: [
        {
          isEmailSend: true,
          callLeadStatus: activeLead?.leadUpdates?.length > 0
            ? activeLead?.leadUpdates[activeLead?.leadUpdates?.length - 1]
              ?.callLeadStatus
            : 1,
          text: dateTime[getActive]?.text,
          updateDate:
            activeLead?.leadUpdates?.length > 0
              ? activeLead?.leadUpdates[activeLead?.leadUpdates?.length - 1]
                ?.updateDate
              : "",
          updateTime: activeLead?.leadUpdates?.length > 0
            ? activeLead?.leadUpdates[activeLead?.leadUpdates?.length - 1]
              ?.updateTime
            : false,
          senderId: localStorage.getItem("userId"),
        },
      ],
    });



    if (res && !res.error) {
      let x = leads;
      x.data[activeLead?.idx] = res?.data?.data;
      await setLeads({ ...x });
      showAlert("Mail Sent successfully and Status updated  ", "Okay");
    }
  };

  const handleSendCallEmail = async () => {
    // await handleSendEmail();

    console.log('updating email status')

    const res = await postReq(constants.CRM_API + "/user/newleadActions", {
      leadId: activeLead?._id,
      leadStatus: activeLead?.leadStatus,
      leadUpdates: [
        {
          isEmailSend: true,
          callLeadStatus: leadstatus[getActive]?.value,
          text: dateTime[getActive]?.text,
          updateDate:
            dateTime[getActive]?.updateDate || formateDate(new Date()),
          updateTime: dateTime[getActive]?.updateTime,
          senderId: localStorage.getItem("userId"),
        },
      ],
    });

    if (res && !res.error) {
      let x = leads;
      x.data[activeLead?.idx] = res?.data?.data;
      await setLeads({ ...x });
      showAlert("Mail Sent successfully and Status updated  ", "Okay");
    }
  };
  function removeMail(index) {
    const filteredMails = mails?.filter((item, idx) => idx !== index);
    setMails(filteredMails);
  }
  function removeFile(index) {
    const filteredFiles = mailFiles?.filter((item, idx) => idx !== index);
    setMailFiles([...filteredFiles]);
  }
  function cancelClick() {
    setMails([activeLead?.email]);
    setMailFiles([]);
    dispatch(setShowEmailModal(false));
  }

  function setMailData(events) {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,100}$/i;
    const form4Example1 = document.getElementById("form4Example1");
    if (events.key === "Enter") {
      if (emailPattern.test(events.target.value)) {
        setMails([...mails, events.target.value]);
        setTempmail("");
        setEmailError(false);
        events.target.value = "";
      } else {
        if (tempmail.length > 0) {
          setEmailError(true);
        } else {
          setEmailError(false);
        }

        form4Example1.setCustomValidity("I expect an e-mail, darling!");
        // setLeadEmail(inputValue);
        // events.target.value = ;
      }
    } else {
      if (tempmail.length == 0) {
        setEmailError(false);
      }
    }
  }
  useEffect(() => {
    if (myRef?.current) {
      myRef.current.scrollLeft = myRef.current.scrollWidth;
    }
  }, [mails]);

  const uploadFile = async (event) => {
    const { files } = event.target;
    for (let i = 0; i < files.length; i++) {
      let filesToUpload = new FormData();
      filesToUpload.append("files", event.target.files[i]);
      const res = await putReq(`${constants.S3_API}/api/upload`, filesToUpload);
      if (res && !res.error) {
        setMailFiles([
          ...mailFiles,
          {
            filename: files[i].name,
            path: res.data.locations[0],
          },
        ]);
      } else {
        console.log(res.error);
      }
    }
  };

console.log(leadstatus,)

  return (
    <div >
      <input
        className="d-none"
        ref={uploadFileRef}
        onChange={uploadFile}
        // multiple
        type="file"
        name=""
        id=""
      />
      <Modal
        // title="Update Lead"
        // width="max-content"
        // closable={true}
        // show={updatePanel}
        // style={{ top: "33px" }}
        // dialogClassName={}
        // footer={[]}
        style={{
          overflow: "hidden",
          maxHeight: "fit-content",
          borderBottomLeftRadius: "0.5rem"
        }}
        backdrop={true}
        onHide={() => dispatch(setShowEmailModal(false))}
        // size="lg"
        show={showEmailModal}
        dialogClassName="modal-90w"
      >
        {/* <Modal.Header closeButton style={{ borderBottom: "none" }}>
              <Modal.Title>
                <span style={{ fontSize: "20px" }}>Update Project</span>
              </Modal.Title>
            </Modal.Header> */}

       
          <Modal.Body >

            
            <div style={{ maxHeight:"74vh", overflowX:"scroll", display: "flex", gap: "5px", flexDirection: "column" }}>
              {/* {isLead && <h5 className="px-4">Select a template</h5>} */}
              <div style={{ height: "48px", padding: "24px", flexShrink: "0", display: "flex", alignItems: "center" }}>
                <h6 style={{ width: "", height: "22px", fontSize: "18px", fontWeight: "500", letterSpacing: "0.21px", color: "#000" }}>Send a email to {activeLead?.name}</h6>

              </div>
              <div className="d-flex w-100 justify-content-md-center " style={{ padding: "0px 24px", height: "31px" }}>
                {leadstatus &&
                 
                  Object.keys(leadstatus)?.map((e, i) => {
   
                    console.log("Email leaddata",leadstatus[e],leadstatus[e].label)
             

                    return (
                      !leadstatus[e].nomail && 
                      (isnewlead
                        ? true
                        : !mailStatus?.of
                          ? true
                          : !leadstatus[e].isNewOnly) && (
                        <button
                          key={i}
                          type="button"
                          style={{
                            height: window.screen.width < 1400 ? "4vh" : "5vh",
                            display: "flex",
                            alignItems: "center",
                            padding: "8px 34.375px 8px 36.375px",
                            width: "30%",
                     
                            height: "31px",
                            justifyContent: "center",
                          }}

                          className={`btn ${leadstatus[e].active
                            ? "btn-secondary  "
                            : "btn-outline-light border cl-grey1 "
                            } px-2 fw-normal me-2 mt-0 ws-nowrap`}
                          // data-mdb-ripple-color="dark"
                          onClick={async () => {
                            try {
                              getActive
                                ? await setLeadstatus({
                                  ...leadstatus,
                                  [getActive]: {
                                    ...leadstatus[getActive],
                                    active: false,
                                    clicked: false,
                                  },
                                  [e]: {
                                    ...leadstatus[e],
                                    active: true,
                                    clicked: false,
                                  },
                                })
                                : await setLeadstatus({
                                  ...leadstatus,
                                  [e]: {
                                    ...leadstatus[e],
                                    active: true,
                                    clicked: false,
                                  },
                                });
                              isnewlead && setMyLeadStatus(e);
                            } catch (error) {
                              console.log(error);
                            }
                          }}
                        >
                          {leadstatus[e]?.label==="Meeting Scheduled"?"Interested":leadstatus[e]?.label}
                          {/* {leadstatus[e]?.label} */}
                        </button>
                      )
                    );
                  })}
              </div>
              {console.log('templatess', leadstatus[getActive]?.templates['0'].name)}
              {console.log('getactiveee', getActive)}
              <p style={{ fontSize: "14px", fontWeight: "500",  marginTop: "4px", marginBottom:"0px", padding: "0px 0px", color: "#252525" }}  >Email Template</p>
              <div className="px-1">
                {getActive && leadstatus[getActive]?.templates && (
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder={!leadstatus[getActive]?.templates[0].body ? 'No Template' : leadstatus[getActive]?.templates['0'].name}
                    isClearable={true}
                    isSearchable={true}


                    components={{
                      IndicatorSeparator: () => null
                    }}
                    onChange={(e) => setTemplate(e?.value)}
                    name="assignTo"
                    options={!leadstatus[getActive]?.templates[0].body ? [{ value: 'No Option Available', label: 'No Option Available', disabled: true }] : [...(
                      [...Object.keys(leadstatus[getActive]?.templates)] || []
                    ).map((d) => ({
                      value: d,
                      label: leadstatus[getActive]?.templates[d].name,
                    }))]}
                    noOptionsMessage={() => "No more options"}
                    isOptionDisabled={(option) => option.disabled}
                  />
                )}
              </div>
              <div className="" style={{ height: "60vh", marginTop:"0px" ,padding:"0px"}}>
                {/* <h6 className="px-4">Send a email to {activeLead?.name}</h6> */}
                {/* <hr  style={{background:"red"}}/> */}
                <div
                  className=" pt-2 lmsmail"
                  style={{ height: window.screen.width < 1400 ? "75%" : "65%" ,}}
                >
                  <div style={{display:"flex",flexDirection:"column",gap:"4px"}}>
                    <div
                      className="form-outline d-flex align-items-center p-1 inpactive subinput3"
                      style={{
                        position: "relative",
                        borderRadius: "5px",
                        border: "1px solid #d3d3d3",
                        marginTop:"5px",

                        padding: "18px 16px",
                        // width:"559px",
                        flexShrink: "0",
                      }}
                    >
                      <div
                        className="labelTop"
                        style={{ transform: "translate(10px, -23px)", marginTop: "6px", fontSize: "12px", fontWeight: "500", color: "black" }}
                      >
                        To{" "}
                      </div>
                      <ul
                        className="chipContainerEmail d-flex"
                        id="chipContainerId"
                        ref={myRef}
                        style={{
                          minWidth: mails?.length == 1 ? "fit-content" : "",

                        }}
                      >
                        {mails?.map((mail, index) => {
                         
                          return (
                            <li className="chipsEmail" style={{ border: "1px solid #d3d3d3", fontWeight: "500", fontSize: "12px", padding: "2px 3px" }}>
                              {mail}
                              {!mailStatus?.of && <RxCross2
                                size={16}
                                style={{ cursor: "pointer" }}
                                color="#74737F"
                                onClick={() => removeMail(index)}
                              />}
                            </li>
                          );
                        })}
                      </ul>
                      <input
                        type="text"
                        // ref={inputFocusRef}
                        id="form4Example1"
                        placeholder="Enter mail"
                        autoFocus
                    
                        // value={activeLead?.email || ""}
                        onChange={(e) => setTempmail(e.target.value)}
                        onKeyUp={setMailData}
                        disabled={mailStatus?.of}
                        style={{
                          height: "inherit",
                          fontSize: "13px"
                          ,width:"100%",padding:"5px",outline:"none",border:"none"
                          // borderBottom: "1px solid grey",
                        }}
                      // disabled={true}
                      />
                    </div>
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        color: "red",
                        fontSize: "12px",
                        paddingRight: "2.5rem",
                        transform: "translateY(-20px)",
                      }}
                    >
                      {emailError && "Enter valid email"}
                    </p>
                    <div
                      className="form-outline mb-4 p-1 subinput4"
                      style={{
                        position: "relative",
                        borderRadius: "5px",
                        border: "1px solid #d3d3d3",
                      }}
                    >
                      <div className="labelTop" style={{ fontSize: "13px", fontWeight: "500", color: "black", marginTop: "1px" }}>Subject : </div>

                      {activeTem && (
                        <input
                          type="email"
                          id="form4Example2"
                          className=" "
                          value={mailForm!==undefined?mailForm?.subject:""}
                          onChange={(e) => {
                            setMailForm({
                              ...mailForm,
                              subject: e.target.value,
                            });
                          }}


                          style={{ fontSize: "13px",width:"100%",padding:"5px",outline:"none",border:"none" }}
                        />
                      )}
                    </div>
                    <div
                      className=" mb-4 p-1 subinput5"
                      style={{
                        position: "relative",
                        borderRadius: "5px",
                        border: "1px solid #d3d3d3",
                      }}
                    >
                      <div className="labelTop" style={{ color: "black", fontSize: "13px" }}>Body : </div>
                      <div  className="">
                      <ReactQuill theme="snow" style={{display:"flex",flexDirection:"column-reverse"}}  className="react-quill" modules={{ toolbar: toolbarOptions }}          value={mailForm!==undefined?mailForm?.newText2:""} onChange={(e) => {
                        setMailForm({
                          ...mailForm,
                          newText2: e,
                        });
                      }} />
                      </div>

                      {/* edit by me  */}
                     

                      {/* <div
                      className="desc form-control br-0 overflow-auto "
                      style={{
                        height: window.screen.width < 1400 ? "19vh" : "150px",
                        fontSize:"14px",
                        
                      }}
                      id={`desc`}
                      ref={descRef}
                      onClick={() => {
                        desceditRef.current.innerHTML =
                          descRef.current?.innerHTML;
                        desceditRef.current.classList.remove("d-none");
                        descRef.current.classList.add("d-none");
                        desceditRef.current.focus();
                      }}
                    >
                      {parse(mailForm?.newText2 || "")}
                    </div> */}

                      {/* <div
                      className={`descedit  d-none  form-control overflow-auto  br-0`}
                      style={{
                        height: window.screen.width < 1400 ? "19vh" : "150px",
                        fontSize:"14px",
                        

                      }}
                      id={`descedit`}
                      ref={desceditRef}
                      contentEditable="true"
                      suppressContentEditableWarning="true"
                      onInput={(e) => {
                        setMailForm({
                          ...mailForm,
                          newText2: e.target.innerHTML,
                        });
                      }}
                      
                    ></div> */}
                    </div>

                    {/* Checkbox */}
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "column", gap: "20px",  marginTop: "5px" }}>
                  <div
                    className="d-flex align-items-center"
                    style={{ height: "3rem" }}
                  >
                    <div style={{ display: "flex", flexDirection: "column", marginTop: "5px",width:"100%" }}>


                      <div
                        className="chipContainer"

                        style={{ display: "flex",flexWrap: "wrap", padding: "0px 20px", margin: "", height: "4rem",marginTop:"10px" }}
                      >
                        {mailFiles?.map((file, index) => {
                          console.log(file.filename.includes(".pdf"), "filedata")
                          return (
                            <div className="chips">
                              {/* {file.includes(".pdf")} */}
                              {/* pdf icon  */}
                              {
                                file && file.filename && file.filename.includes(".pdf") ? <Pdf /> : file.filename.includes(".xlsx") ? <Exe /> : file.filename.includes("jpeg") ? <Jpg /> : file.filename.includes("dwg") ? <Dwg /> : ""
                              }
                              <span style={{ whiteSpace: "nowrap" }}>
                                {file?.filename}
                              </span>
                              <span>
                                <RxCross2
                                  size={16}
                                  style={{ cursor: "pointer" }}
                                  color="#74737F"
                                  onClick={() => removeFile(index)}
                                />
                              </span>
                            </div>
                          );
                        })}


                        {mailFiles?.filter((file, inde) => {
                          return
                        })}
                      </div>
                      <div className="attach-main" style={{ display: "flex", marginLeft: "10px", height: "36px", width: "116px", padding: "4px 8px", alignItems: "center", borderRadius: "1px", gap: "4px", background: "#fff", justifyContent: "center" }}>
                        <img
                          src={attachedFile}
                          className="attachfile"
                          alt=""
                          style={{ borderWidth: 0 }}
                          onClick={() => uploadFileRef.current.click()}
                        />
                        <span
                          className="attach"
                          style={commonCss}
                          onClick={() => uploadFileRef.current.click()}
                        >
                          Attach Files
                        </span>
                      </div>

                    </div>
                  </div>

                  {/* Submit button */}
                  <div
                    className="d-flex justify-content-end"
                    style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
                  >
                    <div className="mv-w-50 px-2">
                      <button
                        type="button"
                        className="btn btn-light bg-grey1 mv-w-100  px-5  "
                        data-mdb-toggle="modal"
                        data-mdb-target={`${isMd && "#leadmail"}`}
                        onClick={() => cancelClick()}
                      >
                        Cancel
                      </button>
                    </div>

                    <div className="mv-w-50 px-2">
                      {mailStatus?.of && !leadstatus[getActive]?.addEmail ? (
                        <button
                          type="button"
                          className="btn btn-primary mv-w-100 bg-base-blue box-shadow-none px-5  "
                          onClick={() => {
                            setMailStatus({ send: true, of: getActive });
                            dispatch(setShowEmailModal(false));
                          }}
                        // data-mdb-target={`${isMd ? "#staticBackdrop" : ""}`}
                        // data-mdb-dismiss="modal"
                        // data-mdb-toggle="modal"
                        >
                          Save
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-primary mv-w-100 box-shadow-none px-5 "
                          onClick={() => {
                            // setMailStatus({ send: true, "of": getActive })
                            // if (leadstatus[getActive]?.addEmail) {
                            //   handleSendCallEmail();
                            // } else {
                            handleSendEmail();
                            // }
                          }}
                          // data-mdb-target={`${isMd ? "#staticBackdrop" : ""}`}
                          // data-mdb-dismiss="modal"
                          // data-mdb-toggle="modal"
                          disabled={tempmail?.length > 0 || !Boolean(mailForm?.subject?.length) || emailError || buttonClickedOnce || !Boolean(mailForm?.newText2?.length)}
                        >
                          {!buttonClickedOnce ? (
                            "Send Mail"
                          ) : (
                            <Spinner
                              animation="border"
                              role="status"
                              style={{ height: "1.25rem", width: "1.25rem" }}
                            >
                              <span className="visually-hidden">Loading...</span>
                            </Spinner>
                          )}

                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>  
          </Modal.Body>
       
      </Modal>
    </div>
  );
};

export default LmsMail;
