import React, { useState } from "react";
import TopHeaderCommon from "./TopHeaderCommon";
import ProjectHeader from "./ProjectHeader";
import { useParams } from "react-router-dom";
import styles from "./NewLayout.module.css";
import Lms from "../../Lms/Lms";
import LeadsDetail from "../../Lms/LeadManagements/LeadsDetail";

const MainLayoutPage = ({ setProgress }) => {
  const { id } = useParams();
  const [selectedtab, setSelectedtab] = useState();

  return (
    <div className="d-flex flex-column" style={{ height: "" }}>
      <div className={styles.main_cont}>
        <TopHeaderCommon />
        <ProjectHeader
          // a={selectedtab}
          toggler="true"
          setProgress={setProgress}
        />
      </div>
      <div
        className="container-box"
        style={{ position: "relative", zIndex: "" }}
      >
        <Lms setSelectedtab={setSelectedtab} setProgress={setProgress} />
      </div>
    </div>
  );
};

export default MainLayoutPage;
