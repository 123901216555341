import React, { useContext } from 'react'
import desquoContext from '../picontext/DashBoard/desquoContext';
import { getReq } from './apis';
// import expf from './constants';
import { constants } from './constants';
import { formateDate } from './compCommon';
import { setAddLeadFormTemplate, setLeadsCount } from '../Redux/Slices/lmsSlice';
import { useDispatch } from 'react-redux';

function useApiFetchForLeadCountCustomHook() {
  const context = useContext(desquoContext);
const dispatch = useDispatch()

  const getLeadsCount = async(tab)=>{
    let countObj = {
    }
    const res = await getReq(
      constants.CRM_API + "/user/newLeadCount?pageNo=0" + (tab > 0 ? "&type=" + tab : "")
    );
    if (res && !res.error) {
      countObj.all = res?.data?.data?.totalCount
      countObj.interested = res?.data?.data?.interestedLeadsCount
      countObj.followUp = res?.data?.data?.followUpLeadsCount
      countObj.noResponse = res?.data?.data?.noResponseLeadsCount
      countObj.notInterested = res?.data?.data?.notInterestedLeadsCount
      countObj.unassigned = res?.data?.data?.unAssignedLeadsCount
      countObj.won=res?.data?.data?.wonLeadsCount
      dispatch(setLeadsCount({ ...countObj }))
    } else {
      console.error("Something went wrong")
    }
  }
  const getLeadFormTemplate = async (templateId)=>{
    let res;
    if(templateId){
      res = await getReq(
        `${constants.CRM_API}/template/get-default-lead-template?userId=${localStorage.getItem('userId')}&id=${templateId}`
      );
    }else{
      res = await getReq(
        `${constants.CRM_API}/template/get-default-lead-template?userId=${localStorage.getItem('userId')}`
      );
    }
   
    if (res && !res.error) {
     if(templateId){

       dispatch(setAddLeadFormTemplate(res.data.data))
     }else{
      dispatch(setAddLeadFormTemplate(res.data.data[0]))

     }
    }else{

    }
  }

  return {getLeadsCount, getLeadFormTemplate}
}

export default useApiFetchForLeadCountCustomHook