import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isChecked: false,
    isChecked2: false,
    isChecked3: false,
    currProjectDataObj:{},
    prevBtn:false,
    clientId:"",
    mapState:false
}
export const convertToProjectSlice = createSlice({
    name: 'convertToProjectLead',
    initialState,
    reducers:{
        setIsChecked: (state, action) => {
            state.isChecked = action.payload;
          },
        setIsChecked2: (state, action) => {
            state.isChecked2 = action.payload;
          },
        setIsChecked3: (state, action) => {
            state.isChecked3 = action.payload;
          },
        projectDataCurrObj: (state, action) => {
            state.currProjectDataObj = action.payload;
        },
        setPrevButton: (state, action) => {
            state.prevBtn = action.payload;
        },
        mapState: (state, action) => {
            state.mapState= action.payload;
        },
        setClientId: (state, action) => {
            state.clientId = action.payload;
        }
    }
})

export const {setIsChecked,setIsChecked2,setIsChecked3, projectDataCurrObj,setClientId,setPrevButton, mapState} = convertToProjectSlice.actions;
export default convertToProjectSlice.reducer;
