import React, { useEffect, useState } from "react";
import styles from "../NewLayout.module.css";
import {
  Autocomplete,
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getReq, postReq } from "../../../../piservices/apis";
import { constants } from "../../../../piservices/constants";
import toast from "react-hot-toast";
import { PiCaretDown } from "react-icons/pi";

// get sepecified component
const GetComponent = React.memo(
  ({ field, individualData, changeIndividualValueHandler }) => {
    const [filteredWorkScopeName, setFilteredWorkScopeName] = useState([]);
    const [inputValueWorkScope, setInputValueWorkScope] = useState("");
    const [addNewWorkScope, setAddNewWorkType] = useState("");
    const [leadWorkTypes, setLeadWorkTypes] = useState([]);
    const [filteredLeadSourceName, setFilteredLeadSourceName] = useState([]);
    const [inputValueLeadSource, setInputValueLeadSource] = useState("");
    const [addNewLeadSource, setAddLeadNewSource] = useState("");
    const [editLeadBoolean, setEditLeadBoolean] = useState(false);
    const [leadSources, setLeadSources] = useState([]);
    const [userData, setUserData] = useState({});
    const [loggedInUserName, setLoggedInUserName] = useState(true);
    const [teamLeadArr, setTeamLeadArr] = useState([]);
    const [assignedTo, setAssignedTo] = useState("");
    const [assignedToName, setAssignedToName] = useState("UNASSIGNED");
    const [assignedToObject, setAssignedToObject] = useState("");

    const defaultValue = {
      label: "UNASSIGNED",
      _id: "",
      email: "",
    };  

    useEffect(() => {
      getLeadSource();
      // getSourceName();
      // getTeamLeads();
      getWorkTypes();
      getUserbyId(localStorage.getItem("userId"));
    }, []);

    const getUserbyId = async (id) => {
      const res = await getReq(
        `${constants.CRM_API}/user/getUserById?userId=${id}`
      );
      if (res && !res.error) {
        setUserData(res?.data?.data);
        setLoggedInUserName(res?.data?.data.fullName);
        // console.log("YeRahaUser", res?.data?.data.rolesAssigned.CRM_API.edit);
        setEditLeadBoolean(res?.data?.data?.rolesAssigned?.crm?.edit);
      } else {
        console.log(res.error);
      }
    };

    const handleInputValueInputChange = (event, newInputValue) => {
      setInputValueLeadSource(newInputValue);
      setAddLeadNewSource(newInputValue);
    };
    const saveLeadSource = async () => {
      if (addNewLeadSource != "" && editLeadBoolean) {
        const res = await postReq(
          `${constants.CRM_API}/user/save-lead-source`,
          {
            userId: localStorage.getItem("userId"),
            leadSource: addNewLeadSource,
          }
        );

        if (res && !res.error) {
          // console.log('getting lead source', res.data.data)
          getLeadSource();
          setAddLeadNewSource("");
          toast.success("New Lead Source Added");
        } else {
          console.log(res.error);
        }
      }
    };
    const getLeadSource = async () => {
      const res = await getReq(
        `${
          constants.CRM_API
        }/user/get-lead-source?userId=${localStorage.getItem("userId")}`
      );
      if (res && !res.error) {
        // console.log('getting lead source', res.data.data)
        setLeadSources(res.data.data);
        setFilteredLeadSourceName(res.data.data);
      } else {
        console.log(res.error);
      }
    };

    const handleWorkScopeInputChange = (event, newInputValue) => {
      setInputValueWorkScope(newInputValue);
      setAddNewWorkType(newInputValue);
    };
    const saveworkScope = async () => {
      if (addNewWorkScope != "") {
        const res = await postReq(`${constants.CRM_API}/user/save-work-type`, {
          userId: localStorage.getItem("userId"),
          type: inputValueWorkScope,
          // type: addNewWorkScope,
        });

        if (res && !res.error) {
          // console.log('getting lead source', res.data.data)
          getWorkTypes();
          setAddNewWorkType("");
          toast.success("New Work Scope Added");
        } else {
          console.log(res.error);
        }
      }
    };
    const getWorkTypes = async () => {
      const res = await getReq(
        `${constants.CRM_API}/user/get-work-types?userId=${localStorage.getItem(
          "userId"
        )}`
      );
      if (res && !res.error) {
        setLeadWorkTypes(res.data.data);

        setFilteredWorkScopeName(res.data.data);

        console.log("thisIsData : ", res.data.data);
      } else {
        console.log(res.error);
      }
    };

    // console.log(field, "okaisj");
    switch (field?.fieldType) {
      case "Text":
        return (
          <TextField
            label={field?.fieldName}
            type="text"
            required={field?.isRequired}
            value={individualData[field._id]}
            onChange={(e) => changeIndividualValueHandler(e, field._id)}
            fullWidth
          />
        );
      case "Number":
        if (field?.fieldName === "Phone Number") {
          return (
            <FormControl
              fullWidth
              sx={{
                m: 0,
                width: "100%",
                "& .MuiOutlinedInput-root": { padding: 0 },
              }}
              variant="outlined"
            >
              <InputLabel htmlFor="phone-number">{field?.fieldName}</InputLabel>
              <OutlinedInput
                id="phone-number"
                // type="number"
                required={field?.isRequired}
                value={individualData[field._id]}
                onChange={(e) => changeIndividualValueHandler(e, field._id)}
                startAdornment={
                  <InputAdornment
                    style={{
                      borderRight: "1px solid #EDEDED",
                      padding: "10px",
                    }}
                    position="start"
                  >
                    +91
                  </InputAdornment>
                }
                label={field?.fieldName}
              />
            </FormControl>
          );
        } else if (field?.fieldName === "Expected Budget") {
          return (
            <FormControl
              fullWidth
              sx={{
                m: 0,
                width: "100%",
                "& .MuiOutlinedInput-root": { padding: 0 },
              }}
              variant="outlined"
            >
              <InputLabel htmlFor="expected-budget">
                {field?.fieldName}
              </InputLabel>
              <OutlinedInput
                id="expected-budget"
                // type="number"
                required={field?.isRequired}
                value={individualData[field._id]}
                onChange={(e) => changeIndividualValueHandler(e, field._id)}
                startAdornment={
                  <InputAdornment style={{ padding: "10px" }} position="start">
                    ₹
                  </InputAdornment>
                }
                label={field?.fieldName}
              />
            </FormControl>
          );
        } else if (field?.fieldName === "Area") {
          return (
            <FormControl fullWidth sx={{ m: 0 }} variant="outlined">
              <InputLabel htmlFor="area">{field?.fieldName}</InputLabel>
              <OutlinedInput
                id="area"
                // type="number"
                required={field?.isRequired}
                value={individualData[field._id]}
                onChange={(e) => changeIndividualValueHandler(e, field._id)}
                endAdornment={
                  <InputAdornment
                    position="end"
                    style={{ display: "flex", gap: "4px" }}
                  >
                    Sqft <PiCaretDown />
                  </InputAdornment>
                }
                label={field?.fieldName}
              />
            </FormControl>
          );
        } else {
          return (
            <TextField
              label={field?.fieldName}
              // type="number"
              required={field?.isRequired}
              value={individualData[field._id]}
              onChange={(e) => changeIndividualValueHandler(e, field._id)}
              fullWidth
            />
          );
        }
      case "Email":
        return (
          <TextField
            label={field?.fieldName}
            type="email"
            required={field?.isRequired}
            value={individualData[field._id]}
            onChange={(e) => changeIndividualValueHandler(e, field._id)}
            fullWidth
          />
        );
      case "Website":
        return (
          <TextField
            label={field?.fieldName}
            type="text"
            required={field?.isRequired}
            value={individualData[field._id]}
            onChange={(e) => changeIndividualValueHandler(e, field._id)}
            fullWidth
          />
        );
      case "Date":
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={field?.fieldName}
              required={field?.isRequired}
              value={individualData[field._id]}
              onChange={(e) => changeIndividualValueHandler(e, field._id)}
              fullWidth
            />
          </LocalizationProvider>
        );
      case "Drop-Down":
      case "Dropdown":
        if (field?.fieldName === "Work Scope") {
          return (
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              // style={{ width: "14rem" }}
              options={filteredWorkScopeName
                ?.slice()
                .sort((a, b) => a.type.localeCompare(b.type))
                .map((workScope) => {
                  return {
                    label: workScope.type,
                  };
                })}
              onChange={(_object, value) => {
                // setWorkScope(value?.label);
                changeIndividualValueHandler(value?.label, field._id);
              }}
              inputValue={inputValueWorkScope}
              onInputChange={handleWorkScopeInputChange}
              noOptionsText={
                <span onClick={saveworkScope} style={{ cursor: "pointer" }}>
                  {inputValueWorkScope ? (
                    <span>
                      Create :{" "}
                      <span style={{ color: "blue" }}>
                        "{inputValueWorkScope}"
                      </span>
                    </span>
                  ) : (
                    "No options"
                  )}
                </span>
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  // size="small"
                  label="Work Scope"
                  onKeyDown={(event) => {
                    if (
                      event.key === "Enter" &&
                      !filteredWorkScopeName.some(
                        (option) => option.type === inputValueWorkScope
                      )
                    ) {
                      saveworkScope();
                    }
                  }}
                />
              )}
            />
          );
        } else if (field?.fieldName === "Lead Source") {
          return (
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              // style={{ width: "14rem" }}
              options={filteredLeadSourceName
                ?.slice()
                .sort((a, b) => a.leadSource.localeCompare(b.leadSource))
                .map((leadWorkType) => {
                  return {
                    label: leadWorkType.leadSource,
                  };
                })}
              onChange={(_object, value) => {
                // setSelectedLeadSource(value?.label);
                changeIndividualValueHandler(value?.label, field._id);
              }}
              inputValue={inputValueLeadSource}
              onInputChange={handleInputValueInputChange}
              noOptionsText={
                <span onClick={saveLeadSource} style={{ cursor: "pointer" }}>
                  {inputValueLeadSource && editLeadBoolean ? (
                    <span>
                      Create :{" "}
                      <span style={{ color: "blue" }}>
                        "{inputValueLeadSource}"
                      </span>
                    </span>
                  ) : (
                    "No options"
                  )}
                </span>
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  // size="small"
                  label="Lead Source"
                  onKeyDown={(event) => {
                    if (
                      event.key === "Enter" &&
                      editLeadBoolean &&
                      !filteredLeadSourceName.some(
                        (option) => option.leadSource === inputValueLeadSource
                      )
                    ) {
                      saveLeadSource();
                    }
                  }}
                />
              )}
            />
          );
        } else if (field?.fieldName === "Assign To") {
          console.log("inside!")
          return (
            <FormControl style={{ width: "14rem" }} size="small">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                // options={teamLeadArr?.map((teamLead) => {
                //   return {
                //     label: teamLead.fullName,
                //     _id: teamLead._id,
                //     email: teamLead.email,
                //   };
                // })}
                options={[
                  defaultValue,
                  ...teamLeadArr?.map((teamLead) => ({
                    label: teamLead.fullName,
                    _id: teamLead._id,
                    email: teamLead.email,
                  })),
                ]}
                // onChange={(object, value) => (
                //   setAssignedTo(value?._id),
                //   setAssignedToName(value?.label),
                //   setAssignedToObject({
                //     email: value?.email,
                //     fullName: value?.label,
                //     _id: value?._id,
                //   })
                // )}
                onChange={(object, value) => {
                  if (value) {
                    setAssignedTo(value._id);
                    setAssignedToName(value.label);
                    setAssignedToObject({
                      email: value.email,
                      fullName: value.label,
                      _id: value._id,
                    });
                  } else {
                    // Handle the case when "UNASSIGNED" is selected
                    // setAssignedTo("");
                    // setAssignedToName("UNASSIGNED");
                    // setAssignedToObject("");
                  }
                }}
                value={assignedToName}
                // sx={{ width: 225 }}
                renderInput={(params) => (
                  <TextField {...params} size="small" label="Assign To" />
                )}
                // MenuProps={{ PaperProps: { sx: { maxHeight: 100 } } }}
              />
            </FormControl>
          );
        }
        return (
          <FormControl fullWidth>
            <InputLabel id={field?.fieldName.toLowerCase()}>
              {field?.fieldName}
            </InputLabel>
            <Select
              labelId={field?.fieldName.toLowerCase()}
              id={field?.fieldName.toLowerCase()}
              label={field?.fieldName}
              required={field?.isRequired}
              value={individualData[field._id]}
              onChange={(e) => changeIndividualValueHandler(e, field._id)}
            >
              {field?.options &&
                field?.options.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        );
    }
  }
);

export default GetComponent;
