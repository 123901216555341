import React, { useEffect, useRef, useState } from "react";
import PlusBlue from "../../../Assets/PlusBlue.svg";
import ArrowUp from "../../../Assets/ArrowUp.svg";
import ArrowBelow from "../../../Assets/ArrowBelow.svg";
import crossBlack from "../../../Assets/crossBlack.svg";
import upGrey from "../../../Assets/upGrey.svg";
import dropdownArrow from "../../../Assets/dropdownArrow.svg";
import greenCheck from "../../../Assets/greenCheck.svg";
import MagnifyingBlack from "../../../Assets/MagnifyingBlack.svg";
import "./pipeLineStyle.css";
import TextField from "@mui/material/TextField";
import { Dropdown, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setShowModalPipe } from "../../../Redux/Slices/lmsSlice";
import { constants } from "../../../piservices/constants";
import axios from "axios";
import Board, { moveCard } from "@asseinfo/react-kanban";
import "@asseinfo/react-kanban/dist/styles.css";
import { IoEllipse } from "react-icons/io5";
import { PiBriefcaseLight } from "react-icons/pi";
import { getReq, postTReq } from "../../../piservices/apis";
import { toast } from "react-toastify";

const Pipelines = () => {
  const showModalPipe = useSelector((state) => state.lmsSlice.showModalPipe);
  const dispatch = useDispatch();
  const [isExpanded, setIsExpanded] = useState(false);
  const [pipelineName, setPipelineName] = useState("");
  const [pipelineDesc, setPipelineDesc] = useState("");
  const [storePipeline, setStorePipeline] = useState();
  const [storePart, setStorePart] = useState({});
  const [storePipelineStage, setStorePipelineStage] = useState([]);
  const [newStages, setNewStages] = useState([]);
  const [pipelineStoreId, setPipelineStoreId] = useState();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedIds, setSelectedIds] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [leadTagss, setLeadTagss] = useState([]);
  const [newId, setNewId] = useState();
  const dropdownRef = useRef(null);
  const [editLeadBoolean, setEditLeadBoolean] = useState(false);

  const getUserbyId = async (id) => {
    const res = await getReq(
      `${constants.CRM_API}/user/getUserById?userId=${localStorage.getItem("userId")}`
    );

    if (res && !res.error) {
      setEditLeadBoolean(res?.data?.data?.rolesAssigned?.crm?.edit);
    } else {
    }
  };
  useEffect(() => {
    getUserbyId();
  }, [])
  
  const getPipeline = async (_id, check) => {
    await axios
      .get(
        `${constants.CRM_API}/user/get-pipeline?userId=${localStorage.getItem(
          "userId"
        )}`
      )
      .then(function (response) {
        const data = response?.data?.data;
        setStorePipeline(data);
        setPipelineStoreId(data?._id);
        setStorePipelineStage(data?.stages);
        setPipelineName(data.name);
        setPipelineDesc(data.description);
      })
      .catch(function (error) {
      });
  };
  const getPartiLead = async () => {
    const stageIds = storePipelineStage.map(stage => stage._id);
    const res = await postTReq(
      `${constants.CRM_API
      }/user/leads-in-stage?userId=${localStorage.getItem(
        "userId"
      )}`, {
      stageId: stageIds,
      leadTag: selectedIds,
      assignedTo: selectedUsers
    }
    );
    if (res && !res.error) {
      setStorePart(res?.data?.data);
    } else {
    }
  };
  const [stagesData, setStagesData] = useState([]);
  useEffect(() => {
    if (storePart && Object.keys(storePart).length > 0) {
      const newStagesData = storePipelineStage.map(stage => {
        const leads = storePart[stage.stageName] || [];
        const items = leads.map(lead => {
          const date = new Date(lead.updatedAt);
          const formattedDate = date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: '2-digit'
          });

          return {
            name: `${lead.firstName} ${lead.lastName}`,
            amount: lead.budget == undefined ? 0 : lead.budget,
            lastUpdate: formattedDate,
            status: lead?.leadStatus == 2 ? "Follow up later" : lead?.leadStatus == 1 ? "New Lead" : lead?.leadStatus == 3 ? "Interested" : lead?.leadStatus == 5 ? "Not Interested" : lead?.leadStatus == 6 ? "No Response" : "Won", // Adjust based on your status mapping
            tag: lead?.leadTag[0]?.tagName, // Adjust based on your tag mapping
            tagNo: lead?.leadTag?.length == 1 ? 0 : lead?.leadTag?.length == 0 ? "0" : lead?.leadTag?.length - 1, // Adjust based on your tag number mapping
            id: lead._id,
            tagColor: lead?.leadTag[0]?.tagColorCode
          };
        });
        return {
          stage: {
            name: stage.stageName,
            forecastedRevenue: stage.forecastedRevenue,
            leadsCount: stage.leadsCount
          },
          items: items
        };
      });
      setStagesData(newStagesData);
    }
  }, [storePart]);

  const [board, setBoard] = useState({ columns: [] });
  const colors = [
    "#26355D",
    "#AF47D2",
    "#E65C19",
    "#FF8F00",
    "#028391",
    "#667BC6",
    "#E0A75E",
    "#1679AB",
    "#365E32",
  ];

  const KanbanStage = ({ name, forecastedRevenue,leadsCount, index }) => {
    return (
      <div className="kanbanStageMain">
        <div style={{display:"flex",justifyContent:"space-between"}}>
        <div className="kanbanStageName" title={name}>
          {/* <IoEllipse style={{ height: "10px", color: colors[index % colors.length], width: "10px" }} /> */}
           {name}
        </div>
        <div style={{backgroundColor:"#ECF6FE",borderRadius:"13px",padding:"4px",fontSize:"14px",fontWeight:"500",color:"#0084EF"}}>
        {leadsCount < 10 ? `0${leadsCount}` : leadsCount}
        </div>
        </div>
        <div className="kanbanStageRevenue">
          <span
            style={{ color: "#64748B", fontWeight: "400", fontSize: "14px" }}
          >
            Budget
          </span>
          <span
            style={{ color: "#0084EF", fontWeight: "500", fontSize: "15px" }}
          >
            {forecastedRevenue === "-"
              ? forecastedRevenue
              : `₹ ${Number(forecastedRevenue).toLocaleString('en-IN')}`}

          </span>
        </div>
      </div>
    );
  };
  const hexToRgba = (hex, opacity) => {
    let r = 0, g = 0, b = 0;
    if (hex.length === 4) {
      r = parseInt(hex[1] + hex[1], 16);
      g = parseInt(hex[2] + hex[2], 16);
      b = parseInt(hex[3] + hex[3], 16);
    } else if (hex.length === 7) {
      r = parseInt(hex[1] + hex[2], 16);
      g = parseInt(hex[3] + hex[4], 16);
      b = parseInt(hex[5] + hex[6], 16);
    }
    return `rgba(${r},${g},${b},${opacity})`;
  };
  const KanbanItemTitle = ({ name, amount, lastUpdate }) => {
    return (
      <>
        <div className="kanbanItemTitle1">
          {/* <PiBriefcaseLight style={{ width: "34px", height: "34px" }} /> */}
          <div>
            <div className="kanbanItemName">{name}</div>
           <div style={{display:"flex",alignItems:"center",gap:"10px"}}>
            <div className="kanbanItemLastUpdate">Last update {lastUpdate}</div>
           <div className="kanbanItemAmount"> {amount === "-"
          ? amount
          : `₹ ${Number(amount).toLocaleString('en-IN')}`}
        </div>

           </div> 
          </div>
        </div>
       

      </>
    );
  };

  const KanbanItemDescription = ({ status, tag, tagNo, tagColor }) => {
    const backgroundColor = tagColor ? hexToRgba(tagColor, 0.2) : 'transparent';
    return (
      <>
        <div className="kanbanItemStatus">{status}</div>
        <div className="kanbanItemDesc1">
          <div className="kanbanItemTag" style={{ backgroundColor, color: tagColor }}>{tag}</div>
          <div className="kanbanItemNo">{tagNo <= 0 ? "" : `+${tagNo}`}</div>
        </div>
      </>
    );
  };

  useEffect(() => {
    const data = { columns: [] };
    let idStage = 0;
    let idItem = 0;
    for (let i of stagesData) {
      const cards = [];
      for (let j of i.items) {
        cards.push({
          id: idItem,
          title: (
            <KanbanItemTitle
              name={j.name}
              amount={j.amount}
              lastUpdate={j.lastUpdate}
            />
          ),
          description: (
            <KanbanItemDescription
              status={j.status}
              tag={j.tag}
              tagNo={j.tagNo}
              tagColor={j.tagColor}
            />
          ),
        });
        idItem += 1;
      }
      data.columns.push({
        id: idStage,
        title: (
          <KanbanStage
            name={i.stage.name}
            forecastedRevenue={i.stage.forecastedRevenue}
            leadsCount={i.stage.leadsCount}
            index={idStage}
          />
        ),
        cards,
      });
      idStage += 1;
    }
    setBoard(data);
  }, [stagesData]);
  const handleCardMove = (_card, source, destination) => {
    // Check if editLeadBoolean is false, show toast and stop further execution
    if (!editLeadBoolean) {
      toast.error("Access disabled by Admin");
      return; // Stop here if access is disabled
    }
    const leadId = stagesData[source.fromColumnId].items[source.fromPosition]?.id;
    const stageId = storePipelineStage[destination.toColumnId]?._id;
    axios
      .put(`${constants.CRM_API}/user/update-lead-stage?userId=${localStorage.getItem("userId")}`, {
        leadId: leadId,
        stageId: stageId,
      })
      .then((response) => {
        if (response.data.data.msg) {
          toast.error(response.data.data.msg);
          return; // Stop here if there's an error message in the response
        }
        const updatedBoard = moveCard(board, source, destination);
        setBoard(updatedBoard);
        getPipeline(); // Refresh the pipeline data
      })
      .catch((error) => {
        toast.error("An error occurred while updating the lead stage");
      });
  };
  
  const [showReadMore, setShowReadMore] = useState(false);
  const textRef = useRef(null);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  useEffect(() => {
    if (textRef.current) {
      setShowReadMore(textRef.current.scrollHeight > 36);
    }
  }, [pipelineDesc]);
  const handlePipelineNameChange = (event) => {
    setPipelineName(event.target.value);
  };
  const handlePipelineDescChange = (event) => {
    setPipelineDesc(event.target.value);
  };
  const handleAddNewStage = () => {
    if (storePipeline && Array.isArray(storePipeline.stages)) {
      // Find the index of the "Won" stage
      const wonIndex = storePipeline.stages.findIndex(stage => stage.stageName === 'Won');

      // Determine the sequence number for the new stage
      const newSequence = wonIndex === -1 ? storePipeline.stages.length + 1 : storePipeline.stages[wonIndex].sequence;

      // Create the new stage with the correct sequence number
      const newStage = { stageName: '', sequence: newSequence };

      // Update the sequence numbers of the existing stages
      const updatedStages = storePipeline.stages.map((stage, index) => {
        if (index >= wonIndex && wonIndex !== -1) {
          return { ...stage, sequence: stage.sequence + 1 };
        }
        return stage;
      });

      // Insert the new stage in the correct position
      const newStages = wonIndex === -1
        ? [newStage, ...updatedStages]
        : [
          ...updatedStages.slice(0, wonIndex),
          newStage,
          ...updatedStages.slice(wonIndex)
        ];

      // Update storePipeline with the new stages
      setStorePipeline(prevState => ({
        ...prevState,
        stages: newStages
      }));
    }
  };

  const handleStageNameChange = (index, event) => {
    // Ensure storePipeline is not null and stages is an array
    if (storePipeline && Array.isArray(storePipeline.stages)) {
      const updatedStages = [...storePipeline.stages];
      updatedStages[index].stageName = event.target.value;
      setStorePipeline({
        ...storePipeline,
        stages: updatedStages,
      });
    }
  };
  const handleRemoveStage = (index) => {
    // Ensure storePipeline is not null and stages is an array
    if (storePipeline && Array.isArray(storePipeline.stages)) {
      const updatedStages = [...storePipeline.stages];
      updatedStages.splice(index, 1);
      setStorePipeline({
        ...storePipeline,
        stages: updatedStages
      });
    }
  };
  const moveStageUp = (index) => {
    if (index > 0) {
      setStorePipeline(prevState => {
        const updatedStages = [...prevState.stages];
        // Swap the stages in the array
        [updatedStages[index], updatedStages[index - 1]] = [updatedStages[index - 1], updatedStages[index]];
        // Preserve the original sequence numbers
        updatedStages.forEach((stage, i) => {
          stage.sequence = i + 1; // Assuming sequence starts from 1
        });
        return { ...prevState, stages: updatedStages };
      });
    }
  };

  const moveStageDown = (index) => {
    if (index < storePipeline.stages.length - 1) {
      setStorePipeline(prevState => {
        const updatedStages = [...prevState.stages];
        // Swap the stages in the array
        [updatedStages[index], updatedStages[index + 1]] = [updatedStages[index + 1], updatedStages[index]];
        // Preserve the original sequence numbers
        updatedStages.forEach((stage, i) => {
          stage.sequence = i + 1; // Assuming sequence starts from 1
        });
        return { ...prevState, stages: updatedStages };
      });
    }
  };
  const updatedPayload = storePipeline?.stages
    .filter((stage) => !stage._id) // Keep only stages without _id
    .map((stage) => ({
      stageName: stage.stageName,
      sequence: stage.sequence
    }));
  
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectAllUsers, setSelectAllUsers] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisibleState(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);
  useEffect(() => {
    // Close dropdown when clicking outside
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const getMembers = async () => {
    const res = await getReq(
      `${constants.CRM_API
      }/api/enterprise/get-team-leaders?userId=${localStorage.getItem(
        "userId"
      )}&access=1 `
    );
    if (res && !res.error) {
      setUserDetails(res?.data?.data);
    } else {
      console.log(res.error);
    }
  };
  useEffect(() => {
    const getLeadTags = async () => {
      const res = await getReq(
        `${constants.CRM_API}/user/get-lead-tag?userId=${localStorage.getItem("userId")}`
      );
      if (res && !res.error) {
        setLeadTagss(res?.data?.data);
      } else {
        console.log(res.error);
      }
    };

    getLeadTags();
  }, []);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSelectAll = () => {
    if (isAllSelected) {
      setSelectedIds([]);
      setIsAllSelected(false);
    } else {
      const allIds = leadTagss.map(tag => tag._id);
      setSelectedIds(allIds);
      setIsAllSelected(true);
    }
  };

  const handleSelect = (id) => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter(selectedId => selectedId !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  const handleClearSelection = () => {
    setSelectedIds([]);
    setIsAllSelected(false);
  };

  const filteredTags = leadTagss.filter(tag =>
    tag.tagName.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const handleToggleDropdown = () => setDropdownVisible(!dropdownVisible);

  const handleSearchChange2 = (e) => setSearchQuery(e.target.value);

  const handleSelectUser = (userId) => {
    setSelectedUsers((prevSelected) =>
      prevSelected.includes(userId)
        ? prevSelected.filter(id => id !== userId)
        : [...prevSelected, userId]
    );
  };

  const handleSelectAll2 = () => {
    if (selectAllUsers) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(userDetails.map(user => user._id));
    }
    setSelectAllUsers(!selectAllUsers);
  };

  const handleClearSelection2 = () => {
    setSelectedUsers([]);
    setSelectAllUsers(false);
  };

  const filteredUsers = userDetails.filter(user =>
    user.fullName.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const [checkCount, setCheckCount] = useState();

  const handleClickClick = (stageID, index) => {
    if (stageID === undefined) {
      handleRemoveStage(index);
    } else {
      // Directly use the checkStages function here
      axios.post(
        `${constants.CRM_API}/user/leads-in-stage?userId=${localStorage.getItem("userId")}`,
        { stageId: [stageID] }
      )
        .then(response => {
          const checkCount = response?.data?.data;
          if (checkCount && Object.keys(checkCount).some(key => Array.isArray(checkCount[key]) && checkCount[key].length == 0)) {
            handleRemoveStage(index);
            toast.success("Stage Deleted Successfully")
          } else {
            toast.error("This stage cannot be deleted because multiple leads are currently assigned to it. Please reassign these leads before attempting to delete the stage.");
          }
        })
        .catch(error => {
          console.error("Error checking stages:", error);
        });
    }
  };
  const addStages = async (id) => {
    try {
      const response = await axios.post(
        `${constants.CRM_API}/user/add-lead-stage?userId=${localStorage.getItem("userId")}&pipelineId=${pipelineStoreId}`,
        updatedPayload
      );
      const newId = response?.data?.data[0]?._id;
      return newId; // Return newId for further use
    } catch (error) {
      console.error(error);
      throw error; 
    }
  };

  const editPipeline = async (id) => {
    try {
      const newId = await addStages(); // Wait for addStages to complete and get newId
      const filterPayload = {
        pipelineId: storePipeline?._id || newId, // Use newId if _id is not present
        pipelineName: pipelineName,
        // description: pipelineDesc,
        stages: storePipeline?.stages.map((stage) => ({
          stageName: stage.stageName,
          id: stage._id || newId,
          sequence: stage.sequence,
        })),
        ...(storePipeline?._id ? {} : { newId: { _id: newId } }), // Add newId key only if _id is missing
      };

      const response = await axios.put(
        `${constants.CRM_API}/user/edit-pipeline?userId=${localStorage.getItem("userId")}`,
        filterPayload
      );

      toast.success("Pipeline edited Successfully")
      getPartiLead();
      getPipeline();
      dispatch(setShowModalPipe(false));
    } catch (error) {
    }
  };

  const dummyTags = [
    { _id: 2, tagName: 'Follow up' },
    { _id: 3, tagName: 'Interested' },
    { _id: 4, tagName: 'Not interested' },
    { _id: 6, tagName: 'No response' },
  ];
  const [dropdownVisibleState, setDropdownVisibleState] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const [allTagsSelected, setAllTagsSelected] = useState(false);
  const dropdownRefNew = useRef(null);

  const handleToggleDropdownState = () => {
    setDropdownVisibleState((prevState) => !prevState);
  };

  const handleTagSelection = (id, e) => {
    e.stopPropagation();
    setSelectedTags((prevTags) =>
      prevTags.includes(id) ? prevTags.filter((tag) => tag !== id) : [...prevTags, id]
    );
  };

  const handleSelectAllTags = (e) => {
    e.stopPropagation();
    if (allTagsSelected) {
      setSelectedTags([]);
    } else {
      setSelectedTags(dummyTags.map((tag) => tag._id));
    }
    setAllTagsSelected(!allTagsSelected);
  };

  const handleClearAllSelections = (e) => {
    e.stopPropagation();
    setSelectedTags([]);
    setAllTagsSelected(false);
  };

  const handleSearchChangeText = (e) => {
    e.stopPropagation();
    setSearchText(e.target.value);
  };
  const [isRotated, setIsRotated] = useState(true);
  const dropdownContainerRef = useRef(null);

  const handleClickOutsideNew = (event) => {
    if (dropdownContainerRef.current && !dropdownContainerRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideNew);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideNew);
    };
  }, []);

  const filteredStatus = dummyTags.filter((tag) =>
    tag.tagName.toLowerCase().includes(searchText.toLowerCase())
  );
  useEffect(() => {
    getPipeline();
  }, []);
  useEffect(() => {
    getMembers();
  }, [])
  useEffect(() => {
    if (storePipelineStage.length > 0) {
      getPartiLead();
    }
  }, [storePipelineStage, selectedIds, selectedUsers]);
  return (
    <div>
      <div style={{ padding: "26px", paddingLeft: "41px", paddingTop: "3px" }}>
        <div style={{ display: "flex", top: "0", paddingTop: "10px", position: "absolute", background: "white", justifyContent: "space-between", width: "100%" }}>
          <div style={{ display: "flex", gap: "12px", flexDirection: "column", background: "white" }}>
            <div>
              <div className="pipingss">{storePipeline?.name} <span className="pipelinVal">: ₹ {storePipeline?.pipelineValue?.toLocaleString('en-IN')}</span></div>
              <div>
                <div>{/* dropdownsss */}</div>
              </div>
            </div>
            {/* {storePipeline?.description?.length > 0 && <div className="noteHead">
              <span className="wraping"> Note :</span>
              <span ref={textRef} className={`brieff ${isExpanded ? "expanded" : "collapsed"}`}>
                {storePipeline?.description}
              </span>
              {showReadMore && storePipeline?.description?.length > 0 && <span className="blueR" style={{ paddingLeft: "3px" }} onClick={toggleExpand}>
                {isExpanded ? "Read less" : "Read more"}
              </span>}
            </div>
            } */}
            {/* <div className="pipelinVal">Pipeline Value: ₹12,00,000</div> */}
          </div>
          <div style={{ display: "flex" }}>
            <div>
              <div style={{ fontSize: "11px", fontWeight: "400", color: "#454545" }}>Select Members</div>
              <Dropdown show={dropdownVisible} drop="left" style={{ width: "174px" }} ref={dropdownContainerRef}>
                <Dropdown.Toggle
                  className="header_drop"
                  style={{
                    width: "150px",
                    padding: "11px 10px",
                    height: "34px",
                    backgroundColor: "white",
                    border: "1px solid #999",
                  }}
                  onClick={handleToggleDropdown}
                >
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <div style={{ fontSize: "10px", fontWeight: "500", color: "#454545" }}>{selectedUsers?.length > 0 ?"Selected":"All"}</div>
                    <div><img src={dropdownArrow} alt="Dropdown Arrow" /></div>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ maxHeight: "260px", overflow: "scroll", width: "190px" }} onClick={(e) => e.stopPropagation()}>
                  <Dropdown.Item>
                    <div style={{ border: "1px solid #EDEDED", display: "flex", width: "163px", borderRadius: "8px", height: "39px", padding: "6px 16px", justifyContent: "space-between" }}>
                      <input
                        placeholder="Search"
                        className="searchinput"
                        style={{ width: "127px" }}
                        value={searchQuery}
                        onChange={handleSearchChange2}
                      />
                      <div><img src={MagnifyingBlack} alt="Search" /></div>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleSelectAll2}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <div style={{ fontSize: "12px", fontWeight: "500", color: "#363535" }}>Select All</div>
                      <div>{selectAllUsers && <img src={greenCheck} alt="Selected" />}</div>
                    </div>
                  </Dropdown.Item>
                  {filteredUsers.length === 0 ? (
                    <Dropdown.Item>
                      <div style={{ fontSize: "12px", fontWeight: "500", color: "#363535" }}>Search Not Found</div>
                    </Dropdown.Item>
                  ) : (
                    filteredUsers.map(user => (
                      <Dropdown.Item key={user._id} onClick={() => handleSelectUser(user._id)}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div style={{ fontSize: "12px", fontWeight: "500", color: "#363535", width: "103px", overflow: "hidden", textOverflow: "ellipsis" }}>{user.fullName}</div>
                          {selectedUsers.includes(user._id) && <div><img src={greenCheck} alt="Selected" /></div>}
                        </div>
                      </Dropdown.Item>
                    ))
                  )}
                  <Dropdown.Item onClick={handleClearSelection2}>
                    <div style={{ display: "flex", justifyContent: "end" }}>
                      <div className="clearest">Clear Selection</div>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            {/* <div>
              <div style={{ fontSize: '11px', fontWeight: '400', color: '#454545' }}>Status</div>
              <Dropdown show={dropdownVisibleState} drop="left" style={{ width: '174px' }} ref={dropdownRefNew}>
                <Dropdown.Toggle
                  className="header_drop"
                  style={{
                    width: '150px',
                    padding: '11px 10px',
                    height: '34px',
                    backgroundColor: 'white',
                    border: '1px solid #999',
                  }}
                  onClick={handleToggleDropdownState}
                >
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ fontSize: '10px', fontWeight: '500', color: '#454545' }}>
                      {allTagsSelected ? 'All Selected' : 'Select Status'}
                    </div>
                    <div>
                      <img src={dropdownArrow} alt="Dropdown Arrow" />
                    </div>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu
                  style={{ height: '210px', overflow: 'scroll' }}
                  onClick={(e) => e.stopPropagation()}  >
                  <Dropdown.Item as="div">
                    <div
                      style={{
                        border: '1px solid #EDEDED',
                        display: 'flex',
                        width: '194px',
                        borderRadius: '8px',
                        height: '39px',
                        padding: '6px 16px',
                        justifyContent: 'space-between',
                      }}
                    >
                      <input
                        placeholder="Search"
                        className="searchinput"
                        style={{ width: '140px' }}
                        value={searchText}
                        onChange={handleSearchChangeText}
                        onClick={(e) => e.stopPropagation()} />
                      <div>
                        <img src={MagnifyingBlack} alt="Search" />
                      </div>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item as="div" onClick={handleSelectAllTags}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div style={{ fontSize: '12px', fontWeight: '500', color: '#363535' }}>
                        {allTagsSelected ? 'Deselect All' : 'Select All'}
                      </div>
                      {allTagsSelected && (
                        <div>
                          <img src={greenCheck} alt="Selected" />
                        </div>
                      )}
                    </div>
                  </Dropdown.Item>
                  {filteredStatus.length === 0 ? (
                    <Dropdown.Item as="div">
                      <div style={{ fontSize: '12px', fontWeight: '500', color: '#363535' }}>Search Not Found</div>
                    </Dropdown.Item>
                  ) : (
                    filteredStatus.map((tag) => (
                      <Dropdown.Item as="div" key={tag._id} onClick={(e) => handleTagSelection(tag._id, e)}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <div style={{ fontSize: '12px', fontWeight: '500', color: '#363535' }}>{tag.tagName}</div>
                          {selectedTags.includes(tag._id) && (
                            <div>
                              <img src={greenCheck} alt="Selected" />
                            </div>
                          )}
                        </div>
                      </Dropdown.Item>
                    ))
                  )}
                  <Dropdown.Item as="div" onClick={handleClearAllSelections}>
                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                      <div className="clearest">Clear Selection</div>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div> */}
            <div style={{ width: "213px" }}>
              <div style={{ fontSize: "11px", fontWeight: "400", color: "#454545" }}>Tags</div>
              <Dropdown show={dropdownOpen} drop="left" style={{ width: "174px" }} ref={dropdownRef}>
                <Dropdown.Toggle
                  className="header_drop"
                  style={{
                    width: "150px",
                    padding: "11px 10px",
                    height: "34px",
                    backgroundColor: "white",
                    border: "1px solid #999",
                  }}
                  onClick={toggleDropdown}
                >
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <div style={{ fontSize: "10px", fontWeight: "500", color: "#454545" }}>All</div>
                    <div><img src={dropdownArrow} /></div>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ maxHeight: "260px", overflow: "scroll", width: "190px" }} onClick={(e) => e.stopPropagation()}>
                  <Dropdown.Item>
                    <div style={{ border: "1px solid #EDEDED", display: "flex", width: "160px", borderRadius: "8px", height: "39px", padding: "6px 16px", justifyContent: "space-between" }}>
                      <input
                        placeholder="Search"
                        className="searchinput"
                        style={{ width: "127px" }}
                        value={searchTerm}
                        onChange={handleSearchChange}
                        onClick={(e) => e.stopPropagation()}
                      />
                      <div><img src={MagnifyingBlack} alt="" /></div>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleSelectAll}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <div style={{ fontSize: "12px", fontWeight: "500", color: "#363535" }}>Select All</div>
                      {isAllSelected && <div><img src={greenCheck} alt="" /></div>}
                    </div>
                  </Dropdown.Item>
                  {filteredTags.length === 0 ? (
                    <Dropdown.Item>
                      <div style={{ fontSize: "12px", fontWeight: "500", color: "#363535" }}>Search Not Found</div>
                    </Dropdown.Item>
                  ) : (
                    filteredTags.map((data) => (
                      <Dropdown.Item key={data._id} onClick={() => handleSelect(data._id)}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div style={{ fontSize: "12px", fontWeight: "500", color: "#363535" }}>{data.tagName}</div>
                          {selectedIds.includes(data._id) && <div><img src={greenCheck} alt="" /></div>}
                        </div>
                      </Dropdown.Item>
                    ))
                  )}
                  <Dropdown.Item onClick={handleClearSelection}>
                    <div style={{ display: "flex", justifyContent: "end" }}>
                      <div className="clearest">Clear Selection</div>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "5rem" }}>
          <Board onCardDragEnd={handleCardMove} disableColumnDrag>
            {board}
          </Board>
        </div>
      </div>
      <Modal show={showModalPipe} dialogClassName="predModal" centered>
        <Modal.Body style={{ padding: "unset" }}>
          <div>
            <div className="add_head">Edit Pipeline</div>
            <div>
              <div style={{
                padding: "24px", paddingTop: "18px",
                paddingBottom: "18px"
              }}>
                {/* <TextField
                  id="outlined-basic"
                  label="Pipeline name"
                  variant="outlined"
                  className="saas-form"
                  InputProps={{
                    style: {
                      borderRadius: "4px",
                      width: "578px",
                      height: "39px",
                    },
                  }}
                  inputProps={{ style: { fontSize: "14px" } }}
                  value={pipelineName}
                  onChange={handlePipelineNameChange}
                />
               */}
                <div style={{ display: "flex", justifyContent: "space-between", width: "578px", paddingTop: "13px" }}>
                  <div className='bolding' style={{ fontSize: "16px" }}>Edit Pipeline Stages</div>
                  {storePipeline?.stages?.length < 20 ? <div className='newStage' onClick={handleAddNewStage}><img src={PlusBlue} /> Add New Stage</div> :
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip id="button-tooltip">
                          maximum limit : 20 pipelines</Tooltip>
                      }
                    >
                      <div className='newStage' style={{ color: "rgb(136, 134, 134)" }}>
                        <span style={{ color: "rgb(136, 134, 134)" }}>+</span>
                        <span /> Add New Stage
                      </div>
                    </OverlayTrigger>
                  }
                </div>
                <div className='stages'>Stage Name</div>
                <div className="offcanvasBody" style={{ gap: "16px", display: "flex", height: "290px", flexDirection: "column", overflow: "scroll" }}>
                  {storePipeline?.stages.map((stage, index) => (
                    <div style={{ display: "flex", alignItems: "center" }} key={stage._id}>
                      <div className='stripss' style={{ background: colors[index % colors.length] }}></div>
                      {(stage.stageName === "Won" || stage.stageName === "Unassigned" ||stage.stageName === "Assigned" || stage.stageName === "Lost") ? (
                        <div className='unassign' style={{ marginRight: "12px" }}>
                          {stage.stageName}
                        </div>
                      ) : (
                        <input
                          className='unassignBlack'
                          style={{ marginRight: "12px" }}
                          value={stage.stageName}
                          maxLength={45}
                          onChange={(e) => handleStageNameChange(index, e)}
                        />
                      )}
                      {(index == 1 || index === 0 || index === 2 || index === storePipeline.stages.length - 1 || index === storePipeline.stages.length - 2) ?
                        <div className='arrowGrey' style={{ marginRight: "8px", background: "#FAFAFA", padding: "0px" }}>
                          <img src={upGrey} alt='' />
                        </div>
                        :
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id="button-tooltip">
                              Move Up</Tooltip>
                          }
                        >
                          <div className='arrowGrey' style={{
                            marginRight: "8px", cursor: index === 0 ? "default" : "pointer",
                            background: index === 0 ? "#FAFAFA" : "initial"
                          }} onClick={() => moveStageUp(index)}>
                            <img src={ArrowUp} alt='' />
                          </div>
                        </OverlayTrigger>
                      }
                      {(index == 0 ||index == 1|| index === storePipeline.stages.length - 2 || index === storePipeline.stages.length - 1 || index === storePipeline.stages.length - 3) ?
                        <div className={`arrowGreydownn ${isRotated ? 'rotateDown' : ''}`} style={{ marginRight: "8px", background: "#FAFAFA", padding: "0px" }}>
                          <img src={upGrey} alt='' />
                        </div>
                        :
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id="button-tooltip">
                              Move Down</Tooltip>
                          }
                        >
                          <div className='arrowGrey' style={{ marginRight: "8px", cursor: "pointer" }} onClick={() => moveStageDown(index)}>
                            <img src={ArrowBelow} alt='' />
                          </div>
                        </OverlayTrigger>
                      }
                      {(stage.stageName === "Won" || stage.stageName === "Unassigned" || stage.stageName === "Lost"|| stage.stageName === "Assigned") ?
                        <div className='arrowGrey' style={{ background: "#FAFAFA" }}>
                          <img src={crossBlack} alt='' />
                        </div>

                        :
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id="button-tooltip">
                              Delete</Tooltip>
                          }
                        >
                          <div className='arrowGrey' style={{ cursor: "pointer" }}
                            // onClick={() => { stage?._id == undefined ? handleRemoveStage(index) : checkStages(stage?._id) }}
                            onClick={() => { handleClickClick(stage?._id, index) }}>
                            <img src={crossBlack} alt='' />
                          </div>
                        </OverlayTrigger>
                      }
                    </div>
                  ))}
                </div>
              </div>
              <div className="bottDiv">
                <div
                  className="cancy"
                  onClick={() => {
                    dispatch(setShowModalPipe(false));
                  }}
                >
                  Cancel
                </div>
                <div
                  className="submii"
                  onClick={() => {
                    editPipeline();
                    dispatch(setShowModalPipe(false));
                  }}
                >
                  Submit
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Pipelines;
