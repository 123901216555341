import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { MdCheck } from "react-icons/md";
import { constants } from "../../../../piservices/constants";
import toast from "react-hot-toast";

const EditTagModal = ({ show, hide, colors, data }) => {
  const [colorIndex, setColorIndex] = useState(-1);

  const [editTagDetails, setEditTagDetails] = useState({
    tagName: "",
    tagColorCode: "",
  });

  useEffect(() => {
    const color = data?.tagColorCode;
    const index = colors.findIndex((item) => item[0] === color);
    setColorIndex(index);
    setEditTagDetails({
      tagName: data?.tagName,
      tagColorCode: data?.tagColorCode,
    });
  }, [data, colors]);

  // change tag name
  const editTagNameHandler = (e) => {
    e.preventDefault();
    const value = e.target.value;
    setEditTagDetails((prev) => ({ ...prev, tagName: value }));
  };

  // change color
  const editTagColorHandler = (index) => {
    setEditTagDetails((prev) => ({ ...prev, tagColorCode: colors[index][0] }));
    setColorIndex(index);
  };

  // save edited tag
  const saveEditTagHandler = () => {
    if (editTagDetails.tagName !== "" && editTagDetails.tagColorCode !== "") {
      const uploadTag = async () => {
        try {
          await axios.put(
            `${constants.CRM_API}/user/edit-lead-tag?tagId=${data?._id}`,
            { ...editTagDetails, userId: data?.userId }
          );
          toast.success("Tag edited successfully!");
          hide();
        } catch (err) {
          console.log(err);
        }
      };
      uploadTag();
    }
  };

  //   console.log(data);
  //   console.log(editTagDetails);
  //   console.log(colorIndex);
  // console.log(colors, "data!");

  return (
    <Modal show={show} onHide={hide} centered>
      <Modal.Header
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid #DEDEDE",
          padding: "14px 28px",
        }}
      >
        <div style={{ fontWeight: "600", fontSize: "18px", color: "#212121" }}>
          Edit Tag
        </div>
        <div style={{ display: "flex", gap: "12px", alignItems: "center" }}>
          <button
            onClick={hide}
            style={{
              color: "#454545",
              fontWeight: "500",
              fontSize: "14px",
              backgroundColor: "white",
              border: "none",
              padding: "10px 14px",
            }}
          >
            Cancel
          </button>
          <button
            style={{
              color: "#FFFFFF",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: "#0084EF",
              border: "none",
              borderRadius: "4px",
              padding: "10px 14px",
            }}
            onClick={saveEditTagHandler}
          >
            Save
          </button>
        </div>
      </Modal.Header>
      <Modal.Body
        style={{ display: "flex", flexDirection: "column", gap: "8px" }}
      >
        <label
          htmlFor="tagName"
          style={{ color: "#191D23", fontWeight: "500", fontSize: "14px" }}
        >
          Tag Name
        </label>
        <input
          placeholder="Type here"
          id="tagName"
          maxLength={15}
          value={editTagDetails.tagName}
          onChange={editTagNameHandler}
          style={{
            padding: "12px 16px",
            border: "1px solid #999999",
            borderRadius: "8px",
          }}
        />
        <label
          style={{ color: "#191D23", fontWeight: "500", fontSize: "14px" }}
        >
          Select Color
        </label>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
            gap: "4px",
          }}
        >
          {colors &&
            colors.map((item, index) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: colorIndex === index && "#E5E5E5",
                  cursor: "pointer",
                  padding: "5px",
                }}
                onClick={editTagColorHandler.bind(null, index)}
              >
                <div
                  style={{ display: "flex", gap: "16px", alignItems: "center" }}
                >
                  <div
                    style={{
                      backgroundColor: item[0],
                      width: "15px",
                      height: "15px",
                      borderRadius: "2px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  ></div>
                  <div
                    style={{
                      color: "#454545",
                      fontWeight: "400",
                      fontSize: "12px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {item[1]}
                  </div>
                </div>
                {colorIndex === index ? (
                  <MdCheck />
                ) : (
                  <MdCheck style={{ color: "white" }} />
                )}
              </div>
            ))}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditTagModal;
