import React, { useEffect, useState } from "react";
import { sidebar } from "./sidebarRoutes";
import styles from "./NewLayout.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import expf, { constants } from "../../../piservices/constants";
import axios from "axios";
import { getReq, postReq } from "../../../piservices/apis";
import { notification } from "antd";
// import LockBlack from '../../Assets/LockBlack.svg'

// fix the bug on uat 20-06-2023

const SideProjectPanel = ({ shouldAnimatee, leadDetail }) => {

  //  fix the bug of side pannel 
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const [projects, setProjects] = useState([]);
  const [aclData, setAclData] = useState([]);
  const { sidebarToggle } = useSelector((state) => state.lmsSlice);
  const [activeToggle, setActiveToggle] = useState(0);
  const selectedLeadsData = useSelector(
    (state) => state.selectedLeadSlice.selectedLeadData
  );
  console.log(selectedLeadsData, "diyxewdxwa")
  const getAclData = async () => {
    const res = await getReq(
      `${constants.CRM_API}/user/get-features-list?userId=${localStorage.getItem(
        "userId"
      )}`
    );
    if (res && !res.error) {
      // setProfileData({ ...res.data.data });
      setAclData(res?.data?.data);
    }
    // else {
    //   console.log(res.error);
    //   localStorage.clear();
    //   window.location.assign(`${constants. BASE_URL_ESS}`);
    // }
  };
  const getProjects = async () => {
    const res = await getReq(
      `${constants.pmt}/api/projects/getProjects?userId=${localStorage.getItem(
        "userId"
      )}&projectId=${localStorage.getItem("projectId")}`
    );
    if (res && !res.error) {
      setProjects(res?.data?.projects[0]);
    } else {
      console.log(res.error);
    }
  };

  const convertleadToClient = async (currElementHref) => {

    try {
      await axios
        .post(`${constants.PMT_API}/api/projects/addClient?userId=${localStorage.getItem(
          "userId"
        )}`, {
          email: selectedLeadsData.email,
          location: selectedLeadsData.address,
          name: selectedLeadsData.name,
          projectName: selectedLeadsData.name,
        })
        .then(async (res) => {
          await axios
            .post(
              `${constants.PMT_API
              }/api/projects/add-project?userId=${localStorage.getItem(
                "userId"
              )}`,
              {
                clientId: res.data._id,
                location: selectedLeadsData.address,
                projectName: selectedLeadsData.name,
                userId: localStorage.getItem("userId"),
                isConfirmed: false,
                phoneNumber: selectedLeadsData.phoneNumber,
                clientName: selectedLeadsData.name,
                isFromLead: true,
                projectAdmin: selectedLeadsData?.assignedTo?._id,
                teamMembers: [selectedLeadsData?.assignedTo?._id]
              }
            )
            .then(async (response) => {

              localStorage.setItem("projectId", response?.data?._id);
              await postReq(
                `${constants.CRM_API}/api/save-projectId-leads?leadId=${selectedLeadsData?._id}&projectId=${response?.data?._id}`
              )
                .then((res2) => {
                  window.location.assign(
                    `${constants.BASE_URL}/${currElementHref}/${response?.data?._id
                    }?isLead=${true}`
                  );
                })
                .catch((err) => {
                  console.log(err.error, "callingaddclienterror11");
                  notification.error({
                    message: err?.error?.response?.data?.message || "client already exist",
                    duration: 1,
                  });
                });
            })
            .catch((e) => {
              console.log(e, 'callingaddclienterror11')
              console.log(e);
              notification.error({
                message: e?.error?.response?.data?.message || "project already exists",
                duration: 1,
              });
            })
            .catch((err) => {
              console.log(err, 'callingaddclienterror22')
              notification.error({
                message: err?.error?.response?.data?.message || "client already exists",
                duration: 1,
              });
            });
        });
    } catch (e) {
      console.log(e, 'callingaddclienterror32', e.response.data.message)
      console.log(e);
      notification.error({
        message: e?.response?.data?.message || "client already exists",
        duration: 1,
      });
    }
  };

  // const convertleadToClient = async (currElementHref) => {
  //     try{
  //         const res = await postReq(`${constants.PMT_API}/api/projects/addClient`, {
  //             email: selectedLeadsData.email,
  //             location: selectedLeadsData.address,
  //             name: selectedLeadsData.name,
  //         });
  //         console.log('creating client')
  //         if (res) {
  //             await axios
  //                 .post(`${constants.PMT_API}/api/projects/add-project`, {
  //                     clientId: res.data._id,
  //                     location: selectedLeadsData.address,
  //                     projectName: selectedLeadsData.name,
  //                     userId: localStorage.getItem("userId"),
  //                     isConfirmed: false,
  //                 }).then(async (response) => {
  //                     console.log('responseinsideprojectpanel: ' + response)
  //                     localStorage.setItem("projectId", response?.data?._id);
  //                     const res2 = await postReq(`${constants.CRM_API}/api/save-projectId-leads?leadId=${selectedLeadsData?._id}&projectId=${response?.data?._id}`)
  //                     if(res2){
  //                         window.location.assign(`${constants.constants.BASE_URL}/${currElementHref}/${response?.data?._id}?isLead=${true}`);
  //                     }
  //                     else{
  //                         console.log(res2.error)
  //                         notification.error({
  //                             message: res2.error,
  //                             duration: 1,
  //                         });
  //                     }

  //                 })
  //                 console.log('creating project id')
  //         }
  //         else {
  //             console.log(res.error);
  //             notification.error({
  //                 message: res.error,
  //                 duration: 1,
  //             });
  //         }
  //     }
  //     catch (e) {
  //         console.log(e);
  //         notification.error({
  //             message: e.error ||  "client already exists",
  //             duration: 1,
  //         });
  //     }

  // }

  useEffect(() => {
    getProjects();
  }, [localStorage.getItem("projectId")]);

  useEffect(() => {
    getAclData();
  }, []);
  return (
    <div className="d-flex h-100">
      {/* {sidebarToggle === true &&  */}
      {sidebarToggle === true && (
        <div
          className={styles.mainContainer3}
          id={shouldAnimatee === true ? "sidenav_items_main_sub" : ""}
        >
          <div
            id={"sidenav__items"}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {console.log(localStorage.getItem("projectId"),sidebar, "xqwsxqs")}
            {sidebar?.map((curElem, index) => {
              return (
                (aclData?.includes(curElem?.accessName) || curElem?.accessName == 'default') && (

                  <div
                    className={
                      curElem?.active === true
                        ? styles.eachItemActive
                        : styles.eachItem
                    }
                  >
                    <div
                      className="d-flex flex-column align-items-center justify-content-center"
                      style={{ cursor: "pointer", padding: "0.625rem 0.5rem" }}
                      onClick={() => {
                        localStorage.setItem("visited", true);
                        localStorage.setItem("leadQuo", true);
                        
                        const projectId = localStorage.getItem("projectId");
                        console.log("Project ID:",curElem, typeof projectId,projectId == undefined); 
                        if (projectId == 'undefined' ) {
                          console.log(projectId,projectId != undefined,"Project ID is undefined or null. Checking href conditions.");        
                          if (
                            curElem.href == "file-manager" ||
                            curElem.href == "timeline/task-manager/all-task/task" ||
                            curElem.href == "mom" ||
                            curElem.href == "quotation"
                          ) { 
                            console.log("Project ID is undefined and href matches. Calling convertleadToClient.");
                            convertleadToClient(curElem.href);
                          } else {
                            console.log("Project ID is undefined, but href does not match expected values.");
                          }
                        } else {
                          if (curElem.href === "pmt/project-discussion" || curElem.href === "timeline/task-manager/project-task") {
                            console.log("Navigating to project discussion or project task manager.");
                            window.location.assign(`${constants.BASE_URL}/${curElem.href}`);
                          } else if (curElem?.label == "Summary") {
                            console.log("Navigating to Summary with leadId.");
                            window.location.assign(
                              `${constants.BASE_URL}/${curElem.href}/${localStorage.getItem("leadId")}`
                            );
                          } else {
                            console.log("Navigating with projectId and isLead=true.");
                            window.location.assign(
                              `${constants.BASE_URL}/${curElem.href}/${projectId}?isLead=${true}`
                            );
                          }
                        }
                        


                      }}
                    >
                      <img
                        src={curElem?.image}
                        className="mb-1"
                        style={{ width: "2rem", height: "2rem" }}
                      />
                      <div className={styles.fontDetails}>{curElem?.label}</div>
                    </div>
                  </div>
                )
              );
            })}
          </div>
        </div>
      )}
      {/*  } */}

      {/* <div className='d-flex flex-column p-3 ' style={{ borderRight: '1px solid #eeeeef', width: '11.5625rem', gap: '0.75rem' }}>
                <div className={activeToggle == 0 ? styles.sidepanel_active : styles.sidepanel_inactive} onClick={() => setActiveToggle(0)}>Project Task <div className={styles.updates}>22</div></div>
                <div className={activeToggle == 1 ? styles.sidepanel_active : styles.sidepanel_inactive} onClick={() => setActiveToggle(1)}>Dependency </div>
                <div className={activeToggle == 2 ? styles.sidepanel_active : styles.sidepanel_inactive} onClick={() => setActiveToggle(2)}>Other Tasks </div>
            </div> */}
    </div>
  );
};

export default SideProjectPanel;
