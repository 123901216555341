import React, { useContext, useEffect, useState } from "react";
import parse from "html-react-parser";
import desquoContext from "../../picontext/DashBoard/desquoContext";
import { useDispatch, useSelector } from "react-redux";
import { setIsChecked } from "../../Redux/Slices/lmsSlice";
import { postReq } from "../../piservices/apis";
// import expf from "../../piservices/constants";
import { constants } from "../../piservices/constants";

const PiAlert = (props) => {

  const context = useContext(desquoContext);
  const { paData, activeLead, init } = context;
  const dispatch = useDispatch();
  const isChecked = useSelector((state) => state.lmsSlice.isChecked);


  const handleChange = async(e) => {
    if (e.target.checked === true) {
      const res = await postReq(constants.CRM_API + "/user/addEditLead", {
        dontShowAgain: true,
        leadId: activeLead?._id,
      });
      if (res && !res.error) {
        console.log(res);
        dispatch(setIsChecked(false));
        // window.location.reload();
      } else {
        console.log(res.error);
      }
    } else {
      const res = await postReq(constants.CRM_API + "/user/addEditLead", {
        dontShowAgain: false,
        leadId: activeLead?._id,
      });
      if (res && !res.error) {
        console.log(res);
        dispatch(setIsChecked(false));
        // window.location.reload();
      } else {
        console.log(res.error);
      }
    }
}


    // dispatch(setIsChecked(e.target.checked));

//   console.log(isChecked)
  // useEffect(() => {
  // if(activeLead && activeLead?.dontShowAgain === true){
  //     dispatch(setIsChecked(true))
  // }
  // else{
  //     dispatch(setIsChecked(false));
  // }

  // },[activeLead])

//   console.log(paData?.text);
//   console.log(isChecked)

  return (
    paData && (
      <div
        className={`position-absolute ${window.screen.width>1024?'w-50':'w-100'} d-flex justify-content-center top-0 px-4 start-0  `}
        style={{
          paddingTop: "200px",
          zIndex: 2,
          // background:"red"
        }}
      >
        <div className="bg-white box-shadow  br-5 p-4 pfs-14 cl-black">
          <div className={`${paData?.cancelBtn ? "" : "text-center"}`}>
            {parse(paData?.text || "")}
            {/* You have successfully updated your lead status
                    <br /><br />
                    Want to send a reference email? */}
          </div>
          <div
            className={`d-flex w-100 justify-content-${
              paData?.cancelBtn ? "start" : "center"
            } mt-4`}
          >
            {paData?.onClick && (
              <button
                className="btn btn-primary bg-base-blue box-shadow-none me-3"
                onClick={paData?.onClick}
              >
                {paData?.btnText}
              </button>
            )}
            {paData?.cancelBtn && (
              <button
                type="button"
                className="btn btn-light bg-grey1"
                data-mdb-ripple-color="dark"
                onClick={paData?.onCloseClick}
              >
                No
              </button>
            )}
          </div>
          {paData?.text?.includes("reference") && (
            <div className="d-flex" style={{ marginTop: "0.75rem" }}>
              <input
                type="checkbox"
                // checked={isChecked}
                onChange={(e) => handleChange(e)}
                className="me-1"
                style={{ cursor: "pointer" }}
              />
              <div style={{ fontSize: "12px" }}>Don't Show Again</div>
            </div>
          )}
          {/* Button trigger modal */}
        </div>
      </div>
    )
  );
};

export default PiAlert;
