import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);
const DonutChart = ({ data }) => {
  return (
    <div style={{ width: "200px", height: "200px" }}>
      <Doughnut data={data}></Doughnut>
    </div>
  );
};

export default DonutChart;
