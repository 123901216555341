import { createSlice } from "@reduxjs/toolkit";

const initialState = {
allNotificationsObjects : [],
allAssignToAccessUser:[]
}
export const notificationSlice = createSlice({
    name: 'notifications', 
    initialState,
    reducers:{
        setAllNotificationsObjects:(state, action)=>{
            state.allNotificationsObjects = action.payload;
        },
        setAllAssignToAccessUser:(state, action)=>{
            state.allAssignToAccessUser = action.payload;
        },
    }
})

export const {setAllNotificationsObjects, setAllAssignToAccessUser} = notificationSlice.actions;
export default notificationSlice.reducer;
