import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import Trash from "../../../../../src/Assets/Trash.svg";
import plusBluehover from "../../../../../src/Assets/plusBluehover.svg";
import styles from "../NewLayout.module.css";
import {
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import axios from "axios";
import { constants } from "../../../../piservices/constants";
import { setProfileData } from "../../../../Redux/Slices/lmsSlice";
import { getReq } from "../../../../piservices/apis";
import { getToken } from "../../../../piservices/authService";
import toast from "react-hot-toast";

import GetComponent from "./GetComponent";

const AddLead = ({ show, hide }) => {
  const [option, setOption] = useState("Individual");
  const [individualFields, setIndividualFields] = useState([]);
  const [corporateFields, setCorporateFields] = useState([]);
  const [individualFieldsCluster, setIndividualFieldsCluster] = useState([]);
  const [corporateFieldsCluster, setCorporateFieldsCluster] = useState([]);
  const [individualData, setIndividualData] = useState({});
  const [corpData, setCorpData] = useState({});
  const [corporateData, setCorporateData] = useState({});
  const [leadCompanyName, setLeadCompanyName] = useState("");
  const [companyNameError, setCompanyNameError] = useState(false);
  const [phoneCode, setPhoneCode] = useState(""); 
  const [discardOpen, setDiscardOpen] = useState(false);
  // get fields
  const [projectFirstName, setProjectFirstName] = useState("");
  const [projectLastName, setProjectLastName] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [profileData, setProfileData] = useState({});

  const getProfileData = async () => {
    const res = await getReq(
      `${constants.CRM_API}/user/profile`,
      {},
      { Authorization: `Bearer ${getToken()}` }
    );
    if (res && !res.error) {
      setProfileData({ ...res.data.data });
    } else {
    }
  };
  useEffect(() => {
    getProfileData();
  }, []);
  useEffect(() => {
    setPhoneCode(profileData?.CompanySettingDetails?.countryCode);
  }, [profileData]);
  console.log(profileData, "vewrv");
  const [contacts, setContacts] = useState([
    {
      otherContactFirstName: "",
      otherContactLastName: "",
      otherContactEmailId: "",
      otherContactDesginationName: "",
      otherContactPhoneNumber: [""],
      errors: {
        otherContactFirstName: "",
        otherContactLastName: "",
        otherContactEmailId: "",
        otherContactDesginationName: "",
        otherContactPhoneNumber: [""],
      },
    },
  ]);

  const [errors, setErrors] = useState({
    firstNameError: false,
    lastNameError: false,
    emailError: false,
    phoneError: false,
  });

  const handleInputChange = (contactIndex, field, value) => {
    setContacts((prevContacts) => {
      const newContacts = [...prevContacts];
      newContacts[contactIndex][field] = value;

      // Validate only the specific field that has changed
      const errors = validateField(newContacts[contactIndex], field);
      newContacts[contactIndex].errors = {
        ...newContacts[contactIndex].errors,
        ...errors,
      };

      return newContacts;
    });
  };

  const handlePhoneNumberChange = (contactIndex, phoneIndex, value) => {
    const cleanedValue = value.replace(/[^0-9]/g, "");
    setContacts((prevContacts) => {
      const newContacts = [...prevContacts];
      newContacts[contactIndex].otherContactPhoneNumber[phoneIndex] = cleanedValue;

      // Validate only the specific phone number field that has changed
      const errors = validatePhoneNumber(newContacts[contactIndex], phoneIndex);
      newContacts[contactIndex].errors.otherContactPhoneNumber[phoneIndex] =
        errors.otherContactPhoneNumber[phoneIndex];

      return newContacts;
    });
  };

  const validateField = (contact, field) => {
    const errors = {
      otherContactFirstName: "",
      otherContactLastName: "",
      otherContactEmailId: "",
      otherContactDesginationName: "",
    };

    switch (field) {
      case "firstName":
        if (contact.otherContactFirstName.trim() === "") {
          errors.otherContactFirstName = "Please enter first name";
        }
        break;

      case "lastName":
        if (contact.otherContactLastName.trim() === "") {
          errors.otherContactLastName = "Please enter last name";
        }
        break;

      case "designation":
        if (contact.otherContactDesginationName.trim() === "") {
          errors.otherContactDesginationName = "Please enter designation name";
        }
        break;

      case "email": {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(contact.otherContactEmailId)) {
          errors.otherContactEmailId = "Invalid email address";
        }
        break;
      }

      default:
        break;
    }

    return errors;
  };

  const validatePhoneNumber = (contact, phoneIndex) => {
    const errors = { otherContactPhoneNumber: [] };
    const cleanedNumber = contact.otherContactPhoneNumber[phoneIndex].replace(/[^0-9]/g, "");
    if (phoneCode === "+91" && cleanedNumber.length !== 10) {
      errors.otherContactPhoneNumber[phoneIndex] = "Invalid phone number";
    } else {
      errors.otherContactPhoneNumber[phoneIndex] = "";
    }
    return errors;
  };

  const handleAddContact = () => {
    setContacts((prevContacts) => [
      ...prevContacts,
      {
        otherContactFirstName: "",
        otherContactLastName: "",
        otherContactEmailId: "",
        otherContactDesginationName: "",
        otherContactPhoneNumber: [""],
        errors: {
          otherContactFirstName: "",
          otherContactLastName: "",
          otherContactEmailId: "",
          otherContactDesginationName: "",
          otherContactPhoneNumber: [""],
        },
      },
    ]);
  };

  const handleAddPhoneNumber = (contactIndex) => {
    setContacts((prevContacts) => {
      const newContacts = [...prevContacts];
      newContacts[contactIndex].otherContactPhoneNumber.push("");
      newContacts[contactIndex].errors.otherContactPhoneNumber.push("");
      return newContacts;
    });
  };

  const handleRemovePhoneNumber = (contactIndex, phoneIndex) => {
    setContacts((prevContacts) => {
      const newContacts = [...prevContacts];
      newContacts[contactIndex].otherContactPhoneNumber.splice(phoneIndex, 1);
      newContacts[contactIndex].errors.otherContactPhoneNumber.splice(phoneIndex, 1);
      return newContacts;
    });
  };

  const leadCompanyNameHandle = (e) => {
    const { value } = e.target;
    setLeadCompanyName(value);
    // Validation logic
    setCompanyNameError(value.length === 0);
  };
  // Handle Last Name change
  const projectLastNameHandle = (e) => {
    const { value } = e.target;
    setProjectLastName(value);
    setLastNameError(value.length === 0); // Error if input is empty
  };
  useEffect(() => {
    async function fetchFields() {
      const response = await axios.get(
        `${
          constants.CRM_API
        }/api/get-custom-fields-new?userId=${localStorage.getItem("userId")}`
      );
      const data = response.data.data;
      // console.log(data, "data!");
      const individual = [];
      const corporate = [];
      for (let i of data.data) {
        if (i.section.indexOf(0) !== -1) {
          individual.push(i);
        }
        if (i.section.indexOf(1) !== -1) {
          corporate.push(i);
        }
      }
      // console.log(individual, corporate, "data!");
      setIndividualFields(individual);
    }
    if (show) {
      fetchFields();
    }
  }, [show]);

  console.log(individualFields, "dewjnnw");
  const [data, setData] = useState({
    uniqueFields: [],
    primaryContact: [],
  });

  useEffect(() => {
    async function corporateFetchFields() {
      try {
        const response = await axios.get(
          `${
            constants.CRM_API
          }/api/get-custom-fields-corporate?userId=${localStorage.getItem(
            "userId"
          )}`
        );

        console.log("Response received:", response);

        const fieldsData = response.data.data.data;
        const primaryContactData = response.data.data.primaryContact;

        console.log("Fields Data:", fieldsData);
        console.log("Primary Contact Data:", primaryContactData);

        if (!Array.isArray(fieldsData)) {
          console.error("Fields Data is not an array:", fieldsData);
          return;
        }

        const uniqueFieldNames = new Set();
        const uniqueFields = [];

        fieldsData.forEach((field) => {
          if (
            field.fieldName &&
            field.fieldName !== "companyName" &&
            !uniqueFieldNames.has(field.fieldName)
          ) {
            uniqueFieldNames.add(field.fieldName);
            uniqueFields.push(field);
          }
        });

        console.log("Unique fields:", uniqueFields);

        setData({
          uniqueFields: uniqueFields,
          primaryContact: primaryContactData[0],
        });
      } catch (error) {
        console.error("Error fetching corporate fields:", error);
      }
    }

    corporateFetchFields();
  }, []);

  useEffect(() => {
    let i = 0;
    const copy = [...individualFields];
    if (copy.length > 0) {
      const cluster = [];
      const values = {};
      const fixedFields = [
        ["First Name", "Last Name"],
        ["Phone Number", "Email Address"],
        ["Address"],
        ["City", "Zip/Postal Code"],
        ["Work Scope", "Expected Budget"],
        ["Area", "Lead Source"],
        ["Source Name", "Assign To"],
      ];
      // console.log(copy, "dat!");
      for (let i of fixedFields) {
        const arr = [];
        for (let j of i) {
          // console.log( j, arr, copy.findIndex((item) => item.fieldName === j), copy, "dat!");  
          const field = copy.splice(
            copy.findIndex((item) => item.fieldName === j),
            1
          )[0];
          arr.push(field);
          values[field._id] = field.fieldType === "Date" ? null : "";
        }
        cluster.push(arr);
        // console.log(cluster, values, "dat!");
      }
      const remarksField = copy.splice(
        copy.findIndex((item) => item.fieldName === "Remarks"),
        1
      )[0];
      while (i < copy.length) {
        if (i + 1 < copy.length) {
          cluster.push([copy[i], copy[i + 1]]);
          values[copy[i]._id] = copy[i].fieldType === "Date" ? null : "";
          values[copy[i + 1]._id] = copy[i + 1].fieldType === "Date" ? null : "";
          i += 2;
        } else {
          cluster.push([copy[i], null]);
          values[copy[i]._id] = copy[i].fieldType === "Date" ? null : "";
          i++;
        }
      }
      // while (i < individualFields.length) {
      //   if (
      //     i + 1 < individualFields.length &&
      //     individualFields[i].fieldName !== "Address" &&
      //     individualFields[i].fieldName !== "Remarks"
      //   ) {
      //     cluster.push([individualFields[i], individualFields[i + 1]]);
      //     if (individualFields[i].fieldType === "Date") {
      //       values[individualFields[i]._id] = null;
      //     } else {
      //       values[individualFields[i]._id] = "";
      //     }
      //     if (individualFields[i + 1].fieldType === "Date") {
      //       values[individualFields[i + 1]._id] = null;
      //     } else {
      //       values[individualFields[i + 1]._id] = "";
      //     }
      //     i += 2;
      //   } else {
      //     cluster.push([individualFields[i]]);
      //     if (individualFields[i].fieldType === "Date") {
      //       values[individualFields[i]._id] = null;
      //     } else {
      //       values[individualFields[i]._id] = "";
      //     }
      //     i++;
      //   }
      // }
      if (remarksField) {
        cluster.push([remarksField]);
        values[remarksField._id] = "";
      }
      setIndividualFieldsCluster(cluster);
      setIndividualData(values);
      // console.log(values, "data!");
      // console.log(cluster, "data!");
    }
  }, [individualFields]);

  const handleRemoveContact = (index) => {
    setContacts((prevContacts) => prevContacts.filter((_, i) => i !== index));
  };

  // console.log(individualFields, "fields!")

  useEffect(() => {
    let i = 0;
    const cluster = [];
    const values = {};

    const addSpecialField = (field) => {
      cluster.push([field]);
      values[field.fieldName] =
        field.fieldType === "Date" ? dayjs(new Date()) : "";
    };

    while (i < data?.uniqueFields.length) {
      const field = data?.uniqueFields[i];

      if (field.fieldName === "Address" || field.fieldName === "Remarks") {
        // Add special fields directly
        addSpecialField(field);
        i++;
      } else {
        if (
          i + 1 < data?.uniqueFields.length &&
          data?.uniqueFields[i + 1].fieldName !== "Address" &&
          data?.uniqueFields[i + 1].fieldName !== "Remarks"
        ) {
          // Pair fields, excluding special fields
          cluster.push([data?.uniqueFields[i], data?.uniqueFields[i + 1]]);
          values[data?.uniqueFields[i].fieldName] =
            data?.uniqueFields[i].fieldType === "Date" ? dayjs(new Date()) : "";
          values[data?.uniqueFields[i + 1].fieldName] =
            data?.uniqueFields[i + 1].fieldType === "Date"
              ? dayjs(new Date())
              : "";
          i += 2;
        } else {
          // Handle single fields
          cluster.push([data?.uniqueFields[i]]);
          values[data?.uniqueFields[i].fieldName] =
            data?.uniqueFields[i].fieldType === "Date" ? dayjs(new Date()) : "";
          i++;
        }
      }
    }
    // Set the state with all clusters
    setCorporateFieldsCluster(cluster);
    setCorpData(values);
    // Debug logs
    console.log("Cluster:", cluster);
    console.log("Values:", values);
  }, [data]);

  // change individual or corporate lead
  const changeOption = (option) => {
    setOption(option);
  };

  // change field values for individual
  const changeIndividualValueHandler = useCallback((e, id) => {
    let value = null;
    try {
      value = e.target.value;
    } catch {
      value = e;
    }
    setIndividualData((prev) => ({ ...prev, [id]: value }));
    // e.target.focus();
  }, []);

  const changeCorporateValueHandler = React.useCallback((e, fieldId) => {
    const { value } = e.target;
    setCorpData((prevData) => ({
      ...prevData,
      [fieldId]: value,
    }));
  }, []);

  const GetComponentCorporate = React.memo(({ field, corpData, onChange }) => {
    console.log(field, "Rendering GetComponentCorporate");
    console.log(corpData, "dwiheindw");

    const [localValue, setLocalValue] = useState(
      corpData[field.fieldName] || ""
    );

    useEffect(() => {
      setLocalValue(corpData[field.fieldName] || "");
    }, [corpData, field.fieldName]);

    const handleChange = useCallback((e) => {
      setLocalValue(e.target.value);
    }, []);

    const handleBlur = useCallback(() => {
      onChange({ target: { value: localValue } }, field.fieldName);
    }, [localValue, onChange, field.fieldName]);

    switch (field?.fieldType) {
      case "Text":
        if (field?.fieldName === "Source Name") {
          return (
            <TextField
              label={field?.fieldName}
              type="text"
              required={field?.isRequired}
              value={localValue}
              onChange={handleChange}
              style={{ width: "234px" }}
              onBlur={handleBlur}
              fullWidth
            />
          );
        } else {
          return (
            <TextField
              label={field?.fieldName}
              type="text"
              required={field?.isRequired}
              value={localValue}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
            />
          );
        }
      case "Number":
        if (field?.fieldName === "Phone Number") {
          return (
            <FormControl
              fullWidth
              sx={{
                m: 0,
                width: "100%",
                "& .MuiOutlinedInput-root": { padding: 0 },
              }}
              variant="outlined"
            >
              <InputLabel htmlFor="phone-number">{field?.fieldName}</InputLabel>
              <OutlinedInput
                id="phone-number"
                type="number"
                style={{ width: "232px" }}
                required={field?.isRequired}
                value={localValue}
                onChange={handleChange}
                onBlur={handleBlur}
                startAdornment={
                  <InputAdornment
                    style={{
                      borderRight: "1px solid #EDEDED",
                      padding: "10px",
                    }}
                    position="start"
                  >
                    +91
                  </InputAdornment>
                }
                label={field?.fieldName}
              />
            </FormControl>
          );
        } else if (field?.fieldName === "Expected Budget") {
          return (
            <FormControl
              fullWidth
              sx={{
                m: 0,
                width: "100%",
                "& .MuiOutlinedInput-root": { padding: 0 },
              }}
              variant="outlined"
            >
              <InputLabel htmlFor="expected-budget">
                {field?.fieldName}
              </InputLabel>
              <OutlinedInput
                id="expected-budget"
                type="number"
                required={field?.isRequired}
                value={localValue}
                onChange={handleChange}
                onBlur={handleBlur}
                startAdornment={
                  <InputAdornment style={{ padding: "10px" }} position="start">
                    ₹
                  </InputAdornment>
                }
                label={field?.fieldName}
              />
            </FormControl>
          );
        } else if (field?.fieldName === "Area") {
          return (
            <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
              <InputLabel htmlFor="area">{field?.fieldName}</InputLabel>
              <OutlinedInput
                id="area"
                type="number"
                required={field?.isRequired}
                value={localValue}
                onChange={handleChange}
                style={{
                  width: "232px",
                  marginRight: "-8px",
                  marginLeft: "-7px",
                }}
                onBlur={handleBlur}
                endAdornment={
                  <InputAdornment position="end">Sqft</InputAdornment>
                }
                label={field?.fieldName}
              />
            </FormControl>
          );
        } else {
          return (
            <TextField
              label={field?.fieldName}
              type="number"
              required={field?.isRequired}
              value={localValue}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
            />
          );
        }
      case "Email":
        return (
          <TextField
            label={field?.fieldName}
            type="email"
            required={field?.isRequired}
            value={localValue}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
          />
        );
      case "Website":
        return (
          <TextField
            label={field?.fieldName}
            type="text"
            required={field?.isRequired}
            value={localValue}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
          />
        );
      case "Date":
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={field?.fieldName}
              required={field?.isRequired}
              value={localValue || null}
              onChange={(date) => handleChange({ target: { value: date } })}
              onBlur={handleBlur}
              fullWidth
            />
          </LocalizationProvider>
        );
      case "Drop-Down":
      case "Dropdown":
        return (
          <FormControl fullWidth>
            <InputLabel id={field?.fieldName.toLowerCase()}>
              {field?.fieldName}
            </InputLabel>
            <Select
              labelId={field?.fieldName.toLowerCase()}
              id={field?.fieldName.toLowerCase()}
              label={field?.fieldName}
              required={field?.isRequired}
              value={localValue}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              {field?.options?.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      default:
        return null;
    }
  });

  const combineData = (leadCompanyName, corpData, contacts) => {
    // Add leadCompanyName to corpData
    const updatedCorpData = {
      ...corpData,
      "companyName": leadCompanyName,
    };

    // Create the final payload
    const finalPayload = {
      ...updatedCorpData,
      type: 1,
      otherContactDetails: contacts.map(({ errors, ...rest }) => rest),
    };

    return finalPayload;
  };
  const finalPayload = combineData(leadCompanyName, corpData, contacts);
  console.log(finalPayload, "heyyouuu");

  const createCorpLead = async () => {
    await axios
      .post(
        `${constants.CRM_API}/user/add-lead?userId=${localStorage.getItem(
          "userId"
        )}`,
        finalPayload
      )
      .then(function (response) {
        console.log(response, "oobbjj");
        toast.success("Added Successfully");
        // window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const createIndiLead = async () => {
    const obj = {};
    for (let i of individualFields) {
      obj[i.fieldName] = individualData[i._id];
    }
    obj["type"] = 0;
    // console.log(obj, "obj");
    try {
      await axios.post(
        `${constants.CRM_API}/user/add-lead?userId=${localStorage.getItem(
          "userId"
        )}`,
        obj
      );
      toast.success("Added Successfully");
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  const handleNewLeadClose = () => {
    setDiscardOpen(false);
    hide();
  };

  return (
    <>
      <Modal
        title={false}
        show={discardOpen}
        centered
        width={200}
        bodyStyle={{ height: 60 }}
        closable={false}
      >
        <div
          style={{
            border: "1px solid black",
            borderRadius: "5px",
            margin: "5px",
          }}
        >
          <h6 style={{ padding: "3%" }}>Do you want to discard the lead?</h6>
          <Modal.Footer>
            <div className={styles.discardCloseBtn}>
              <button
                className={styles.createModalSave}
                onClick={handleNewLeadClose}
              >
                <span
                  style={{
                    color: "#FFF",
                    fontWeight: "600",
                    fontSize: "1rem",
                  }}
                >
                  Yes{" "}
                </span>
              </button>
              <button
                className={styles.createModalClose}
                onClick={() => setDiscardOpen(false)}
              >
                <span
                  style={{
                    color: "#C9C9C9",
                    fontWeight: "600",
                    fontSize: "1rem",
                  }}
                >
                  No{" "}
                </span>
              </button>
            </div>
          </Modal.Footer>
        </div>
      </Modal>
      <Modal
        show={show}
        onHide={hide}
        size="md"
        centered
        dialogClassName={styles.predModalm}
      >
        <Modal.Header className={styles.addLeadHeader}>
          <div className={styles.addLeadTitle}>Add Lead</div>
          <div className={styles.addLeadHeaderButtons}>
            <button
              onClick={() => setDiscardOpen(true)}
              className={styles.addLeadCancel}
            >
              Cancel
            </button>
            <button
              className={styles.addLeadAdd}
              onClick={() => {
                option == "Corporate" && createCorpLead();
                option === "Individual" && createIndiLead();
              }}
            >
              Add Lead
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className={styles.addLeadOptions}>
            {console.log(
              data?.uniqueFields,
              corporateFieldsCluster,
              "corporateFields"
            )}
            <div
              className={
                option === "Individual"
                  ? styles.addLeadOptionSelected
                  : styles.addLeadOptionNotSelected
              }
              onClick={changeOption.bind(null, "Individual")}
            >
              Individual
            </div>
            <div
              className={
                option === "Corporate"
                  ? styles.addLeadOptionSelected
                  : styles.addLeadOptionNotSelected
              }
              onClick={changeOption.bind(null, "Corporate")}
            >
              Corporate
            </div>
          </div>
          {option === "Individual" && (
            <div className={styles.addLeadFields}>
              {individualFieldsCluster.map((item, index) => (
                <div className={styles.addLeadFieldsRow} key={index}>
                  {console.log(item, "dcwecxwe")}
                  {item && item.length === 2 ? (
                    item.map((field, index) => (
                      <div className={styles.addLeadFieldsTwo}>
                        {/* {console.log(field, "cervc")} */}
                        <GetComponent
                          key={index}
                          field={field}
                          individualData={individualData}
                          changeIndividualValueHandler={
                            changeIndividualValueHandler
                          }
                        />
                      </div>
                    ))
                  ) : (
                    <div className={styles.addLeadFieldsOne}>
                      {/* {console.log(item[0], "qwswedx")} */}
                      <GetComponent
                        field={item[0]}
                        individualData={individualData}
                        changeIndividualValueHandler={
                          changeIndividualValueHandler
                        }
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
          {option === "Corporate" && (
            <div
              className={styles.addLeadFields}
              style={{ alignItems: "baseline" }}
            >
              {console.log(data?.uniqueFields, corpData, "xqxq")}

              <TextField
                style={{ width: "31.5rem" }}
                id="outlined-basic"
                label="Company Name"
                size="small"
                helperText={companyNameError ? "Please enter company name" : ""}
                variant="outlined"
                required
                error={companyNameError} // Error state
                value={leadCompanyName} // Controlled value
                InputProps={{
                  inputMode: "text",
                }}
                onChange={leadCompanyNameHandle} // Event handler for input change
              />
              {console.log(contacts, leadCompanyName, "shwetaasharmaa")}
              <div className={styles.primryCon}>Primary Contact</div>
              <div>
                <div>
                  {contacts?.map((contact, contactIndex) => (
                    <div key={contactIndex} style={{ marginBottom: "20px" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent:
                            contactIndex === 1 ? "space-between" : "flex-end",
                        }}
                      >
                        {contactIndex == 1 && (
                          <div
                            style={{
                              fontSize: "12px",
                              fontWeight: "500",
                              color: "#212529",
                              marginBottom: "10px",
                            }}
                          >
                            Other Contact
                          </div>
                        )}
                        {contacts?.length > 1 && contactIndex != 0 && (
                          <div
                            style={{
                              fontSize: "12px",
                              fontWeight: "400",
                              color: "#CC0000",
                              cursor: "pointer",
                            }}
                            onClick={() => handleRemoveContact(contactIndex)}
                          >
                            Remove
                          </div>
                        )}
                      </div>
                      <div style={{ display: "flex", gap: "31px" }}>
                        <TextField
                          style={{ width: "14.5rem", borderRadius: "8px" }}
                          id={`first-name-${contactIndex}`}
                          label="First Name"
                          variant="outlined"
                          required
                          error={Boolean(contact.errors?.otherContactFirstName)}
                          helperText={contact.errors?.otherContactFirstName || ""}
                          value={contact.otherContactFirstName}
                          onChange={(e) =>
                            handleInputChange(
                              contactIndex,
                              "otherContactFirstName",
                              e.target.value
                            )
                          }
                        />
                        <TextField
                          style={{ width: "14.5rem", borderRadius: "8px" }}
                          id={`last-name-${contactIndex}`}
                          label="Last Name"
                          variant="outlined"
                          required
                          error={Boolean(contact.errors?.otherContactLastName)}
                          helperText={contact.errors?.otherContactLastName || ""}
                          value={contact.otherContactLastName}
                          onChange={(e) =>
                            handleInputChange(
                              contactIndex,
                              "otherContactLastName",
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          gap: "31px",
                          marginTop: "21px",
                        }}
                      >
                        <TextField
                          style={{ width: "14.5rem", borderRadius: "8px" }}
                          id={`designation-${contactIndex}`}
                          label="Designation Name"
                          variant="outlined"
                          required
                          error={Boolean(contact.errors?.otherContactDesginationName)}
                          helperText={contact.errors?.otherContactDesginationName || ""}
                          value={contact.otherContactDesginationName || ""}
                          onChange={(e) =>
                            handleInputChange(
                              contactIndex,
                              "otherContactDesginationName",
                              e.target.value
                            )
                          }
                        />
                        <TextField
                          style={{ width: "14.5rem", borderRadius: "8px" }}
                          id={`email-${contactIndex}`}
                          label="Email Address"
                          variant="outlined"
                          required
                          error={Boolean(contact.errors?.otherContactEmailId)}
                          helperText={contact.errors?.otherContactEmailId || ""}
                          value={contact.otherContactEmailId}
                          onChange={(e) =>
                            handleInputChange(
                              contactIndex,
                              "otherContactEmailId",
                              e.target.value
                            )
                          }
                        />
                      </div>
                      {contact.otherContactPhoneNumber.map((phone, phoneIndex) => (
                        <div
                          key={phoneIndex}
                          style={{
                            display: "flex",
                            gap: "13px",
                            marginTop: "21px",
                            alignItems: "center",
                          }}
                        >
                          <TextField
                            style={{ width: "220px" }}
                            label="Phone number"
                            value={phone}
                            error={Boolean(contact.errors?.otherContactPhoneNumber[phoneIndex])}
                            helperText={
                              contact.errors?.otherContactPhoneNumber[phoneIndex] || ""
                            }
                            onChange={(e) =>
                              handlePhoneNumberChange(
                                contactIndex,
                                phoneIndex,
                                e.target.value
                              )
                            }
                            InputProps={{
                              inputMode: "numeric",
                              startAdornment: (
                                <InputAdornment position="start">
                                  {phoneCode}
                                </InputAdornment>
                              ),
                            }}
                          />
                          {contact.otherContactPhoneNumber.length > 1 && (
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                handleRemovePhoneNumber(
                                  contactIndex,
                                  phoneIndex
                                )
                              }
                            >
                              <img
                                src={Trash}
                                style={{ height: "20px", width: "27px" }}
                              />
                            </div>
                          )}
                          <div
                            className={styles.plusii}
                            onClick={() => handleAddPhoneNumber(contactIndex)}
                            style={{ cursor: "pointer" }}
                          >
                            <img
                              src={plusBluehover}
                              style={{ height: "22px", width: "24px" }}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}

                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <div
                      className={styles.addduuucon}
                      style={{ marginTop: "12px", cursor: "pointer" }}
                      onClick={handleAddContact}
                    >
                      + Add Contact
                    </div>
                  </div>
                </div>

                {corporateFieldsCluster.map((item, index) => (
                  <div className={styles.addLeadFieldsRow} key={index}>
                    {console.log(item, "dcwecxwedcwecxwe")}
                    {item && item.length === 2 ? (
                      item.map((field, index) => (
                        <div className={styles.addLeadFieldsTwo}>
                          {console.log(field, "cervccervc")}
                          <GetComponentCorporate
                            field={field}
                            corpData={corpData}
                            onChange={changeCorporateValueHandler}
                          />
                        </div>
                      ))
                    ) : (
                      <div className={styles.addLeadFieldsOne}>
                        {console.log(item[0], "qwswedxqwswedx")}
                        <GetComponentCorporate
                          field={item[0]}
                          corpData={corpData}
                          onChange={changeCorporateValueHandler}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddLead;
