export const showColumnOptions=[
    {
        label:"Lead Name",
        value:"1"
    },
    {
        label:"Mobile No.",
        value:"2"

    },
    {
        label:"Email address",
        value:"3"

    },
    {
        label:"City",
        value:"4"

    },
    {
        label:"Budget",
        value:"5"

    },
    {
        label:"Work Scope",
        value:"6"

    },
    {
        label:"Area",
        value:"7"

    },
    {
        label:"Stage",
        value:"8"

    },
    {
        label:"Last Call Status",
        value:"9"
    },
    {
        label:"Last Call",
        value:"10"
    },
    {
        label:"Follow up",
        value:"11"
    },
    {
        label:"Exp. Closure",
        value:"12"
    },
    {
        label:"Assigned To",
        value:"13"
    },
    {
        label:"Lead Source",
        value:"14"
    },
    {
        label:"Source Name",
        value:"15"
    },
    {
        label:"Address",
        value:"16"
    },
]