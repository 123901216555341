import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import styles from "./createNewProjectModal.module.css";
import { mapState, projectDataCurrObj, setIsChecked, setIsChecked2, setIsChecked3, setPrevButton } from "../../../Redux/Slices/convertToProjectSlice";
import { useEffect } from "react";
import {   MenuItem,TextField,InputAdornment, Select ,Autocomplete} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Checkbox, Form, } from "antd";
import { createProjectModal, createTeam, currentLead, newProjectData, setLagg, setLat } from "../../../Redux/Slices/lmsSlice";
import {  constants } from "../../../piservices/constants";
import { FiUpload } from "react-icons/fi"
import { postReq } from "../../../piservices/apis";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import scanIcon from "../../../Assets/material-symbols_search-rounded.svg";
import CreateNewProject2 from "./CreateNewProject2";
import { BsChevronDown } from "react-icons/bs";
import { GoogleMap, MarkerF } from "@react-google-maps/api";
const libraries = ["places"];

const mapContainerStyle = {
  width: "100%",
  height: "367px",
  marginTop: "5%"
};

const center = {
  lat: 28.69680310102602,
  lng: 77.22127332285639,
};


const options = {
  disableDefaultUI: true,
  zoomControl: true,
};
const apiKey = "AIzaSyAdUtzsZsgXw6tacKy2WqbPAWreWOZM-pk";

const CreateNewProjectNew = ({ currentData }) => {
  const dispatch = useDispatch();
  const [discardOpen, setDiscardOpen] = useState(false);
  const [mapModalopen, setMapModalopen] = useState(false)
  const [city, setCity] = useState("");
  const [address2, setAddress2] = useState("");

  const [markerPosition, setMarkerPosition] = useState(center);

  const createProjectOpen = useSelector((state) => state.lmsSlice.createProject);
  const currentLeadData = useSelector((state) => state.lmsSlice.currentLeadStatus);
  const [lan, setLan] = useState("");
  const [lag, setLag] = useState("");
  const [form] = Form.useForm();
  const [addressValue, setaddressValue] = useState();
  const [projectFirstName, setProjectFirstName] = useState("");
  const [projectLastName, setProjectLastName] = useState("");
  const [projectProjectName, setProjectProjectName] = useState("");
  const [projectAddress, setProjectAddress] = useState("");
  const [projectCity, setProjectCity] = useState("");
  const [projectCode, setProjectCode] = useState("");
  const [projectphone, setProjectPhone] = useState("");
  const [projectEmail, setProjectEmail] = useState("");
  const [error, setError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [errorPin, setErrorPin] = useState(false);
  const [emailCheck, setEmailCheck] = useState(false)
  const [isAddressChange, setIsAddressChange] = useState(false)
  const [currProjectIdValue, setCurrProjectIdValue] = useState("");
  const [openTeamMember, setOpenTeamMember] = useState(false);
  const [moreDetail, setMoreDetail] = useState(false);
  const [projectBillTo, setProjectBillTo] = useState("");
  const [projectNote, setProjectNote] = useState("");
  const [projectGstReg, setProjectGstReg] = useState("");
  const [projectBillingLandmark, setProjectBillingLandmark] = useState("");
  const [projectBillingCity, setProjectBillingCity] = useState("");
  const [projectCompanyName, setProjectCompanyName] = useState("");
  const [projectStartDate, setProjectStartDate] = useState("");
  const [projectEndDate, setProjectEndDate] = useState("");
  const [projectBillingAddress, setProjectBillingAddress] = useState("");
const [newLag, setNewLag] = useState("")
const [newLan, setNewLan] = useState("")
const [kycType, setkycType] = useState(0);
const [projectAreaBudget, setProjectAreaBudget] = useState("");
const [areaUnit, setAreaUnit] = useState("sqft");
const [projectWorkScope, setProjectWorkScope] = useState("");
const [sameProjectAddress, setSameProjectAddress] = useState("");
const [errorProjectName, setErrorProjectName] = useState(false);
const [projectArea, setProjectArea] = useState("");
const [projectCountry, setProjectCountry] = useState("");
const [allCountry, setAllCountry] = useState("");
const [phoneCode, setPhoneCode] = useState("");
const [currency, setCurrency] = useState("");
const [errorCityName, setErrorCityName] = useState(false);
const [errorAddressName, setErrorAddressName] = useState(false);
const [currentCompany, setCurrentCompany] = useState("");


//  map location

const [mapLocation,setmapLocation]=useState("")


  //  seting state for data manage between previous value and next value 

  const [nextvalue,setnextvalue]=useState(null);
  

  // const [createTeamModal, setCreateTeamModal] = useState(false)
  // const [clientId, setClientId] = useState("");
  // const [uploadedItem, setUploadedItem] = useState("");
  // const [uploadFileLink, setUploadFileLink] = useState("");
  // const [city, setCity] = useState("");
  // const [address2, setAddress2] = useState('');
  // const [lan, setLan] = useState('');
  // const [lag, setLag] = useState('');
  const checked = useSelector((state) => state.convertToProjectSlice.isChecked);
  const prev = useSelector((state) => state.convertToProjectSlice.prevBtn);
  const currProjectDataValue = useSelector(
    (state) => state.convertToProjectSlice.currProjectDataObj
  );


  const profileData = useSelector((state) => state.lmsSlice.profileData);
  const mapSt = useSelector((state) => state.convertToProjectSlice.mapState);

  useEffect(() => {
    if (sameProjectAddress) {
      setProjectBillingCity(projectCity);
      setProjectBillingAddress(projectAddress);
    } else {
      setProjectBillingCity("");
      setProjectBillingAddress("");
    }
  }, [sameProjectAddress]);


  // const handleChange2 = (value) => {
  //   setAddress2(value);

  // };

  // const handleSelect2 = async (value) => {
  //   try {
  //     const results = await geocodeByAddress(value);
  //     const latLng = await getLatLng(results[0]);

  //     setAddress2(results[0].formatted_address)
  //     setLan(latLng.lat)

  //     setLag(latLng.lng)
  //   } catch (error) {
  //     console.error('Error:', error);
  //   }

  // };
  // const handleSubmit = async () => {
  // event.preventDefault();


  // fetch(
  //   `https://maps.googleapis.com/maps/api/geocode/json?address=${address2}&key=AIzaSyAdUtzsZsgXw6tacKy2WqbPAWreWOZM-pk`
  // )
  //   .then((response) => response.json())
  //   .then((data) => {
  //     const { lat, lng } = data.results[0].geometry.location;
  //     setMarkerPosition({ lat, lng });
  //     localStorage.setItem("address", data.results[0].geometry.location);
  //     localStorage.setItem("Long & Lat",{ lat, lng });
  //   })
  //   .catch((error) => console.error("Error:", error));
  //   const res = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${address2}&key=AIzaSyAdUtzsZsgXw6tacKy2WqbPAWreWOZM-pk`)

  //   // console.log("@@@@@@@@", res?.data?.results[0]?.formatted_address)
  //   const { lat, lng } = res?.data?.results[0]?.geometry?.location;
  //   setMarkerPosition({ lat, lng });
  //   localStorage.setItem("address", res?.data?.results[0]?.geometry.location);
  //   localStorage.setItem("Long & Lat", { lat, lng });


  // };



  // let cityName = `${addressValue ? addressValue : ""},${city}`


  // const inputHandlerAddress = (event) => {
  //   setaddressValue(event.target.value);
  // };

  // email validation
  // const emailValidation = () => {
  //   return /\S+@\S+\.\S+/.test(details.email);
  // };

  // useEffect(() => {
  //   const mailE = emailValidation();
  //   if (!mailE) {
  //     setEmailErr("Invalid Email");
  //   } else {
  //     setEmailErr("");
  //   }
  // }, [details.email]);

  // useEffect(() => {
  //   localStorage.setItem("address", `${addressValue ? addressValue : ""},${city}`);

  //   // setAddressLS(addressValue,city)
  // }, [city]);

  // const onEditProject = async (formData) => {
  //   const projRes = await postReq(
  //     `${constants.pmt
  //     }/api/projects/edit-location?projectId=${localStorage.getItem(
  //       "projectId"
  //     )}`,
  //     {
  //       id: localStorage.getItem("projectId"),
  //       name: formData.projName,
  //       address: addressValue,
  //       city: formData.city,
  //       landmark: formData.landmark,
  //       phn: formData.phone,
  //       userId: localStorage.getItem("userId"),
  //     }
  //   );
  // const editClient = await postReq(
  //   `${constants.B2B_BASE}/user/editClient?id=${localStorage.getItem(
  //     "clientId"
  //   )}`,
  //   {
  //     fullName: formData.cliName,
  //     phoneNumber: formData.phone,

  //     address: addressValue,
  //   }
  // );
  //   if ((projRes) && (!projRes.error)) {



  //   }
  // }
  // const [previousData, setPreviousData] = useState("");
  // const getPriousData = async () => {
  //   const res = await axios.get(`${constants.pmt}/api/projects/get-project-byId?projectId=${localStorage.getItem("projectId")}`)
  //   setPreviousData(res.data)
  // }

  // useEffect(() => {
  //   if (localStorage.getItem("prev")) {

  //     getPriousData()
  //   }
  // }, [localStorage.getItem("prev")]);

  // const onformFinish = (formD) => {
  //   // createProject1(formD);
  //   dispatch(newProjectData(formD))
  //   setMapModalopen(true)
  //   dispatch(createProjectModal(false))
  // };
  // useEffect(() => {
  //   if (localStorage.getItem("prev")) {

  //     getPriousData()
  //   }
  // }, [localStorage.getItem("prev")]);
  // const handleFileUpload = (e) => {
  //   let uploadIt = e.target.files;
  //   let file = new FormData();
  //   file.append("bucketName", "idesign-quotation");
  //   file.append("files", uploadIt[0]);
  //   upload(file);
  //   let x = e.target.value.split("\\");
  //   setUploadedItem(x[x.length - 1]);
  // };
  // const upload = async (data) => {
  //   return await axios
  //     .put(`${constants.S3_BUCKET}/api/upload/`, data)
  //     .then((re) => setUploadFileLink(re.data.locations[0]))
  //     .catch((err) => console.log(err));
  // };

  // const handleNewProjectClose = () => {
  //   setDiscardOpen(true)
  // }
  // const [discardOpen, setDiscardOpen] = useState(false);
  // const [projectName, setProjectName] = useState(null);
  // const handleProjectName = (e) => {
  //   if(e.target.value?.length<=50){
  //     console.log('pname', e.target.value)
  //     setProjectName(e.target.value)
  //   }

  // }
  // const deleteCreatedProject = async () => {
  //   const res = await postReq(
  //     `${constants.pmt
  //     }/api/projects/edit-location?projectId=${localStorage.getItem(
  //       "projectId"
  //     )}`,
  //     {

  //       isDeleted: true,

  //     }
  //   );
  //   if (res) {
  //     console.log(res)
  //   }
  // }
  // const [discardModalopen, setDiscardModalopen] = useState(false)
  // const handleDiscardClose = () => {


  //   dispatch(createProjectModal(false))
  //   dispatch(currentLead(""))
  //   setDiscardModalopen(false)
  //   deleteCreatedProject()
  //   form.resetFields();


  // }

  // // Map
  // const [markerPosition, setMarkerPosition] = useState(center);

  // const handleMarkerDrag = (e) => {
  //   const newPosition = {
  //     lat: e.latLng.lat(),
  //     lng: e.latLng.lng(),
  //   };

  //   setMarkerPosition(newPosition);
  //   getLocationFromLatLng()
  // };
  // const changeAddress = async () => {
  //   const res = await postReq(
  //     `${constants.pmt
  //     }/api/projects/edit-location?projectId=${localStorage.getItem(
  //       "projectId"
  //     )}`,
  //     {
  //       location: address2

  //     }
  //   );
  // }
  // const getLocationFromLatLng = async () => {

  //   const response = await axios.get(
  //     `https://maps.googleapis.com/maps/api/geocode/json?latlng=${markerPosition?.lat},${markerPosition?.lng}&key=AIzaSyAdUtzsZsgXw6tacKy2WqbPAWreWOZM-pk`
  //   );

  //   if (response.data.results.length > 0) {
  //     const location = response.data.results[0].formatted_address;
  //     setAddress2(location)

  //   }
  // };
 
  let projectDataObj = {
    firstName: "",
    lastName: "",
    projectName: "",
    address: "",
    city: "",
    code: "",
    phnNum: "",
    email: "",
    document: "",
    documentLink: "",
    emailCheck: false,
    lan:"",
    country: "",
    kycType: 0,
    companyName: "",
    expectedBudget: "",
    projectUom: "",
    area: "",
    mapLocation:"",
    expectedStartDate: "",
    expectedEndDate: "",
    workScope: "",
    billingAddress: "",
    billingLandmark: "",
    billingCity: "",
    billTo: "",
    gstRegNo: "",
    note: "",
    phoneCode: "",
    currency: "",
    lng:"",
    projectUom:""
  };
  const handleNewProjectClose = () => {
    setDiscardOpen(true);
  };
  const getLocationFromLatLng = async () => {
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${markerPosition?.lat},${markerPosition?.lng}&key=AIzaSyAdUtzsZsgXw6tacKy2WqbPAWreWOZM-pk`
    );

    // if (response.data.results.length > 0) {
      const { lat, lng } = response?.data?.results[0]?.geometry?.location;
      setLan(lat);
      setLag(lng);
      dispatch(setLagg(lng))
      dispatch(setLat(lat))
      projectDataObj.lan = lat
      projectDataObj.lag = lng  
      const location = response.data.results[0].formatted_address;
      // setAddress2(location);
      setmapLocation(location)
    // } else if (response?.data?.results?.length === 0) {
    //   alert("Enter the address and city correctly")
    // }
  };
  const handleMarkerDrag = (e) => {
    const newPosition = {
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    };

    setMarkerPosition(newPosition);
    getLocationFromLatLng();
  };


  // console.log(projectCode.length)
  // console.log(errorPin)

  const onformFinish = (formD) => {
    // if (prev === true) {
    //   onEditProject(formD);
    // } else 

    if (
      (projectProjectName == "" ||
        projectProjectName == undefined ||
        projectFirstName == "" ||
        projectFirstName == undefined ||
        projectLastName == "" ||
        projectLastName == undefined ||
        projectCountry == "" ||
        projectCountry == undefined ||
        (projectCode?.length !=0 && projectCode?.length<6)||
        
        // projectAddress == "" ||
        // projectAddress == undefined ||
        // projectCity == "" ||
        // projectCity == undefined ||
        // projectCode == "" ||
        // projectCode == undefined ||
        // projectphone == "" ||
        // projectphone == undefined ||
        // error ||
        // errorPin ||
        errorProjectName ||
        errorCityName ||
        errorAddressName||
        emailError ||
        projectEmail == "" ||
        projectEmail == undefined)

    ) {
      alert("Fill all the details correctly");
    }
    else {
      // createProject1(formD);
      
      dispatch(projectDataCurrObj(projectDataObj));
      dispatch(mapState(true));
      dispatch(setIsChecked(false));

    }
    // dispatch(mapState(true));
    // dispatch(newProject(formD));
  };
  const deleteCreatedProject = async () => {
    const res = await postReq(
      `${constants.pmt}/api/projects/edit-location?projectId=${currProjectIdValue}`,
      {
        isDeleted: true,
      }
    );
    if (res) {
      console.log(res);
    }
  };






  const handleChange2 = (value) => {
    console.log(value,"value")
   
    let newObj = JSON.parse(JSON.stringify(currProjectDataValue))
    // newObj["address"] = value
    newObj["mapLocation"]=value;
    newObj["lan"] = lan
    newObj["lag"] = lag


    // setAddress2(value);
    setmapLocation(value)
    setIsAddressChange(true)
    setnextvalue(newObj)
    // dispatch(projectDataCurrObj(newObj));

  };
  const cityNameHandle = (e) => {
    if (
      e.target.value?.length <= 50 &&
      (/^[a-zA-Z\s]*$/.test(e.target.value) || e.target.value === "")
    ) {
      setProjectCity(e.target.value);
      setErrorCityName(false);
    } else {
      setErrorCityName(true);
      setProjectCity(e.target.value);
    }
  };
  const getCountry = async () => {
    const res = await axios.get(
      "https://restcountries.com/v2/all"
    );
    if (res) {
      console.log(res.data);
      setAllCountry(res.data);
    }
  };
  useEffect(() => {
    getCountry();
  }, []);


  const handleSelect2 = async (value) => {

    try {
      let newObj = JSON.parse(JSON.stringify(currProjectDataValue))

      const results = await geocodeByAddress(value);
      const latLng = await getLatLng(results[0]);
      setLan(latLng.lat);
      setLag(latLng.lng);
      dispatch(setLagg(latLng.lng))
      dispatch(setLat(latLng.lat))

      // setAddress2(results[0].formatted_address);
      setmapLocation(results[0].formatted_address)
      // newObj["address"] = results[0].formatted_address
         newObj["mapLocation"] = results[0].formatted_address
      newObj["lan"] = latLng.lat
      newObj["lag"] = latLng.lng
      setnextvalue(newObj)

      // dispatch(projectDataCurrObj(newObj));
      // dispatch(projectDataCurrObj({...projectDataObj,address:results[0].formatted_address}));


    } catch (error) {
      console.error("Error:", error);
    }

  };
  const handleDiscardClose = () => {
    setDiscardOpen(false);
    // dispatch(isLeadProject(false))
    dispatch(projectDataCurrObj());
    setProjectFirstName("")
    setProjectLastName("")
    setProjectProjectName("")
    setProjectAddress("")
    setProjectCity("")
    setProjectCode("")
    setProjectPhone("")
    setProjectEmail("")
    setErrorPin(false)
    setError(false)
    setEmailError(false)
    setErrorProjectName(false);
    dispatch(projectDataCurrObj(""));
    setmapLocation("")
    // dispatch(existClientModal(false));
    // deleteCreatedProject();
    dispatch(setIsChecked(false));
    // dispatch(setProjectReload(true));
    form.resetFields();
  };


  
  const handleSubmit = async () => {


        const res = await axios.get(
          // `https://maps.googleapis.com/maps/api/geocode/json?address=${address2}&key=AIzaSyAdUtzsZsgXw6tacKy2WqbPAWreWOZM-pk`
          `https://maps.googleapis.com/maps/api/geocode/json?address=${mapLocation}&key=AIzaSyAdUtzsZsgXw6tacKy2WqbPAWreWOZM-pk`
        );
    // if(res?.data?.results?.length>0){
        const { lat, lng } = res?.data?.results[0]?.geometry?.location;
        setLan(lat);
        setLag(lng);
        dispatch(setLagg(lng))
        dispatch(setLat(lat))
        setNewLan(lat)
        setNewLag(lng)
        projectDataObj.lan = lat
        projectDataObj.lng = lng
        setMarkerPosition({ lat, lng });
        localStorage.setItem("address", res?.data?.results[0]?.geometry.location);
        localStorage.setItem("Long & Lat", { lat, lng });
        // onformFinish();
    // }
    // else{
    //   alert("Please enter address correctly")
    // }
      };
      useEffect(()=>{
        handleSubmit()
      },[lan, lag])
  const handleSubmitProject = () => {
   
    // handleSubmit()
    projectDataObj.firstName = projectFirstName;
    projectDataObj.lastName = projectLastName;
    projectDataObj.projectName = projectProjectName;
    projectDataObj.address = projectAddress;
    projectDataObj.city = projectCity;
    projectDataObj.code = projectCode;
    projectDataObj.phnNum = projectphone;
    projectDataObj.email = projectEmail;
    projectDataObj.emailCheck = emailCheck
    projectDataObj.lan = newLan
    projectDataObj.lng = newLag
    projectDataObj.country = projectCountry;
    projectDataObj.kycType = kycType;
    projectDataObj.companyName = projectCompanyName;
    projectDataObj.expectedBudget = projectAreaBudget;
    projectDataObj.projectUom = areaUnit;
    projectDataObj.area = projectArea;
    projectDataObj.expectedStartDate = projectStartDate;
    projectDataObj.expectedEndDate = projectEndDate;
    projectDataObj.workScope = projectWorkScope;
    projectDataObj.billingAddress = projectBillingAddress;
    projectDataObj.billingLandmark = projectBillingLandmark;
    projectDataObj.billingCity = projectBillingCity;
    projectDataObj.billTo = projectBillTo;
    projectDataObj.gstRegNo = projectGstReg;
    projectDataObj.note = projectNote;
    projectDataObj.phoneCode = phoneCode;
    projectDataObj.currency = currency;
    projectDataObj.mapLocation=mapLocation;
    onformFinish()
  };
 
  
  const changeAddress = async () => {
    if (isAddressChange) {
      const res = await postReq(
        `${constants.pmt
        }/api/projects/edit-location?projectId=${localStorage.getItem(
          "projectId"
        )}`,
        {
          location: address2,
        }
      );
    }
  };
  useEffect(() => {
    setProjectCountry("");
    setPhoneCode("");
    setCurrency("");
    setProjectCountry(profileData?.CompanySettingDetails?.country);
    setCurrency(profileData?.CompanySettingDetails?.currencySymbol);
    setPhoneCode(profileData?.CompanySettingDetails?.countryCode);
  }, [profileData?.CompanySettingDetails])
  useEffect(() => {
    setProjectFirstName(currProjectDataValue?.firstName);
    setProjectLastName(currProjectDataValue?.lastName);
    setProjectProjectName(currProjectDataValue?.projectName);
    setProjectAddress(currProjectDataValue?.address);
    setProjectCity(currProjectDataValue?.city);
    setProjectCode(currProjectDataValue?.code);
    setProjectPhone(currProjectDataValue?.phnNum);
    setProjectEmail(currProjectDataValue?.email);
    // setAddress2(currProjectDataValue?.address)
    setmapLocation(currProjectDataValue?.mapLocation)
    
  }, [currProjectDataValue]);



  const handlePhoneNumberChange = (event) => {
    const inputValue = event.target.value.replace(/[^0-9]/g, "");
    if (event.target.value?.length <= 10) {
      setProjectPhone(inputValue);

      if (
        /^\d+$/.test(inputValue) &&
        inputValue?.length >= 10 &&
        inputValue?.length <= 10
      ) {
        setProjectPhone(inputValue);
        setError(false);
      } else {
        setError(true);
        setProjectPhone(inputValue);
      }
    }
  };
  const projectAreaBudgetHandle = (e) => {
    const inputValue = e.target.value.replace(/[^0-9]/g, "");
    if (e.target.value?.length <= 15) {
      setProjectAreaBudget(inputValue);
    }
  };
  const handleProjectCompanyName = (e) => {
    if (e.target.value?.length <= 36 && (/.*[a-zA-Z].*/.test(e.target.value) || e.target.value === "")) {
      // let res = e.target.value.replace(/[^A-Za-z]/g, "");
      setProjectCompanyName(e.target.value);
    }
  };
  const projectAreaHandle = (e) => {
    const inputValue = e.target.value.replace(/[^0-9]/g, "");
    if (inputValue?.length <= 10) {
      setProjectArea(inputValue);
    }
  };
  const projectFirstNameHandle = (e) => {
    if (e.target.value?.length <= 50 && /^[a-zA-Z\s]*$/.test(e.target.value)) {
      setProjectFirstName(e.target.value)
    }
  }
  const projectLastNameHandle = (e) => {
    if (e.target.value?.length <= 50 && /^[a-zA-Z\s]*$/.test(e.target.value)) {
      setProjectLastName(e.target.value)
    }
  }
  const projectNameHandle = (e) => {
    if (e.target.value?.length <= 50) {
      setProjectProjectName(e.target.value)
    }
  }
  const handlePinCodeChange = (event) => {
    const inputValue = event.target.value.replace(/[^0-9]/g, '');
    if (event.target.value?.length <= 6) {
      setProjectCode(inputValue);

      if (/^\d+$/.test(inputValue) && inputValue?.length >= 6 && inputValue?.length <= 6) {
        setProjectCode(inputValue);
        setErrorPin(false);
      } else {
        setErrorPin(true);
        setProjectCode(inputValue);
      }
    }
  };
  const handleEmailChange = (event) => {
    const inputValue = event.target.value;
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,100}$/i;
    if (emailPattern.test(inputValue)) {
      setProjectEmail(inputValue);
      setEmailError(false);
    } else {
      setEmailError(true);
      setProjectEmail(inputValue);
    }
  };

  return (
    <React.Fragment>
      {openTeamMember && <CreateNewProject2 leadInfo={currentData} />}
      <Modal
        show={checked}
        onHide={() => setDiscardOpen(true)}
        backdrop="static"
        keyboard={false}
        centered
        dialogClassName="createNewModal"
        size="lg"
      >
        <Modal.Header style={{ borderBottom: "none",paddingBottom: "0" }}>
          <Modal.Title>
            <div className={styles.createProjectModalHeader}>
              <span>Create Project</span>
              <div className={styles.closeBtn}>
                <button
                  className={styles.createModalClose}
                  onClick={handleNewProjectClose}
                >
                  <span
                    style={{
                      color: "#C9C9C9",
                      fontWeight: "600",
                      fontSize: "1rem",
                    }}
                  >
                    Cancel{" "}
                  </span>
                </button>
                <button
                  className={styles.createModalSave}
                  onClick={handleSubmitProject}
                >
                  <span
                    style={{
                      color: "#FFF",
                      fontWeight: "600",
                      fontSize: "1rem",
                    }}
                  >
                    Next{" "}
                  </span>
                </button>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="createNewProjectModalBody"
          style={{ width: "49.8rem", maxHeight: "80vh", overflowY: "scroll" }}>
        <div className={styles.typeKyc}>
            <div
              className={
               ( kycType === 0 || kycType === undefined)? styles.individualActive : styles.individual
              }
              onClick={() => setkycType(0)}
            >
              <span
                className={
                 ( kycType === 0 || kycType === undefined)
                    ? styles.individualActiveText
                    : styles.individualText
                }
              >
                Individual
              </span>
            </div>
            <div
              className={
                kycType === 1 ? styles.individualActive : styles.individual
              }
              onClick={() => setkycType(1)}
            >
              <span
                className={
                  kycType === 1
                    ? styles.individualActiveText
                    : styles.individualText
                }
              >
                Corporate
              </span>
            </div>
          </div>
          {kycType === 1 && (
            <div className={styles.formName}>
              <TextField
                style={{ width: "46rem" }}
                id="outlined-basic"
                label="Company Name*"
                variant="outlined"
                isRequired={true}
                value={projectCompanyName}
                onChange={(e) => handleProjectCompanyName(e)}
              />
            </div>
          )}
          <div className={styles.formName}>
            <TextField
              style={{ width: "22.1875rem" }}
              id="outlined-basic"
              label="First Name*"
              variant="outlined"
              isRequired={true}
              value={projectFirstName}
              onChange={(e) => { projectFirstNameHandle(e) }}
            />

            <TextField
              style={{ width: "22.1875rem" }}
              id="outlined-basic"
              label="Last Name*"
              variant="outlined"
              isRequired={true}
              value={projectLastName}
              onChange={(e) => projectLastNameHandle(e)}
            />
          </div>
          <div className={styles.formName}>
            <TextField
              style={{ width: "22.1875rem" }}
              id="outlined-basic"
              label="Project Name*"
              variant="outlined"
              isRequired={true}
              value={projectProjectName}
              error={errorProjectName}
              helperText={
                errorProjectName ? "Project name should be alphanumeric" : ""
              }
              onChange={(e) => projectNameHandle(e)}
            />
            <Autocomplete
              disablePortal
              style={{ width: "22.1875rem" }}
              value={projectCountry}
              id="combo-box-demo"
              options={
                allCountry?.length > 0 &&
                allCountry?.map((curr) => {
                  return { label: curr?.name, id: curr?.name, all: curr };
                })
              }
              onChange={(object, value) => (setProjectCountry(value?.id), setCurrentCompany(value?.all),setCurrency(value?.all?.currencies[0].symbol),setPhoneCode(value?.all?.callingCodes[0]))}
              renderInput={(params) => (
                <TextField
                  style={{ width: "22.1875rem" }}
                  {...params}
                  label="Country*"
                />
              )}
            />
          </div>
          <div className={styles.formName}>
            <TextField
              style={{ width: "46.5rem" }}
              id="outlined-basic"
              label="Address"
              variant="outlined"
              isRequired={true}
              value={projectAddress}
              // onChange={(e) => (setProjectAddress(e.target.value),setAddress2(e.target.value))}
              onChange={(e) => (setProjectAddress(e.target.value))}
             
              
            />
          </div>
          <div className={styles.formName}>
          <TextField
              style={{ width: "22.1875rem" }}
              id="outlined-basic"
              label="City"
              variant="outlined"
              isRequired={true}
              value={projectCity}
              error={errorCityName}
              helperText={
              errorCityName ? "City name should only have alphabets" : ""
              }
              onChange={(e) => cityNameHandle(e)}
            />

            <TextField
              style={{ width: "22.1875rem" }}
              id="outlined-basic"
              label="Zip/Postal code"
              variant="outlined"
             
              value={projectCode}
              error={projectCode?.length>0? errorPin:""}
              helperText={errorPin && projectCode?.length>0 ? 'Invalid Pin Code' : ''}
              onChange={(e) => handlePinCodeChange(e)}
              InputProps={{
                inputMode: 'numeric',

              }}
            />  
          </div>
          <div className={styles.formName}>
          <TextField
              style={{ width: "22.1875rem" }}
              id="outlined-basic"
              label="Contact No."
              variant="outlined"
              value={projectphone}
              error={error}
              helperText={error ? "Invalid phone number" : ""}
              onChange={(e) => handlePhoneNumberChange(e)}
              InputProps={{
                inputMode: "numeric",
                startAdornment: (
                  <InputAdornment position="start">
                    {profileData?.CompanySettingDetails?.countryCode}
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              style={{ width: "22.1875rem" }}
              id="outlined-basic"
              label="Email Address*"
              variant="outlined"
              isRequired={true}
              value={projectEmail}
              error={emailError}
              helperText={emailError ? 'Invalid email address' : ''}
              onChange={(e) => handleEmailChange(e)}
            />
          </div>

          {moreDetail ? (
            <div
              className={styles.addMoreDetail}
              onClick={() => setMoreDetail(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <path
                  d="M12.4688 7C12.4688 7.17405 12.3996 7.34097 12.2765 7.46404C12.1535 7.58711 11.9865 7.65625 11.8125 7.65625H2.1875C2.01345 7.65625 1.84653 7.58711 1.72346 7.46404C1.60039 7.34097 1.53125 7.17405 1.53125 7C1.53125 6.82595 1.60039 6.65903 1.72346 6.53596C1.84653 6.41289 2.01345 6.34375 2.1875 6.34375H11.8125C11.9865 6.34375 12.1535 6.41289 12.2765 6.53596C12.3996 6.65903 12.4688 6.82595 12.4688 7Z"
                  fill="#343330"
                />
              </svg>
              <span className={styles.addMoreDetailText}>
                Hide Additional Details
              </span>
            </div>
          ) : (
            <div
              className={styles.addMoreDetailActive}
              onClick={() => setMoreDetail(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <path
                  d="M6.41675 7.58268H2.91675V6.41602H6.41675V2.91602H7.58342V6.41602H11.0834V7.58268H7.58342V11.0827H6.41675V7.58268Z"
                  fill="#5B81F3"
                />
              </svg>

              <span className={styles.addMoreDetailTextActive}>
                Add Additional Details
              </span>
            </div>
          )}
          {moreDetail && (
            <>
              <div className={styles.formName}>
                <TextField
                  style={{ width: "22.1875rem" }}
                  id="outlined-basic"
                  label="Expected Budget"
                  variant="outlined"
                  value={projectAreaBudget}
                  onChange={(e) => projectAreaBudgetHandle(e)}
                  InputProps={{
                    inputMode: "numeric",
                    startAdornment: (
                      <InputAdornment position="start">
                        {profileData?.CompanySettingDetails?.currencySymbol}
                      </InputAdornment>
                    ),
                  }}
                />

                <TextField
                  style={{ width: "22.1875rem" }}
                  id="outlined-basic"
                  label="Area"
                  // variant="outlined"
                  isRequired={true}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Select
                          variant="standard"
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          value={areaUnit}
                          renderValue={() => areaUnit}
                          label="Unit"
                          // onChange={handleChange}
                          InputProps={{
                            disableUnderline: true, // <== added this
                          }}
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                maxHeight: 150,
                                "&.MuiPaper-root": {
                                  // left: window.screen.width < 1400 ? '30vw !important' : '35vw !important',
                                },
                              },
                            },
                          }}
                        >
                          <MenuItem
                            sx={{ minWidth: 200 }}
                            onClick={() => setAreaUnit("sqft")}
                            value={"sqft"}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div className={styles.areaUnit}>Sqft</div>
                            <div className={styles.areaDescription}>
                              Square feet
                            </div>
                          </MenuItem>
                          <MenuItem
                            sx={{ minWidth: 200 }}
                            onClick={() => setAreaUnit("sqm")}
                            value={"sqm"}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div className={styles.areaUnit}>Sqm</div>
                            <div className={styles.areaDescription}>
                              Square meter
                            </div>
                          </MenuItem>
                        </Select>
                      </InputAdornment>
                    ),
                  }}
                  value={projectArea}
                  onChange={(e) => projectAreaHandle(e)}
                />
              </div>
              <div className={styles.formName}>
                <div className="w-50 px-2" style={{ marginLeft: "0.8%" }}>
                  <div
                    type="button"
                    className={`br-5 btn-light  border px-3 px-md-2    position-relative`}
                    style={{
                      height: "3.5rem",
                      borderRadius: "4px",
                      width: "22.1875rem",
                      color: "#00000099",
                      padding: "13.5px 14px",
                    }}
                    data-mdb-ripple-color="dark"
                  >
                    <div className="d-flex align-items-center justify-content-between">
                      <span className="me-4 pfs-12 mv-pfs-14 text-nowrap">
                        {" "}
                        {(projectStartDate === "" || projectStartDate === undefined)
                          ? "Start Date"
                          : projectStartDate}
                      </span>
                      {/* <img src={vector6} alt="" width={14}
                                height={14} className="cursor-pointer" /> */}
                      <BsChevronDown />
                    </div>
                    <input
                      type="date"
                      min={new Date().toISOString().split("T")[0]}
                      id="birthday"
                      name="birthday"
                      className="position-absolute form-control top-0 end-0 w-100 px-2 h-100 opacity-0"
                      onChange={(e) => {
                        setProjectStartDate(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="w-50 px-2" style={{ marginLeft: "0.8%" }}>
                  <div
                    type="button"
                    className={`br-5 btn-light  border px-3 px-md-2    position-relative`}
                    style={{
                      height: "3.5rem",
                      borderRadius: "4px",
                      width: "22.1875rem",
                      color: "#00000099",
                      padding: "13.5px 14px",
                    }}
                    data-mdb-ripple-color="dark"
                  >
                    <div className="d-flex align-items-center justify-content-between">
                      <span className="me-4 pfs-12 mv-pfs-14 text-nowrap">
                        {" "}
                        {(projectEndDate === "" || projectEndDate === undefined) ? "End Date" : projectEndDate}
                      </span>
                      {/* <img src={vector6} alt="" width={14}
                                height={14} className="cursor-pointer" /> */}
                      <BsChevronDown />
                    </div>
                    {(projectStartDate !== "" && projectStartDate !== undefined) && (
                      <input
                        min={
                          new Date(projectStartDate).toISOString().split("T")[0]
                        }
                        type="date"
                        id="birthday"

                        name="birthday"
                        className="position-absolute form-control top-0 end-0 w-100 px-2 h-100 opacity-0"
                        onChange={(e) => {
                          setProjectEndDate(e.target.value);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className={styles.formName}>
                <TextField
                  style={{ width: "46rem" }}
                  id="outlined-basic"
                  label="Work Scope"
                  variant="outlined"
                  isRequired={true}
                  value={projectWorkScope}
                  onChange={(e) => {e.target.value?.length <=50 && setProjectWorkScope(e.target.value)}}
                />
              </div>
              <div className={styles.formCheckbox}>
                <div
                  style={{
                    width: "36%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Checkbox
                    checked={sameProjectAddress}
                    onChange={() => setSameProjectAddress(!sameProjectAddress)}
                  />
                  <p
                    style={{
                      display: "flex",
                      marginBottom: "0%",
                      marginLeft: "2%",
                    }}
                  >
                    Same as project address
                  </p>
                </div>
              </div>
              <div className={styles.formName}>
                <TextField
                  style={{ width: "46rem" }}
                  id="outlined-basic"
                  label="Billing Address"
                  variant="outlined"
                  isRequired={true}
                  value={projectBillingAddress}
                  onChange={(e) => { e.target.value?.length <=400 && setProjectBillingAddress(e.target.value)}}
                />
              </div>
              <div className={styles.formName}>
                <TextField
                  style={{ width: "22.1875rem" }}
                  id="outlined-basic"
                  label="Billing Landmark"
                  variant="outlined"
                  isRequired={true}
                  value={projectBillingLandmark}
                  onChange={(e) => {e.target.value?.length<=500 && setProjectBillingLandmark(e.target.value)}}
                />
                <TextField
                  style={{ width: "22.1875rem" }}
                  id="outlined-basic"
                  label="Billing City"
                  variant="outlined"
                  isRequired={true}
                  value={projectBillingCity}
                  onChange={(e) => {e.target.value?.length<=50 && setProjectBillingCity(e.target.value)}}
                />
              </div>
              <span
                style={{
                  color: "#212121",
                  fontSize: "1rem",
                  fontWeight: "600",
                  paddingLeft: "1.5%",
                }}
              >
                GST Bill
              </span>
              <div className={styles.formName}>
                <TextField
                  style={{ width: "22.1875rem" }}
                  id="outlined-basic"
                  label="Bill To"
                  variant="outlined"
                  isRequired={true}
                  value={projectBillTo}
                  onChange={(e) => {e.target.value?.length <=400 && setProjectBillTo(e.target.value)}}
                />
                <TextField
                  style={{ width: "22.1875rem" }}
                  id="outlined-basic"
                  label="GST Reg No."
                  variant="outlined"
                  isRequired={true}
                  value={projectGstReg}
                  onChange={(e) => {e.target.value?.length<=16 && setProjectGstReg(e.target.value)}}
                />
              </div>
              <div className={styles.formName1}>
                <TextField
                  style={{ width: "46rem" }}
                  // className={styles.addNoteText}
                  id="outlined-basic"
                  label="Add Note"
                  variant="outlined"
                  isRequired={true}
                  value={projectNote}
                  rows={3}
                  multiline
                  onChange={(e) => setProjectNote(e.target.value)}
                />
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer className={styles.createModalFooter}>
          <div className={styles.formCheckbox}>
            <div
              style={{ width: "36%", display: "flex", alignItems: "center" }}
            >
              <Checkbox
                checked={emailCheck}
                onChange={() => setEmailCheck(!emailCheck)}
              />
              <p
                style={{
                  display: "flex",
                  marginBottom: "0%",
                  marginLeft: "2%",
                }}
              >
                Send email invite to client
              </p>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        title={false}
        show={discardOpen}
        centered
        width={300}
        bodyStyle={{ height: 60 }}
        closable={false}
      >
        <h6 style={{ padding: "3%" }}>Do you want to discard the project ?</h6>
        <Modal.Footer>
          <div className={styles.discardCloseBtn}>
            <button
              className={styles.createModalSave}
              onClick={handleDiscardClose}
            >
              <span
                style={{
                  color: "#FFF",
                  fontWeight: "600",
                  fontSize: "1rem",
                }}
              >
                Yes{" "}
              </span>
            </button>
            <button
              className={styles.createModalClose}
              onClick={() => setDiscardOpen(false)}
            >
              <span
                style={{
                  color: "#C9C9C9",
                  fontWeight: "600",
                  fontSize: "1rem",
                }}
              >
                No{" "}
              </span>
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* mapSt */}
      {mapSt && (
        <Modal
          show={true}
          size="lg"
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header style={{ borderBottom: "none" }}>
            <Modal.Title>
              <div className={styles.createProjectModalHeader}>
                <span>Set Location</span><span style={{
                  color: '#888',
                  fontSize: '14px',
                  paddingTop:'5px',

                }}>(Selected location will be used to mark geo attendance)</span>
                <div style={{left:'10%'}} className={styles.closeBtn}>
                  <button
                    className={styles.createModalClose}
                    onClick={() => (
                      dispatch(mapState(false)),
                      dispatch(setIsChecked(true)),
                      localStorage.setItem("prev", "yes"),
                      dispatch(setPrevButton(true))
                    )}
                  >
                    <span
                      style={{
                        color: "#C9C9C9",
                        fontWeight: "600",
                        fontSize: "1rem",
                      }}
                    >
                      Previous{" "}
                    </span>
                  </button>
                  <button
                    className={styles.createModalSave}
                    onClick={() => {
                      setOpenTeamMember(true);
                      dispatch(mapState(false));
                      dispatch(setIsChecked(false));
                      dispatch(setIsChecked2(true));
                      localStorage.setItem("address", city);
                      console.log(nextvalue,"nextvalue");
                      if(nextvalue!==null){
                        dispatch(projectDataCurrObj(nextvalue));
                      }
                

                    }}
                  >
                    <span
                      style={{
                        color: "#FFF",
                        fontWeight: "600",
                        fontSize: "1rem",
                      }}
                    >
                      Next{" "}
                    </span>
                  </button>
                </div>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              <PlacesAutocomplete
                // value={address2}
                value={mapLocation}
                onChange={handleChange2}
                onSelect={handleSelect2}
              >

                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>

                    <div style={{ display: "flex" }}>
                      {/* <input
                      style={{
                        padding:""
                      }}
                      {...getInputProps({
                        placeholder: "Search your location",
                      })}
                      value={address2}
                    /> */}
                      <div className={styles.formName}>
                        <TextField
                          style={{ width: "46.5rem" }}
                          id="outlined-basic"
                          label="Search for Location"
                          variant="outlined"
                          isRequired={true}
                          value={mapLocation}
                     
                          {...getInputProps({
                            placeholder: "Search your location",
                          })}
                        />
                      </div>
                      <img
                        src={scanIcon}
                        alt=""
                        style={{ marginLeft: "-5%", height: "100%", paddingTop: "2.5%", zIndex: "99", color: "#888888", cursor: "pointer" }}
                        onClick={handleSubmit}
                      />

                      {/* <button
                      className={styles.addressBTN}
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Search
                    </button> */}
                    </div>
                    <div
                      style={{
                        marginTop: "-2%",
                        marginLeft: "1%",
                        position: "fixed",
                        zIndex: "999",
                        width: "46.5rem",

                      }}
                    >
                      {loading ? <div>Loading...</div> : null}
                      {suggestions.map((suggestion) => {
                        const style = {
                          backgroundColor: suggestion.active ? "#FAFAFA" : "#fff",
                          cursor: "pointer",
                          // marginBottom:"2%",
                          padding: "2%",
                          borderBottom: "1px solid #DFDFDF",
                        };
                        return (
                          <div
                            key={suggestion.placeId}
                            {...getSuggestionItemProps(suggestion, { style })}
                          >
                            {suggestion.description}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={markerPosition}
                zoom={15}
                options={options}
              >
                <MarkerF
                  position={markerPosition}
                  draggable={true}
                  onDragEnd={handleMarkerDrag}
                />
              </GoogleMap>
            </>
          </Modal.Body>
        </Modal>
      )}
    </React.Fragment>

    // <React.Fragment>

    //   <Modal
    //     open={createProjectOpen}
    //     footer={false}
    //     onCancel={() => setDiscardModalopen(true)}
    //     maskClosable={false}
    //     width={500}
    //   // bodyStyle={{padding:-20}}
    //   // bodyStyle={{padding:"0px!important"}}
    //   >
    //     <h3 style={{ backgroundClip: 'border-box', width: '100%' }}>Create project</h3>
    //     <Form
    //       name="basic"
    //       // labelCol={{
    //       //   span: 6,
    //       // }}
    //       // wrapperCol={{
    //       //   span: 16,
    //       // }}
    //       style={{
    //         maxWidth: 800,
    //         marginTop: "1.5rem",
    //       }}
    //       initialValues={{
    //         remember: false,
    //       }}
    //       form={form}
    //       onFinish={onformFinish}
    //       // onFinishFailed={onFinishFailed}
    //       autoComplete="off"
    //     >
    //       <Form.Item
    //         name="projName"

    //         rules={[
    //           {
    //             required: true,
    //             message: "Please Enter Project Name!",

    //           },
    //           {
    //             max: 50,
    //             message: "Value should be less than 50 characters",
    //           }
    //         ]}
    //       >
    //         <Input name="projName" className={styles.inputBox} placeholder="Enter Project's Name" onChange={(e) => handleProjectName(e)} />
    //       </Form.Item>
    //       <Form.Item
    //         name="cliName"
    //         initialValue={currentData.name}
    //         rules={[
    //           {
    //             required: true,
    //             message: "Please Enter Client Name!",
    //           },
    //           {
    //             max: 50,
    //             message: "Value should be less than 50 characters",
    //           }
    //         ]}
    //       >
    //         <Input name="cliName" className={styles.inputBox} placeholder="Enter Client's Name" />
    //       </Form.Item>
    //       <Form.Item
    //         name="location"
    //         initialValue={currentData.address}
    //         rules={[
    //           {
    //             required: true,

    //           },
    //           {
    //             max: 50,
    //             message: "Value should be less than 50 characters",
    //           }
    //         ]}
    //       >
    //         <Input
    //           className={styles.inputBox}
    //           placeholder="Enter Address"
    //           value={addressValue}
    //           onChange={inputHandlerAddress}
    //           name="location"
    //         />

    //       </Form.Item>
    //       <Form.Item
    //         name="city"
    //         rules={[
    //           {
    //             required: true,
    //             message: "Enter City!",
    //           },
    //           {
    //             max: 60,
    //             message: "Value should be less than 50 characters",
    //           }
    //         ]}
    //       //      
    //       >
    //         <Input className={styles.inputBox} placeholder="Enter City" />
    //       </Form.Item>
    //       <Form.Item
    //         name="landmark"
    //         rules={[
    //           {
    //             message: "Please Enter Landmark!",
    //           },
    //           {
    //             max: 50,
    //             message: "Value should be less than 50 characters",
    //           }
    //         ]}
    //       >
    //         <Input className={styles.inputBox} placeholder="Enter Landmark" />
    //       </Form.Item>
    //       <Form.Item
    //         name="phone"
    //         initialValue={currentData.phoneNumber}
    //         rules={[
    //           {
    //             pattern: new RegExp(
    //               /^(?=.*(\d)(?=\d)(?!\1))\d{10}$/
    //             ),
    //             message: "Please Enter valid Phone Number!",
    //           },

    //         ]}
    //       >
    //         <Input  onKeyDown={(e) =>
    //                       ['e'].includes(e.key) &&
    //                       e.preventDefault()
    //                     } className={styles.inputBox} id="phone" placeholder="Enter Phone Numbers" type="number" />
    //       </Form.Item>
    //       <Form.Item
    //         name="email"
    //         initialValue={currentData.email}
    //         rules={[
    //           {
    //             required: true,
    //             pattern: new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/g),
    //             message: "Please Enter valid Email Id!",
    //           },
    //         ]}
    //       >
    //         <Input className={styles.inputBox} placeholder="Enter Email Address" />
    //       </Form.Item>
    //       <Form.Item
    //         wrapperCol={{
    //           offset: 19,
    //           span: 18,
    //         }}
    //       >
    //         <div className={styles.createNewFooter}>
    //           <div className={styles.uploadProof}>
    //             <div style={{ display: "flex", cursor: "pointer" }}>
    //               <div className={styles.upload}>
    //                 <input
    //                   type="file"
    //                   accept=".doc,.docx,.png,.jpg,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    //                   onChange={(e) => handleFileUpload(e)}
    //                   multiple
    //                 />
    //                 <div style={{ cursor: "pointer" }}>
    //                   <FiUpload color="#0084EF" />
    //                 </div>
    //               </div>
    //               <div>
    //                 {uploadedItem ? (
    //                   <label
    //                     htmlFor=""
    //                     style={{
    //                       color: "#0084EF",
    //                       height: "19px",
    //                       paddingTop: "8px"
    //                     }}
    //                   >
    //                     <a
    //                       style={{
    //                         color: "#0084EF",
    //                         height: "25px",
    //                         width: "auto",
    //                         borderSpacing: "10px",
    //                         textDecoration: "none",
    //                       }}
    //                       target="_blank"
    //                       href={uploadFileLink}
    //                     >
    //                       {uploadedItem}
    //                     </a>
    //                   </label>
    //                 ) : (
    //                   <label
    //                     htmlFor=""
    //                     style={{
    //                       color: "#0084EF",
    //                       height: "19px",
    //                       paddingTop: "8px"
    //                     }}
    //                   >
    //                     Upload Agreement
    //                   </label>
    //                 )}
    //               </div>
    //             </div>
    //             {/* {uploadedItem && (
    //               <>
    //                 <p style={{ marginLeft: "8px" }}> File Name : {""}
    //                   <a
    //                     style={{
    //                       color: "#5255A4",
    //                       height: "25px",
    //                       width: "auto",
    //                       borderSpacing: "10px",
    //                       textDecoration: "none",
    //                     }}
    //                     target="_blank"
    //                     href={uploadFileLink}
    //                   >
    //                     {uploadedItem}
    //                   </a>
    //                 </p>
    //               </>
    //             )} */}
    //           </div>

    //           <Button
    //             type="primary"
    //             htmlType="submit"
    //             //  className={!details.cliName || !details.projName || !details.city || !details.landmark || !details.email || !addressValue || emailErr ? styles.nextBtn2 : styles.nextBtn}
    //             //  disabled={!details.cliName || !details.projName || !details.city || !details.landmark || !details.email || !addressValue || emailErr}
    //             style={{ background: "#0084EF", marginLeft: "4vw" }}
    //             size="large"
    //           >
    //             Next
    //           </Button>
    //         </div>
    //       </Form.Item>
    //     </Form>
    //   </Modal>


    //   <Modal
    //     title={false}
    //     open={discardModalopen}
    //     onOk={handleDiscardClose}
    //     onCancel={() => setDiscardModalopen(false)}
    //     centered
    //     width={300}
    //     bodyStyle={{ height: 60 }}
    //     closable={false}
    //   >
    //     <h6>
    //       Do you want discard the project ?
    //     </h6>
    //   </Modal>

    //   <Modal
    //     title={false}
    //     open={mapModalopen}
    //     footer={null}
    //     centered
    //     width={500}

    //     bodyStyle={{ height: 550 }}
    //     closable={false}

    //   >
    //     <>
    //       <PlacesAutocomplete value={address2} onChange={handleChange2} onSelect={handleSelect2}>
    //         {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
    //           <div>
    //             <div style={{ fontSize: "20px", fontWeight: "600", color: "black", fontFamily: "Inter", borderBottom: "1px solid #DFDFDF", marginLeft: "-5%", width: "110%", marginBottom: "6%", paddingLeft: "5%" }}> Set Location</div>
    //             <div style={{ display: "flex" }}>
    //               <input style={{ width: "80%", borderRadius: "4px", border: "1px solid #DFDFDF", paddingLeft: "12px", background: "#F0F0F0", height: "5vh", marginRight: "5%" }} {...getInputProps({ placeholder: 'Search your location' })} value={address2} />
    //               {/* <img src={scanIcon} alt="" style={{marginLeft:"-10%", color:"#888888"}}/> */}
    //               <button className={styles.addressBTN} type="submit" style={{ background: '#0084EF' }} onClick={handleSubmit}>Search</button>
    //             </div>
    //             <div style={{ marginTop: "0%", position: "fixed", zIndex: "999", width: "25vw" }}>
    //               {loading ? <div>Loading...</div> : null}
    //               {suggestions.map((suggestion) => {
    //                 const style = {
    //                   backgroundColor: suggestion.active ? '#FAFAFA' : '#fff',
    //                   cursor: "pointer",
    //                   // marginBottom:"2%",
    //                   padding: "2%",
    //                   borderBottom: "1px solid #DFDFDF"
    //                 };
    //                 return (
    //                   <div key={suggestion.placeId} {...getSuggestionItemProps(suggestion, { style })} >
    //                     {suggestion.description}
    //                   </div>
    //                 );

    //               })}
    //             </div>
    //           </div>
    //         )}

    //       </PlacesAutocomplete>
    //       <GoogleMap
    //         mapContainerStyle={mapContainerStyle}
    //         center={markerPosition}


    //         zoom={10}
    //         options={options}
    //       >
    //         <Marker position={markerPosition} draggable={true} onDragEnd={handleMarkerDrag}
    //         />
    //       </GoogleMap>
    //       <div style={{ position: "absolute", bottom: "2%", right: "5%", display: "flex" }}>
    //         <Button style={{ marginRight: "5%", width: "100px", height: "39px", fontSize: "16px", fontWeight: "500", color: "#0084EF" }} onClick={() => (dispatch(createProjectModal(true)), localStorage.setItem("prev", "yes"), setMapModalopen(false))}>
    //           Previous
    //         </Button>
    //         <Button style={{ background: "#0084EF", color: "white ", width: "100px", height: "39px", fontSize: "16px", fontWeight: "500" }} onClick={() => {

    //           localStorage.setItem("address", city);

    //           setMapModalopen(false)
    //           dispatch(createTeam(true))
    //           setCreateTeamModal(true)
    //         }}>
    //           Next
    //         </Button>
    //       </div>
    //     </>
    //   </Modal>
    //   {createTeamModal && <CreateNewProject2  />}setCreateTeamModal = {setCreateTeamModal} setMapModalopen={setMapModalopen} data={currentData}
    // </React.Fragment>
  );
};

export default CreateNewProjectNew;
