import React, { useEffect, useState } from "react";
import { PiQuestionLight } from "react-icons/pi";
import { PiNotePencilLight } from "react-icons/pi";
import { PiTrashLight } from "react-icons/pi";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import AddTagModal from "./modals/AddTagModal";
import axios from "axios";
import { constants } from "../../../piservices/constants";
import EditTagModal from "./modals/EditTagModal";
import ConfirmDelete from "../Modals/ConfirmDelete";
import toast from "react-hot-toast";
import { getReq } from "../../../piservices/apis";

const dummyTagsList = [
  { id: 1, tagName: "Follow up" },
  { id: 1, tagName: "Follow up" },
  { id: 1, tagName: "Follow up" },
  { id: 1, tagName: "Follow up" },
  { id: 1, tagName: "Follow up" },
];

const colorsList = [
  ["#9F6B53", "Brown"],
  ["#787774", "Grey"],
  ["#D9730D", "Orange"],
  ["#CB912F", "Yellow"],
  ["#448361", "Green"],
  ["#337EA9", "Blue"],
  ["#9065B0", "Purple"],
  ["#C14C8A", "Pink"],
  ["#D44C47", "Red"],
  ["#47BBD4", "Cyan Blue"],
];

const Tags = ({ showAddTagModal }) => {
  const [tags, setTags] = useState(dummyTagsList);
  const [colors, setColors] = useState(colorsList);
  const [editLeadBoolean, setEditLeadBoolean] = useState(false);
  const [showAddTag, setShowAddTag] = useState(false);
  const [showEditTag, setShowEditTag] = useState(false);
  const [usedColors, setUsedColors] = useState([]);
  const [unusedColors, setUnusedColors] = useState([]);
  const [tagData, setTagData] = useState({
    tagName: "",
    tagColorCode: "",
  });
  const [deleted, setDeleted] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);

  useEffect(() => {
    if (!showAddTag) {
      fetchTags();
    }
  }, [showAddTag]);

  useEffect(() => {
    if (!showEditTag) {
      fetchTags();
    }
  }, [showEditTag]);

  useEffect(() => {
    if (deleted) {
      fetchTags();
      setDeleted(false);
    }
  }, [deleted]);

  useEffect(() => {
    const unusedColors = [];
    for (let i of colors) {
      if (usedColors.indexOf(i[0]) === -1) {
        unusedColors.push(i);
      }
    }
    setUnusedColors(unusedColors);
  }, [usedColors]);

  const getUserbyId = async (id) => {
    const res = await getReq(
      `${constants.CRM_API}/user/getUserById?userId=${localStorage.getItem("userId")}`
    );
    if (res && !res.error) {
      console.log(res?.data?.data, "res!")
      setEditLeadBoolean(res?.data?.data?.rolesAssigned?.crm?.edit);
    } else {
      console.log(res.error);
    }
  };
  useEffect(() => {
   getUserbyId();
  }, [])

  useEffect(() => {
    if (showEditTag) {
      const index = colors.findIndex(
        (item) => item[0] === tagData.tagColorCode
      );
      setUnusedColors((prev) => [...prev, colors[index]]);
    }
  }, [showEditTag]);

  const closeDeleteModal = () => {
    setShowDelete(false);
  };

  const fetchTags = async () => {
    try {
      const response = await axios.get(
        `${constants.CRM_API}/user/get-lead-tag?userId=${localStorage.getItem(
          "userId"
        )}`
      );
      const data = response.data.data;
      setTags(data);
      // console.log(data, "data!")
      for (let i of data) {
        setUsedColors((prev) => [...prev, i.tagColorCode]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // delete a tag
  const deleteTagHandler = async (tag) => {
    try {
      await axios.put(
        `${constants.CRM_API}/user/edit-lead-tag?tagId=${tag?._id}`,
        { ...tag, isDeleted: true }
      );
      setDeleted(true);
    } catch (err) {
      console.log(err);
    }
  };

  // show and hide add tag modal
  const addTagModalShow = () => {
    setShowAddTag(true);
  };
  const addTagModalHide = () => {
    setShowAddTag(false);
  };

  // show and hide edit tag modal
  const editTagModalShow = () => {
    console.log("fghjk")
    setShowEditTag(true);
  };
  const editTagModalHide = () => {
    setShowEditTag(false);
  };

  // to edit a tag
  const editTagHandler = (index) => {
    console.log("xdfghjkjh")
    setTagData(tags[index]);
    editTagModalShow();
  };

  // console.log(tags, "tags!");

  return (
    <>
      <AddTagModal
        show={showAddTag}
        hide={addTagModalHide}
        colors={unusedColors}
      />
      <EditTagModal
        show={showEditTag}
        hide={editTagModalHide}
        colors={unusedColors}
        data={tagData}
      />
      <ConfirmDelete
        show={showDelete}
        hide={closeDeleteModal}
        name="Tag"
        action={deleteTagHandler.bind(null, deleteItem)}
      />
      <div
        style={{
          position: "fixed",
          left: "15%",
          width: "85%",
          paddingTop: "2rem",
          border: "1px solid #EEEEEF",
          height: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginLeft: "2rem",
            marginRight: "4rem",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "5px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h3
              style={{
                fontWeight: "600",
                fontSize: "24px",
                color: "#212529",
                marginTop: "auto",
                marginBottom: "auto",
              }}
            >
              Create your own custom tags
            </h3>
            <Tooltip
              id="manage-custom-fields-tooltip"
              style={{ width: "350px", fontWeight: "600", fontSize: "12px" }}
            />
            <PiQuestionLight
              style={{ height: "22px", width: "22px", color: "#212529" }}
              data-tooltip-id="manage-custom-fields-tooltip"
              data-tooltip-content="Customize lead tags effortlessly: Create, edit, and 
delete fields to tailor your data entry experience."
              data-tooltip-place="top"
            />
          </div>
          <button
            style={{
              border: "1px solid #0084EF",
              borderRadius: "8px",
              padding: "8px 24px 8px 24px",
              backgroundColor: "#FFFFFF",
              fontWeight: "500",
              fontSize: "14px",
              color: "#0084EF",
            }}
            onClick={() => {
              if (editLeadBoolean) {
                addTagModalShow();
              } else {
                toast.error("Access disabled by Admin");
              }
            }}
            >
            Add Tag
          </button>
        </div>
        <div
          style={{
            marginTop: "2.5rem",
            marginLeft: "2rem",
            marginRight: "2rem",
            overflowY: "auto",
            maxHeight: "63%",
            position: "relative",
          }}
        >
          <table style={{ width: "100%" }}>
            <thead
              style={{ color: "#64748B", fontWeight: "600", fontSize: "12px" }}
            >
              <tr>
                <th
                  style={{ borderBottom: "1px solid #E7EAEE", padding: "8px" }}
                >
                  S no.
                </th>
                <th
                  style={{ borderBottom: "1px solid #E7EAEE", padding: "8px" }}
                >
                  Tag Name
                </th>
                <th
                  style={{
                    borderBottom: "1px solid #E7EAEE",
                    padding: "8px 0px 8px 8px",
                    textAlign: "center",
                  }}
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody
              style={{ color: "#191D23", fontWeight: "400", fontSize: "12px" }}
            >
              {tags &&
                tags.map((item, index) => (
                  <tr>
                    <td
                      style={{
                        borderBottom: "1px solid #E7EAEE",
                        padding: "8px",
                      }}
                    >
                      {index + 1}.
                    </td>
                    <td
                      style={{
                        borderBottom: "1px solid #E7EAEE",
                        padding: "8px",
                      }}
                    >
                      {item.tagName}
                    </td>
                    <td
                      style={{
                        borderBottom: "1px solid #E7EAEE",
                        padding: "8px 0px 8px 8px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          justifyContent: "center",
                        }}
                      >
                        <button
                          style={{ backgroundColor: "white", border: "none" }}
                          onClick={() => {
                            if (editLeadBoolean) {
                              editTagHandler(index); // Call the function directly with the index
                            } else {
                              toast.error("Access disabled by Admin");
                            }
                          }}>
                          <PiNotePencilLight
                            style={{ height: "20px", width: "20px" }}
                          />
                        </button>
                        <Tooltip
                          id="deleteButton"
                          events={["click"]}
                          style={{
                            width: "186px",
                            fontWeight: "600",
                            fontSize: "12px",
                          }}
                        />
                        <button
                          style={{
                            backgroundColor: "white",
                            border: "none",
                          }}onClick={() => {
                            if (editLeadBoolean) {
                              setShowDelete(true);
                              setDeleteItem(item);
                            } else {
                              toast.error("Access disabled by Admin");
                            }
                          }}
                          
                          data-tooltip-id={item.permanent && "deleteButton"}
                          data-tooltip-content="This field can’t be deleted"
                          data-tooltip-place="top"
                        >
                          <PiTrashLight
                            style={{
                              height: "20px",
                              width: "20px",
                            }}
                          />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Tags;
