import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import { CgClose } from "react-icons/cg";
import styles from "./modals.module.css";
import axios from "axios";
import { constants } from "../../../piservices/constants";
import { Button } from "antd";

const AssignLead = ({ show, hide, leadsToChange }) => {
  const [assignTo, setAssignTo] = useState(null);
  const [members, setMembers] = useState([]);

  // console.log(leadsToChange, "leads!");

  useEffect(() => {
    async function fetchMembers() {
      try {
        const response = await axios.get(
          `${
            constants.CRM_API
          }/api/enterprise/get-team-leaders?userId=${localStorage.getItem(
            "userId"
          )}&access=1`
        );
        const data = response?.data?.data;
        const sortedMembers = data
        ?.map((item) => ({ value: item._id, label: item.fullName }))
        .sort((a, b) => a.label.localeCompare(b.label)); // Sort alphabetically

      setMembers(sortedMembers);
      } catch (err) {
        console.log(err);
      }
      // console.log(response, "res!");
    }
    if (show) {
      fetchMembers();
    }
  }, [show]);

  const changeAssignedHandler = async () => {
    for (let i of leadsToChange) {
      try {
        const response = await axios.get(
          `${constants.CRM_API}/api/get-lead-by-id?id=${i}`
        );
        const data = response.data.data;
        console.log(data, "data!");
        const obj = {
          assignedTo: assignTo.value,
          logText: `The Lead has been reassigned from ${data.name} to ${assignTo.label}`,
        };
        // console.log(obj, "obj!")
        await axios.post(
          `${
            constants.CRM_API
          }/user/edit-lead?id=${i}&userId=${localStorage.getItem("userId")}`,
          obj
        );
      } catch (err) {
        console.log(err);
      }
    }
    hide();
    window.location.reload();
  };

  // console.log(assignTo);
  console.log(leadsToChange);

  return (
    <Modal show={show} onHide={hide}>
      <Modal.Header style={{ borderBottom: "none" }}>
        <div>Assign lead to</div>
        <div onClick={hide}>
          <CgClose />
        </div>
      </Modal.Header>
      <Modal.Body className={styles.assignLeadBody}>
        <div style={{ width: "100%" }}>
          <Select
            placeholder="Assign to"
            options={members}
            onChange={setAssignTo}
          />
        </div>
        <div className={styles.assignLeadApply}>
          <Button type="primary" onClick={changeAssignedHandler}>
            Apply
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AssignLead;
