import React, { useState, useContext, useEffect } from "react";
import ProjectHeader from "../../Common/NewLayout/ProjectHeader";
import TopHeaderCommon from "../../Common/NewLayout/TopHeaderCommon";
import SideProjectPanel from "../../Common/NewLayout/SideProjectPanel";
import desquoContext from "../../../picontext/DashBoard/desquoContext";
import { formateDate, valueToLeadStatus, valueToLeadStatusFormatted, countryCurrencies } from "../../../piservices/compCommon";
import {
  Dropdown,
  Space,
  Form,
  Input,
  Button,
  Select,
  Tooltip,
  notification,
} from "antd";
import LockBlack from '../../../Assets/LockBlack.svg'
import { useLocation, useParams } from "react-router-dom";
import { postReq, getReq } from "../../../piservices/apis";
import  { constants } from "../../../piservices/constants";
import styles from "./LeadManagement.module.css";
import { FaEllipsisV } from "react-icons/fa";
import { PiPhoneCallLight } from "react-icons/pi";
import { CiMail, CiLocationOn } from "react-icons/ci";
import { useSelector, useDispatch } from "react-redux";
import { BsCheck2 } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import UnAssignedSidebardebar from './UnAssignedSidebar';
import {
  createProjectModal,
  currentLead,
  selectNextSlide,
  slideIn,
  slideOut,
  setEditLead,
  setUserData,
  setProfileData,
} from "../../../Redux/Slices/lmsSlice";
import Interaction from "./Interaction";
import LeadInfoSidePanel from "./LeadInfoSidePanel";
import {
  updateClosureMonth,
  selectedLeadData,
  updateCallStatusUpdatePanel,
} from "../../../Redux/Slices/specificleadSlice";
import Logs from "./Logs";
import AddLeadModal from "../AddLeadModal";
import EditModal from "../../Common/NewLayout/Modals/EditModal";
import { BiChevronDown } from "react-icons/bi";
import SendMail from "../LeadUpdate/SendMail";
import CreateNewProjectNew from "../CreateNewProject/CreateNewProjectNew";
import LmsMail from "../LmsMail.js/LmsMail";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { projectDataCurrObj, setIsChecked } from "../../../Redux/Slices/convertToProjectSlice";
import EditLeadModal from "../EditLeadModal";
import { getToken } from "../../../piservices/authService";
import { ThreeDots } from 'react-loader-spinner'
import useApiFetchCustomHookForEmailService from "../../../piservices/ApiFetchCustomHookForEmailService";
// import { Dropdown, Space, Modal, Form, Input, Button, Select,notification } from 'antd';

function LeadsDetail() {
  const [shouldAnimatee, setShouldAnimatee] = useState(false);
  const [isdeleteModal, setIsDeleteModal] = useState(false);
  const [openedTab, setOpenedTab] = useState("logs");
  // const [teamLeadArr, setTeamLeadArr] = useState([]);
  // const [leadSources, setLeadSources] = useState([]);
  // const [sourceNames, setSourceNames] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [actualLeadStatus, setActualLeadStatus] = useState(1);
  const [createProjectModalOpen, setCreateProjectModalOpen] = useState(false);
  const [updatePanel, setUpdatePanel] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedLeadSourceId, setSelectedLeadSourceId] = useState();
  const [filteredSourceNames, setFilteredSourceNames] = useState([]);
  const [detailRenderer, setDetailRenderer] = useState(false);
  // const [userData, setUserData] = useState({});
  // const [workType, setWorkType] = useState([])
  const leadSources = useSelector((state) => state.lmsSlice.leadSources);
  const callStatusUpdatePanel = useSelector((state) => state.selectedLeadSlice.callStatusUpdatePanel);
  const sourceNames = useSelector((state) => state.lmsSlice.sourceNames);
  const teamLeadArr = useSelector((state) => state.lmsSlice.teamLeadArr);
  const workType = useSelector((state) => state.lmsSlice.workType);
  const userData = useSelector((state) => state.lmsSlice.userData);
  const { sidebarToggle } = useSelector((state) => state.lmsSlice);
  const profileData = useSelector((state) => state.lmsSlice.profileData);
  const [loadinguserDataApi, setLoadinguserDataApi] = useState(true)
  const [loadingApiFetch, setLoadingFetchApi] = useState(true)
  //  interection data  state 
  const [interactions, setInteractions] = useState([])
  const { sendEmailPushBellNotifications } = useApiFetchCustomHookForEmailService()
  const dispatch = useDispatch();
  const [headerSelectedTab, setSelectedHeaderTab] =
    useState("Leads Management");
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const context = useContext(desquoContext);
  const { id } = useParams();
  let { state } = useLocation();
  const tab = state?.tab;
  const {
    activeLead,
    setActiveLeadState,
    mailStatus,
    setMailStatus,
    dateTime,
    leads,
    getActive,
    setActiveLead,
    leadStatusItem,
  } = context;
  const selectedLeadsData = useSelector(
    (state) => state.selectedLeadSlice.selectedLeadData
  );
  const showEmailModal = useSelector((state) => state.lmsSlice.showEmailModal);


  useEffect(() => {
    setData(selectedLeadsData);
    setDetailRenderer(!detailRenderer);
  }, [selectedLeadsData?.leadUpdates?.length]);
  const [data, setData] = useState(selectedLeadsData);
  console.log(data)

  const showDeleteModal = () => {
    setIsDeleteModal(true);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    deleteLead();
  };

  const handleDeletModal = () => {
    setIsDeleteModal(false);
  };
  const handleLeadSourceChange = (value) => {
    setSelectedLeadSourceId(value);
    const filtered = sourceNames.filter((item) => {
      return item.id === value;
    });
    setFilteredSourceNames(filtered);
    const selectedOptionObject = leadSources.find(
      (option) => option.id === value
    );
    setSelectedOption(
      selectedOptionObject ? selectedOptionObject.leadSource : null
    );
  };
const [addedByName, setAddedByName]= useState('')
  const getUserNamebyId = async (id, loggedInuser) => {

    const res = await getReq(`${constants.CRM_API}/user/getUserById?userId=${id}`);
    console.log(res?.data, id,"userdatawhoaddlead")
    if (res && !res.error) {
      // dispatch(setUserData(res?.data?.data));
      if(loggedInuser){
        dispatch(setUserData(res?.data?.data));
      }
      console.log( "assigned name",res?.data)
      setAddedByName(res?.data?.data?.fullName)
    } else {
      console.log(res.error);
    }
  };



  const deleteLead = async (formData) => {
    const res = await postReq(
      `${constants.CRM_API}/user/edit-lead?id=${activeLead?._id}&userId=${localStorage.getItem("userId")}`,
      {
        isDeleted: true,
        deletedBy: localStorage.getItem("userId"),
      }
    );
    if (res && !res.error) {
      notification.success({
        message: res?.message || "Delete Lead Successfully",
        duration: 1,
      });
      // form.resetFields();
      navigate("/leads");
      handleDeletModal();
      // after delete we again fetch data
      // apifetch();
    } else {
      console.log(res.error);
      notification.error({
        message: res?.message || "Oops Something Went Wrong",
        duration: 1,
      });
    }
  };

  const items = [
    {
      label: (
        <div className={styles.lockBox}><a rel="noopener noreferrer" onClick={() => showModal()}>
          Edit Lead
        </a>
          {!userData?.rolesAssigned?.crm?.edit && <Tooltip title="Access Disabled by the Admin">
            <img src={LockBlack} alt="LockImg"></img>
          </Tooltip>}
        </div>
      ),
      key: "0",
      disabled: !userData?.rolesAssigned?.crm?.edit,
    },
    {
      label: (<div className={styles.lockBox}>
        <a
          rel="noopener noreferrer"
          onClick={() => {
            dispatch(setIsChecked(true));
            setCreateProjectModalOpen(true);
            projectDataObj.firstName = data.firstName ? data.firstName : data.name
            projectDataObj.lastName = data.lastName
            projectDataObj.address = data.address
            projectDataObj.phnNum = data.phoneNumber
            projectDataObj.email = data.email
            projectDataObj.code = data.pinCode
            projectDataObj.city = data.city
            dispatch(projectDataCurrObj(projectDataObj))
          }
          }
        >
          Convert to Project
        </a>
        {!userData?.rolesAssigned?.pmt?.canCreateProject && <Tooltip title="Access Disabled by the Admin">
          <img src={LockBlack} alt="LockImg"></img>
        </Tooltip>}
      </div>
      ),
      key: "4",
      disabled: !userData?.rolesAssigned?.pmt?.canCreateProject,
    },
    {
      label: (
        <div className={styles.lockBox}>
          <a rel="noopener noreferrer" onClick={() => showDeleteModal()}>
            Delete Lead
          </a>
          {!userData?.rolesAssigned?.crm?.edit && <Tooltip title="Access Disabled by the Admin">
            <img src={LockBlack} alt="LockImg"></img>
          </Tooltip>}</div>
      ),
      key: "1",
      disabled: !userData?.rolesAssigned?.crm?.edit,
    },
  ];

  const {
    address,
    budget,
    config,
    email,
    assignedTo,
    leadUpdates,
    leadStatus,
    leadstatus,
    showAlert,
    sourceName,
    setMyLeadStatus,
    phoneNumber,
    setLeads,
    leadSource,
    name,
    renovationTimeline,
    requirements,
    updatedAt,
    clouserMonth,
    userId,
    createdAt,
  } = data;


  //  loader state ;

  const [loading, setLoading] = useState(false);

//  getting interection data 
 
  console.log(interactions)
  console.log(data)

  let projectDataObj = {
    firstName: "",
    lastName: "",
    projectName: "",
    address: "",
    city: "",
    code: "",
    phnNum: "",
    email: "",
    document: "",
    documentLink: "",
  };


  useEffect(() => {
    getUserbyId()
    setData(selectedLeadsData)
    setDetailRenderer(!detailRenderer);
  }, [selectedLeadsData?.leadUpdates?.length])

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleTrue = () => {
    setShouldAnimatee(true);
  };

  const updateLeadStatus = async (e) => {
    try {
      const res = await postReq(constants?.CRM_API + "/user/newleadActions", {
        leadId: activeLead?._id,
        leadStatus: e,
        logText:[ `The Lead status has been updated to ${valueToLeadStatusFormatted[e]}`]
      });

      if (res && !res?.error) {
        let y = {
          ...selectedLeadsData,
          leadStatus: res?.data?.data?.leadStatus,
        };
        if (e == 3) {
          sendEmailPushBellNotifications("Follow Up -> Interested", {
            "@leadName\'s": activeLead?.name,
  
          });
        }
        else if (e == 2) {
          sendEmailPushBellNotifications("Follow Up -> Follow Up", {
            "@leadName's": activeLead?.name,
  
          });
        }
        else if (e == 5) {
          sendEmailPushBellNotifications("Follow Up -> Not Interested", {
            "@leadName's": activeLead?.name,
  
          });
        }
        setData(y);
        dispatch(selectedLeadData(y));
        showAlert("You have successfully updated your lead status ", "Okay");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const monthChange = async (val) => {
    const res = await postReq(
      `${constants.CRM_API}/user/edit-lead?id=${selectedLeadsData?._id
      }&userId=${localStorage.getItem("userId")}`,
      {
        // leadId: activeLead?._id,
        clouserMonth: val,
        logText:[`The Lead Expected Closure has been updated to ${val}`]
      }
    );
    if (res && !res.error) {
      // dispatch(selectedLeadData({...selectedLeadsData, clouserMonth:val}))
      setData({ ...data, clouserMonth: val });
      dispatch(updateClosureMonth({ ...selectedLeadsData, clouserMonth: val }));
      notification.success({
        message: res?.message || "Closure Updated Successfully",
        duration: 1,
      });
      // apifetch();
    } else {
      console.log(res.error);
      notification.error({
        message: res?.message || "Oops Something Went Wrong",
        duration: 1,
      });
    }
  };
  const apifetch = async () => {
    let res;
    if (tab == "9") {
      res = await getReq(constants.CRM_API + "/user/newLeadsData?pageNo=0&type=9");
    } else {
      res = await getReq(constants.CRM_API + "/user/newLeadsData?pageNo=0");
    }
    if (res && !res.error) {
      setLoadingFetchApi(false)
      const leadData = res?.data?.data?.data.find((d) => d._id === id);
      leadData?.projectId &&
        localStorage.setItem("projectId", leadData?.projectId);
      dispatch(selectedLeadData(leadData));
      setData(leadData);
      setActiveLeadState(leadData);
      // window.location.reload()
      // setLeads(res?.data?.data);
    }
  };
  const getProfileData = async () => {
   
    const res = await getReq(
      `${constants.CRM_API}/user/profile`,
      {},
      { Authorization: `Bearer ${getToken()}` }
    );
    if (res && !res.error) {
      dispatch(setProfileData(res.data.data))
      setLoadinguserDataApi(false)
    } else {
      // console.log(res.error);
      // localStorage.clear();
      // window.location.assign(`${constants. BASE_URL}`);
    }
  };

  console.log(data,"userId")
  console.log(data?.userId,localStorage.getItem("userId"),"userID")

  useEffect(() => {
    
    // getUserNamebyId(localStorage.getItem('userId'), true);
    getUserNamebyId(data?.userId, false);
    getProfileData()
    // localStorage.removeItem("projectId");
    // localStorage.removeItem('visited')
    // localStorage.setItem("selectedLeadId", data?._id);
    apifetch();


    if (data.projectId) {
      localStorage.setItem("projectId", data.projectId);
      localStorage.setItem("selectedLeadId", data?._id);
    }
  }, [data?.userId]);


  const normalizeValue = (value) => {
    // Just replace the following regex to what you wnat
    const filteredValue = value.replace(/[a-zA-Z]/g, '');
    return filteredValue;
  };
  const normalizeBudgetValue = (value) => {
    // Just replace the following regex to what you wnat
    const filteredValue = value.replace(/[a-zA-Z\!\@\#\$\%\^\&\*\(\_\+\=\)\-]/g, '');
    return filteredValue;
  };
  // useEffect(() => {
  //   getTeamLeads();
  //   getLeadSource();
  //   getSourceName();
  //   getWorkTypes();
  //   getUserbyId(localStorage.getItem("userId"));
  // }, []);

  const getUserbyId = async (id) => {
    console.log(id,"userdatawhoaddlead")
    const res = await getReq(`${constants.CRM_API}/user/getUserById?userId=${id}`);
    if (res && !res.error) {
      setUserData(res?.data?.data);
    } else {
      console.log(res.error);
    }
  };

  return (
    <>
      <EditLeadModal show={isModalOpen} onHide={handleCancel} />
      <Modal
        title={false}
        show={isdeleteModal}
        centered
        width={300}
        bodyStyle={{ height: 60 }}
        closable={false}
      >
        <h6 style={{ padding: "3%" }}>Do you want to delete the lead ?</h6>
        <Modal.Footer>
          <div className={styles.discardCloseBtn}>
            <button className={styles.createModalSave} onClick={handleOk}>
              <span
                style={{
                  color: "#FFF",
                  fontWeight: "600",
                  fontSize: "1rem",
                }}
              >
                Yes{" "}
              </span>
            </button>
            <button
              className={styles.createModalClose}
              onClick={handleDeletModal}
            >
              <span
                style={{
                  color: "#C9C9C9",
                  fontWeight: "600",
                  fontSize: "1rem",
                }}
              >
                No{" "}
              </span>
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <div className={`${styles.main_cont}`}>
        <TopHeaderCommon />
        <ProjectHeader
          handleTrue={handleTrue}
          setUpdatePanel={setUpdatePanel}
          leadsDetail={true}
          selectedLeadData={selectedLeadData}
        />
      </div>
      <div
        className={`${styles.firstFrameContainer} d-flex`}
        style={{ marginTop: "6em" }}
      >
        {!activeLead?.isProjectCreated && <div
          style={{
            position: "fixed",
            top: "6rem",
            left: "0px",
            // zIndex:'-1'
            width: shouldAnimatee ? "0%" : "4rem"

          }}
        >
          <SideProjectPanel showSide="true" shouldAnimatee={shouldAnimatee} leadDetail={true} />
        </div>}

        {/* Main container page */}
        {(loadinguserDataApi && loadingApiFetch) ?
          <div
            className=""
            style={{
              position: 'relative',
              marginTop: '25vh',
              marginLeft: '45vw',
              // transform: 'translate(-50%, -50%)'
            }}
          >

            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#0084EF"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div> :
          <div style={{ left: !sidebarToggle ? '0rem' : activeLead?.isProjectCreated ?"1rem":"3rem", position: "relative" }}>
            <div className={`${styles.firstContainer}`}>
              <div className={`${styles.topBar}`}>
                <div
                  style={{
                    color: "#454545",
                    fontFamily: "Inter",
                    fontSize: "24px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "normal",
                  }}
                >
                  {name?.charAt(0).toUpperCase() + name?.slice(1)}
                </div>
                <div>
                  {(tab == "9")?<div
                style={{
                  width: "4rem",
                }}
                className=" new-bg leadStatusDropdownbutton bg-interested box-shadow-none py-1 mt-1 px-1 pfs-11"
              >
                {"Won"}
              </div>:(!leadStatusItem[valueToLeadStatus[leadStatus]]?.label ? (
                    <button
                      type="button"
                      className="btn leadStatusDropdownbutton new-bg bg-base-blue box-shadow-none py-1 px-1"
                      style={{
                        minWidth: "60px",
                      }}
                    >
                      {"New Lead"}
                    </button>
                  ) : (
                    <button
                      type="button"
                      id="dropdownMenuButton"
                      data-mdb-toggle="dropdown"
                      className={`btn leadStatusDropdownbutton btn-primary dropdown-toggle ${leadStatus === 6
                        ? "bg-no-response"
                        : leadStatus === 2
                          ? "bg-follow-up"
                          : leadStatus === 1
                            ? "bg-base-blue"
                            : leadStatus === 3
                              ? "bg-interested"
                              : leadStatus === 7
                                ? "bg-meeting-scheduled"
                                : leadStatus === 5
                                  ? "bg-not-interested"
                                  : "bg-base-blue"
                        } box-shadow-none py-1 px-1`}
                    >
                      {leadStatusItem[valueToLeadStatus[leadStatus]]?.label ||
                        "New Lead"}
                      {/* <BiChevronDown className="mx-0" />{!userData?.rolesAssigned?.crm?.edit && <Tooltip title="Access Disabled by the Admin">
                        <img src={LockBlack} alt="LockImg"></img>
                      </Tooltip>} */}
                    </button>
                  ))}
                  {userData?.rolesAssigned?.crm?.edit && <ul className={`dropdown-menu ${styles.leadStatushovercontaineer}`}>
                    {leadStatus !== 2 && (
                      <li>
                        <a
                          className={`dropdown-item ${styles.leadStatushover}`}
                          style={{ color: "#000000" }}
                          // href="#"
                          onClick={() => {
                            // setActualLeadStatus(leadstatus[e]?.value);
                            // setMyLeadStatus(e);
                            updateLeadStatus(2);
                          }}
                        >
                          Inactive/Follow Up
                        </a>
                      </li>
                    )}
                    {leadStatus !== 3 && (
                      <li>
                        <a
                          className={`dropdown-item ${styles.leadStatushover}`}
                          style={{ color: "#000000" }}
                          onClick={() => updateLeadStatus(3)}
                        >
                          Interested
                        </a>
                      </li>
                    )}
                    {leadStatus !== 5 && (
                      <li>
                        <a
                          className={`dropdown-item ${styles.leadStatushover}`}
                          style={{ color: "#000000" }}
                          onClick={() => updateLeadStatus(5)}
                        >
                          Not Interested
                        </a>
                      </li>
                    )}
                    {leadStatus !== 6 && (
                      <li>
                        <a
                          className={`dropdown-item ${styles.leadStatushover}`}
                          style={{ color: "#000000" }}
                          onClick={() => updateLeadStatus(6)}
                        >
                          No Response
                        </a>
                      </li>
                    )}
                  </ul>}
                </div>
                {/* {!activeLead?.isProjectCreated && <div>
                  <span className={styles.columnText} >:</span>
                  <Select
                    defaultValue={"Select Month"}
                    value={clouserMonth}
                    disabled={!userData?.rolesAssigned?.crm?.edit}
                    bordered={false}
                    style={{
                      width: "max-content",
                      fontSize: "12px",
                    }}
                    className="antd-selectt"
                    onClick={(e) => e.stopPropagation()}
                    onChange={(value) => {
                      monthChange(value);
                    }}
                    options={[
                      { value: "Jan", label: "Jan" },
                      { value: "Feb", label: "Feb" },
                      { value: "Mar", label: "March" },
                      { value: "April", label: "April" },
                      { value: "May", label: "May" },
                      { value: "June", label: "June" },
                      { value: "July", label: "July" },
                      { value: "Aug", label: "Aug" },
                      { value: "Sept", label: "Sept" },
                      { value: "Oct", label: "Oct" },
                      { value: "Nov", label: "Nov" },
                      { value: "Dec", label: "Dec" },
                    ]}
                  />
                </div>} */}
                {/* {!(tab == "9") &&<Dropdown
                  trigger={["click"]}
                  menu={{
                    items,
                  }}
                  onClick={() => dispatch(currentLead(activeLead))}
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                      <FaEllipsisV style={{ cursor: "pointer" }} color="#343330" />
                    </Space>
                  </a>
                </Dropdown>} */}
                <div>
                  {/* <Dropdown
                  trigger={["click"]}
                  menu={{
                    items,
                  }}
                // onClick={()=>dispatch(currentLead(activeLead))}
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                      <FaEllipsisV />
                    </Space>
                  </a>
                </Dropdown> */}
                </div>
              </div>
              <div className={styles.addedByContainer}>
                Added By: {userId ? addedByName : "-"}
              </div>
              <div className={styles.midContainer}>
                <div style={{ padding: "0 1px" }}>
                  <div>
                    <PiPhoneCallLight />
                    <span>&nbsp;{phoneNumber}</span>
                  </div>
                  <div>
                    <CiMail />
                    <span>&nbsp;{email}</span>
                  </div>
                  <div>
                    <CiLocationOn />
                    <span>&nbsp;{address}</span>
                  </div>
                </div>
                <div style={{ padding: "0 1px" }}>
                  <span className={styles.columnText}>Budget:</span>{` ${profileData?.CompanySettingDetails?.currencySymbol&&profileData?.CompanySettingDetails?.currencySymbol?profileData?.CompanySettingDetails?.currencySymbol:'₹'}${budget ? Number(budget).toLocaleString(countryCurrencies[profileData?.CompanySettingDetails?.currencySymbol?profileData?.CompanySettingDetails?.currencySymbol:'₹']) : '-'}`}
                  <div><span className={styles.columnText}>Work Type:</span> {requirements ? requirements : "-"}</div>
                  <div><span className={styles.columnText}>Source Name:</span> {sourceName ? sourceName : "-"}</div>
                </div>
                <div style={{ padding: "0 1px" }}>
                  <div><span className={styles.columnText}>Assigned To:</span>{` ${assignedTo?.fullName ? assignedTo?.fullName : "-"
                    }`}</div>
                  <div><span className={styles.columnText}>Last Call:</span>{` ${leadUpdates &&
                    leadUpdates[leadUpdates?.length - 1]?.updateDate
                    ? leadUpdates[leadUpdates?.length - 1]?.updateDate
                    : "-"
                    }`}</div>
                  <div className="text-capitalize">
                    <span className={styles.columnText}>Call Outcome:</span>{" "}
                    {leadUpdates &&
                      valueToLeadStatusFormatted[
                      Number(leadUpdates[leadUpdates?.length - 1]?.callLeadStatus)
                      ]
                      ? valueToLeadStatusFormatted[
                      Number(
                        leadUpdates[leadUpdates?.length - 1]?.callLeadStatus
                      )
                      ]
                      : "-"}
                  </div>
                </div>
              </div>
            </div>
            <div className={`${styles.secondContainer}`}>
              <div className={`${styles.tabs__section}`}>
                <div className="d-flex">
                  {/* <div
                    className={`${openedTab === "interaction"
                      ? styles.tab__selected
                      : styles.tab__not__selcted
                      }`}
                    onClick={() => {
                      setOpenedTab("interaction");
                    }}
                  >

                    Interaction
                  </div> */}
                  <div
                    className={`${openedTab === "logs"
                      ? styles.tab__selected
                      : styles.tab__not__selcted
                      } ${styles.marginLeft}`}
                    style={{ marginLeft: "1rem" }}
                   
                  >
                    Logs
                  </div>
                </div>
              </div>
              <Logs data={data}/>
            </div>
            <AddLeadModal />
            <Modal
              // title="Update Lead"
              // width="max-content"
              // closable={true}
              show={callStatusUpdatePanel}
              // style={{ top: "33px" }}
              dialogClassName={styles.modalWidth}
              // footer={[]}
              onHide={() =>  dispatch(updateCallStatusUpdatePanel(false))}
            // size="lg"
            >
              {/* <Modal.Header closeButton style={{ borderBottom: "none" }}>
              <Modal.Title>
                <span style={{ fontSize: "20px" }}>Update Project</span>
              </Modal.Title>
            </Modal.Header> */}
              <Modal.Body>
                {data?.assignedTo ? <LeadInfoSidePanel /> : <UnAssignedSidebardebar />}
              </Modal.Body>
            </Modal>
           {showEmailModal && <LmsMail />}
          </div>
        }
      </div>
      {createProjectModalOpen && (
        <CreateNewProjectNew currentData={activeLead} />
      )}
    </>
  );
}

export default LeadsDetail;
