import React, { useEffect, useState } from "react";
import { IoEllipse } from "react-icons/io5";
import { PiCaretDownLight, PiInfo } from "react-icons/pi";
import { GoArrowUp } from "react-icons/go";
import { GoArrowDown } from "react-icons/go";
import { IoMdClose } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { constants } from "../../../piservices/constants";
import "./leadManagementSettings.css";
import toast from "react-hot-toast";

const EditField = ({
  setSaveEditField,
  saveEditField,
  saveEditAndNewField,
  setIsChanged,
}) => {
  const navigate = useNavigate();

  const [fieldType, setFieldType] = useState("Select");
  const [openfieldType, setOpenFieldType] = useState(false);
  const [dropDownOptions, setDropDownOptions] = useState([""]);
  const { id } = useParams();
  // const fields = useSelector(state => state.leadManagementSettings.fields);
  const [field, setField] = useState(null);
  const [initialField, setInitialField] = useState(null);

  const fieldTypes = [
    "Text",
    "Number",
    "Email",
    "Website",
    "Date",
    "Drop-down",
  ];

  useEffect(() => {
    console.log(field, initialField, "fff1");
    if (
      field?.fieldName !== initialField?.fieldName ||
      fieldType !== initialField?.fieldType ||
      field?.isRequired !== initialField?.isRequired ||
      JSON.stringify(field?.section) !== JSON.stringify(initialField?.section)
    ) {
      setIsChanged(true);
    } else {
      setIsChanged(false);
    }
  }, [field, initialField, fieldType]);

  // get field data
  useEffect(() => {
    async function fetchField() {
      const response = await axios.get(
        `${constants.CRM_API}/api/get-specific-custom-field?id=${id}`
      );
      const data = response.data.data;
      // console.log(data, "data!");
      setField(JSON.parse(JSON.stringify(data)));
      setInitialField(JSON.parse(JSON.stringify(data)));
      setDropDownOptions(data.options);
    }
    fetchField();
  }, [id]);

  // set field type
  useEffect(() => {
    setFieldType(
      field?.fieldType === "Dropdown" ? "Drop-down" : field?.fieldType
    );
  }, [field]);

  // save new field
  useEffect(() => {
    if (saveEditField) {
      const uploadNewField = async () => {
        const fieldName = field.fieldName;
        const section = field.section;
        const isRequired = field.isRequired;
        let options = dropDownOptions;
        // console.log("inside");
        options = options.filter((item) => item !== "");
        if (
          fieldName.length === 0 ||
          section.length === 0 ||
          fieldType === "Select" ||
          (fieldType === "Drop-down" && options.length === 0)
        ) {
          setSaveEditField(false);
        } else {
          try {
            const obj = {
              fieldName,
              section,
              fieldType,
              isRequired,
              options,
            };
            if (obj.fieldType === "Drop-down") {
              obj.fieldType = "Dropdown";
            }
            await axios.post(
              `${constants.CRM_API}/api/edit-custom-field?id=${id}`,
              obj
            );
            if (saveEditAndNewField) {
              navigate("/leads/lead-management-settings/create-new-field");
            } else {
              navigate("/leads/lead-management-settings/custom-field");
            }
            setSaveEditField(false);
            toast.success("Custom field edited successfully!");
          } catch (err) {
            console.log(err);
          }
        }
      };
      uploadNewField();
    }
  }, [saveEditField]);

  // toggle field drop down
  const togglefieldType = () => {
    setOpenFieldType((prev) => !prev);
  };

  // add drop down option
  const addDropDownOption = () => {
    setDropDownOptions((prev) => [...prev, ""]);
  };

  // change drop down option value
  const changeDropDownOptionValue = (e, index) => {
    const value = e.target.value;
    const options = [...dropDownOptions];
    options[index] = value;
    setDropDownOptions(options);
  };

  // move drop down value up
  const moveUpDropDownOption = (index) => {
    const options = [...dropDownOptions];
    const option = options[index];
    if (index > 0) {
      options[index] = options[index - 1];
      options[index - 1] = option;
    }
    setDropDownOptions(options);
  };

  // move drop down value down
  const moveDownDropDownOption = (index) => {
    const options = [...dropDownOptions];
    const option = options[index];
    if (index < options.length - 1) {
      options[index] = options[index + 1];
      options[index + 1] = option;
    }
    console.log(options, "options!!!");
    setDropDownOptions(options);
  };

  // delete drop down value
  const deleteDropDownOption = (index) => {
    const options = [...dropDownOptions];
    options.splice(index, 1);
    setDropDownOptions(options);
  };

  // change field type
  const changeFieldType = (index) => {
    setFieldType(fieldTypes[index]);
    setOpenFieldType(false);
  };

  // change field name
  const fieldNameHandler = (e) => {
    const value = e.target.value;
    setField((prev) => ({ ...prev, fieldName: value }));
  };

  // change individual section checkbox
  const individualSectionHandler = (e) => {
    const selected = e.target.checked;
    setField((prev) => {
      const section = prev.section;
      const index = section.indexOf(0);
      if (selected) {
        if (index === -1) section.push(0);
      } else if (index !== -1) {
        section.splice(index, 1);
      }
      return { ...prev, section };
    });
  };

  // change corporate section checkbox
  const corporateSectionHandler = (e) => {
    const selected = e.target.checked;
    setField((prev) => {
      const section = prev.section;
      const index = section.indexOf(1);
      if (selected) {
        if (index === -1) section.push(1);
      } else if (index !== -1) {
        section.splice(index, 1);
      }
      return { ...prev, section };
    });
  };

  // change mandatory section checkbox
  const mandatoryHandler = (e) => {
    const selected = e.target.checked;
    setField((prev) => {
      if (selected) return { ...prev, isRequired: true };
      else return { ...prev, isRequired: false };
    });
  };

  return (
    <div
      style={{
        position: "fixed",
        left: "15%",
        width: "85%",
        paddingTop: "3rem",
        border: "1px solid #EEEEEF",
        height: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: "50px",
          flexDirection: "column",
          marginLeft: "4rem",
          marginRight: "4rem",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "5px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h3
              style={{
                fontWeight: "600",
                fontSize: "24px",
                color: "#212529",
                marginTop: "auto",
                marginBottom: "auto",
              }}
            >
              Custom Fields
            </h3>
            <IoEllipse
              style={{ color: "#212121", height: "5px", width: "5px" }}
            />
            <h3
              style={{
                fontWeight: "500",
                fontSize: "24px",
                color: "#3D454C",
                marginTop: "auto",
                marginBottom: "auto",
              }}
            >
              Edit Field
            </h3>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            gap: "18px",
            flexDirection: "column",
            height: "65vh",
            overflow: "auto",
          }}
        >
          <div style={{ display: "flex", gap: "119px", alignItems: "center" }}>
            <label
              htmlFor="fieldName"
              style={{ color: "#191D23", fontWeight: "500", fontSize: "14px" }}
            >
              Field Name
            </label>
            <div className="editFieldFieldNameDiv">
              <input
                id="fieldName"
                type="text"
                placeholder="Enter field name"
                value={field?.fieldName}
                onChange={fieldNameHandler}
                disabled={field?.isDefault}
                style={{
                  border: "1px solid #CCCCCC",
                  borderRadius: "8px",
                  padding: "12px 16px 12px 16px",
                  width: "350px",
                }}
              />
              {field?.isDefault && (
                <div className="editFieldFieldNameInfo">
                  <PiInfo style={{ width: "12px", height: "12px" }} />
                  that the field name cannot be modified, as it serves a general
                  purpose
                </div>
              )}
            </div>
          </div>
          <div style={{ display: "flex", gap: "55px" }}>
            <label
              htmlFor="fieldName"
              style={{ color: "#191D23", fontWeight: "500", fontSize: "14px" }}
            >
              Input Field in Section
            </label>
            <div style={{ display: "flex", gap: "18px" }}>
              <input
                type="checkbox"
                id="individual"
                checked={field?.section.indexOf(0) !== -1}
                onChange={individualSectionHandler}
                disabled={field?.isDefault}
              />
              <label
                htmlFor="individual"
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#000000",
                }}
              >
                Individual
              </label>
            </div>
            <div style={{ display: "flex", gap: "18px" }}>
              <input
                type="checkbox"
                id="corporate"
                checked={field?.section.indexOf(1) !== -1}
                onChange={corporateSectionHandler}
                disabled={field?.isDefault}
              />
              <label
                htmlFor="corporate"
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#000000",
                }}
              >
                Corporate
              </label>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{ display: "flex", gap: "125px", alignItems: "center" }}
            >
              <label
                style={{
                  color: "#191D23",
                  fontWeight: "500",
                  fontSize: "14px",
                }}
              >
                Field Type
              </label>
              <div
                style={{
                  border: "1px solid #CCCCCC",
                  borderRadius: "8px",
                  padding: "12px 16px 12px 16px",
                  width: "350px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                  pointerEvents: field?.isDefault && "none",
                }}
                onClick={togglefieldType}
              >
                <span>{fieldType}</span>
                <PiCaretDownLight style={{ height: "16px", width: "16px" }} />
              </div>
            </div>
            <div>
              {openfieldType && (
                <div
                  style={{
                    padding: "12px",
                    borderRadius: "0px 0px 8px 8px",
                    border: "1px solid black",
                    width: "350px",
                    marginLeft: "194px",
                  }}
                >
                  {fieldTypes.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        padding: "10px",
                        cursor: "pointer",
                        fontWeight: "500",
                        fontSize: "14px",
                      }}
                      onClick={changeFieldType.bind(null, index)}
                    >
                      {item}
                    </div>
                  ))}
                </div>
              )}
              {fieldType === "Drop-down" && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "14px",
                    marginLeft: "194px",
                    marginTop: "15px",
                    display: field?.isDefault && "none",
                  }}
                >
                  <h6
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#000000",
                    }}
                  >
                    Select Options
                  </h6>
                  <div
                    style={{
                      display: "flex",
                      gap: "12px",
                      flexDirection: "column",
                    }}
                  >
                    {dropDownOptions &&
                      dropDownOptions.map((item, index) => (
                        <div
                          key={index}
                          style={{ display: "flex", gap: "20px" }}
                        >
                          <input
                            value={item}
                            onChange={(e) =>
                              changeDropDownOptionValue(e, index)
                            }
                            style={{
                              border: "1px solid #CCCCCC",
                              borderRadius: "8px",
                              padding: "12px 16px 12px 16px",
                              width: "645px",
                            }}
                          />
                          <div
                            style={{
                              display: "flex",
                              gap: "8px",
                              alignItems: "center",
                            }}
                          >
                            <button
                              onClick={moveUpDropDownOption.bind(null, index)}
                              style={{
                                border: "1px solid #DEDEDE",
                                borderRadius: "8px",
                                backgroundColor: "#FFFFFF",
                                padding: "8px",
                              }}
                            >
                              <GoArrowUp
                                style={{ height: "18px", width: "18px" }}
                              />
                            </button>
                            <button
                              onClick={moveDownDropDownOption.bind(null, index)}
                              style={{
                                border: "1px solid #DEDEDE",
                                borderRadius: "8px",
                                backgroundColor: "#FFFFFF",
                                padding: "8px",
                              }}
                            >
                              <GoArrowDown
                                style={{ height: "18px", width: "18px" }}
                              />
                            </button>
                            <button
                              onClick={deleteDropDownOption.bind(null, index)}
                              style={{
                                border: "1px solid #DEDEDE",
                                borderRadius: "8px",
                                backgroundColor: "#FFFFFF",
                                padding: "8px",
                              }}
                            >
                              <IoMdClose
                                style={{ height: "18px", width: "18px" }}
                              />
                            </button>
                          </div>
                        </div>
                      ))}
                    <button
                      onClick={addDropDownOption}
                      style={{
                        border: "none",
                        padding: "8px 12px",
                        width: "120px",
                        fontWeight: "500",
                        fontSize: "14px",
                        color: "#0084EF",
                        backgroundColor: "white",
                      }}
                    >
                      + Add Option
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
            <input
              type="checkbox"
              id="mandatory"
              checked={field?.isRequired}
              onChange={mandatoryHandler}
            />
            <label
              htmlFor="mandatory"
              style={{
                fontSize: "14px",
                fontWeight: "500",
                color: "#000000",
              }}
            >
              Make it Mandatory
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditField;
