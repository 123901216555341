import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { MdCheck } from "react-icons/md";
import { constants } from "../../../../piservices/constants";
import toast from "react-hot-toast";

const AddTagModal = ({ show, hide, colors }) => {
  const [colorIndex, setColorIndex] = useState(-1);

  const [newTagDetails, setNewTagDetails] = useState({
    tagName: "",
    tagColorCode: "",
  });

  useEffect(() => {
    if (show) {
      setColorIndex(-1);
      setNewTagDetails({ tagName: "", tagColorCode: "" });
    }
  }, [show]);

  // change tag name
  const newTagNameHandler = (e) => {
    e.preventDefault();
    const value = e.target.value;
    setNewTagDetails((prev) => ({ ...prev, tagName: value }));
  };

  // change tag color
  const newTagColorHandler = (index) => {
    setNewTagDetails((prev) => ({ ...prev, tagColorCode: colors[index][0] }));
    setColorIndex(index);
  };

  // save new tag
  const saveNewTagHandler = () => {
    if (newTagDetails.tagName !== "" && newTagDetails.tagColorCode !== "") {
      const uploadTag = async () => {
        try {
          await axios.post(
            `${
              constants.CRM_API
            }/user/save-lead-tag?userId=${localStorage.getItem("userId")}`,
            [newTagDetails]
          );
          toast.success("Tag added successfully!");
          hide();
        } catch (err) {
          console.log(err);
        }
      };
      uploadTag();
    }
  };

  return (
    <Modal show={show} onHide={hide} centered>
      <Modal.Header
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid #DEDEDE",
          padding: "14px 28px",
        }}
      >
        <div style={{ fontWeight: "600", fontSize: "18px", color: "#212121" }}>
          Add Tag
        </div>
        <div style={{ display: "flex", gap: "12px", alignItems: "center" }}>
          <button
            onClick={hide}
            style={{
              color: "#454545",
              fontWeight: "500",
              fontSize: "14px",
              backgroundColor: "white",
              border: "none",
              padding: "10px 14px",
            }}
          >
            Cancel
          </button>
          <button
            style={{
              color: "#FFFFFF",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: "#0084EF",
              border: "none",
              borderRadius: "4px",
              padding: "10px 14px",
            }}
            onClick={saveNewTagHandler}
          >
            Save
          </button>
        </div>
      </Modal.Header>
      <Modal.Body
        style={{ display: "flex", flexDirection: "column", gap: "8px" }}
      >
        <label
          htmlFor="tagName"
          style={{ color: "#191D23", fontWeight: "500", fontSize: "14px" }}
        >
          Tag Name
        </label>
        <input
          placeholder="Type here"
          id="tagName"
          value={newTagDetails.tagName}
          onChange={newTagNameHandler}
          maxLength={15}
          style={{
            padding: "12px 16px",
            border: "1px solid #999999",
            borderRadius: "8px",
          }}
        />
        <label
          style={{ color: "#191D23", fontWeight: "500", fontSize: "14px" }}
        >
          Select Color
        </label>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
            gap: "4px",
          }}
        >
          {colors &&
            colors.map((item, index) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: colorIndex === index && "#E5E5E5",
                  cursor: "pointer",
                  padding: "5px",
                }}
                onClick={newTagColorHandler.bind(null, index)}
              >
                <div
                  style={{ display: "flex", gap: "16px", alignItems: "center" }}
                >
                  <div
                    style={{
                      backgroundColor: item[0],
                      width: "15px",
                      height: "15px",
                      borderRadius: "2px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  ></div>
                  <div
                    style={{
                      color: "#454545",
                      fontWeight: "400",
                      fontSize: "12px",
                      display: "flex",
                      alignItems: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {item[1]}
                  </div>
                </div>
                {colorIndex === index ? (
                  <MdCheck />
                ) : (
                  <MdCheck style={{ color: "white" }} />
                )}
              </div>
            ))}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddTagModal;
