import React, { useEffect, useState } from "react";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import { HiMagnifyingGlass } from "react-icons/hi2";
import { GoGear } from "react-icons/go";
import { MdFilterList } from "react-icons/md";
import { MdExpandMore } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { PiCloudArrowDown } from "react-icons/pi";
import { PiUploadSimple } from "react-icons/pi";
import AddLead from "./Modals/AddLead";
import ConfirmCancel from "../../Lms/Modals/ConfirmCancel";
import { setAddLeadModalOpen } from "../../../Redux/Slices/newLeadSlice";
import { useDispatch } from "react-redux";
import AddLeadModalNew from "../../Lms/AddLeadModalNew";

const LeadManagementSettingsHeader = ({
  setSaveNewField,
  setSaveAndNewField,
  setSaveEditField,
  setSaveEditAndNewField,
  isChanged,
}) => {
  const [url, setUrl] = useState("");
  const [show, setShow] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [name, setName] = useState("");
  const [action, setAction] = useState(() => console.log("action!"));
const dispatch = useDispatch();
  const location = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    setUrl(location.pathname);
  }, [location]);

  const closeCancelModal = () => {
    setShowCancel(false);
  };

  // cancel add and edit custom field
  const changeFieldCancelHandler = () => {
    navigate("/leads/lead-management-settings/custom-field");
  };

  // save new field
  const saveNewFieldHandler = () => {
    setSaveNewField(true);
  };

  // save and add new field
  const saveAndNewFieldHandler = () => {
    setSaveNewField(true);
    setSaveAndNewField(true);
  };

  // save edit field
  const saveEditFieldHandler = () => {
    setSaveEditField(true);
  };

  // save edit and add new field
  const saveEditAndNewFieldHandler = () => {
    setSaveEditField(true);
    setSaveEditAndNewField(true);
  };

  // open add lead modal
  const showAddLead = () => {
    setShow(true);
  };

  // hide add lead modal
  const hideAddLead = () => {
    setShow(false);
  };

  // add new lead
  const addLeadHandler = () => {
    showAddLead();
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: "0.8rem",
        marginLeft: "2rem",
        marginRight: "2rem",
      }}
    >
      <ConfirmCancel
        show={showCancel}
        hide={closeCancelModal}
        name={name}
        action={action}
      />
      <AddLead show={show} hide={hideAddLead} />
      <div style={{ display: "flex", gap: "18px", alignItems: "center" }}>
        <div style={{ display: "flex", gap: "12px" }}>
          <button
            style={{ backgroundColor: "white", border: "none" }}
            onClick={() => navigate(-1)}
          >
            <MdKeyboardArrowLeft style={{ height: "25px", width: "25px" }} />
          </button>
          <button
            style={{ backgroundColor: "white", border: "none" }}
            onClick={() => navigate(1)}
          >
            <MdKeyboardArrowRight style={{ height: "25px", width: "25px" }} />
          </button>
        </div>
        <span style={{ fontSize: "16px", color: "#212529", fontWeight: "500" }}>
          Leads Setting
        </span>
      </div>
      {url.includes("/leads/lead-management-settings") &&
        (url.includes("custom-field") ||
          url.includes("status") ||
          url.includes("tags")) && (
          <div
            style={{
              display: "flex",
              gap: "12px",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {/* <button
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
              padding: "8px 12px 8px 12px",
              backgroundColor: "white",
              border: "none",
              color: "#6F6E6E",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            <MdFilterList style={{ height: "20px", width: "20px" }} />
            <div
              style={{
                display: "flex",
                gap: "4px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Filter
              <MdExpandMore style={{ height: "16px", width: "16px" }} />
            </div>
          </button> */}
            {/* <button
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "11px",
                backgroundColor: "#FAFAFA",
                color: "#6F6E6E",
                borderRadius: "8px",
                padding: "8px",
                border: "none",
              }}
            >
              <GoGear style={{ height: "19px", width: "19px" }} />
            </button> */}
            <div
              style={{
                backgroundColor: "#FAFAFA",
                borderRadius: "8px",
                display: "flex",
                gap: "10px",
                alignItems: "center",
                padding: "6px 12px 6px 12px",
                fontWeight: "400",
                width:"165px",
                fontSize: "16px",
              }}
            >
              <HiMagnifyingGlass />
              <input
                placeholder="search"
                style={{ border: "none", width:"136px",backgroundColor: "#FAFAFA" }}
              />
            </div>
            {/* <button
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "11px",
                backgroundColor: "#FAFAFA",
                color: "#0084EF",
                borderRadius: "8px",
                padding: "8px",
                border: "none",
              }}
            >
              <PiCloudArrowDown style={{ height: "24px", width: "24px" }} />
            </button>
            <button
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "11px",
                backgroundColor: "#FAFAFA",
                color: "#0084EF",
                borderRadius: "8px",
                padding: "8px",
                border: "none",
              }}
            >
              <PiUploadSimple style={{ height: "24px", width: "24px" }} />
            </button> */}
            <button
              style={{
                border: "1px solid #0084EF",
                borderRadius: "8px",
                backgroundColor: "#0084EF",
                fontWeight: "500",
                fontSize: "16px",
                color: "#FFFFFF",
                padding: "8px 16px 8px 16px",
              }}
              onClick={() => {
                // getLeadFormTemplate(),
                console.log("kxlqkxwx");
                dispatch(setAddLeadModalOpen(true));
             } }
              // onClick={addLeadHandler}
            >
              Add Lead
            </button>
          </div>
        )}
      {url.includes("/leads/lead-management-settings/create-new-field") && (
        <div style={{ display: "flex", gap: "12px" }}>
          <button
            style={{
              border: "1px solid #DEDEDE",
              borderRadius: "8px",
              padding: "8px 24px 8px 24px",
              backgroundColor: "#FFFFFF",
              color: "#A6A6A6",
              fontWeight: "500",
              fontSize: "16px",
            }}
            onClick={() => {
              if (isChanged) {
                setAction(() => changeFieldCancelHandler);
                setName("Custom Field");
                setShowCancel(true);
              } else {
                changeFieldCancelHandler();
              }
            }}
          >
            Cancel
          </button>
          <button
            style={{
              border: "1px solid #0084EF",
              borderRadius: "8px",
              padding: "8px 24px 8px 24px",
              backgroundColor: "#FFFFFF",
              color: "#0084EF",
              fontWeight: "600",
              fontSize: "16px",
            }}
            onClick={saveAndNewFieldHandler}
          >
            Save and add new
          </button>
          <button
            style={{
              border: "1px solid #0084EF",
              borderRadius: "8px",
              padding: "8px 24px 8px 24px",
              backgroundColor: "#0084EF",
              color: "#FFFFFF",
              fontWeight: "600",
              fontSize: "16px",
            }}
            onClick={saveNewFieldHandler}
          >
            Save
          </button>
        </div>
      )}
      {url.includes("/leads/lead-management-settings/edit-field") && (
        <div style={{ display: "flex", gap: "12px" }}>
          <button
            style={{
              border: "1px solid #DEDEDE",
              borderRadius: "8px",
              padding: "8px 24px 8px 24px",
              backgroundColor: "#FFFFFF",
              color: "#A6A6A6",
              fontWeight: "500",
              fontSize: "16px",
            }}
            onClick={() => {
              if (isChanged) {
                setAction(() => changeFieldCancelHandler);
                setName("Custom Field");
                setShowCancel(true);
              } else {
                changeFieldCancelHandler();
              }
            }}
          >
            Cancel
          </button>
          {/* <button
            style={{
              border: "1px solid #0084EF",
              borderRadius: "8px",
              padding: "8px 24px 8px 24px",
              backgroundColor: "#FFFFFF",
              color: "#0084EF",
              fontWeight: "600",
              fontSize: "16px",
            }}
            onClick={saveEditAndNewFieldHandler}
          >
            Save and add new
          </button> */}
          <button
            style={{
              border: "1px solid #0084EF",
              borderRadius: "8px",
              padding: "8px 24px 8px 24px",
              backgroundColor: "#0084EF",
              color: "#FFFFFF",
              fontWeight: "600",
              fontSize: "16px",
            }}
            onClick={saveEditFieldHandler}
          >
            Save
          </button>
        </div>
      )}
       <AddLeadModalNew />
    </div>
  );
};

export default LeadManagementSettingsHeader;
