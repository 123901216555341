import React from "react";
import DynamicToaster from "./DynamicToaster/DynamicToaster";

const ToasterCollection = () => {
  return (
    <React.Fragment>
      <DynamicToaster />
    </React.Fragment>
  );
};

export default ToasterCollection;
