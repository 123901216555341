import "./PiApp.css";
import "./piindex.css";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NotFound from "./picomponents/NotFound/NotFound";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import LoadingBar from "react-top-loading-bar";
import DesQuoState from "./picontext/DashBoard/dashState";
import MainLayoutPage from "./picomponents/Common/NewLayout/MainLayoutPage";
import MainLayoutPage1 from "./picomponents/Common/NewLayout/MainLayoutPage1";
import GetOtp from "./Pages/GetOtp/GetOtp";
import VerifyUser from "./Pages/verifyUser/VerifyUser";
import SignUp from "./Pages/Signup/SignUp";
import PersonalDetails from "./Pages/Personaldetails/PersonalDetails";
import AdminPersonalData from "./Pages/AdminPersonalData/AdminPersonalData";
import CompanyDetails from "./Pages/CompanyDetails/CompanyDetails";
import TeamOnboard from "./Pages/TeamOnboard/TeamOnboard";
import SignIn from "./Pages/SignIn/SignIn";
import ResetPassword from "./Pages/GetOtpForgot/ResetPassword";
import VerifyOtpForgot from "./Pages/verifyOtpForgot/VerifyOtpForgot";
import "react-phone-number-input/style.css";
import ToasterCollection from "./Components/ToasterCollection/ToasterCollection";
import CreatePassword from "./Pages/CreatePassword/CreatePassword";
import { setSelectedTabs } from "./Redux/Slices/lmsSlice";
import { useDispatch, useSelector } from "react-redux";
import LoginPage from "./Pages/Login/login";
import { constants } from "./piservices/constants";
import { Toaster } from "react-hot-toast";
import LeadManagementSettings from "./picomponents/Common/NewLayout/LeadManagementSettings";
import LeadDetails from "./picomponents/Lms/LeadDetails";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { getToken } from "./piservices/authService";
import { getReq } from "./piservices/apis";


function PiApp() {
  const getData = useSelector((state) => state)
  const [profileData, setProfileData] = useState({});
  const [progress, setProgress] = useState(0);
  const dispatch = useDispatch();
  const getProfileData = async () => {
    const res = await getReq(
      `${constants.CRM_API}/user/profile`,
      {},
      { Authorization: `Bearer ${getToken()}` }
    );
    if (res && !res.error) {
      setProfileData({ ...res.data.data });
    } else {
      console.log(res.error);
    }
  };

  useEffect(() => {
    getProfileData();
  }, []);
console.log(profileData?.isClient,"wedwedwe")
  const routes = [
    // { path: "/", element: <LoginPage /> },
    // { path: "/forgot-password", element: <ForgotPassword />},
    // { path: "/leads", element: <MainLayoutPage setProgress={setProgress} /> },
    // { path: "/leads/:tab", element: <MainLayoutPage setProgress={setProgress} /> },
    // { path: "/leads/lead-details/:id", element: <MainLayoutPage1 setProgress={setProgress} />},
    {
      path: "/get-otp",
      element: <GetOtp />,
    },
    {
      path: "/get-otp/:type",
      element: <GetOtp />,
    },
    {
      path: "/get-otp/:type/:userId",
      element: <GetOtp />,
    },
    {
      path: "/verify-user",
      element: <VerifyUser />,
    },
    {
      path: "/sign-up",
      element: <SignUp />,
    },
    {
      path: "/personal-details",
      element: <PersonalDetails />,
    },
    {
      path: "/personal-data",
      element: <AdminPersonalData />,
    },

    {
      path: "/company-details",
      element: <CompanyDetails />,
    },

    {
      path: "/team-onboard",
      element: <TeamOnboard />,
    },

    {
      path: "/",
      element: <SignIn />,
    },

    {
      path: "/reset",
      element: <ResetPassword />,
    },

    {
      path: "/verify-forgot",
      element: <VerifyOtpForgot />,
    },

    {
      path: "/create-password",
      element: <CreatePassword />
    },
    {
      path: "/login-page",
      element: <LoginPage />
    },


    { path: "/leads", element: <MainLayoutPage setProgress={setProgress} /> },
    { path: "/leads/:tab", element: <MainLayoutPage setProgress={setProgress} /> },
    { path: "/leads/lead-details/:id", element: <MainLayoutPage1 setProgress={setProgress} /> },
    { path: "/leads/lead-management-settings/*", element: <LeadManagementSettings />},
    {path: "/leads/lead-full-details/:id", element: <LeadDetails/>},
  ];
  // const checkUserId =()=>{
  //   const userId = localStorage.getItem('userId');
  //     if (userId) {
  //       // setUserIdPresent(true);
  //       window.location.assign(`${constants.BASE_URL}/admin/`)
  //       // alert("Hai data")
  //     } 
  // }
  useEffect(() => {
    if(window.location.href ===`${constants. BASE_URL}/`){
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem("token");
          if (userId && token) {
            // setUserIdPresent(true);
            if(profileData?.isClient == true){
              window.location.assign(`${constants. BASE_URL}/client/`)
            }
            else {
              window.location.assign(`${constants. BASE_URL}/admin/`)
            }
            // alert("Hai data")
          } 
    }
    // checkUserId()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dispatch(setSelectedTabs("Leads Management"))
    localStorage.setItem("userPosition", "des");
    localStorage.removeItem('headerTab')
  }, []);

  useEffect(() => {
    const getPageKey = localStorage.getItem("currentPageUrl")
    if (window.location.pathname != "/company-details" && getPageKey == "company-details") {
      window.location.href = "/company-details"
    } else if (window.location.pathname != "/personal-data" && getPageKey == "personal-data") {
      window.location.href = "/personal-data"
    } else if (window.location.pathname != "/team-onboard" && getPageKey == "team-onboard") {
      window.location.href = "/team-onboard"
    } else if (window.location.pathname != "/personal-details" && getPageKey == "personal-details") {
      window.location.href = "/personal-details"
    }
  }, [localStorage.getItem("currentPageUrl"), getData])

  return (
    <div className="App">
      <Toaster
        containerStyle={{ zIndex: 99999999 }} />
      <Helmet>
        <link rel="alternate" href={window.location.href} hreflang="en-in" />
      </Helmet>
      <ToasterCollection />
      <DesQuoState>
        <Router>
          <LoadingBar color="linear-gradient(90deg, #49B7CF 0%, #D9EFF4 0%, #49B7CF 100%)" progress={progress} height={3} />
          <Routes>
            {routes.map((e, i) => {
              // if(e.path === "/"){
              //   checkUserId()
              // }
              return <Route exact path={e.path} element={e.element} key={i} />;
            })}
            <Route path="*" element={<NotFound status={404} />} />
            <Route path="/404" element={<NotFound status={404} />} />
          </Routes>
          <button className="d-none" data-mdb-toggle="modal" data-mdb-target="#piAlertModal" id="alertbtn"></button>
        </Router>
      </DesQuoState>
      {/* {window.location.href == "https://uat.idesign.market/"? "" :<button className="floating_btn" onClick={()=> (window.open(`${constants.BASE_URL}/timeline/task-manager-dashboard`),localStorage.setItem("task_modal",true))}> <img src={floatingTask} alt="" height="20" width="20"  /></button>} */}
    </div>
  );
}

export default PiApp;
