import React from "react";
import { Modal } from "react-bootstrap";
import styles from "./modals.module.css";

const ConfirmCancel = ({ show, hide, name, action }) => {
  return (
    <Modal show={show} onHide={hide} centered dialogClassName="deleteModal">
      <Modal.Body className={styles.deleteBody}>
        <div style={{fontSize:"18px",fontWeight:"600",paddingBottom:"1rem",color:"#10128"}}>Delete {name}</div>
        <div>Do you want to delete the {name}?</div>
      </Modal.Body>
      {/* <Modal.Footer className={styles.deleteFooter}> */}
      <div style={{display:"flex",alignItems:"center",alignSelf:"end",marginRight:"1rem",marginBottom:"1rem"}}>
        <div className={styles.deleteCancel} onClick={hide} style={{cursor:"pointer"}}>
          Cancel
        </div>
        <div
          className={styles.deleteDelete}
          style={{cursor:"pointer"}}
          onClick={() => {
            action();
            hide();
          }}
        >
          Delete
        </div>
      </div>
      {/* </Modal.Footer> */}
    </Modal>
  );
};

export default ConfirmCancel;
