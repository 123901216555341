import React, { useContext } from 'react'
import desquoContext from '../picontext/DashBoard/desquoContext';
import { getReq } from './apis';
// import expf from './constants';
import { constants } from './constants';
import {useDispatch} from 'react-redux'
import { formateDate, formateDateNameMonth } from './compCommon';
import { setAddLeadFormTemplate } from '../Redux/Slices/lmsSlice';

function useApiFetchCustomHook() {
  const context = useContext(desquoContext);
  const dispatch = useDispatch();
  const {
    setLeads2,
    setLeads,
  } = context;
  const apiFetch = async(tab)=>{
    const res = await getReq(
        constants.CRM_API + "/user/newLeadsData?pageNo=0" + (tab > 0 ? "&type=" + tab : "")
      );
      if (res && !res.error) {
        if (tab === "2") {
          let today = new Date();
          let followUpLeadsBeforeToday = res?.data?.data?.data
            ?.filter(
              (item) =>
                new Date(formateDateNameMonth(today)) >
                new Date(
                  item?.leadUpdates[item?.leadUpdates?.length - 1].updateDate
                )
            )
            ?.sort(
              (a, b) =>
                new Date(b?.leadUpdates[b?.leadUpdates?.length - 1].updateDate) -
                new Date(a?.leadUpdates[a?.leadUpdates?.length - 1].updateDate)
            );
          let followUpLeadsTodayAndAfter = res?.data?.data?.data
            ?.filter(
              (item) =>
                new Date(formateDateNameMonth(today)) <=
                new Date(
                  item?.leadUpdates[item?.leadUpdates?.length - 1].updateDate
                )
            )
            ?.sort(
              (b, a) =>
                new Date(b?.leadUpdates[b?.leadUpdates?.length - 1].updateDate) -
                new Date(a?.leadUpdates[a?.leadUpdates?.length - 1].updateDate)
            );
          let x = JSON.parse(JSON.stringify(res?.data?.data));
          delete x["data"];
          x["data"] = [
            ...followUpLeadsTodayAndAfter,
            ...followUpLeadsBeforeToday,
          ];
          setLeads(x);
          setLeads2(x);
        } else {
          const sortedLeads = res?.data?.data?.data?.sort(
            (a, b) => new Date(b?.updatedAt) - new Date(a?.updatedAt)
          );
          let x = JSON.parse(JSON.stringify(res?.data?.data));
          delete x["data"];
          x["data"] = sortedLeads;
          setLeads({...x});
          setLeads2(x);
        }
      }
  }
  const getLeadFormTemplate = async (templateId)=>{
    let res;
    if(templateId){
      res = await getReq(
        `${constants.CRM_API}/template/get-default-lead-template?userId=${localStorage.getItem('userId')}&id=${templateId}`
      );
    }else{
      res = await getReq(
        `${constants.CRM_API}/template/get-default-lead-template?userId=${localStorage.getItem('userId')}`
      );
    }
   
    if (res && !res.error) {
      console.log('templateresponse', res)
      dispatch(setAddLeadFormTemplate())
    }else{

    }
  }
  return {apiFetch,getLeadFormTemplate}
}

export default useApiFetchCustomHook