import React, { useEffect, useState } from "react";
import styles from "./NewLayout.module.css";
import TopHeaderCommon from "./TopHeaderCommon";
import LeadManagementSettingsHeader from "./LeadManagementSettingsHeader";
import LeadManagementSettingsSidebar from "./LeadManagementSettingsSidebar";
import { Route, Routes, useNavigate } from "react-router-dom";
import CustomField from "../../Lms/LeadManagementSettings/CustomField";
import CreateNewField from "../../Lms/LeadManagementSettings/CreateNewField";
import EditField from "../../Lms/LeadManagementSettings/EditField";
import Status from "../../Lms/LeadManagementSettings/Status";
import Tags from "../../Lms/LeadManagementSettings/Tags";
import Teams from "../../Lms/LeadManagementSettings/Teams";

const LeadManagementSettings = () => {
  const navigate = useNavigate();

  const [saveNewField, setSaveNewField] = useState(false);
  const [saveAndNewField, setSaveAndNewField] = useState(false);
  const [saveEditField, setSaveEditField] = useState(false);
  const [saveEditAndNewField, setSaveEditAndNewField] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    navigate("/leads/lead-management-settings/status");
  }, []);

  console.log(isChanged, "changed!")

  return (
    <>
      <div className="d-flex flex-column" style={{ height: "" }}>
        <div className={styles.main_cont} style={{ zIndex: "0" }}>
          <TopHeaderCommon />
          <LeadManagementSettingsHeader
            setSaveNewField={setSaveNewField}
            setSaveAndNewField={setSaveAndNewField}
            setSaveEditField={setSaveEditField}
            setSaveEditAndNewField={setSaveEditAndNewField}
            isChanged={isChanged}
          />
        </div>
        <div
          className="container-box"
          style={{
            position: "relative",
            zIndex: "",
            display: "flex",
            marginTop: "7rem",
          }}
        >
          <LeadManagementSettingsSidebar />
          <Routes>
            <Route path="/custom-field" element={<CustomField />} />
            <Route
              path="/create-new-field"
              element={
                <CreateNewField
                  setSaveNewField={setSaveNewField}
                  saveNewField={saveNewField}
                  saveAndNewField={saveAndNewField}
                  setSaveAndNewField={setSaveAndNewField}
                  setSaveEditAndNewField={setSaveEditAndNewField}
                  setIsChanged={setIsChanged}
                />
              }
            />
            <Route
              path="/edit-field/:id"
              element={
                <EditField
                  setSaveEditField={setSaveEditField}
                  saveEditField={saveEditField}
                  saveEditAndNewField={saveEditAndNewField}
                  setIsChanged={setIsChanged}
                />
              }
            />
            <Route path="/status" element={<Status />} />
            <Route path="/tags" element={<Tags />} />
            <Route path="/teams" element={<Teams/>}/>
          </Routes>
        </div>
      </div>
    </>
  );
};

export default LeadManagementSettings;
