import React, { useEffect, useState } from 'react'
import DonutChart from "./DonutChart";
import Card from "react-bootstrap/Card";
import TrendUp from "../../../Assets/TrendUp.svg"
import TrendUpBlue from "../../../Assets/TrendUpBlue.svg"
import zeroBar from "../../../Assets/zeroBar.svg"
import barChart from "../../../Assets/barChart.svg"
import TrendUpRed from "../../../Assets/TrendUpRed.svg"
import CloseGrey from "../../../Assets/CloseGrey.svg"
import leadZero from "../../../Assets/leadZero.svg"
import purpRound from "../../../Assets/purpRound.svg"
import { LineChart, XAxis, YAxis, Line, axisClasses, BarChart } from '@mui/x-charts';
// import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
// import BarChart from "react-bar-chart";

import lightRound from "../../../Assets/lightRound.svg"
import greenyRound from "../../../Assets/greenyRound.svg"
import yellround from "../../../Assets/yellround.svg"
import bluesRound from "../../../Assets/bluesRound.svg"
import skyRound from "../../../Assets/skyRound.svg"
import darkestLIightt from "../../../Assets/darkestLIightt.svg"
import drkingBlu from "../../../Assets/drkingBlu.svg"
import purpRoundd from "../../../Assets/purpRoundd.svg"
import MagnifyingGrey from "../../../Assets/MagnifyingGrey.svg"
import medPurp from "../../../Assets/medPurp.svg"
import sabseLight from "../../../Assets/sabseLight.svg"
import newBlueShape from "../../../Assets/newBlueShape.svg"
import zBlue from "../../../Assets/zBlue.svg"
import FolderSimple from "../../../Assets/FolderSimple.svg"
import blueRound from "../../../Assets/blueRound.svg"
import yellowRound from "../../../Assets/yellowRound.svg"
import lightPurp from "../../../Assets/lightPurp.svg"
import midBlue from "../../../Assets/midBlue.svg"
import darkBlue from "../../../Assets/darkBlue.svg"
import purpShape from "../../../Assets/purpShape.svg"
import darkestB from "../../../Assets/darkestB.svg"
import blueShape from "../../../Assets/blueShape.svg"
import TrendUpdBlue from "../../../Assets/TrendUpdBlue.svg"
import "./Analytics.css"
import { getReq, postTReq } from '../../../piservices/apis';
import { constants } from '../../../piservices/constants';
import { getToken } from '../../../piservices/authService';
import { Offcanvas, ProgressBar } from 'react-bootstrap';

const months = {
    0: 'Jan',
    1: 'Feb',
    2: 'Mar',
    3: 'Apr',
    4: 'May',
    5: 'Jun',
    6: 'Jul',
    7: 'Aug',
    8: 'Sep',
    9: 'Oct',
    10: 'Nov',
    11: 'Dec'
}

export const getDateString = (Date) => {
    let month = Date.getMonth() + 1;
    let dt = Date.getDate();
    if (month < 10) {
        month = "0" + month;
    }
    if (dt < 10) {
        dt = "0" + dt;
    }
    return `${Date.getFullYear()}-${month}-${dt}`;
};
const images = [darkestB, darkestLIightt, midBlue, drkingBlu, purpRoundd];
const leadImages = [blueShape, purpShape, darkBlue, lightPurp, medPurp, sabseLight,newBlueShape,zBlue]
const imagesStage = [purpRound, blueRound, skyRound, lightRound,greenyRound,yellround,bluesRound]
const MainLandingPageAnaly = ({
    selectedPeriod,
    value,
    setStartDate,
    setEndDate,
    getDateStr2,
    startDate,
    selectedIds,
    endDate,
}) => {
    const [analyticsData, setAnalyticsData] = useState([]);
    const [weekDataWon, setWeekDataWon] = useState();
    const [showCanvas, setShowCanvas] = useState(false);
    // ************  week Data
    const [weekDataInterested, setWeekDataInterested] = useState();
    const [weekDataContacted, setWeekDataContacted] = useState();
    const [weekTotalResponse, setWeekTotalResponse] = useState();

    //   ********** Month Data
    const [monthDataInterested, setMonthDataInterested] = useState();
    const [monthDataContacted, setMonthDataContacted] = useState();
    const [monthDataWon, setMonthDataWon] = useState();
    const [monthDataTotalResponse, setMonthDataTotalResponse] = useState();

    //  *********** quarter wise
    const [quarterDataInterested, setQuarterDataInterested] = useState();

    const [quarterDataContacted, setQuarterDataContacted] = useState();

    const [quarterDataWon, setQuarterDataWon] = useState();
    const [totalLeads, setTotalLeads] = useState();

    const [quarterDataTotalResponse, setQuarterDataTotalResponse] = useState();

    //   ********** Year Data
    const [yearDataInterested, setYearDataInterested] = useState();
    const [yearDataContacted, setYearDataContacted] = useState();
    const [yearDataWon, setYearDataWon] = useState();
    const [yearDataTotalResponse, setYearDataTotalResponse] = useState();
    const [storeAnalytics, setStoreAnalytics] = useState();
    const [storeDailyAnalytics, setStoreDailyAnalytics] = useState();
    const [storeDailyLogs, setStoreDailyLogs] = useState();
    const [storeSources, setStoreSources] = useState();
    const [storeSales, setStoreSales] = useState();
    const [leadStatusObj, setLeadStatusObj] = useState({
        FOLLOW_UP: 0,
        INTERESTED: 0,
        NOT_INTERESTED: 0,
        NO_RESPONSE: 0,
        NEW_LEADS: 0
    });

    // ************* custom Data for days selected more than 15
    const [customDataInterested, setCustomDataInterested] = useState([
        ["Name", "Intersted"],
        ["Week 1", 0],
        ["Week 2", 0],
        ["Week 3", 0],
        ["Week 4", 0],
        ["Week 5", 0],
        ["Week 6", 0],
        ["Week 7", 0],
        ["Week 8", 0],
        ["Week 9", 0],
        ["Week 10", 0],
        ["Week 11", 0],
        ["Week 12", 0],
    ]);
    const [customDataContacted, setCustomDataContacted] = useState([
        ["Name", "Contacted"],
        ["Week 1", 0],
        ["Week 2", 0],
        ["Week 3", 0],
        ["Week 4", 0],
        ["Week 5", 0],
        ["Week 6", 0],
        ["Week 7", 0],
        ["Week 8", 0],
        ["Week 9", 0],
        ["Week 10", 0],
        ["Week 11", 0],
        ["Week 12", 0],
    ]);
    const [customDataWon, setCustomDataWon] = useState([
        ["Name", "Won"],
        ["Week 1", 0],
        ["Week 2", 0],
        ["Week 3", 0],
        ["Week 4", 0],
        ["Week 5", 0],
        ["Week 6", 0],
        ["Week 7", 0],
        ["Week 8", 0],
        ["Week 9", 0],
        ["Week 10", 0],
        ["Week 11", 0],
        ["Week 12", 0],
    ]);
    const [customDataTotalResponse, setCustomDataTotalResponse] = useState([
        ["Name", "Total Response"],
        ["Week 1", 0],
        ["Week 2", 0],
        ["Week 3", 0],
        ["Week 4", 0],
        ["Week 5", 0],
        ["Week 6", 0],
        ["Week 7", 0],
        ["Week 8", 0],
        ["Week 9", 0],
        ["Week 10", 0],
        ["Week 11", 0],
        ["Week 12", 0],
    ]);
    // ************* custom Data1 for days selected less or equal to 15
    const [customDataInterested1, setCustomDataInterested1] = useState([
        ["Name", "Intersted"],
        ["day 1", 0],
        ["day 2", 0],
        ["day 3", 0],
        ["day 4", 0],
        ["day 5", 0],
        ["day 6", 0],
        ["day 7", 0],
        ["day 8", 0],
        ["day 9", 0],
        ["day 10", 0],
        ["day 11", 0],
        ["day 12", 0],
        ["day 13", 0],
        ["day 14", 0],
        ["day 15", 0],
    ]);
    const [customDataContacted1, setCustomDataContacted1] = useState([
        ["Name", "Contacted"],
        ["day 1", 0],
        ["day 2", 0],
        ["day 3", 0],
        ["day 4", 0],
        ["day 5", 0],
        ["day 6", 0],
        ["day 7", 0],
        ["day 8", 0],
        ["day 9", 0],
        ["day 10", 0],
        ["day 11", 0],
        ["day 12", 0],
        ["day 13", 0],
        ["day 14", 0],
        ["day 15", 0],
    ]);
    const [customDataWon1, setCustomDataWon1] = useState([
        ["Name", "Won"],
        ["day 1", 0],
        ["day 2", 0],
        ["day 3", 0],
        ["day 4", 0],
        ["day 5", 0],
        ["day 6", 0],
        ["day 7", 0],
        ["day 8", 0],
        ["day 9", 0],
        ["day 10", 0],
        ["day 11", 0],
        ["day 12", 0],
        ["day 13", 0],
        ["day 14", 0],
        ["day 15", 0],
    ]);
    const [customDataTotalResponse1, setCustomDataTotalResponse1] = useState([
        ["Name", "Total Response"],
        ["day 1", 0],
        ["day 2", 0],
        ["day 3", 0],
        ["day 4", 0],
        ["day 5", 0],
        ["day 6", 0],
        ["day 7", 0],
        ["day 8", 0],
        ["day 9", 0],
        ["day 10", 0],
        ["day 11", 0],
        ["day 12", 0],
        ["day 13", 0],
        ["day 14", 0],
        ["day 15", 0],
    ]);
    const [storeConversion, setStoreConversion] = useState([]);
    const [storeTotalDeal, setStoreTotalDeal] = useState([]);
    const [storeAverage, setStoreAverage] = useState();
    //   getting Data week wise
    const getDataWeek = (leads) => {

        let temp = [
            ["Name", "Interested"],
            ["Mon", 0],
            ["Tue", 0],
            ["Wed", 0],
            ["Thur", 0],
            ["Fri", 0],
            ["Sat", 0],
            ["Sun", 0],
        ];
        let tempCont = [
            ["Name", "Contacted"],
            ["Mon", 0],
            ["Tue", 0],
            ["Wed", 0],
            ["Thur", 0],
            ["Fri", 0],
            ["Sat", 0],
            ["Sun", 0],
        ];
        let tempWon = [
            ["Name", "Won"],
            ["Mon", 0],
            ["Tue", 0],
            ["Wed", 0],
            ["Thur", 0],
            ["Fri", 0],
            ["Sat", 0],
            ["Sun", 0],
        ];
        let tempTotal = [
            ["Name", "Total Response"],
            ["Mon", 0],
            ["Tue", 0],
            ["Wed", 0],
            ["Thur", 0],
            ["Fri", 0],
            ["Sat", 0],
            ["Sun", 0],
        ];
        leads.forEach((o) => {
            let date = new Date(o?.createdAt);
            const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];
            let day = date.getDay();
            //   Interested
            if (o?.leadStatus == 3 && !o?.isProjectCreated) {
                temp = temp.map((o) => {
                    if (o[0] === dayNames[day]) {
                        o[1] = o[1] + 1;
                        return o;
                    }
                    return o;
                });
            }

            //   contacted
            if (o?.leadUpdates?.length > 0 && !o?.isProjectCreated) {
                tempCont = tempCont.map((b) => {
                    if (b[0] === dayNames[day]) {
                        b[1] = b[1] + 1;
                        return b;
                    }
                    return b;
                });
            }

            //   won cases
            if (o?.isProjectCreated) {

                tempWon = tempWon.map((k) => {
                    if (k[0] === dayNames[day]) {
                        k[1] = k[1] + 1;
                        return k;
                    }
                    return k;
                });
            }

            //   total response
            if (o?.leadStatus != 11) {
                tempTotal = tempTotal.map((j) => {
                    if (j[0] === dayNames[day]) {
                        j[1] = j[1] + 1;
                        return j;
                    }
                    return j;
                });
            }
        });
        console.log('tempTotal', tempTotal)
        setTotalLeads(leads?.length)
        setWeekDataWon(tempWon);
        setWeekDataContacted(tempCont);
        setWeekDataInterested(temp);
        setWeekTotalResponse(tempTotal);
    };
    //   getting data year wise
    const getDataYear = (leads) => {
        let temp = [
            ["Name", "Interested"],
            ["Jan", 0],
            ["Feb", 0],
            ["Mar", 0],
            ["Apr", 0],
            ["May", 0],
            ["Jun", 0],
            ["Jul", 0],
            ["Aug", 0],
            ["Sep", 0],
            ["Oct", 0],
            ["Nov", 0],
            ["Dec", 0],
        ];
        let tempCont = [
            ["Name", "Contacted"],
            ["Jan", 0],
            ["Feb", 0],
            ["Mar", 0],
            ["Apr", 0],
            ["May", 0],
            ["Jun", 0],
            ["Jul", 0],
            ["Aug", 0],
            ["Sep", 0],
            ["Oct", 0],
            ["Nov", 0],
            ["Dec", 0],
        ];
        let tempWon = [
            ["Name", "Won"],
            ["Jan", 0],
            ["Feb", 0],
            ["Mar", 0],
            ["Apr", 0],
            ["May", 0],
            ["Jun", 0],
            ["Jul", 0],
            ["Aug", 0],
            ["Sep", 0],
            ["Oct", 0],
            ["Nov", 0],
            ["Dec", 0],
        ];
        let tempTotal = [
            ["Name", "Total Response"],
            ["Jan", 0],
            ["Feb", 0],
            ["Mar", 0],
            ["Apr", 0],
            ["May", 0],
            ["Jun", 0],
            ["Jul", 0],
            ["Aug", 0],
            ["Sep", 0],
            ["Oct", 0],
            ["Nov", 0],
            ["Dec", 0],
        ];

        leads?.forEach((o) => {
            let date = new Date(o?.createdAt);

            // Get the month names using the options
            const monthName = date.toLocaleString("default", { month: "short" });
            if (o?.leadStatus == 3 && !o?.isProjectCreated) {
                temp = temp.map((o) => {
                    if (o[0] == monthName) {
                        o[1] = o[1] + 1;
                        return o;
                    }
                    return o;
                });
            }
            if (o?.leadUpdates?.length > 0 && !o?.isProjectCreated) {
                tempCont = tempCont.map((b) => {
                    if (b[0] == monthName) {
                        b[1] = b[1] + 1;
                        return b;
                    }
                    return b;
                });
            }
            if (o?.isProjectCreated) {
                tempWon = tempWon.map((k) => {
                    if (k[0] == monthName) {
                        k[1] = k[1] + 1;
                        return k;
                    }
                    return k;
                });
            }
            if (o?.leadStatus != 11) {
                tempTotal = tempTotal.map((j) => {
                    if (j[0] == monthName) {
                        j[1] = j[1] + 1;
                        return j;
                    }
                    return j;
                });
            }
        });

        let dt = new Date();
        let currMonth = dt.getMonth() + 1;
        temp = temp.splice(0, currMonth + 1);
        tempCont = tempCont.splice(0, currMonth + 1);
        tempWon = tempWon.splice(0, currMonth + 1);
        tempTotal = tempTotal.splice(0, currMonth + 1);
        setTotalLeads(leads?.length)
        setYearDataContacted(tempCont);
        setYearDataInterested(temp);
        setYearDataWon(tempWon);
        setYearDataTotalResponse(tempTotal);
    };
    const getDataLastQuarter = (leads) => {
        const q1 = [0, 1, 2]
        const q2 = [3, 4, 5]
        const q3 = [6, 7, 8]
        const q4 = [9, 10, 11]
        const date = new Date();
        const currentQuarter = q1.includes(date.getMonth()) ? q1 : q2.includes(date.getMonth()) ? q2 : q3.includes(date.getMonth()) ? q3 : q4;
        let lastQuarter = ''
        if (currentQuarter == q1) {
            lastQuarter = [...q1]
        } else if (currentQuarter == q2) {
            lastQuarter = [...q1]
        } else if (currentQuarter == q3) {
            lastQuarter = [...q2]
        } else {
            lastQuarter = [...q3]
        }

        let temp = [
            ["Name", "Interested"],
        ];
        let tempCont = [
            ["Name", "Contacted"],
        ];
        let tempWon = [
            ["Name", "Won"],
        ];
        let tempTotal = [
            ["Name", "Total Response"],
        ];
        lastQuarter.map((item, i) => {
            tempTotal.push([months[item], 0]);
            tempCont.push([months[item], 0]);
            tempWon.push([months[item], 0]);
            temp.push([months[item], 0]);
        })
        leads?.forEach((o) => {
            let date = new Date(o?.createdAt);
            // Get the month names using the options
            const monthName = date.toLocaleString("default", { month: "short" });
            // Interested
            if (o?.leadStatus == 3 && !o?.isProjectCreated) {
                temp = temp.map((o) => {
                    if (o[0] == monthName) {
                        o[1] = o[1] + 1;
                        return o;
                    }
                    return o;
                });
            }
            if (o?.leadUpdates?.length > 0 && !o?.isProjectCreated) {
                tempCont = tempCont.map((b) => {
                    if (b[0] == monthName) {
                        b[1] = b[1] + 1;
                        return b;
                    }
                    return b;
                });
            }
            //converted to project
            if (o?.isProjectCreated) {
                tempWon = tempWon.map((k) => {
                    if (k[0] == monthName) {
                        k[1] = k[1] + 1;
                        return k;
                    }
                    return k;
                });
            }
            //
            if (o?.leadStatus != 6 && !o?.isProjectCreated) {
                tempTotal = tempTotal.map((j) => {
                    if (j[0] == monthName) {
                        j[1] = j[1] + 1;
                        return j;
                    }
                    return j;
                });
            }
        });

        setTotalLeads(leads?.length)
        setQuarterDataContacted(tempCont);
        setQuarterDataInterested(temp);
        setQuarterDataWon(tempWon);
        setQuarterDataTotalResponse(tempTotal);
    };
    // getting custom data for less than or equal to 15 days
    const getDataCustom1 = (leads) => {
        let temp = [...customDataInterested1];
        let tempCont = [...customDataContacted1];
        let tempWon = [...customDataWon1];
        let tempTotal = [...customDataTotalResponse1];
        leads?.forEach((o) => {
            let date = new Date(o?.createdAt);
            let stDate = new Date(startDate);
            stDate = stDate.getDate();
            let dateSpecific = date.getDate();
            let rem = dateSpecific - stDate;
            // interested
            if (o?.leadStatus == 3) {
                temp[rem + 1][1] = temp[rem + 1][1] + 1;
            }

            // contacted
            if (o?.leadUpdates?.length > 0) {
                tempCont[rem + 1][1] = tempCont[rem + 1][1] + 1;
            }

            // won
            if (o?.leadStatus == 7) {
                tempWon[rem + 1][1] = tempWon[rem + 1][1] + 1;
            }
            // total response
            if (o?.leadStatus != 6) {
                tempTotal[rem + 1][1] = tempTotal[rem + 1][1] + 1;
            }
        });

        setCustomDataContacted1(tempCont);
        setCustomDataInterested1(temp);
        setCustomDataWon1(tempWon);
        setCustomDataTotalResponse1(tempTotal);
    };

    // getting data custom
    const getDataCustom = (leads) => {
        let temp = [...customDataInterested];
        let tempCont = [...customDataContacted];
        let tempWon = [...customDataWon];
        let tempTotal = [...customDataTotalResponse];
        leads?.forEach((o) => {
            let date = new Date(o?.createdAt);
            let stDate = new Date(startDate);
            stDate = stDate.getDate();
            let dateSpecific = date.getDate();
            let rem = dateSpecific - stDate;
            // interested
            if (o?.leadStatus == 3) {
                if (rem < 7) {
                    temp[1][1] = temp[1][1] + 1;
                } else if (rem >= 7 && rem <= 14) {
                    temp[2][1] = temp[2][1] + 1;
                } else if (rem > 14 && rem <= 21) {
                    temp[3][1] = temp[3][1] + 1;
                } else if (rem > 21 && rem <= 28) {
                    temp[4][1] = temp[4][1] + 1;
                } else if (rem > 28 && rem <= 35) {
                    temp[5][1] = temp[5][1] + 1;
                } else if (rem > 35 && rem <= 42) {
                    temp[6][1] = temp[6][1] + 1;
                } else if (rem > 42 && rem <= 49) {
                    temp[7][1] = temp[7][1] + 1;
                } else if (rem > 49 && rem <= 56) {
                    temp[8][1] = temp[8][1] + 1;
                } else if (rem > 56 && rem <= 63) {
                    temp[9][1] = temp[9][1] + 1;
                } else if (rem > 63 && rem <= 70) {
                    temp[10][1] = temp[10][1] + 1;
                } else if (rem > 70 && rem <= 77) {
                    temp[11][1] = temp[11][1] + 1;
                } else {
                    temp[12][1] = temp[12][1] + 1;
                }
            }

            // contacted
            if (o?.leadUpdates?.length > 0) {
                if (rem <= 7) {
                    tempCont[1][1] = tempCont[1][1] + 1;
                } else if (rem > 7 && rem <= 14) {
                    tempCont[2][1] = tempCont[2][1] + 1;
                } else if (rem > 14 && rem <= 21) {
                    tempCont[3][1] = tempCont[3][1] + 1;
                } else if (rem > 21 && rem <= 28) {
                    tempCont[4][1] = tempCont[4][1] + 1;
                } else if (rem > 28 && rem <= 35) {
                    tempCont[5][1] = tempCont[5][1] + 1;
                } else if (rem > 35 && rem <= 42) {
                    tempCont[6][1] = tempCont[6][1] + 1;
                } else if (rem > 42 && rem <= 49) {
                    tempCont[7][1] = tempCont[7][1] + 1;
                } else if (rem > 49 && rem <= 56) {
                    tempCont[8][1] = tempCont[8][1] + 1;
                } else if (rem > 56 && rem <= 63) {
                    tempCont[9][1] = tempCont[9][1] + 1;
                } else if (rem > 63 && rem <= 70) {
                    tempCont[10][1] = tempCont[10][1] + 1;
                } else if (rem > 70 && rem <= 77) {
                    tempCont[11][1] = tempCont[11][1] + 1;
                } else {
                    tempCont[12][1] = tempCont[12][1] + 1;
                }
            }

            // won
            if (o?.leadStatus == 7) {
                if (rem < 7) {
                    tempWon[1][1] = tempWon[1][1] + 1;
                } else if (rem >= 7 && rem <= 14) {
                    tempWon[2][1] = tempWon[2][1] + 1;
                } else if (rem > 14 && rem <= 21) {
                    tempWon[3][1] = tempWon[3][1] + 1;
                } else if (rem > 21 && rem <= 28) {
                    tempWon[4][1] = tempWon[4][1] + 1;
                } else if (rem > 28 && rem <= 35) {
                    tempWon[5][1] = tempWon[5][1] + 1;
                } else if (rem > 35 && rem <= 42) {
                    tempWon[6][1] = tempWon[6][1] + 1;
                } else if (rem > 42 && rem <= 49) {
                    tempWon[7][1] = tempWon[7][1] + 1;
                } else if (rem > 49 && rem <= 56) {
                    tempWon[8][1] = tempWon[8][1] + 1;
                } else if (rem > 56 && rem <= 63) {
                    tempWon[9][1] = tempWon[9][1] + 1;
                } else if (rem > 63 && rem <= 70) {
                    tempWon[10][1] = tempWon[10][1] + 1;
                } else if (rem > 70 && rem <= 77) {
                    tempWon[11][1] = tempWon[11][1] + 1;
                } else {
                    tempWon[12][1] = tempWon[12][1] + 1;
                }
            }
            // total response
            if (o?.leadStatus != 6) {
                if (rem < 7) {
                    tempTotal[1][1] = tempTotal[1][1] + 1;
                } else if (rem >= 7 && rem <= 14) {
                    tempTotal[2][1] = tempTotal[2][1] + 1;
                } else if (rem > 14 && rem <= 21) {
                    tempTotal[3][1] = tempTotal[3][1] + 1;
                } else if (rem > 21 && rem <= 28) {
                    tempTotal[4][1] = tempTotal[4][1] + 1;
                } else if (rem > 28 && rem <= 35) {
                    tempTotal[5][1] = tempTotal[5][1] + 1;
                } else if (rem > 35 && rem <= 42) {
                    tempTotal[6][1] = tempTotal[6][1] + 1;
                } else if (rem > 42 && rem <= 49) {
                    tempTotal[7][1] = tempTotal[7][1] + 1;
                } else if (rem > 49 && rem <= 56) {
                    tempTotal[8][1] = tempTotal[8][1] + 1;
                } else if (rem > 56 && rem <= 63) {
                    tempTotal[9][1] = tempTotal[9][1] + 1;
                } else if (rem > 63 && rem <= 70) {
                    tempTotal[10][1] = tempTotal[10][1] + 1;
                } else if (rem > 70 && rem <= 77) {
                    tempTotal[11][1] = tempTotal[11][1] + 1;
                } else {
                    tempTotal[12][1] = tempTotal[12][1] + 1;
                }
            }
        });
        temp = temp.filter((o) => {
            if (o[0] !== "Name") {
                return o[1] > 0;
            }
        });
        tempCont = tempCont.filter((o) => {
            if (o[0] !== "Name") {
                return o[1] > 0;
            }
        });
        tempWon = tempWon.filter((o) => {
            if (o[0] !== "Name") {
                return o[1] > 0;
            }
        });
        tempTotal = tempTotal.filter((o) => {
            if (o[0] !== "Name") {
                return o[1] > 0;
            }
        });

        temp.unshift(["Name", "Interested"]);
        tempCont.unshift(["Name", "Contacted"]);
        tempWon.unshift(["Name", "Won"]);
        tempTotal.unshift(["Name", "Total Response"]);
        setCustomDataContacted(tempCont);
        setCustomDataInterested(temp);
        setCustomDataWon(tempWon);
        setCustomDataTotalResponse(tempTotal);
    };

    const getDataQuarter = (leads) => {
        const q1 = [0, 1, 2]
        const q2 = [3, 4, 5]
        const q3 = [6, 7, 8]
        const q4 = [9, 10, 11]
        const date = new Date();
        const currentQuarter = q1.includes(date.getMonth()) ? q1 : q2.includes(date.getMonth()) ? q2 : q3.includes(date.getMonth()) ? q3 : q4;

        let temp = [
            ["Name", "Interested"],
        ];
        let tempCont = [
            ["Name", "Contacted"],
        ];
        let tempWon = [
            ["Name", "Won"],
        ];
        let tempTotal = [
            ["Name", "Total Response"],
        ];

        currentQuarter.map((item, i) => {
            tempTotal.push([months[item], 0]);
            tempCont.push([months[item], 0]);
            tempWon.push([months[item], 0]);
            temp.push([months[item], 0]);
        })
        leads?.forEach((o) => {
            let date = new Date(o?.createdAt);
            // Get the month names using the options
            const monthName = date.toLocaleString("default", { month: "short" });
            // Interested
            if (o?.leadStatus == 3 && !o?.isProjectCreated) {
                temp = temp.map((o) => {
                    if (o[0] == monthName) {
                        o[1] = o[1] + 1;
                        return o;
                    }
                    return o;
                });
            }
            if (o?.leadUpdates?.length > 0 && !o?.isProjectCreated) {
                tempCont = tempCont.map((b) => {
                    if (b[0] == monthName) {
                        b[1] = b[1] + 1;
                        return b;
                    }
                    return b;
                });
            }
            //converted to project
            if (o?.isProjectCreated) {
                tempWon = tempWon.map((k) => {
                    if (k[0] == monthName) {
                        k[1] = k[1] + 1;
                        return k;
                    }
                    return k;
                });
            }
            //
            if (o?.leadStatus != 11) {
                tempTotal = tempTotal.map((j) => {
                    if (j[0] == monthName) {
                        j[1] = j[1] + 1;
                        return j;
                    }
                    return j;
                });
            }
        });

        console.log('tempTotalqq', tempTotal)

        setTotalLeads(leads?.length)
        setQuarterDataContacted(tempCont);
        setQuarterDataInterested(temp);
        setQuarterDataWon(tempWon);
        setQuarterDataTotalResponse(tempTotal);
    };
    function addOneDay(date) {
        date.setDate(date.getDate() + 1);
        return date;
    }
    function getDaysInMonth(month, year) {
        return new Date(year, month + 1, 0).getDate();
    }

    const setupLeadStatusObj = (leads) => {
        let temp = {
            FOLLOW_UP: 0,
            INTERESTED: 0,
            NOT_INTERESTED: 0,
            NO_RESPONSE: 0,
            NEW_LEADS: 0,
        };
        leads?.forEach((o) => {
            if (o?.leadStatus === 2 && !o?.isProjectCreated) {
                temp.FOLLOW_UP = temp?.FOLLOW_UP + 1;
            } else if (o?.leadStatus == 3 && !o?.isProjectCreated) {
                temp.INTERESTED = temp?.INTERESTED + 1;
            } else if (o?.leadStatus == 5 && !o?.isProjectCreated) {
                temp.NOT_INTERESTED = temp?.NOT_INTERESTED + 1;
            } else if (o?.leadStatus == 6 && !o?.isProjectCreated) {
                temp.NO_RESPONSE = temp?.NO_RESPONSE + 1;
            }
            else if (o?.leadStatus == 1 && !o?.isProjectCreated) {
                temp.NEW_LEADS = temp?.NEW_LEADS + 1;
            }
        });
        setLeadStatusObj(temp);
    };

    //   getting data month wise
    const getDataMonth = (leads) => {
        console.log("data", leads);



        let temp = [
            ["Name", "Interested"],
            ["Week 1", 0],
            ["Week 2", 0],
            ["Week 3", 0],
            ["Week 4", 0],
        ];
        let tempCont = [
            ["Name", "Contacted"],
            ["Week 1", 0],
            ["Week 2", 0],
            ["Week 3", 0],
            ["Week 4", 0],
        ];
        let tempWon = [
            ["Name", "Won"],
            ["Week 1", 0],
            ["Week 2", 0],
            ["Week 3", 0],
            ["Week 4", 0],
        ];
        let tempTotal = [
            ["Name", "Total Response"],
            ["Week 1", 0],
            ["Week 2", 0],
            ["Week 3", 0],
            ["Week 4", 0],
        ];


        leads.forEach((o) => {
            let date = new Date(o?.createdAt);
            let startDate = 1;
            let dateSpecific = date.getDate();
            let rem = dateSpecific - startDate;
            // interested
            if (o?.leadStatus == 3 && !o?.isProjectCreated) {
                if (dateSpecific <= 7) {
                    temp[1][1] = temp[1][1] + 1;
                } else if (dateSpecific > 7 && dateSpecific <= 15) {
                    temp[2][1] = temp[2][1] + 1;
                } else if (dateSpecific > 15 && dateSpecific <= 22) {
                    temp[3][1] = temp[3][1] + 1;
                }
                else {
                    temp[4][1] = temp[4][1] + 1;
                }
            }
            //contacted
            if (o?.leadUpdates?.length > 0 && !o?.isProjectCreated) {
                if (dateSpecific <= 7) {
                    tempCont[1][1] = tempCont[1][1] + 1;
                } else if (dateSpecific > 7 && dateSpecific <= 15) {
                    tempCont[2][1] = tempCont[2][1] + 1;
                } else if (dateSpecific > 15 && dateSpecific <= 22) {
                    tempCont[3][1] = tempCont[3][1] + 1;
                }
                else {
                    tempCont[4][1] = tempCont[4][1] + 1;
                }
            }
            //won


            // if (o?.isConvertedToProject) {

            //   console.log(o?.isConvertedToProject,"converted project")
            //   if (dateSpecific <=7) {
            //     tempWon[1][1] = tempWon[1][1] + 1;
            //   } else if (dateSpecific > 7 && dateSpecific <= 15) {
            //     tempWon[2][1] = tempWon[2][1] + 1;
            //   }else if(dateSpecific > 15 && dateSpecific <= 22){
            //     tempWon[3][1] = tempWon[3][1] + 1;
            //   }
            //    else {
            //     tempWon[4][1] = tempWon[4][1] + 1;
            //   }
            // }

            console.log(o?.isProjectCreated, "converted project")
            if (o?.isProjectCreated) {

                if (dateSpecific <= 7) {
                    tempWon[1][1] = tempWon[1][1] + 1;
                } else if (dateSpecific > 7 && dateSpecific <= 15) {
                    tempWon[2][1] = tempWon[2][1] + 1;
                } else if (dateSpecific > 15 && dateSpecific <= 22) {
                    tempWon[3][1] = tempWon[3][1] + 1;
                }
                else {
                    tempWon[4][1] = tempWon[4][1] + 1;
                }
            }



            // total response

            if (o?.leadStatus != 11) {
                if (dateSpecific <= 7) {
                    tempTotal[1][1] = tempTotal[1][1] + 1;
                } else if (dateSpecific > 7 && dateSpecific <= 15) {
                    tempTotal[2][1] = tempTotal[2][1] + 1;
                } else if (dateSpecific > 15 && dateSpecific <= 22) {
                    tempTotal[3][1] = tempTotal[3][1] + 1;
                }
                else {
                    tempTotal[4][1] = tempTotal[4][1] + 1;
                }
            }
        });


        setTotalLeads(leads?.length)
        setMonthDataContacted(tempCont);
        setMonthDataInterested(temp);
        console.log(tempWon, "won data")
        setMonthDataWon(tempWon);
        setMonthDataTotalResponse(tempTotal);
    };
    const data = {
        datasets: [
            {
                data: [
                    leadStatusObj?.FOLLOW_UP,
                    leadStatusObj?.INTERESTED,
                    leadStatusObj?.NOT_INTERESTED,
                    leadStatusObj?.NO_RESPONSE,

                ],
                backgroundColor: ["#BF5AF8", "#5230C2", "#63D6EB", "#0079FF"],
            },
        ],
    };

    const getAnalyticsData = async () => {

        let todayDate = getDateString(new Date());
        if (selectedPeriod === "this week") {
            const currentDate = new Date();
            const newDate = getDateString(addOneDay(currentDate));
            const currentDayOfWeek = currentDate.getDay();
            const offset = currentDayOfWeek > 0 ? -currentDayOfWeek + 1 : -7;
            currentDate.setDate(currentDate.getDate() + offset);
            let firstDateWeek = getDateString(currentDate);
            setStartDate(firstDateWeek);
            setEndDate(todayDate);
            const res = await getReq(
                `${constants.CRM_API}/user/newLeadsData?pageNo=0&startDate=${firstDateWeek}&endDate=${newDate}`,
                {},
                { Authorization: `Bearer ${getToken()}` }
            );
            setAnalyticsData(res?.data?.data?.data);
            getDataWeek(res?.data?.data?.data);
            setupLeadStatusObj(res?.data?.data?.data);
        } else if (selectedPeriod === "this month") {
            const newDate = addOneDay(new Date());
            let firstDateMonth = new Date();
            firstDateMonth.setDate(1);
            firstDateMonth = getDateString(firstDateMonth);
            setStartDate(firstDateMonth);
            setEndDate(todayDate);
            const res = await getReq(
                `${constants.CRM_API}/user/newLeadsData?pageNo=0&startDate=${firstDateMonth}&endDate=${newDate}`,
                {},
                { Authorization: `Bearer ${getToken()}` }
            );
            setAnalyticsData(res?.data?.data?.data);
            console.log("thismonthdata", res?.data?.data?.data,)
            getDataMonth(res?.data?.data?.data);
            setupLeadStatusObj(res?.data?.data?.data);
        } else if (selectedPeriod === "this year") {
            const newDate = addOneDay(new Date());
            let firstDateYear = new Date();
            firstDateYear.setDate(1);
            firstDateYear.setMonth(0);
            firstDateYear = getDateString(firstDateYear);
            setStartDate(firstDateYear);
            setEndDate(todayDate);
            const res = await getReq(
                `${constants.CRM_API}/user/newLeadsData?pageNo=0&startDate=${firstDateYear}&endDate=${getDateString(newDate)}`,
                {},
                { Authorization: `Bearer ${getToken()}` }
            );
            setAnalyticsData(res?.data?.data?.data);
            getDataYear(res?.data?.data?.data);
            setupLeadStatusObj(res?.data?.data?.data);
        } else if (selectedPeriod == "this quarter") {
            let quarterStartMonth = ''
            const newDate = addOneDay(new Date());
            let firstDateQuarter = new Date();
            let currMonth = firstDateQuarter.getMonth();
            if (currMonth + 1 == 1 || currMonth + 1 == 2 || currMonth + 1 == 3) {
                quarterStartMonth = 1
            } else if (currMonth + 1 == 4 || currMonth + 1 == 5 || currMonth + 1 == 6) {
                quarterStartMonth = 4
            } else if (currMonth + 1 == 7 || currMonth + 1 == 8 || currMonth + 1 == 9) {
                quarterStartMonth = 7
            } else {
                quarterStartMonth = 10
            }
            firstDateQuarter.setDate(1);
            firstDateQuarter.setMonth(quarterStartMonth - 1);
            firstDateQuarter = getDateString(firstDateQuarter);
            setStartDate(firstDateQuarter);
            setEndDate(todayDate);

            const res = await getReq(
                `${constants.CRM_API}/user/newLeadsData?pageNo=0&startDate=${firstDateQuarter}&endDate=${getDateString(newDate)}`,
                {},
                { Authorization: `Bearer ${getToken()}` }
            );
            setAnalyticsData(res?.data?.data?.data);
            getDataQuarter(res?.data?.data?.data);
            setupLeadStatusObj(res?.data?.data?.data);
        } else if (selectedPeriod === "last week") {
            const currentDate = new Date();
            const currentDayOfWeek = currentDate.getDay();
            const offset = currentDayOfWeek > 0 ? -currentDayOfWeek + 1 : -7;
            currentDate.setDate(currentDate.getDate() + offset);
            currentDate.setDate(currentDate.getDate() - 1);
            let lastDateWeek = getDateString(currentDate);
            currentDate.setDate(currentDate.getDate() - 7);
            let firstDateWeek = getDateString(currentDate);
            setStartDate(firstDateWeek);
            setEndDate(lastDateWeek);
            const res = await getReq(
                `${constants.CRM_API}/user/newLeadsData?pageNo=0&startDate=${firstDateWeek}&endDate=${lastDateWeek}`,
                {},
                { Authorization: `Bearer ${getToken()}` }
            );
            setAnalyticsData(res?.data?.data?.data);
            getDataWeek(res?.data?.data?.data);
            setupLeadStatusObj(res?.data?.data?.data);
        } else if (selectedPeriod === "last month") {
            let firstDateMonth = new Date();
            let lastDateMonth = new Date();
            firstDateMonth.setDate(1);
            firstDateMonth.setMonth(firstDateMonth.getMonth() - 1);
            lastDateMonth.setMonth(lastDateMonth.getMonth() - 1);
            lastDateMonth.setDate(getDaysInMonth(lastDateMonth.getMonth() + 1, lastDateMonth.getFullYear()));
            setStartDate(firstDateMonth);
            setEndDate(lastDateMonth);
            const res = await getReq(
                `${constants.CRM_API}/user/newLeadsData?pageNo=0&startDate=${getDateString(firstDateMonth)}&endDate=${getDateString(lastDateMonth)}`,
                {},
                { Authorization: `Bearer ${getToken()}` }
            );
            setAnalyticsData(res?.data?.data?.data);
            // console.log(res?.data?.data?.data,"last month data")
            getDataMonth(res?.data?.data?.data);
            setupLeadStatusObj(res?.data?.data?.data);
        } else if (selectedPeriod === "last quarter") {
            let quarterStartMonth = ''
            let firstDateQuarter = new Date();
            let currMonth = firstDateQuarter.getMonth();
            if (currMonth + 1 == 1 || currMonth + 1 == 2 || currMonth + 1 == 3) {
                quarterStartMonth = 1
            } else if (currMonth + 1 == 4 || currMonth + 1 == 5 || currMonth + 1 == 6) {
                quarterStartMonth = 1
            } else if (currMonth + 1 == 7 || currMonth + 1 == 8 || currMonth + 1 == 9) {
                quarterStartMonth = 4
            } else {
                quarterStartMonth = 7
            }
            firstDateQuarter.setDate(1);
            firstDateQuarter.setMonth(quarterStartMonth - 1);
            // firstDateQuarter.setDate(firstDateQuarter.getDate() - 1);
            let firstDateQuarterstr = getDateString(firstDateQuarter);
            let lastDatequarter = new Date()
            lastDatequarter.setMonth(quarterStartMonth + 1)
            const noOfDays = getDaysInMonth(lastDatequarter.getMonth(), lastDatequarter.getFullYear())
            lastDatequarter.setDate(noOfDays)
            let lastDateQuarterstr = getDateString(lastDatequarter)
            // firstDateQuarter.setDate(firstDateQuarter.getDate() - 89);
            let firstDateLastQuarter = getDateString(firstDateQuarter);
            setStartDate(firstDateQuarterstr);
            setEndDate(lastDateQuarterstr);
            const res = await getReq(
                `${constants.CRM_API}/user/newLeadsData?pageNo=0&startDate=${firstDateQuarterstr}&endDate=${lastDateQuarterstr}`,
                {},
                { Authorization: `Bearer ${getToken()}` }
            );
            setAnalyticsData(res?.data?.data?.data);
            getDataLastQuarter(res?.data?.data?.data);
            setupLeadStatusObj(res?.data?.data?.data);
        } else if (selectedPeriod === "last year") {
            let firstDateYear = new Date();
            firstDateYear.setDate(1);
            firstDateYear.setMonth(0);
            firstDateYear.setFullYear(firstDateYear.getFullYear() - 1);
            let firstDtLastYear = getDateString(firstDateYear);
            firstDateYear.setDate(firstDateYear.getDate() + 364);
            let lastDateLastYear = getDateString(firstDateYear);
            setStartDate(firstDtLastYear);
            setEndDate(lastDateLastYear);
            const res = await getReq(
                `${constants.CRM_API}/user/newLeadsData?pageNo=0&startDate=${firstDtLastYear}&endDate=${lastDateLastYear}`,
                {},
                { Authorization: `Bearer ${getToken()}` }
            );
            setAnalyticsData(res?.data?.data?.data);
            getDataYear(res?.data?.data?.data);
            setupLeadStatusObj(res?.data?.data?.data);
        } else if (selectedPeriod === "custom1") {
            const res = await getReq(
                `${constants.CRM_API}/user/leadsAnalytics?startDate=${startDate}&endDate=${endDate}&userId=${localStorage.getItem('userId')}`,
                {},
                { Authorization: `Bearer ${getToken()}` }
            );
            setAnalyticsData(res?.data?.data);
            getDataCustom1(res?.data?.data);
            setupLeadStatusObj(res?.data?.data);
        } else {
            const res = await getReq(
                `${constants.CRM_API}/user/leadsAnalytics?startDate=${startDate}&endDate=${endDate}&userId=${localStorage.getItem('userId')}`,
                {},
                { Authorization: `Bearer ${getToken()}` }
            );
            setAnalyticsData(res?.data?.data);
            getDataCustom(res?.data?.data);
            setupLeadStatusObj(res?.data?.data);
        }
    };


    const getLeadAnalytics = async () => {
        const res = await postTReq(
            `${constants.CRM_API
            }/user/lead-analytics?userId=${localStorage.getItem(
                "userId"
            )}&filter=${selectedPeriod == "this week" ? 1 : selectedPeriod == "this month" ? 2 : selectedPeriod == "this quarter" ? 3 :
                selectedPeriod == "this year" ? 4 : selectedPeriod == "last week" ? 5 : selectedPeriod == "last month" ? 6 : selectedPeriod == "last year" ? 7 : ""
            }`, {
            userIds: selectedIds
        }
        );
        if (res && !res.error) {
            setStoreAnalytics(res?.data?.data)
            console.log(res, "respoo")
        } else {
            console.log(res.error);
        }
    };
    const getTodayLeadAnalytics = async () => {
        const res = await postTReq(
            `${constants.CRM_API
            }/user/todays-lead-analytics?userId=${localStorage.getItem(
                "userId"
            )}`, {
            userIds: selectedIds
        }
        );
        if (res && !res.error) {
            setStoreDailyAnalytics(res?.data?.data)
            console.log(res?.data?.data, "respoonse")
        } else {
            console.log(res.error);
        }
    };

    const getTodayLeadLogs = async () => {
        const res = await getReq(
            `${constants.CRM_API
            }/user/todays-lead-logs?userId=${localStorage.getItem(
                "userId"
            )}`
        );
        if (res && !res.error) {
            setStoreDailyLogs(res?.data?.data)
            console.log(res?.data?.data, "respoNew")
        } else {
            console.log(res.error);
        }
    };

    const getLeadSource = async () => {
        const res = await postTReq(
            `${constants.CRM_API
            }/user/lead-source-analytics?userId=${localStorage.getItem(
                "userId"
            )}&filter=${selectedPeriod == "this week" ? 1 : selectedPeriod == "this month" ? 2 : selectedPeriod == "this quarter" ? 3 :
                selectedPeriod == "this year" ? 4 : selectedPeriod == "last week" ? 5 : selectedPeriod == "last month" ? 6 : selectedPeriod == "last year" ? 7 : ""
            }`, {
            userIds: selectedIds
        }
        );
        if (res && !res.error) {
            setStoreSources(res?.data?.data)
            console.log(res?.data?.data, "resdrNew")
        } else {
            console.log(res.error);
        }
    };

    const getTopSales = async () => {
        const res = await postTReq(
            `${constants.CRM_API
            }/user/top-sales-rep?userId=${localStorage.getItem(
                "userId"
            )}&filter=${selectedPeriod == "this week" ? 1 : selectedPeriod == "this month" ? 2 : selectedPeriod == "this quarter" ? 3 :
                selectedPeriod == "this year" ? 4 : selectedPeriod == "last week" ? 5 : selectedPeriod == "last month" ? 6 : selectedPeriod == "last year" ? 7 : ""
            }`
        );
        if (res && !res.error) {
            setStoreSales(res?.data?.data)
            console.log(res?.data?.data, "rewdaNew")
        } else {
            console.log(res.error);
        }
    };
    const getCustomer = async () => {
        const res = await postTReq(
            `${constants.CRM_API
            }/user/customer-conversion-analytics?userId=${localStorage.getItem(
                "userId"
            )}&filter=${selectedPeriod == "this week" ? 1 : selectedPeriod == "this month" ? 2 : selectedPeriod == "this quarter" ? 3 :
                selectedPeriod == "this year" ? 4 : selectedPeriod == "last week" ? 5 : selectedPeriod == "last month" ? 6 : selectedPeriod == "last year" ? 7 : ""
            }`, {
            userIds: selectedIds
        }
        );
        if (res && !res.error) {
            console.log(res?.data?.data, "SDSNew")
            setStoreConversion(res?.data?.data)
        } else {
            console.log(res.error);
        }
    };
    const [tickPlacement, setTickPlacement] = React.useState('middle');
    const [tickLabelPlacement, setTickLabelPlacement] = React.useState('middle');

    const getTotalDeal = async () => {
        const res = await postTReq(
            `${constants.CRM_API
            }/user/total-deal-value?userId=${localStorage.getItem(
                "userId"
            )}&filter=${selectedPeriod == "this week" ? 1 : selectedPeriod == "this month" ? 2 : selectedPeriod == "this quarter" ? 3 :
                selectedPeriod == "this year" ? 4 : selectedPeriod == "last week" ? 5 : selectedPeriod == "last month" ? 6 : selectedPeriod == "last year" ? 7 : ""
            }`, {
            userIds: selectedIds
        }
        );
        if (res && !res.error) {
            console.log(res?.data?.data, "ertyu")
            setStoreTotalDeal(res?.data?.data)
        } else {
            console.log(res.error);
        }
    };

    const getAverageDeal = async () => {
        const res = await postTReq(
            `${constants.CRM_API
            }/user/average-deal-value?userId=${localStorage.getItem(
                "userId"
            )}&filter=${selectedPeriod == "this week" ? 1 : selectedPeriod == "this month" ? 2 : selectedPeriod == "this quarter" ? 3 :
                selectedPeriod == "this year" ? 4 : selectedPeriod == "last week" ? 5 : selectedPeriod == "last month" ? 6 : selectedPeriod == "last year" ? 7 : ""
            }`, {
            userIds: selectedIds
        }
        );
        if (res && !res.error) {
            console.log(res?.data?.data, "SDSfgwshew")
            setStoreAverage(res?.data?.data)
        } else {
            console.log(res.error);
        }
    };
    const [leadsStageStore, setLeadsStageStore] = useState();
    const stagesNumbers = leadsStageStore?.filter((dats) => dats?.stageName !== (undefined || null))?.map(sale => sale?.totalLeads);
    const dataStage = {
        datasets: [
            {
                data: stagesNumbers,
                backgroundColor: ["#BF5AF8", "#5230C2", "#63D6EB", "#0079FF","rgb(2, 131, 145)","rgb(255, 143, 0)","rgb(230, 92, 25)","rgb(38, 53, 93)"]
            },
        ],
    };
    const getLeadsStageData = async () => {
        const res = await postTReq(
            `${constants.CRM_API
            }/user/total-leads-by-stage?userId=${localStorage.getItem(
                "userId"
            )}&filter=${selectedPeriod == "this week" ? 1 : selectedPeriod == "this month" ? 2 : selectedPeriod == "this quarter" ? 3 :
                selectedPeriod == "this year" ? 4 : selectedPeriod == "last week" ? 5 : selectedPeriod == "last month" ? 6 : selectedPeriod == "last year" ? 7 : ""
            }`, {
            userIds: selectedIds
        }
        );
        if (res && !res.error) {
            console.log(res?.data?.data, "SDSfgwshew")
            setLeadsStageStore(res?.data?.data)
        } else {
            console.log(res.error);
        }
    };
    const salesNumbers = storeSales?.map(sale => sale?.numberOfSales);
    console.log(salesNumbers, "weanoi")
    // Define the dataSource object
    const dataSaless = {
        datasets: [
            {
                data: salesNumbers,
                backgroundColor: ["#084BF7", "#396FF9", "#6B93FA", "#9CB7FC", "#CEDBFD"],
            },
        ],
    };
    const leadSourcing = storeSources?.map(sale => sale?.count);
    const dataSources = {
        datasets: [
            {
                data: leadSourcing,
                backgroundColor: ["#3498DB", "#8848A3", "#5D45DB", "#A698EB", "#D3CCF5", "#ECE9FF","#1A6CDD","#48b0ff"],
            },
        ],
    };
    const totalCount = storeSources?.reduce((acc, item) => acc + item.count, 0);
    const storeAvgMax = storeSources?.reduce((acc, item) => acc + item.avgTimeToClose, 0);
    const storeConversionMax = storeConversion?.reduce((acc, item) => acc + item.customerCount, 0);

    const getLeadStatusText = (leadStatus) => {
        switch (leadStatus) {
            case 1:
                return "New Leads";
            case 2:
                return "Follow Up";
            case 3:
                return "Interested";
            case 5:
                return "Not Interested";
            case 6:
                return "No Response";
            default:
                return "Unknown";
        }
    };

    useEffect(() => {
        getCustomer();
        getTopSales();
        getLeadsStageData();
        getAverageDeal();
        getTotalDeal();
        getLeadSource();
        getLeadAnalytics();
        getTodayLeadLogs();
        getTodayLeadAnalytics();
    }, [selectedPeriod, selectedIds])
    useEffect(() => {
        getAnalyticsData();
    }, [selectedPeriod]);

    useEffect(() => {
        getAnalyticsData();
    }, [selectedPeriod, value]);

    const xLabels = storeTotalDeal.map(item => item.stageName);
    const truncateLabel = (label, maxLength) => {
        return label.length > maxLength ? label.slice(0, maxLength) + "..." : label;
      };
      
      const truncatedLabels = xLabels.map(label => truncateLabel(label, 10));  // Truncate to a maximum length of 15 characters
      
      
    const uData = storeTotalDeal.map(item => item.totalDealValue);
    console.log(uData,xLabels,truncatedLabels, "wedew")
    // Transform the data to the required format
    const dataTotal = storeTotalDeal && storeTotalDeal?.map(item => ({
        text: item.stageName,
        value: item.totalDealValue,
    }));

    console.log(storeTotalDeal, "dfghyjuk");

    let uDataLine = [];
    let pDataLine = [];
    let xLabelslineSet = new Set();

    storeAverage && storeAverage?.forEach(stage => {
        stage.monthlyData.forEach(monthly => {
            const label = `${monthly.month} ${monthly.year}`;
            xLabelslineSet.add(label);

            if (stage.stageName === 'Won') {
                uDataLine.push(monthly.averageDealValue);
            } else if (stage.stageName === 'Lost') {
                pDataLine.push(monthly.averageDealValue);
            }
        });
    });

    // Convert the set to an array and sort it by month and year
    let xLabelsline = Array.from(xLabelslineSet).sort((a, b) => {
        const [monthA, yearA] = a.split(' ');
        const [monthB, yearB] = b.split(' ');

        const dateA = new Date(`${monthA} 1, ${yearA}`);
        const dateB = new Date(`${monthB} 1, ${yearB}`);

        return dateA - dateB;
    });

    // Create a map to hold the month and year as key and their index in xLabelsline as value
    let xLabelMap = new Map();
    xLabelsline.forEach((label, index) => {
        xLabelMap.set(label, index);
    });

    // Initialize uDataLine and pDataLine with zeros
    uDataLine = new Array(xLabelsline.length).fill(0);
    pDataLine = new Array(xLabelsline.length).fill(0);

    // Populate uDataLine and pDataLine at the correct index based on the sorted xLabelsline
    storeAverage && storeAverage?.forEach(stage => {
        stage.monthlyData.forEach(monthly => {
            const label = `${monthly.month} ${monthly.year}`;
            const index = xLabelMap.get(label);

            if (stage.stageName === 'Won') {
                uDataLine[index] = monthly.averageDealValue;
            } else if (stage.stageName === 'Lost') {
                pDataLine[index] = monthly.averageDealValue;
            }
        });
    });
    const totalLeadsStoring = leadsStageStore && leadsStageStore?.reduce((total, stage) => total + stage.totalLeads, 0);

    console.log(uDataLine, 'uDataLine'); // [0, 210]
    console.log(pDataLine, 'pDataLine'); // [0, 4525346]
    console.log(xLabelsline, 'xLabelsline');

    return (
        <div >
            {/* className='AnalyticsMain' */}
            <div style={{ display: "flex" }}>
                {console.log(storeAnalytics, "evxwv")}
                <div>
                    <div style={{ display: "flex", gap: "12px", marginRight: "26px" }}>
                        <div className='boxess'>
                            <div className='totCalls'>TOTAL CALLS</div>
                            <div className='boldNm'>{storeAnalytics?.summaryCurrent?.totalCalls}</div>
                            <div className='greyLinee'></div>
                            <div className='lasdivest'>
                                <img src={TrendUp} />
                                <span className='greenNum'>{storeAnalytics?.summaryCurrent?.totalCalls - storeAnalytics?.summaryPrevious?.totalCalls}</span>
                                <span className='reas'>Added {selectedPeriod}</span>
                            </div>
                        </div>
                        <div className='boxess'>
                            <div className='totCallsgREEN'>TOTAL CONNECTED</div>
                            <div className='boldNm'>{storeAnalytics?.summaryCurrent?.totalConnectedCalls}</div>
                            <div className='greyLinee'></div>
                            <div className='lasdivest'>
                                <img src={TrendUpBlue} />
                                <span className='greenBlNum'>{((storeAnalytics?.summaryCurrent?.totalCalls) - (storeAnalytics?.summaryPrevious?.totalCalls)) / 100}%</span>
                                <span className='reas'> Done vs {selectedPeriod}</span>
                            </div>
                        </div>
                        <div className='boxess'>
                            <div className='totCallsBlu'>INTERESTED</div>
                            <div className='boldNm'>{storeAnalytics?.summaryCurrent?.interested}</div>
                            <div className='greyLinee'></div>
                            <div className='lasdivest'>
                                <img src={TrendUp} />
                                <span className='greenNum'>{((storeAnalytics?.summaryCurrent?.interested) - (storeAnalytics?.summaryPrevious?.interested)) / 100}%</span>
                                <span className='reas'>Done vs {selectedPeriod}</span>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex", gap: "12px", marginRight: "26px", marginTop: "12px" }}>
                        <div className='boxess'>
                            <div className='totCallsdbLUE'>FOLLOW UP</div>
                            <div className='boldNm'>{storeAnalytics?.summaryCurrent?.followUp}</div>
                            <div className='greyLinee'></div>
                            <div className='lasdivest'>
                                <img src={TrendUpdBlue} />
                                <span className='blueDNum'>{storeAnalytics?.summaryCurrent?.followUp - storeAnalytics?.summaryPrevious?.followUp}</span>
                                <span className='reas'>Started {selectedPeriod}</span>
                            </div>
                        </div>
                        <div className='boxess'>
                            <div className='totResp'>NO RESPONSE</div>
                            <div className='boldNm'>{storeAnalytics?.summaryCurrent?.noResponse}</div>
                            <div className='greyLinee'></div>
                            <div className='lasdivest'>
                                <img src={TrendUpBlue} />
                                <span className='greenBlNum'>{((storeAnalytics?.summaryCurrent?.noResponse) - (storeAnalytics?.summaryPrevious?.noResponse)) / 100}%</span>
                                <span className='reas'> Done vs {selectedPeriod}</span>
                            </div>
                        </div>
                        <div className='boxess'>
                            <div className='totCallsRed'>Not Interested</div>
                            <div className='boldNm'>{storeAnalytics?.summaryCurrent?.notInterested}</div>
                            <div className='greyLinee'></div>
                            <div className='lasdivest'>
                                <img src={TrendUpRed} />
                                <span className='redNum'>{((storeAnalytics?.summaryCurrent?.notInterested) - (storeAnalytics?.summaryPrevious?.notInterested)) / 100}% </span>
                                <span className='reas'>More vs {selectedPeriod}</span>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex", gap: "12px", marginRight: "26px", marginTop: "12px" }}>
                        <div className='boxess'>
                            <div className='totCallsRed'>Repeated no response</div>
                            <div className='boldNm'>{storeAnalytics?.summaryCurrent?.repeatedNoResponse}</div>
                            <div className='greyLinee'></div>
                            <div className='lasdivest'>
                                <img src={TrendUpRed} />
                                <span className='redNum'>{((storeAnalytics?.summaryCurrent?.repeatedNoResponse) - (storeAnalytics?.summaryPrevious?.repeatedNoResponse)) / 100}%</span>
                                <span className='reas'>More vs {selectedPeriod}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <Card style={{ width: "279px", height: "384px", marginRight: "26px" }}>
                        {(leadsStageStore && totalLeadsStoring != 0) ?
                            <div>
                                <div className="cardHeader">
                                    <h5 className="cardHeading">Leads</h5>
                                </div>
                                <div className="cardBody">
                                    <div style={{ marginLeft: "40px", position: "relative" }}>
                                        <DonutChart data={dataStage} />
                                        <div className="totalLeads">
                                            <div style={{ fontWeight: "500", fontSize: "14px" }}>
                                                Total Leads
                                            </div>
                                            <h1 style={{ fontWeight: "700", fontSize: "26.67px" }}>

                                                {leadsStageStore && totalLeadsStoring}
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ paddingLeft: "43px", gap: "13px", flexWrap: "wrap", display: "flex" }}>
                                    {/* <div style={{ display: "flex", gap: "10px" }}>
                                <div style={{ display: "flex", gap: "8px" }}>
                                    <img src={purpRound} />
                                    <span className='folloo'>Follow up</span>
                                </div>
                                <div style={{ display: "flex", gap: "8px" }}>
                                    <img src={blueRound} />
                                    <span className='folloo'>Interested</span>
                                </div>
                                <div style={{ display: "flex", gap: "8px" }}>
                                    <img src={skyRound} />
                                    <span className='folloo'>No Response</span>
                                </div>
                            </div>
                            <div style={{ display: "flex", gap: "8px", paddingTop: "10px" }}>
                                <img src={lightRound} />
                                <span className='folloo'>Not Interested</span>
                            </div> */}
                                    {leadsStageStore ?.filter((dats) => dats?.stageName !== (undefined || null))?.map((newData, index) => {
                                        return (
                                            <div style={{ display: "flex", gap: "12px" }}>
                                                {console.log(newData,"chjw")}
                                                <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                                    <img style={{ width: "9px", height: "9px" }} src={imagesStage[index % imagesStage.length]} alt="Sales Rep" />
                                                    <div style={{ textWrap: "noWrap", fontSize: "12px" }}>{newData?.stageName }</div>
                                                </div>
                                            </div>

                                        )
                                    })}
                                </div>
                            </div>
                            :
                            <div style={{ display: "flex", gap: "5px", flexDirection: "column", paddingTop: "50px", justifyContent: "center", alignItems: "center" }}>
                                <img src={zeroBar} />
                                <div className='noDealss' style={{ fontWeight: "600", fontSize: "20px" }}>No Lead Data Available</div>
                                <div className='desccdeal'>It looks like you don’t have any leads yet</div>
                            </div>
                        }
                    </Card>
                </div>
                <div>
                    <Card style={{ width: "298px", height: "384px" }}>
                        <div>
                            <div className='todd'>Today’s Activity</div>
                            <div style={{ paddingTop: "19px", flexDirection: "column", paddingLeft: "24px", display: "flex", paddingRight: "24px", gap: "28px" }}>
                                <div style={{ display: "flex", justifyContent: "space-between", paddingtTop: '9px', paddingBottom: "9px" }}>
                                    <div className='calss'>Total Calls: </div>
                                    <div className='desci'>{storeDailyAnalytics?.totalCalls ? storeDailyAnalytics?.totalCalls : "-"}</div>
                                </div>
                                <div style={{ display: "flex", justifyContent: "space-between", paddingtTop: '9px', paddingBottom: "9px" }}>
                                    <div className='calss'>Not Connected calls : </div>
                                    <div className='desci'>{storeDailyAnalytics?.notConnectedCalls ? storeDailyAnalytics?.notConnectedCalls : "-"}</div>
                                </div>
                                <div style={{ display: "flex", justifyContent: "space-between", paddingtTop: '9px', paddingBottom: "9px" }}>
                                    <div className='calss'>Avg Gaps between calls : </div>
                                    <div className='desci'> {storeDailyAnalytics?.averageGapBetweenCalls
                                        ? `${Math.round(storeDailyAnalytics.averageGapBetweenCalls)} min`
                                        : "-"}</div>
                                </div>
                                <div style={{ display: "flex", justifyContent: "space-between", paddingtTop: '9px', paddingBottom: "9px" }}>
                                    <div className='calss'>Total Interested : </div>
                                    <div className='desci'>{storeDailyAnalytics?.interestedLeads ? storeDailyAnalytics?.interestedLeads : "-"}</div>
                                </div>
                                <div style={{ display: "flex", justifyContent: "space-between", paddingtTop: '9px', paddingBottom: "9px" }}>
                                    <div className='calss'>Interested Cases Not <br />
                                        contacted since {">7 Days"}</div>
                                    <div className='desci'>{storeDailyAnalytics?.interestedNotContactedFor7Days ? storeDailyAnalytics?.interestedNotContactedFor7Days : "-"}</div>
                                </div>
                            </div>
                            <div className='setaii' onClick={() => { setShowCanvas(true) }}> Details view</div>
                        </div>
                    </Card>
                </div>

            </div>
            <div>
                <Card style={{ width: "1323px", marginTop: "28px", height: "425px", borderRadius: "8px", padding: "15px 40px" }}>
                    {storeAverage?.length > 0 ? <div>
                        <div className='avgg'>Average Deal Value( ₹) per month</div>
                        <div className='fromi'>From  {getDateStr2(startDate)}-{getDateStr2(endDate)}</div>
                        <div>
                            <LineChart
                                width={571}
                                height={273}
                                series={[
                                    { data: pDataLine },
                                    { data: uDataLine },
                                ]}
                                xAxis={[{ scaleType: 'point', data: xLabelsline }]}
                            />
                        </div>
                        <div style={{ display: "flex", gap: "24px", alignItems: "center", justifyContent: "center" }}>
                            <div style={{ display: "flex", alignItems: "center", gap: "4px" }}><span style={{ height: "10px", width: "10px", color: "#02B2AF", borderRadius: "7px", background: "#02B2AF" }}></span><span /><span className='losty'>Lost</span></div>
                            {/* <div><img src={yellowRound} /><span className='losty'>Lost</span></div> */}
                            <div style={{ display: "flex", gap: "4px" }}><img src={lightRound} /><span className='losty'>Won</span></div>
                        </div>
                    </div>
                        :
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", paddingTop: "80px", gap: "24px" }}>
                            <img src={barChart} />
                            <div className='noDealss'>No Deal Value Data Available</div>
                            <div className='desccdeal'>It looks like you don’t have any deal value data yet</div>
                        </div>
                    }
                </Card>
            </div>
            <div style={{ display: "flex", marginTop: "28px", gap: "20px" }}>
                <Card style={{ width: "840px", height: "385px", borderRadius: "8px", padding: "28px 11px 28px 40px" }}>
                    {storeTotalDeal?.length > 0 ?
                        <div>
                            <div className='avgg'>Total Deal value ( ₹) per status</div>
                            <div className='fromi'>From  {getDateStr2(startDate)}-{getDateStr2(endDate)}</div>
                            <div>
                                <BarChart
                                    width={770}
                                    height={267}
                                    series={[
                                        { data: uData, id: 'uvId', stack: 'total' },
                                    ]}
                                    xAxis={[{ data: truncatedLabels, scaleType: 'band' }]}
                                />
                                {console.log(dataTotal, "gdhs")}
                                {/* <BarChart width={500} height={300} data={dataTotal}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="pv" fill="#8884d8" />
                                <Bar dataKey="uv" fill="#82ca9d" />
                            </BarChart> */}
                            </div>
                        </div>
                        :
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginTop: "70px", alignItems: "center", gap: "20px" }}>
                            <img src={barChart} />
                            <div className='noDealss'>No Deal Value Data Available</div>
                            <div className='desccdeal'>It looks like you don’t have any deal value data yet</div>
                        </div>}
                </Card>
                <Card style={{ width: "556px", height: "390px", borderRadius: "8px", padding: "26px 32px" }}>
                    {storeSources?.length > 0 ?
                        <div>
                            <div className='avgg'>Lead Source</div>
                            <div className='fromi'>From  {getDateStr2(startDate)}-{getDateStr2(endDate)}</div>
                            <div style={{ display: "flex" }}>
                                {console.log(storeSources, "fgyju")}
                                <div style={{ paddingTop: "32px" }}>
                                    {storeSources && storeSources?.map((sourceData, index) => {
                                        return (
                                            <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                                                {console.log(sourceData, "sdfgh")}
                                                <div style={{ display: "flex", gap: "19px" }}>
                                                    <img src={leadImages[index % leadImages.length]} />
                                                    <span>{sourceData?.leadSource}</span>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div>
                                    <div style={{ marginLeft: "40px", position: "relative" }}>
                                        <DonutChart data={dataSources} />
                                        <div className="totalLeads" style={{ left: "81px", top: "76px" }}>
                                            <h1 style={{ fontWeight: "700", fontSize: "26.67px" }}>

                                                {/* {analyticsData && analyticsData.length} */}
                                                {storeSources && totalCount}
                                            </h1>
                                            <div style={{ fontWeight: "500", fontSize: "14px" }}>
                                                Total
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div style={{ display: "flex", gap: "5px", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <img src={zeroBar} />
                            <div className='noDealss'>No Sales Data Available</div>
                            <div className='desccdeal'>It looks like you don’t have any sales performance data yet</div>
                        </div>
                    }
                </Card>
            </div>
            <div style={{ marginTop: "28px" }}>
                {console.log(storeSales, "storesaleee")}
                <Card style={{ padding: "28px 31px" }} >
                    {storeSales?.length > 0 ?
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div>
                                <div className='avgg'>Top 5 sales representative</div>
                                <div className='fromi' style={{ paddingBottom: "18px" }}>From  {getDateStr2(startDate)}-{getDateStr2(endDate)}</div>
                                <div style={{ marginBottom: "10px" }}>
                                    <DonutChart data={dataSaless} />
                                </div>
                                <div style={{ display: "flex", width: "366px", gap: "12px", flexWrap: "wrap" }}>
                                    {storeSales?.map((newData, index) => {
                                        return (
                                            <div style={{ display: "flex", gap: "12px" }}>
                                                <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                                    <img style={{ width: "9px", height: "9px" }} src={images[index % images.length]} alt="Sales Rep" />
                                                    <div style={{ textWrap: "noWrap", fontSize: "12px" }}>{newData?.salesRep}</div>
                                                </div>
                                            </div>

                                        )
                                    })}
                                </div>
                            </div>

                            <div>
                                <table>
                                    <thead>
                                        <tr style={{ display: "flex" }}>
                                            <th className="frstTh">Top 5 sales rep.</th>
                                            <th className="secondth">Number  of sales</th>
                                            <th className="secondth">Avg Sales</th>
                                            <th className="secondth">Total Sales</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {storeSales?.length > 0 ?
                                            <>
                                                {storeSales?.map((data) => {
                                                    return (
                                                        <tr style={{ display: "flex" }}>
                                                            <td className='frsttd'>{data?.salesRep}</td>
                                                            <td className='scndrd'>{data?.numberOfSales}</td>
                                                            <td className='scndrd'>{data?.avgSales ? `₹${data?.avgSales}` : "-"}
                                                            </td>
                                                            <td className='scndrd'>{data?.totalSales ? `₹${data?.totalSales}` : "-"}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </>
                                            :
                                            <tr style={{ display: "flex" }}>
                                                <td className='frsttd'>-</td>
                                                <td className='scndrd'>-</td>
                                                <td className='scndrd'>-
                                                </td>
                                                <td className='scndrd'>-</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        :
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: "20px", marginTop: "69px", alignItems: "center" }}>
                            <img src={barChart} />
                            <div className='noDealss'>No Sales Data Available</div>
                            <div className='desccdeal'>It looks like you don’t have any sales performance data yet</div>
                        </div>
                    }
                </Card>
            </div>
            <div>
                <Card style={{ padding: "28px 31px", marginBottom: "150px", width: "695px", marginTop: "28px" }}>
                    {storeConversion?.filter((data) => data?.city !== "")?.length > 0 ?
                        <div>
                            <div style={{
                                color: "#212121",
                                fontSize: "20px",
                                fontWeight: 500,
                                paddingBottom: "26px"
                            }}>Customer Conversion Analysis</div>
                            <div>
                                <div style={{ display: "flex", gap: "20px", paddingBottom: "12px" }}>
                                    <div className="tabliHead" style={{ width: "128px", textAlign: "right" }}>Lead City</div>
                                    <div className="tabliHead" style={{ width: "303px", textAlign: "left" }}>Customer Count</div>
                                    <div className="tabliHead" style={{ width: "303px", textAlign: "left" }}>Avg. time to close #Days</div>
                                </div>
                                <div>
                                    {storeConversion?.filter((data) => data?.city !== "")?.map((convData) => {
                                        return (
                                            <div style={{ paddingBottom: "13px", display: "flex", gap: "20px" }}>
                                                <div className="inner" style={{ width: "134px", textAlign: "right" }}>{convData?.city}</div>
                                                <div className="nummm">{convData?.customerCount}</div>
                                                <div style={{ width: "303px", textAlign: "left" }}>
                                                    <ProgressBar now={convData?.customerCount} max={storeConversionMax} />
                                                </div>
                                                <div className="nummm">{convData?.avgTimeToClose}</div>
                                                <div style={{ width: "303px", textAlign: "left" }}>
                                                    <ProgressBar now={convData?.avgTimeToClose} max={storeAvgMax} />
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        :
                        <div style={{ display: "flex", flexDirection: "column", gap: "16px", justifyContent: "center", marginTop: "55px", alignItems: "center" }}>
                            <img src={barChart} />
                            <div className='noDealss'>No Conversion Data Available</div>
                            <div className='desccdeal'>It looks like you don’t have any customer conversion data yet</div>
                        </div>
                    }
                </Card>
            </div>
            <Offcanvas
                show={showCanvas}
                placement="end"
            >
                <Offcanvas.Body className="offCanvasBody">
                    <>
                        <div>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div className='todaying'>Today’s Activity</div>
                                <img style={{ cursor: "pointer" }} onClick={() => { setShowCanvas(false); }} src={CloseGrey} />
                            </div>
                            <div className='inputsearch'>
                                <img src={MagnifyingGrey} />
                                <input className="searching" placeholder='Search' />
                            </div>
                            <div>
                                {storeDailyLogs?.length <= 0 ?
                                    <div style={{ display: "flex", marginTop: "5rem", alignItems: 'center', flexDirection: "column" }}>
                                        <img src={leadZero} />
                                        <div style={{ fontSize: "18px", fontWeight: "400", color: "#454545" }}>No Activity Today</div>
                                    </div>
                                    : storeDailyLogs?.map((data) => {
                                        return (
                                            <div style={{ display: "flex", gap: "14px", alignItems: "center", paddingTop: "16px", paddingBottom: "16px", borderBottom: "1px solid #EDEDED" }}>
                                                <div style={{ background: "#E7F3FE", borderRadius: "50px", height: "26px", width: "26px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                    <img style={{ height: "18px", width: "18px" }} src={FolderSimple} />
                                                </div>
                                                <div style={{ gap: "10px", display: "flex", flexDirection: "column" }}>
                                                    <div className='normalTextr'> <span className='boding'></span>{data?.logText}<span className='boding'></span></div>
                                                    <div className='lightingg'>05 May 24  12:00 PM</div>
                                                </div>
                                            </div>
                                        )
                                    })}
                            </div>
                        </div>
                    </>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
}

export default MainLandingPageAnaly
