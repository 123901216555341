import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getReq } from "../../../piservices/apis";
import { constants } from "../../../piservices/constants";
import { baseUrl, formateDate } from "../../../piservices/compCommon";
import styles from "./InteractionAndLogs.module.css";
import { BsEye } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import stylesint from "./InteractionAndLogs.module.css";
import moment from "moment";

function generateLogText(log) {
  const htmlContent = [];
  log.map((logString) => htmlContent.push(<div>{logString}</div>));
  return htmlContent;
}

// Read More react component

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <div className="text">
      {isReadMore ? text.slice(0, 350) : text}
      <span
        onClick={toggleReadMore}
        style={{ color: "#0084EF", cursor: "pointer" }}
      >
        {isReadMore ? "...read more" : " show less"}
      </span>
    </div>
  );
};

function LogComponent({ logDate, logs, interection }) {
  const logsNameURL = {
    momLog: "mom",
    fileLog: "file-manager",
    taskLog: "timeline/task-manager/all-task/task",
  };

  // console.log("logsdata", logs.logText);
  console.log("logsdata",logs[logDate][0].logText)

  return (
    <>

{/* {
logs?.logText?.length >0  
():No data found

} */}
      { 
      
      logs[logDate]?.length > 0 && 
        logs[logDate]

          .filter((value) => {
            console.log("logsdata", value);
            return value?.logText?.length > 0;
          })
          .map((it, index) => {
            console.log( "logdatavalue",it.logText);

            if (it.logText?.length > 0 ) {
              return (
                <>
                  <div
                    className={`${styles.container}`}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      // background: "yellow",
                    }}
                  >
                    {/* <div className={`${styles.leftContainer}`}>{logDate && logDate}</div> */}
                    <div
                      className={`${styles.rightContainer}`}
                      style={{
                        marginTop: "10px",
                        width: "100%",
                        padding: "10px",
                        // background: "blue",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          marginTop: "30px",
                          padding: "10px 5px",
                          borderRadius: "20px",
                          border: "1px solid #9999",
                        }}
                      >
                        <div
                          className={`${styles.leftContainer}`}
                          style={{ marginLeft: "10px" }}
                        >
                          {logDate && logDate}
                        </div>
                        <div
                          className={styles.subRightContainer}
                          style={{
                            display: "flex",
                            // flexDirection:"column",

                            justifyContent: "space-between",
                            alignItems: "center",
                            borderLeft: "0.75px solid #999",
                            paddingLeft: "20px",
                            // marginTop:"10px",
                            // background:"blue",
                            marginLeft: "2%",
                            width: "90%",
                            // background:"blue"
                          }}
                        >
                          <div>
                            {it &&
                              (it.logName || typeof it.logText == "string"
                                ? it.logText
                                : generateLogText(it.logText))}
                          </div>
                          <div style={{ textAlign: "end" }}>
                            <span
                              style={{ marginRight: "28px", fontSize: "13px" }}
                            >
                              {moment(it?.createdAt).format("LT")}
                            </span>
                          </div>

                          {/* <div>{it.assignTo && `Assign to :${it.assignTo} `}</div> */}
                          {/* <div>Status: {it.status}</div> */}
                        </div>
                        {typeof it.logText == "string" && (
                          <div
                            onClick={() =>
                              window.location.assign(
                                `${constants.BASE_URL}/${logsNameURL[it?.logType]}/${
                                  it?.projectId
                                }?isLead=${true}`
                              )
                            }
                            style={{
                              color: "#0084EF",
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            <BsEye /> <span>{`View`}</span>
                          </div>
                        )}
                        <hr />
                      </div>
                    </div>
                  </div>
                </>
              );
            } 

          })}
      {/* </div> */}

      {/*  interection data */}

      {Object.keys(interection).map((date) => {
        console.log(date);
        console.log(logDate === date);
      }) &&
        interection &&
        Object.keys(interection).map((interactionDate, index) => {
          if (logDate === interactionDate) {
            return (
              <>
                <div
                  className={`${stylesint.container}`}
                  style={{ width: "100%" }}
                >
                  <div className={`${stylesint.leftContainer}`}>
                    {logDate && logDate}
                  </div>
                  <div className={`${stylesint.rightContainer}`}>
                    {interection[interactionDate].map((it, index) => {
                      return (
                        <>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                              justifyContent: "space-between",
                            }}
                          >
                            <div className={stylesint.subRightContainer}>
                              <div className={`${stylesint.meetingContainer}`}>
                                <div
                                  style={{ width: "20rem" }}
                                >{`Meeting Mode : ${it.meetingMode}`}</div>
                                <div>{`Outcome : ${it.outcome}`}</div>
                              </div>
                              <div
                                className={`${stylesint.meetingRemarksContainer}`}
                              >
                                <div className={`${stylesint.meetingRemarks}`}>
                                  Meeting Remarks
                                </div>
                                {it?.meetingRemarks?.length > 250 ? (
                                  <ReadMore>{it.meetingRemarks}</ReadMore>
                                ) : (
                                  it.meetingRemarks
                                )}
                              </div>
                              {index + 1 !==
                                interection[interactionDate]?.length && <hr />}
                            </div>
                            <span style={{ fontSize: "13px" }}>
                              {moment(it?.createdAt).format("LT")}
                            </span>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </>
            );
          }
        })}
      <div></div>

      {/* </div> */}
    </>
  );
}

function Logs({ data }) {
  const { id } = useParams();
  const selectedLeadData = useSelector(
    (state) => state.selectedLeadSlice.selectedLeadData
  );
  const [logs, setlogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [interection, setinterection] = useState([]);

  const getInteractionData = async () => {
    setLoading(true);
    const res = await getReq(
      `${constants.CRM_API}/user/get-lead-interactions?leadId=${id}`
    );
    if (res && !res.error) {
      setLoading(false);
      const groupByCreatedDate = res?.data?.data.reduce((group, product) => {
        const { createdAt } = product;
        const formatedDate = formateDate(createdAt);
        group[formatedDate] = group[formatedDate] ?? [];
        group[formatedDate].push(product);
        return group;
      }, {});
      console.log(groupByCreatedDate);
      setinterection(groupByCreatedDate);
      //  setlogs(groupByCreatedDate)
    }
    // else {
    //   console.log(res.error);
    //   localStorage.clear();
    //   window.location.assign(`${constants. BASE_URL_ESS}`);
    // }
  };

  const getlogData = async () => {
    setLoading(true);
    console.log("projectid",data?._id)
    const res = await getReq(
      `${constants.PMT_API}/api/projects/get-all-logs?projectId=${data?.projectId}`
    );

    const res2 = await getReq(
      `${constants.CRM_API}/user/get-lead-logs?leadId=${data?._id}`
    );
    
    console.log("logres2", res2?.data?.data);

      if ((res && !res.error) || (res2 && !res2.error)) {
      let mergeResponseData = [];
      if (res && !res.error) {
        mergeResponseData.push(...res?.data);
      }
      if (res2 && !res2.error) {
        mergeResponseData.push(...res2?.data?.data);
      }
      
      console.log("mergdata", mergeResponseData);
      console.log("insideapicall");
      setLoading(false);
      const groupByCreatedDate = mergeResponseData.reduce((group, product) => {
        const { createdAt } = product;
        const formatedDate = formateDate(createdAt);
        group[formatedDate] = group[formatedDate] ?? [];
        group[formatedDate].push(product);
        return group;
      }, {});

      setlogs(groupByCreatedDate);

      console.log("groupByCreatedDate", groupByCreatedDate);
    } else {
      console.log(res.error);
      setLoading(false);
    }
  };

  console.log("logdata", logs);
  console.log(interection);

  useEffect(() => {
    getlogData();
    getInteractionData();
  }, [selectedLeadData]);
  return (
    <>
      {loading ? (
        <div
          className=""
          style={{
            position: "relative",
            marginTop: "17vh",
            marginLeft: "40vw",
            // transform: 'translate(-50%, -50%)'
          }}
        >
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#0084EF"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      ) : (
        <div className={`${styles.mainContainer}`}>
          {logs && Object.keys(logs)?.length > 0 ? (
            Object.keys(logs).map((logDate, index) => (
              <LogComponent
                logs={logs}
                interection={interection}
                key={index}
                logDate={logDate}
              />
            ))
          ) : (
            <h4>There is no logs</h4>
          )}
        </div>
      )}
    </>
  );
}

export default Logs;
