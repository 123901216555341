// export const countryFlags = {
//     Afghanistan: { flag: "🇦🇫", code: "+93", countrycode: "AF", currency: "AFN" },
//     Albania: { flag: "🇦🇱", code: "+355", countrycode: "AL", currency: "ALL" },
//     "Åland Islands": { flag: "🇦🇽", code: "", countrycode: "AX", currency: "EUR" },
//     Algeria: { flag: "🇩🇿", code: "+213", countrycode: "DZ", currency: "DZD" },
//     Andorra: { flag: "🇦🇩", code: "+376", countrycode: "AD", currency: "EUR" },
//     Angola: { flag: "🇦🇴", code: "+244", countrycode: "AO", currency: "AOA" },
//     Anguilla: { flag: "🇦🇮", code: "+1", countrycode: "AI", currency: "XCD" },
//     Antarctica: { flag: "🇦🇶", code: "", countrycode: "AQ", currency: "" },
//     Argentina: { flag: "🇦🇷", code: "+54", countrycode: "AR", currency: "ARS" },
//     Armenia: { flag: "🇦🇲", code: "+374", countrycode: "AM", currency: "AMD" },
//     Aruba: { flag: "🇦🇼", code: "+297", countrycode: "AW", currency: "AWG" },
//     Australia: { flag: "🇦🇺", code: "+61", countrycode: "AU", currency: "AUD" },
//     Austria: { flag: "🇦🇹", code: "+43", countrycode: "AT", currency: "EUR" },
//     Azerbaijan: { flag: "🇦🇿", code: "+994", countrycode: "AZ", currency: "AZN" },
//     Bahamas: { flag: "🇧🇸", code: "+1-242", countrycode: "BS", currency: "BSD" },
//     Bahrain: { flag: "🇧🇭", code: "+973", countrycode: "BH", currency: "BHD" },
//     Bangladesh: { flag: "🇧🇩", code: "+880", countrycode: "BD", currency: "BDT" },
//     Barbados: { flag: "🇧🇧", code: "+1-246", countrycode: "BB", currency: "BBD" },
//     Belarus: { flag: "🇧🇾", code: "+375", countrycode: "BY", currency: "BYN" },
//     Belgium: { flag: "🇧🇪", code: "+32", countrycode: "BE", currency: "EUR" },
//     Belize: { flag: "🇧🇿", code: "+501", countrycode: "BZ", currency: "BZD" },
//     Benin: { flag: "🇧🇯", code: "+229", countrycode: "BJ", currency: "XOF" },
//     Bermuda: { flag: "🇧🇲", code: "+1-441", countrycode: "BM", currency: "BMD" },
//     Bhutan: { flag: "🇧🇹", code: "+975", countrycode: "BT", currency: "BTN" },
//     Bolivia: { flag: "🇧🇴", code: "+591", countrycode: "BO", currency: "BOB" },
//     Botswana: { flag: "🇧🇼", code: "+267", countrycode: "BW", currency: "BWP" },
//     Brazil: { flag: "🇧🇷", code: "+55", countrycode: "BR", currency: "BRL" },
//     Brunei: { flag: "🇧🇳", code: "+673", countrycode: "BN", currency: "BND" },
//     Bulgaria: { flag: "🇧🇬", code: "+359", countrycode: "BG", currency: "BGN" },
//     Burundi: { flag: "🇧🇮", code: "+257", countrycode: "BI", currency: "BIF" },
//     Cambodia: { flag: "🇰🇭", code: "+855", countrycode: "KH", currency: "KHR" },
//     Cameroon: { flag: "🇨🇲", code: "+237", countrycode: "CM", currency: "XAF" },
//     Canada: { flag: "🇨🇦", code: "+1", countrycode: "CA", currency: "CAD" },
//     Chad: { flag: "🇹🇩", code: "+235", countrycode: "TD", currency: "XAF" },
//     Chile: { flag: "🇨🇱", code: "+56", countrycode: "CL", currency: "CLP" },
//     China: { flag: "🇨🇳", code: "+86", countrycode: "CN", currency: "CNY" },
//     Colombia: { flag: "🇨🇴", code: "+57", countrycode: "CO", currency: "COP" },
//     Comoros: { flag: "🇰🇲", code: "+269", countrycode: "KM", currency: "KMF" },
//     Croatia: { flag: "🇭🇷", code: "+385", countrycode: "HR", currency: "HRK" },
//     Cuba: { flag: "🇨🇺", code: "+53", countrycode: "CU", currency: "CUP" },
//     Curacao: { flag: "🇨🇼", code: "+599", countrycode: "CW", currency: "ANG" },
//     Cyprus: { flag: "🇨🇾", code: "+357", countrycode: "CY", currency: "EUR" },
//     Czechia: { flag: "🇨🇿", code: "+420", countrycode: "CZ", currency: "CZK" },
//     Denmark: { flag: "🇩🇰", code: "+45", countrycode: "DK", currency: "DKK" },
//     Djibouti: { flag: "🇩🇯", code: "+253", countrycode: "DJ", currency: "DJF" },
//     Dominica: { flag: "🇩🇲", code: "+1-767", countrycode: "DM", currency: "XCD" },
//     Ecuador: { flag: "🇪🇨", code: "+593", countrycode: "EC", currency: "USD" },
//     Egypt: { flag: "🇪🇬", code: "+20", countrycode: "EG", currency: "EGP" },
//     Eritrea: { flag: "🇪🇷", code: "+291", countrycode: "ER", currency: "ERN" },
//     Estonia: { flag: "🇪🇪", code: "+372", countrycode: "EE", currency: "EUR" },
//     Eswatini: { flag: "🇸🇿", code: "+268", countrycode: "SZ", currency: "SZL" },
//     Ethiopia: { flag: "🇪🇹", code: "+251", countrycode: "ET", currency: "ETB" },
//     Fiji: { flag: "🇫🇯", code: "+679", countrycode: "FJ", currency: "FJD" },
//     Finland: { flag: "🇫🇮", code: "+358", countrycode: "FI", currency: "EUR" },
//     France: { flag: "🇫🇷", code: "+33", countrycode: "FR", currency: "EUR" },
//     Gabon: { flag: "🇬🇦", code: "+241", countrycode: "GA", currency: "XAF" },
//     Gambia: { flag: "🇬🇲", code: "+220", countrycode: "GM", currency: "GMD" },
//     Georgia: { flag: "🇬🇪", code: "+995", countrycode: "GE", currency: "GEL" },
//     Germany: { flag: "🇩🇪", code: "+49", countrycode: "DE", currency: "EUR" },
//     Ghana: { flag: "🇬🇭", code: "+233", countrycode: "GH", currency: "GHS" },
//     Greece: { flag: "🇬🇷", code: "+30", countrycode: "GR", currency: "EUR" },
//     Greenland: { flag: "🇬🇱", code: "+299", countrycode: "GL", currency: "DKK" },
//     Grenada: { flag: "🇬🇩", code: "+1-473", countrycode: "GD", currency: "XCD" },
//     Guatemala: { flag: "🇬🇹", code: "+502", countrycode: "GT", currency: "GTQ" },
//     Guinea: { flag: "🇬🇳", code: "+224", countrycode: "GN", currency: "GNF" },
//     Guyana: { flag: "🇬🇾", code: "+592", countrycode: "GY", currency: "GYD" },
//     Haiti: { flag: "🇭🇹", code: "+509", countrycode: "HT", currency: "HTG" },
//     Honduras: { flag: "🇭🇳", code: "+504", countrycode: "HN", currency: "HNL" },
//     Hungary: { flag: "🇭🇺", code: "+36", countrycode: "HU", currency: "HUF" },
//     Iceland: { flag: "🇮🇸", code: "+354", countrycode: "IS", currency: "ISK" },
//     India: { flag: "🇮🇳", code: "+91", countrycode: "IN", currency: "INR" },
//     Indonesia: { flag: "🇮🇩", code: "+62", countrycode: "ID", currency: "IDR" },
//     Iran: { flag: "🇮🇷", code: "+98", countrycode: "IR", currency: "IRR" },
//     Iraq: { flag: "🇮🇶", code: "+964", countrycode: "IQ", currency: "IQD" },
//     Ireland: { flag: "🇮🇪", code: "+353", countrycode: "IE", currency: "EUR" },
//     Israel: { flag: "🇮🇱", code: "+972", countrycode: "IL", currency: "ILS" },
//     Italy: { flag: "🇮🇹", code: "+39", countrycode: "IT", currency: "EUR" },
//     Jamaica: { flag: "🇯🇲", code: "+1-876", countrycode: "JM", currency: "JMD" },
//     Japan: { flag: "🇯🇵", code: "+81", countrycode: "JP", currency: "JPY" },
//     Jordan: { flag: "🇯🇴", code: "+962", countrycode: "JO", currency: "JOD" },
//     Kazakhstan: { flag: "🇰🇿", code: "+7", countrycode: "KZ", currency: "KZT" },
//     Kenya: { flag: "🇰🇪", code: "+254", countrycode: "KE", currency: "KES" },
//     Kiribati: { flag: "🇰🇮", code: "+686", countrycode: "KI", currency: "AUD" },
//     Kosovo: { flag: "🇽🇰", code: "+383", countrycode: "XK", currency: "EUR" },
//     Kuwait: { flag: "🇰🇼", code: "+965", countrycode: "KW", currency: "KWD" },
//     Kyrgyzstan: { flag: "🇰🇬", code: "+996", countrycode: "KG", currency: "KGS" },
//     Laos: { flag: "🇱🇦", code: "+856", countrycode: "LA", currency: "LAK" },
//     Latvia: { flag: "🇱🇻", code: "+371", countrycode: "LV", currency: "EUR" },
//     Lebanon: { flag: "🇱🇧", code: "+961", countrycode: "LB", currency: "LBP" },
//     Lesotho: { flag: "🇱🇸", code: "+266", countrycode: "LS", currency: "LSL" },
//     Liberia: { flag: "🇱🇷", code: "+231", countrycode: "LR", currency: "LRD" },
//     Libya: { flag: "🇱🇾", code: "+218", countrycode: "LY", currency: "LYD" },
//     Liechtenstein: { flag: "🇱🇮", code: "+423", countrycode: "LI", currency: "CHF" },
//     Lithuania: { flag: "🇱🇹", code: "+370", countrycode: "LT", currency: "EUR" },
//     Luxembourg: { flag: "🇱🇺", code: "+352", countrycode: "LU", currency: "EUR" },
//     Madagascar: { flag: "🇲🇬", code: "+261", countrycode: "MG", currency: "MGA" },
//     Malawi: { flag: "🇲🇼", code: "+265", countrycode: "MW", currency: "MWK" },
//     Malaysia: { flag: "🇲🇾", code: "+60", countrycode: "MY", currency: "MYR" },
//     Maldives: { flag: "🇲🇻", code: "+960", countrycode: "MV", currency: "MVR" },
//     Mali: { flag: "🇲🇱", code: "+223", countrycode: "ML", currency: "XOF" },
//     Malta: { flag: "🇲🇹", code: "+356", countrycode: "MT", currency: "EUR" },
//     Mauritania: { flag: "🇲🇷", code: "+222", countrycode: "MR", currency: "MRU" },
//     Mauritius: { flag: "🇲🇺", code: "+230", countrycode: "MU", currency: "MUR" },
//     Mexico: { flag: "🇲🇽", code: "+52", countrycode: "MX", currency: "MXN" },
//     Moldova: { flag: "🇲🇩", code: "+373", countrycode: "MD", currency: "MDL" },
//     Monaco: { flag: "🇲🇨", code: "+377", countrycode: "MC", currency: "EUR" },
//     Mongolia: { flag: "🇲🇳", code: "+976", countrycode: "MN", currency: "MNT" },
//     Montenegro: { flag: "🇲🇪", code: "+382", countrycode: "ME", currency: "EUR" },
//     Montserrat: { flag: "🇲🇸", code: "+1-664", countrycode: "MS", currency: "XCD" },
//     Morocco: { flag: "🇲🇦", code: "+212", countrycode: "MA", currency: "MAD" },
//     Mozambique: { flag: "🇲🇿", code: "+258", countrycode: "MZ", currency: "MZN" },
//     Myanmar: { flag: "🇲🇲", code: "+95", countrycode: "MM", currency: "MMK" },
//     Namibia: { flag: "🇳🇦", code: "+264", countrycode: "NA", currency: "NAD" },
//     Nauru: { flag: "🇳🇷", code: "+674", countrycode: "NR", currency: "AUD" },
//     Nepal: { flag: "🇳🇵", code: "+977", countrycode: "NP", currency: "NPR" },
//     Netherlands: { flag: "🇳🇱", code: "+31", countrycode: "NL", currency: "EUR" },
//     "New Caledonia": { flag: "🇳🇨", code: "+687", countrycode: "NC", currency: "XPF" },
//     "New Zealand": { flag: "🇳🇿", code: "+64", countrycode: "NZ", currency: "NZD" },
//     Nicaragua: { flag: "🇳🇮", code: "+505", countrycode: "NI", currency: "NIO" },
//     Niger: { flag: "🇳🇪", code: "+227", countrycode: "NE", currency: "XOF" },
//     Nigeria: { flag: "🇳🇬", code: "+234", countrycode: "NG", currency: "NGN" },
//     Norway: { flag: "🇳🇴", code: "+47", countrycode: "NO", currency: "NOK" },
//     Oman: { flag: "🇴🇲", code: "+968", countrycode: "OM", currency: "OMR" },
//     Pakistan: { flag: "🇵🇰", code: "+92", countrycode: "PK", currency: "PKR" },
//     Palau: { flag: "🇵🇼", code: "+680", countrycode: "PW", currency: "USD" },
//     Panama: { flag: "🇵🇦", code: "+507", countrycode: "PA", currency: "PAB" },
//     "Papua New Guinea": { flag: "🇵🇬", code: "+675", countrycode: "PG", currency: "PGK" },
//     Paraguay: { flag: "🇵🇾", code: "+595", countrycode: "PY", currency: "PYG" },
//     Peru: { flag: "🇵🇪", code: "+51", countrycode: "PE", currency: "PEN" },
//     Philippines: { flag: "🇵🇭", code: "+63", countrycode: "PH", currency: "PHP" },
//     Poland: { flag: "🇵🇱", code: "+48", countrycode: "PL", currency: "PLN" },
//     Portugal: { flag: "🇵🇹", code: "+351", countrycode: "PT", currency: "EUR" },
//     Qatar: { flag: "🇶🇦", code: "+974", countrycode: "QA", currency: "QAR" },
//     Romania: { flag: "🇷🇴", code: "+40", countrycode: "RO", currency: "RON" },
//     Russia: { flag: "🇷🇺", code: "+7", countrycode: "RU", currency: "RUB" },
//     Rwanda: { flag: "🇷🇼", code: "+250", countrycode: "RW", currency: "RWF" },
//     "Saint Kitts and Nevis": { flag: "🇰🇳", code: "+1-869", countrycode: "KN", currency: "XCD" },
//     "Saint Lucia": { flag: "🇱🇨", code: "+1-758", countrycode: "LC", currency: "XCD" },
//     "Saint Vincent and the Grenadines": { flag: "🇻🇨", code: "+1-784", countrycode: "VC", currency: "XCD" },
//     Samoa: { flag: "🇼🇸", code: "+685", countrycode: "WS", currency: "WST" },
//     "San Marino": { flag: "🇸🇲", code: "+378", countrycode: "SM", currency: "EUR" },
//     "São Tomé and Príncipe": { flag: "🇸🇹", code: "+239", countrycode: "ST", currency: "STN" },
//     "Saudi Arabia": { flag: "🇸🇦", code: "+966", countrycode: "SA", currency: "SAR" },
//     Senegal: { flag: "🇸🇳", code: "+221", countrycode: "SN", currency: "XOF" },
//     Serbia: { flag: "🇷🇸", code: "+381", countrycode: "RS", currency: "RSD" },
//     Seychelles: { flag: "🇸🇨", code: "+248", countrycode: "SC", currency: "SCR" },
//     "Sierra Leone": { flag: "🇸🇱", code: "+232", countrycode: "SL", currency: "SLL" },
//     Singapore: { flag: "🇸🇬", code: "+65", countrycode: "SG", currency: "SGD" },
//     Slovakia: { flag: "🇸🇰", code: "+421", countrycode: "SK", currency: "EUR" },
//     Slovenia: { flag: "🇸🇮", code: "+386", countrycode: "SI", currency: "EUR" },
//     Somalia: { flag: "🇸🇴", code: "+252", countrycode: "SO", currency: "SOS" },
//     "South Africa": { flag: "🇿🇦", code: "+27", countrycode: "ZA", currency: "ZAR" },
//     "South Korea": { flag: "🇰🇷", code: "+82", countrycode: "KR", currency: "KRW" },
//     Spain: { flag: "🇪🇸", code: "+34", countrycode: "ES", currency: "EUR" },
//     "Sri Lanka": { flag: "🇱🇰", code: "+94", countrycode: "LK", currency: "LKR" },
//     Sudan: { flag: "🇸🇩", code: "+249", countrycode: "SD", currency: "SDG" },
//     Suriname: { flag: "🇸🇷", code: "+597", countrycode: "SR", currency: "SRD" },
//     Sweden: { flag: "🇸🇪", code: "+46", countrycode: "SE", currency: "SEK" },
//     Switzerland: { flag: "🇨🇭", code: "+41", countrycode: "CH", currency: "CHF" },
//     Syria: { flag: "🇸🇾", code: "+963", countrycode: "SY", currency: "SYP" },
//     Taiwan: { flag: "🇹🇼", code: "+886", countrycode: "TW", currency: "TWD" },
//     Tajikistan: { flag: "🇹🇯", code: "+992", countrycode: "TJ", currency: "TJS" },
//     Tanzania: { flag: "🇹🇿", code: "+255", countrycode: "TZ", currency: "TZS" },
//     Thailand: { flag: "🇹🇭", code: "+66", countrycode: "TH", currency: "THB" },
//     "Timor-Leste": { flag: "🇹🇱", code: "+670", countrycode: "TL", currency: "USD" },
//     Togo: { flag: "🇹🇬", code: "+228", countrycode: "TG", currency: "XOF" },
//     Tonga: { flag: "🇹🇴", code: "+676", countrycode: "TO", currency: "TOP" },
//     "Trinidad and Tobago": { flag: "🇹🇹", code: "+1-868", countrycode: "TT", currency: "TTD" },
//     Tunisia: { flag: "🇹🇳", code: "+216", countrycode: "TN", currency: "TND" },
//     Turkey: { flag: "🇹🇷", code: "+90", countrycode: "TR", currency: "TRY" },
//     Turkmenistan: { flag: "🇹🇲", code: "+993", countrycode: "TM", currency: "TMT" },
//     Tuvalu: { flag: "🇹🇻", code: "+688", countrycode: "TV", currency: "AUD" },
//     Uganda: { flag: "🇺🇬", code: "+256", countrycode: "UG", currency: "UGX" },
//     Ukraine: { flag: "🇺🇦", code: "+380", countrycode: "UA", currency: "UAH" },
//     "United Arab Emirates": { flag: "🇦🇪", code: "+971", countrycode: "AE", currency: "AED" },
//     "United Kingdom": { flag: "🇬🇧", code: "+44", countrycode: "GB", currency: "GBP" },
//     "United States": { flag: "🇺🇸", code: "+1", countrycode: "US", currency: "USD" },
//     Uruguay: { flag: "🇺🇾", code: "+598", countrycode: "UY", currency: "UYU" },
//     Uzbekistan: { flag: "🇺🇿", code: "+998", countrycode: "UZ", currency: "UZS" },
//     Vanuatu: { flag: "🇻🇺", code: "+678", countrycode: "VU", currency: "VUV" },
//     Venezuela: { flag: "🇻🇪", code: "+58", countrycode: "VE", currency: "VES" },
//     Vietnam: { flag: "🇻🇳", code: "+84", countrycode: "VN", currency: "VND" },
//     Yemen: { flag: "🇾🇪", code: "+967", countrycode: "YE", currency: "YER" },
//     Zambia: { flag: "🇿🇲", code: "+260", countrycode: "ZM", currency: "ZMW" },
//     Zimbabwe: { flag: "🇿🇼", code: "+263", countrycode: "ZW", currency: "ZWL" }
//   };
  
export const countryFlags = {
  Afghanistan: { flagUrl: "https://flagcdn.com/af.svg", code: "+93", countrycode: "AF", currency: "AFN", symbol: "؋" },
  Albania: { flagUrl: "https://flagcdn.com/al.svg", code: "+355", countrycode: "AL", currency: "ALL", symbol: "Lek" },
  "Åland Islands": { flagUrl: "https://flagcdn.com/ax.svg", code: "+358", countrycode: "AX", currency: "EUR", symbol: "€" },
  Algeria: { flagUrl: "https://flagcdn.com/dz.svg", code: "+213", countrycode: "DZ", currency: "DZD", symbol: "د.ج" },
  Andorra: { flagUrl: "https://flagcdn.com/ad.svg", code: "+376", countrycode: "AD", currency: "EUR", symbol: "€" },
  Angola: { flagUrl: "https://flagcdn.com/ao.svg", code: "+244", countrycode: "AO", currency: "AOA", symbol: "Kz" },
  Anguilla: { flagUrl: "https://flagcdn.com/ai.svg", code: "+1", countrycode: "AI", currency: "XCD", symbol: "$" },
  Antarctica: { flagUrl: "https://flagcdn.com/aq.svg", code: "+672", countrycode: "AQ", currency: "", symbol: "" },
  Argentina: { flagUrl: "https://flagcdn.com/ar.svg", code: "+54", countrycode: "AR", currency: "ARS", symbol: "$" },
  Armenia: { flagUrl: "https://flagcdn.com/am.svg", code: "+374", countrycode: "AM", currency: "AMD", symbol: "֏" },
  Aruba: { flagUrl: "https://flagcdn.com/aw.svg", code: "+297", countrycode: "AW", currency: "AWG", symbol: "ƒ" },
  Australia: { flagUrl: "https://flagcdn.com/au.svg", code: "+61", countrycode: "AU", currency: "AUD", symbol: "$" },
  Austria: { flagUrl: "https://flagcdn.com/at.svg", code: "+43", countrycode: "AT", currency: "EUR", symbol: "€" },
  Azerbaijan: { flagUrl: "https://flagcdn.com/az.svg", code: "+994", countrycode: "AZ", currency: "AZN", symbol: "₼" },
  Bahamas: { flagUrl: "https://flagcdn.com/bs.svg", code: "+1-242", countrycode: "BS", currency: "BSD", symbol: "$" },
  Bahrain: { flagUrl: "https://flagcdn.com/bh.svg", code: "+973", countrycode: "BH", currency: "BHD", symbol: "ب.د" },
  Bangladesh: { flagUrl: "https://flagcdn.com/bd.svg", code: "+880", countrycode: "BD", currency: "BDT", symbol: "৳" },
  Barbados: { flagUrl: "https://flagcdn.com/bb.svg", code: "+1-246", countrycode: "BB", currency: "BBD", symbol: "$" },
  Belarus: { flagUrl: "https://flagcdn.com/by.svg", code: "+375", countrycode: "BY", currency: "BYN", symbol: "Br" },
  Belgium: { flagUrl: "https://flagcdn.com/be.svg", code: "+32", countrycode: "BE", currency: "EUR", symbol: "€" },
  Belize: { flagUrl: "https://flagcdn.com/bz.svg", code: "+501", countrycode: "BZ", currency: "BZD", symbol: "BZ$" },
  Benin: { flagUrl: "https://flagcdn.com/bj.svg", code: "+229", countrycode: "BJ", currency: "XOF", symbol: "Fr" },
  Bermuda: { flagUrl: "https://flagcdn.com/bm.svg", code: "+1-441", countrycode: "BM", currency: "BMD", symbol: "$" },
  Bhutan: { flagUrl: "https://flagcdn.com/bt.svg", code: "+975", countrycode: "BT", currency: "BTN", symbol: "Nu." },
  Bolivia: { flagUrl: "https://flagcdn.com/bo.svg", code: "+591", countrycode: "BO", currency: "BOB", symbol: "Bs." },
  Botswana: { flagUrl: "https://flagcdn.com/bw.svg", code: "+267", countrycode: "BW", currency: "BWP", symbol: "P" },
  Brazil: { flagUrl: "https://flagcdn.com/br.svg", code: "+55", countrycode: "BR", currency: "BRL", symbol: "R$" },
  Brunei: { flagUrl: "https://flagcdn.com/bn.svg", code: "+673", countrycode: "BN", currency: "BND", symbol: "B$" },
  Bulgaria: { flagUrl: "https://flagcdn.com/bg.svg", code: "+359", countrycode: "BG", currency: "BGN", symbol: "лв" },
  Burundi: { flagUrl: "https://flagcdn.com/bi.svg", code: "+257", countrycode: "BI", currency: "BIF", symbol: "Fr" },
  Cambodia: { flagUrl: "https://flagcdn.com/kh.svg", code: "+855", countrycode: "KH", currency: "KHR", symbol: "៛" },
  Cameroon: { flagUrl: "https://flagcdn.com/cm.svg", code: "+237", countrycode: "CM", currency: "XAF", symbol: "Fr" },
  Canada: { flagUrl: "https://flagcdn.com/ca.svg", code: "+1", countrycode: "CA", currency: "CAD", symbol: "$" },
  "Cape Verde": { flagUrl: "https://flagcdn.com/cv.svg", code: "+238", countrycode: "CV", currency: "CVE", symbol: "$" },
  "Central African Republic": { flagUrl: "https://flagcdn.com/cf.svg", code: "+236", countrycode: "CF", currency: "XAF", symbol: "Fr" },
  Chad: { flagUrl: "https://flagcdn.com/td.svg", code: "+235", countrycode: "TD", currency: "XAF", symbol: "Fr" },
  Chile: { flagUrl: "https://flagcdn.com/cl.svg", code: "+56", countrycode: "CL", currency: "CLP", symbol: "$" },
  China: { flagUrl: "https://flagcdn.com/cn.svg", code: "+86", countrycode: "CN", currency: "CNY", symbol: "¥" },
  Colombia: { flagUrl: "https://flagcdn.com/co.svg", code: "+57", countrycode: "CO", currency: "COP", symbol: "$" },
  Comoros: { flagUrl: "https://flagcdn.com/km.svg", code: "+269", countrycode: "KM", currency: "KMF", symbol: "Fr" },
  "Cook Islands": { flagUrl: "https://flagcdn.com/ck.svg", code: "+682", countrycode: "CK", currency: "NZD", symbol: "$" },
  "Costa Rica": { flagUrl: "https://flagcdn.com/cr.svg", code: "+506", countrycode: "CR", currency: "CRC", symbol: "₡" },
  Croatia: { flagUrl: "https://flagcdn.com/hr.svg", code: "+385", countrycode: "HR", currency: "HRK", symbol: "kn" },
  Cuba: { flagUrl: "https://flagcdn.com/cu.svg", code: "+53", countrycode: "CU", currency: "CUP", symbol: "$" },
  Cyprus: { flagUrl: "https://flagcdn.com/cy.svg", code: "+357", countrycode: "CY", currency: "EUR", symbol: "€" },
  Czechia: { flagUrl: "https://flagcdn.com/cz.svg", code: "+420", countrycode: "CZ", currency: "CZK", symbol: "Kč" },
  "Democratic Republic of the Congo": { flagUrl: "https://flagcdn.com/cd.svg", code: "+243", countrycode: "CD", currency: "CDF", symbol: "Fr" },
  Denmark: { flagUrl: "https://flagcdn.com/dk.svg", code: "+45", countrycode: "DK", currency: "DKK", symbol: "kr" },
  Djibouti: { flagUrl: "https://flagcdn.com/dj.svg", code: "+253", countrycode: "DJ", currency: "DJF", symbol: "Fr" },
  Dominica: { flagUrl: "https://flagcdn.com/dm.svg", code: "+1-767", countrycode: "DM", currency: "XCD", symbol: "$" },
  "Dominican Republic": { flagUrl: "https://flagcdn.com/do.svg", code: "+1-809, +1-829, +1-849", countrycode: "DO", currency: "DOP", symbol: "$" },
  Ecuador: { flagUrl: "https://flagcdn.com/ec.svg", code: "+593", countrycode: "EC", currency: "USD", symbol: "$" },
  Egypt: { flagUrl: "https://flagcdn.com/eg.svg", code: "+20", countrycode: "EG", currency: "EGP", symbol: "£" },
  "El Salvador": { flagUrl: "https://flagcdn.com/sv.svg", code: "+503", countrycode: "SV", currency: "USD", symbol: "$" },
  "Equatorial Guinea": { flagUrl: "https://flagcdn.com/gq.svg", code: "+240", countrycode: "GQ", currency: "XAF", symbol: "Fr" },
  Eritrea: { flagUrl: "https://flagcdn.com/er.svg", code: "+291", countrycode: "ER", currency: "ERN", symbol: "Nfk" },
  Estonia: { flagUrl: "https://flagcdn.com/ee.svg", code: "+372", countrycode: "EE", currency: "EUR", symbol: "€" },
  Eswatini: { flagUrl: "https://flagcdn.com/sz.svg", code: "+268", countrycode: "SZ", currency: "SZL", symbol: "E" },
  Ethiopia: { flagUrl: "https://flagcdn.com/et.svg", code: "+251", countrycode: "ET", currency: "ETB", symbol: "Br" },
  "Falkland Islands": { flagUrl: "https://flagcdn.com/fk.svg", code: "+500", countrycode: "FK", currency: "FKP", symbol: "£" },
  "Faroe Islands": { flagUrl: "https://flagcdn.com/fo.svg", code: "+298", countrycode: "FO", currency: "DKK", symbol: "kr" },
  Fiji: { flagUrl: "https://flagcdn.com/fj.svg", code: "+679", countrycode: "FJ", currency: "FJD", symbol: "$" },
  Finland: { flagUrl: "https://flagcdn.com/fi.svg", code: "+358", countrycode: "FI", currency: "EUR", symbol: "€" },
  France: { flagUrl: "https://flagcdn.com/fr.svg", code: "+33", countrycode: "FR", currency: "EUR", symbol: "€" },
  "French Guiana": { flagUrl: "https://flagcdn.com/gf.svg", code: "+594", countrycode: "GF", currency: "EUR", symbol: "€" },
  "French Polynesia": { flagUrl: "https://flagcdn.com/pf.svg", code: "+689", countrycode: "PF", currency: "XPF", symbol: "Fr" },
  Gabon: { flagUrl: "https://flagcdn.com/ga.svg", code: "+241", countrycode: "GA", currency: "XAF", symbol: "Fr" },
  Gambia: { flagUrl: "https://flagcdn.com/gm.svg", code: "+220", countrycode: "GM", currency: "GMD", symbol: "D" },
  Georgia: { flagUrl: "https://flagcdn.com/ge.svg", code: "+995", countrycode: "GE", currency: "GEL", symbol: "ლ" },
  Germany: { flagUrl: "https://flagcdn.com/de.svg", code: "+49", countrycode: "DE", currency: "EUR", symbol: "€" },
  Ghana: { flagUrl: "https://flagcdn.com/gh.svg", code: "+233", countrycode: "GH", currency: "GHS", symbol: "₵" },
  Gibraltar: { flagUrl: "https://flagcdn.com/gi.svg", code: "+350", countrycode: "GI", currency: "GIP", symbol: "£" },
  Greece: { flagUrl: "https://flagcdn.com/gr.svg", code: "+30", countrycode: "GR", currency: "EUR", symbol: "€" },
  Greenland: { flagUrl: "https://flagcdn.com/gl.svg", code: "+299", countrycode: "GL", currency: "DKK", symbol: "kr" },
  Grenada: { flagUrl: "https://flagcdn.com/gd.svg", code: "+1-473", countrycode: "GD", currency: "XCD", symbol: "$" },
  Guadeloupe: { flagUrl: "https://flagcdn.com/gp.svg", code: "+590", countrycode: "GP", currency: "EUR", symbol: "€" },
  Guam: { flagUrl: "https://flagcdn.com/gu.svg", code: "+1-671", countrycode: "GU", currency: "USD", symbol: "$" },
  Guatemala: { flagUrl: "https://flagcdn.com/gt.svg", code: "+502", countrycode: "GT", currency: "GTQ", symbol: "Q" },
  Guernsey: { flagUrl: "https://flagcdn.com/gg.svg", code: "+44-1481", countrycode: "GG", currency: "GBP", symbol: "£" },
  Guinea: { flagUrl: "https://flagcdn.com/gn.svg", code: "+224", countrycode: "GN", currency: "GNF", symbol: "Fr" },
  "Guinea-Bissau": { flagUrl: "https://flagcdn.com/gw.svg", code: "+245", countrycode: "GW", currency: "XOF", symbol: "Fr" },
  Guyana: { flagUrl: "https://flagcdn.com/gy.svg", code: "+592", countrycode: "GY", currency: "GYD", symbol: "$" },
  Haiti: { flagUrl: "https://flagcdn.com/ht.svg", code: "+509", countrycode: "HT", currency: "HTG", symbol: "G" },
  Honduras: { flagUrl: "https://flagcdn.com/hn.svg", code: "+504", countrycode: "HN", currency: "HNL", symbol: "L" },
  "Hong Kong": { flagUrl: "https://flagcdn.com/hk.svg", code: "+852", countrycode: "HK", currency: "HKD", symbol: "$" },
  Hungary: { flagUrl: "https://flagcdn.com/hu.svg", code: "+36", countrycode: "HU", currency: "HUF", symbol: "Ft" },
  Iceland: { flagUrl: "https://flagcdn.com/is.svg", code: "+354", countrycode: "IS", currency: "ISK", symbol: "kr" },
  India: { flagUrl: "https://flagcdn.com/in.svg", code: "+91", countrycode: "IN", currency: "INR", symbol: "₹" },
  Indonesia: { flagUrl: "https://flagcdn.com/id.svg", code: "+62", countrycode: "ID", currency: "IDR", symbol: "Rp" },
  Iran: { flagUrl: "https://flagcdn.com/ir.svg", code: "+98", countrycode: "IR", currency: "IRR", symbol: "﷼" },
  Iraq: { flagUrl: "https://flagcdn.com/iq.svg", code: "+964", countrycode: "IQ", currency: "IQD", symbol: "ع.د" },
  Ireland: { flagUrl: "https://flagcdn.com/ie.svg", code: "+353", countrycode: "IE", currency: "EUR", symbol: "€" },
  "Isle of Man": { flagUrl: "https://flagcdn.com/im.svg", code: "+44-1624", countrycode: "IM", currency: "GBP", symbol: "£" },
  Israel: { flagUrl: "https://flagcdn.com/il.svg", code: "+972", countrycode: "IL", currency: "ILS", symbol: "₪" },
  Italy: { flagUrl: "https://flagcdn.com/it.svg", code: "+39", countrycode: "IT", currency: "EUR", symbol: "€" },
  Jamaica: { flagUrl: "https://flagcdn.com/jm.svg", code: "+1-876", countrycode: "JM", currency: "JMD", symbol: "$" },
  Japan: { flagUrl: "https://flagcdn.com/jp.svg", code: "+81", countrycode: "JP", currency: "JPY", symbol: "¥" },
  Jersey: { flagUrl: "https://flagcdn.com/je.svg", code: "+44-1534", countrycode: "JE", currency: "GBP", symbol: "£" },
  Jordan: { flagUrl: "https://flagcdn.com/jo.svg", code: "+962", countrycode: "JO", currency: "JOD", symbol: "د.ا" },
  Kazakhstan: { flagUrl: "https://flagcdn.com/kz.svg", code: "+7", countrycode: "KZ", currency: "KZT", symbol: "₸" },
  Kenya: { flagUrl: "https://flagcdn.com/ke.svg", code: "+254", countrycode: "KE", currency: "KES", symbol: "Sh" },
  Kiribati: { flagUrl: "https://flagcdn.com/ki.svg", code: "+686", countrycode: "KI", currency: "AUD", symbol: "$" },
  Kuwait: { flagUrl: "https://flagcdn.com/kw.svg", code: "+965", countrycode: "KW", currency: "KWD", symbol: "د.ك" },
  Kyrgyzstan: { flagUrl: "https://flagcdn.com/kg.svg", code: "+996", countrycode: "KG", currency: "KGS", symbol: "лв" },
  Laos: { flagUrl: "https://flagcdn.com/la.svg", code: "+856", countrycode: "LA", currency: "LAK", symbol: "₭" },
  Latvia: { flagUrl: "https://flagcdn.com/lv.svg", code: "+371", countrycode: "LV", currency: "EUR", symbol: "€" },
  Lebanon: { flagUrl: "https://flagcdn.com/lb.svg", code: "+961", countrycode: "LB", currency: "LBP", symbol: "ل.ل" },
  Lesotho: { flagUrl: "https://flagcdn.com/ls.svg", code: "+266", countrycode: "LS", currency: "LSL", symbol: "L" },
  Liberia: { flagUrl: "https://flagcdn.com/lr.svg", code: "+231", countrycode: "LR", currency: "LRD", symbol: "$" },
  Libya: { flagUrl: "https://flagcdn.com/ly.svg", code: "+218", countrycode: "LY", currency: "LYD", symbol: "ل.د" },
  Liechtenstein: { flagUrl: "https://flagcdn.com/li.svg", code: "+423", countrycode: "LI", currency: "CHF", symbol: "Fr" },
  Lithuania: { flagUrl: "https://flagcdn.com/lt.svg", code: "+370", countrycode: "LT", currency: "EUR", symbol: "€" },
  Luxembourg: { flagUrl: "https://flagcdn.com/lu.svg", code: "+352", countrycode: "LU", currency: "EUR", symbol: "€" },
  Macao: { flagUrl: "https://flagcdn.com/mo.svg", code: "+853", countrycode: "MO", currency: "MOP", symbol: "MOP$" },
  Madagascar: { flagUrl: "https://flagcdn.com/mg.svg", code: "+261", countrycode: "MG", currency: "MGA", symbol: "Ar" },
  Malawi: { flagUrl: "https://flagcdn.com/mw.svg", code: "+265", countrycode: "MW", currency: "MWK", symbol: "MK" },
  Malaysia: { flagUrl: "https://flagcdn.com/my.svg", code: "+60", countrycode: "MY", currency: "MYR", symbol: "RM" },
  Maldives: { flagUrl: "https://flagcdn.com/mv.svg", code: "+960", countrycode: "MV", currency: "MVR", symbol: "Rf" },
  Mali: { flagUrl: "https://flagcdn.com/ml.svg", code: "+223", countrycode: "ML", currency: "XOF", symbol: "Fr" },
  Malta: { flagUrl: "https://flagcdn.com/mt.svg", code: "+356", countrycode: "MT", currency: "EUR", symbol: "€" },
  Martinique: { flagUrl: "https://flagcdn.com/mq.svg", code: "+596", countrycode: "MQ", currency: "EUR", symbol: "€" },
  Mauritania: { flagUrl: "https://flagcdn.com/mr.svg", code: "+222", countrycode: "MR", currency: "MRU", symbol: "UM" },
  Mauritius: { flagUrl: "https://flagcdn.com/mu.svg", code: "+230", countrycode: "MU", currency: "MUR", symbol: "₨" },
  Mayotte: { flagUrl: "https://flagcdn.com/yt.svg", code: "+262", countrycode: "YT", currency: "EUR", symbol: "€" },
  Mexico: { flagUrl: "https://flagcdn.com/mx.svg", code: "+52", countrycode: "MX", currency: "MXN", symbol: "$" },
  Moldova: { flagUrl: "https://flagcdn.com/md.svg", code: "+373", countrycode: "MD", currency: "MDL", symbol: "L" },
  Monaco: { flagUrl: "https://flagcdn.com/mc.svg", code: "+377", countrycode: "MC", currency: "EUR", symbol: "€" },
  Mongolia: { flagUrl: "https://flagcdn.com/mn.svg", code: "+976", countrycode: "MN", currency: "MNT", symbol: "₮" },
  Montenegro: { flagUrl: "https://flagcdn.com/me.svg", code: "+382", countrycode: "ME", currency: "EUR", symbol: "€" },
  Montserrat: { flagUrl: "https://flagcdn.com/ms.svg", code: "+1-664", countrycode: "MS", currency: "XCD", symbol: "$" },
  Morocco: { flagUrl: "https://flagcdn.com/ma.svg", code: "+212", countrycode: "MA", currency: "MAD", symbol: "د.م." },
  Mozambique: { flagUrl: "https://flagcdn.com/mz.svg", code: "+258", countrycode: "MZ", currency: "MZN", symbol: "MT" },
  Myanmar: { flagUrl: "https://flagcdn.com/mm.svg", code: "+95", countrycode: "MM", currency: "MMK", symbol: "K" },
  Namibia: { flagUrl: "https://flagcdn.com/na.svg", code: "+264", countrycode: "NA", currency: "NAD", symbol: "$" },
  Nauru: { flagUrl: "https://flagcdn.com/nr.svg", code: "+674", countrycode: "NR", currency: "AUD", symbol: "$" },
  Nepal: { flagUrl: "https://flagcdn.com/np.svg", code: "+977", countrycode: "NP", currency: "NPR", symbol: "₨" },
  Netherlands: { flagUrl: "https://flagcdn.com/nl.svg", code: "+31", countrycode: "NL", currency: "EUR", symbol: "€" },
  "New Caledonia": { flagUrl: "https://flagcdn.com/nc.svg", code: "+687", countrycode: "NC", currency: "XPF", symbol: "Fr" },
  "New Zealand": { flagUrl: "https://flagcdn.com/nz.svg", code: "+64", countrycode: "NZ", currency: "NZD", symbol: "$" },
  Nicaragua: { flagUrl: "https://flagcdn.com/ni.svg", code: "+505", countrycode: "NI", currency: "NIO", symbol: "C$" },
  Niger: { flagUrl: "https://flagcdn.com/ne.svg", code: "+227", countrycode: "NE", currency: "XOF", symbol: "Fr" },
  Nigeria: { flagUrl: "https://flagcdn.com/ng.svg", code: "+234", countrycode: "NG", currency: "NGN", symbol: "₦" },
  Niue: { flagUrl: "https://flagcdn.com/nu.svg", code: "+683", countrycode: "NU", currency: "NZD", symbol: "$" },
  "North Korea": { flagUrl: "https://flagcdn.com/kp.svg", code: "+850", countrycode: "KP", currency: "KPW", symbol: "₩" },
  "North Macedonia": { flagUrl: "https://flagcdn.com/mk.svg", code: "+389", countrycode: "MK", currency: "MKD", symbol: "ден" },
  Norway: { flagUrl: "https://flagcdn.com/no.svg", code: "+47", countrycode: "NO", currency: "NOK", symbol: "kr" },
  Oman: { flagUrl: "https://flagcdn.com/om.svg", code: "+968", countrycode: "OM", currency: "OMR", symbol: "ر.ع." },
  Pakistan: { flagUrl: "https://flagcdn.com/pk.svg", code: "+92", countrycode: "PK", currency: "PKR", symbol: "₨" },
  Palau: { flagUrl: "https://flagcdn.com/pw.svg", code: "+680", countrycode: "PW", currency: "USD", symbol: "$" },
  Palestine: { flagUrl: "https://flagcdn.com/ps.svg", code: "+970", countrycode: "PS", currency: "ILS", symbol: "₪" },
  Panama: { flagUrl: "https://flagcdn.com/pa.svg", code: "+507", countrycode: "PA", currency: "PAB", symbol: "B/." },
  "Papua New Guinea": { flagUrl: "https://flagcdn.com/pg.svg", code: "+675", countrycode: "PG", currency: "PGK", symbol: "K" },
  Paraguay: { flagUrl: "https://flagcdn.com/py.svg", code: "+595", countrycode: "PY", currency: "PYG", symbol: "₲" },
  Peru: { flagUrl: "https://flagcdn.com/pe.svg", code: "+51", countrycode: "PE", currency: "PEN", symbol: "S/." },
  Philippines: { flagUrl: "https://flagcdn.com/ph.svg", code: "+63", countrycode: "PH", currency: "PHP", symbol: "₱" },
  Poland: { flagUrl: "https://flagcdn.com/pl.svg", code: "+48", countrycode: "PL", currency: "PLN", symbol: "zł" },
  Portugal: { flagUrl: "https://flagcdn.com/pt.svg", code: "+351", countrycode: "PT", currency: "EUR", symbol: "€" },
  Qatar: { flagUrl: "https://flagcdn.com/qa.svg", code: "+974", countrycode: "QA", currency: "QAR", symbol: "ر.ق" },
  Réunion: { flagUrl: "https://flagcdn.com/re.svg", code: "+262", countrycode: "RE", currency: "EUR", symbol: "€" },
  Romania: { flagUrl: "https://flagcdn.com/ro.svg", code: "+40", countrycode: "RO", currency: "RON", symbol: "lei" },
  Russia: { flagUrl: "https://flagcdn.com/ru.svg", code: "+7", countrycode: "RU", currency: "RUB", symbol: "₽" },
  Rwanda: { flagUrl: "https://flagcdn.com/rw.svg", code: "+250", countrycode: "RW", currency: "RWF", symbol: "Fr" },
  "Saint Barthélemy": { flagUrl: "https://flagcdn.com/bl.svg", code: "+590", countrycode: "BL", currency: "EUR", symbol: "€" },
  "Saint Helena": { flagUrl: "https://flagcdn.com/sh.svg", code: "+290", countrycode: "SH", currency: "SHP", symbol: "£" },
  "Saint Kitts and Nevis": { flagUrl: "https://flagcdn.com/kn.svg", code: "+1-869", countrycode: "KN", currency: "XCD", symbol: "$" },
  "Saint Lucia": { flagUrl: "https://flagcdn.com/lc.svg", code: "+1-758", countrycode: "LC", currency: "XCD", symbol: "$" },
  "Saint Martin": { flagUrl: "https://flagcdn.com/mf.svg", code: "+590", countrycode: "MF", currency: "EUR", symbol: "€" },
  "Saint Pierre and Miquelon": { flagUrl: "https://flagcdn.com/pm.svg", code: "+508", countrycode: "PM", currency: "EUR", symbol: "€" },
  "Saint Vincent and the Grenadines": { flagUrl: "https://flagcdn.com/vc.svg", code: "+1-784", countrycode: "VC", currency: "XCD", symbol: "$" },
  Samoa: { flagUrl: "https://flagcdn.com/ws.svg", code: "+685", countrycode: "WS", currency: "WST", symbol: "T" },
  "San Marino": { flagUrl: "https://flagcdn.com/sm.svg", code: "+378", countrycode: "SM", currency: "EUR", symbol: "€" },
  "São Tomé and Príncipe": { flagUrl: "https://flagcdn.com/st.svg", code: "+239", countrycode: "ST", currency: "STN", symbol: "Db" },
  "Saudi Arabia": { flagUrl: "https://flagcdn.com/sa.svg", code: "+966", countrycode: "SA", currency: "SAR", symbol: "ر.س" },
  Senegal: { flagUrl: "https://flagcdn.com/sn.svg", code: "+221", countrycode: "SN", currency: "XOF", symbol: "Fr" },
  Serbia: { flagUrl: "https://flagcdn.com/rs.svg", code: "+381", countrycode: "RS", currency: "RSD", symbol: "дин." },
  Seychelles: { flagUrl: "https://flagcdn.com/sc.svg", code: "+248", countrycode: "SC", currency: "SCR", symbol: "₨" },
  "Sierra Leone": { flagUrl: "https://flagcdn.com/sl.svg", code: "+232", countrycode: "SL", currency: "SLL", symbol: "Le" },
  Singapore: { flagUrl: "https://flagcdn.com/sg.svg", code: "+65", countrycode: "SG", currency: "SGD", symbol: "$" },
  "Sint Maarten": { flagUrl: "https://flagcdn.com/sx.svg", code: "+1-721", countrycode: "SX", currency: "ANG", symbol: "ƒ" },
  Slovakia: { flagUrl: "https://flagcdn.com/sk.svg", code: "+421", countrycode: "SK", currency: "EUR", symbol: "€" },
  Slovenia: { flagUrl: "https://flagcdn.com/si.svg", code: "+386", countrycode: "SI", currency: "EUR", symbol: "€" },
  "Solomon Islands": { flagUrl: "https://flagcdn.com/sb.svg", code: "+677", countrycode: "SB", currency: "SBD", symbol: "$" },
  Somalia: { flagUrl: "https://flagcdn.com/so.svg", code: "+252", countrycode: "SO", currency: "SOS", symbol: "Sh" },
  "South Africa": { flagUrl: "https://flagcdn.com/za.svg", code: "+27", countrycode: "ZA", currency: "ZAR", symbol: "R" },
  "South Korea": { flagUrl: "https://flagcdn.com/kr.svg", code: "+82", countrycode: "KR", currency: "KRW", symbol: "₩" },
  "South Sudan": { flagUrl: "https://flagcdn.com/ss.svg", code: "+211", countrycode: "SS", currency: "SSP", symbol: "£" },
  Spain: { flagUrl: "https://flagcdn.com/es.svg", code: "+34", countrycode: "ES", currency: "EUR", symbol: "€" },
  "Sri Lanka": { flagUrl: "https://flagcdn.com/lk.svg", code: "+94", countrycode: "LK", currency: "LKR", symbol: "Rs" },
  Sudan: { flagUrl: "https://flagcdn.com/sd.svg", code: "+249", countrycode: "SD", currency: "SDG", symbol: "ج.س." },
  Suriname: { flagUrl: "https://flagcdn.com/sr.svg", code: "+597", countrycode: "SR", currency: "SRD", symbol: "$" },
  Sweden: { flagUrl: "https://flagcdn.com/se.svg", code: "+46", countrycode: "SE", currency: "SEK", symbol: "kr" },
  Switzerland: { flagUrl: "https://flagcdn.com/ch.svg", code: "+41", countrycode: "CH", currency: "CHF", symbol: "Fr" },
  Syria: { flagUrl: "https://flagcdn.com/sy.svg", code: "+963", countrycode: "SY", currency: "SYP", symbol: "£" },
  Taiwan: { flagUrl: "https://flagcdn.com/tw.svg", code: "+886", countrycode: "TW", currency: "TWD", symbol: "NT$" },
  Tajikistan: { flagUrl: "https://flagcdn.com/tj.svg", code: "+992", countrycode: "TJ", currency: "TJS", symbol: "ЅМ" },
  Tanzania: { flagUrl: "https://flagcdn.com/tz.svg", code: "+255", countrycode: "TZ", currency: "TZS", symbol: "Sh" },
  Thailand: { flagUrl: "https://flagcdn.com/th.svg", code: "+66", countrycode: "TH", currency: "THB", symbol: "฿" },
  "Timor-Leste": { flagUrl: "https://flagcdn.com/tl.svg", code: "+670", countrycode: "TL", currency: "USD", symbol: "$" },
  Togo: { flagUrl: "https://flagcdn.com/tg.svg", code: "+228", countrycode: "TG", currency: "XOF", symbol: "Fr" },
  Tokelau: { flagUrl: "https://flagcdn.com/tk.svg", code: "+690", countrycode: "TK", currency: "NZD", symbol: "$" },
  Tonga: { flagUrl: "https://flagcdn.com/to.svg", code: "+676", countrycode: "TO", currency: "TOP", symbol: "T$" },
  "Trinidad and Tobago": { flagUrl: "https://flagcdn.com/tt.svg", code: "+1-868", countrycode: "TT", currency: "TTD", symbol: "TT$" },
  Tunisia: { flagUrl: "https://flagcdn.com/tn.svg", code: "+216", countrycode: "TN", currency: "TND", symbol: "د.ت" },
  Turkey: { flagUrl: "https://flagcdn.com/tr.svg", code: "+90", countrycode: "TR", currency: "TRY", symbol: "₺" },
  Turkmenistan: { flagUrl: "https://flagcdn.com/tm.svg", code: "+993", countrycode: "TM", currency: "TMT", symbol: "T" },
  Tuvalu: { flagUrl: "https://flagcdn.com/tv.svg", code: "+688", countrycode: "TV", currency: "AUD", symbol: "$" },
  Uganda: { flagUrl: "https://flagcdn.com/ug.svg", code: "+256", countrycode: "UG", currency: "UGX", symbol: "Sh" },
  Ukraine: { flagUrl: "https://flagcdn.com/ua.svg", code: "+380", countrycode: "UA", currency: "UAH", symbol: "₴" },
  "United Arab Emirates": { flagUrl: "https://flagcdn.com/ae.svg", code: "+971", countrycode: "AE", currency: "AED", symbol: "د.إ" },
  "United Kingdom": { flagUrl: "https://flagcdn.com/gb.svg", code: "+44", countrycode: "GB", currency: "GBP", symbol: "£" },
  "United States": { flagUrl: "https://flagcdn.com/us.svg", code: "+1", countrycode: "US", currency: "USD", symbol: "$" },
  Uruguay: { flagUrl: "https://flagcdn.com/uy.svg", code: "+598", countrycode: "UY", currency: "UYU", symbol: "$" },
  Uzbekistan: { flagUrl: "https://flagcdn.com/uz.svg", code: "+998", countrycode: "UZ", currency: "UZS", symbol: "лв" },
  Vanuatu: { flagUrl: "https://flagcdn.com/vu.svg", code: "+678", countrycode: "VU", currency: "VUV", symbol: "Vt" },
  Venezuela: { flagUrl: "https://flagcdn.com/ve.svg", code: "+58", countrycode: "VE", currency: "VES", symbol: "Bs" },
  Vietnam: { flagUrl: "https://flagcdn.com/vn.svg", code: "+84", countrycode: "VN", currency: "VND", symbol: "₫" },
  "Wallis and Futuna": { flagUrl: "https://flagcdn.com/wf.svg", code: "+681", countrycode: "WF", currency: "XPF", symbol: "Fr" },
  "Western Sahara": { flagUrl: "https://flagcdn.com/eh.svg", code: "+212", countrycode: "EH", currency: "MAD", symbol: "د.م." },
  Yemen: { flagUrl: "https://flagcdn.com/ye.svg", code: "+967", countrycode: "YE", currency: "YER", symbol: "﷼" },
  Zambia: { flagUrl: "https://flagcdn.com/zm.svg", code: "+260", countrycode: "ZM", currency: "ZMW", symbol: "ZK" },
  Zimbabwe: { flagUrl: "https://flagcdn.com/zw.svg", code: "+263", countrycode: "ZW", currency: "ZWL", symbol: "$" }
};