import React from "react";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Autocomplete,
  FormHelperText,
  Select,
  TextField,
} from "@mui/material";
import { Button, Modal, Spinner, Dropdown } from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  setAddLeadModalOpen,
  newLeadObj,
} from "../../Redux/Slices/newLeadSlice";
import { BiChevronDown } from "react-icons/bi";
import "react-phone-input-2/lib/material.css";
import styles from "./AddLeadModalNew.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import expf, { constants } from "../../piservices/constants";
import { useEffect } from "react";
import { getReq, postReq } from "../../piservices/apis";
import toast from "react-hot-toast";
import MuiPhoneNumber from "material-ui-phone-number";
import { notification } from "antd";
import PhoneInput from "react-phone-input-2";
import useApiFetchCustomHook from "../../piservices/ApiFetchCustomHook";
import useApiFetchForLeadCountCustomHook from "../../piservices/ApiFetchForLeadCount";
import useApiFetchCustomHookForEmailService from "../../piservices/ApiFetchCustomHookForEmailService";

function AddLeadModalNewForFormTemplate({ setProgress }) {
  const profileData = useSelector((state) => state.lmsSlice.profileData);
  const haveAssigneToAccess = useSelector(
    (state) => state.notificationSlice.allAssignToAccessUser
  );
  const addLeadModalOpen = useSelector(
    (state) => state.newLeadSlice.addLeadModalOpen
  );
  const importedDataFromFile = useSelector(
    (state) => state.newLeadSlice.fileImportedData
  );

  let { tab } = useParams();

  const uploadImportedData = () => {
    importedDataFromFile?.map((item) => {});
  };

  const { apiFetch } = useApiFetchCustomHook();
  const { getLeadsCount } = useApiFetchForLeadCountCustomHook();

  useEffect(() => {
    if (importedDataFromFile?.length > 0) {
    }
  }, [importedDataFromFile]);
  const dispatch = useDispatch();
  const [leadFirstName, setLeadFirstName] = useState("");
  const [leadLastName, setLeadLastName] = useState("");
  const [phoneNumber, setPhonenumber] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [errorPin, setErrorPin] = useState(false);
  const [leadEmail, setLeadEmail] = useState("");
  const [leadCity, setLeadCity] = useState("");
  const [leadArea, setLeadArea] = useState("");
  const [leadAddress, setLeadAddress] = useState("");
  const [workScope, setWorkScope] = useState("");
  const [leadCityCode, setLeadCityCode] = useState("");
  const [userData, setUserData] = useState({});
  const [leadSources, setLeadSources] = useState([]);
  const [leadWorkTypes, setLeadWorkTypes] = useState([]);
  const [sourceNames, setSourceNames] = useState([]);
  const [leadSource, setLeadSource] = useState([]);
  const [leadWorkType, setLeadWorkType] = useState([]);
  const [sourceName, setSourceName] = useState([]);
  const [selectedLeadSource, setSelectedLeadSource] = useState("");
  const [assignedTo, setAssignedTo] = useState("");
  const [assignedToName, setAssignedToName] = useState("");
  const [areaUnit, setAreaUnit] = useState("sqft");
  const [assignedToObject, setAssignedToObject] = useState("");
  const [selectedSourceName, setSelectedSourceName] = useState("");
  const [addNewWorkScope, setAddNewWorkType] = useState("");
  const [addNewLeadSource, setAddLeadNewSource] = useState("");
  const [addNewSourceName, setAddNewSourceName] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [leadSourceClicked, setLeadSourceClicked] = useState(false);
  const [sourceNameArchitectDetails, setSourceNameArchitectDetails] = useState(
    {}
  );

  const addLeadFormTemplate = useSelector(
    (state) => state.lmsSlice.addLeadFormTemplate
  );

  const { sendEmailPushBellNotifications } =
    useApiFetchCustomHookForEmailService();
  const [buttonClickedOnce, setButtonClickedOnce] = useState(false);
  const saveLeadSource = async () => {
    if (addNewLeadSource.length > 0) {
      const res = await postReq(`${constants.CRM_API}/user/save-lead-source`, {
        userId: localStorage.getItem("userId"),
        leadSource: addNewLeadSource,
      });

      if (res && !res.error) {
        // console.log('getting lead source', res.data.data)
        getLeadSource();
        setAddLeadNewSource("");
        toast.success("New Lead Source Added");
      } else {
        console.log(res.error);
      }
    }
  };
  const saveworkScope = async () => {
    if (addNewWorkScope.length > 0) {
      const res = await postReq(`${constants.CRM_API}/user/save-work-type`, {
        userId: localStorage.getItem("userId"),
        type: addNewWorkScope,
      });

      if (res && !res.error) {
        // console.log('getting lead source', res.data.data)
        getWorkTypes();
        setAddNewWorkType("");
        toast.success("New Work Scope Added");
      } else {
        console.log(res.error);
      }
    }
  };
  const savesourceName = async () => {
    if (addNewSourceName.length > 0) {
      const res = await postReq(`${constants.CRM_API}/user/save-source-name`, {
        userId: localStorage.getItem("userId"),
        sourceName: addNewSourceName,
      });

      if (res && !res.error) {
        // console.log('getting lead source', res.data.data)
        getSourceName();
        setAddNewSourceName("");
        toast.success("New Source Name Added");
      } else {
        console.log(res.error);
      }
    }
  };
  const [formData, setFormData] = useState({});
  const [teamLeadArr, setTeamLeadArr] = useState([]);
  const [budget, setBudget] = useState("");
  const [error, setError] = useState(false);
  const projectObj = useSelector((state) => state.newLeadObj);
  const tempObj = {
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    city: "",
    pinCode: "",
    phoneNumber: "",
    budget: "",
    requirements: "",
    leadSource: "",
    sourceName: "",
    assignedTo: "",
    area: "",
  };
  const exceptThisSymbols2 = [
    "~",
    "`",
    "!",
    "#",
    "%",
    "$",
    "^",
    "&",
    "*",
    "(",
    " ",
    ")",
    "+",
    "=",
    "[",
    "_",
    "]",
    "'",
    ";",
    "/",
    "{",
    "}",
    "|",
    ,
    "<",
    ">",
    "?",
    ",",
  ];

  const leadFirstNameHandle = (e) => {
    if (e.target.value?.length <= 24) {
      if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
        setLeadFirstName(e.target.value);
        setFirstNameError(false);
      }
    } else {
      setFirstNameError(true);
    }
  };

  const leadCityHandle = (e) => {
    if (e.target.value?.length <= 23 && /^[a-zA-Z\s]*$/.test(e.target.value)) {
      setLeadCity(e.target.value);
    }
  };

  const leadLastNameHandle = (e) => {
    if (e.target.value?.length <= 24) {
      if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
        setLeadLastName(e.target.value);
        setLastNameError(false);
      }
    } else {
      setLastNameError(true);
    }
  };
  const handlePhoneNumberChange = (event) => {
    const inputValue = event.target.value.replace(/[^0-9]/g, "");
    if (event.target.value?.length <= 10) {
      setPhonenumber(inputValue);
      if (/^\d+$/.test(inputValue) && inputValue.length <= 10) {
        if (inputValue.length <= 9) {
          setPhoneError(true);
        } else {
          setPhoneError(false);
        }
        setPhonenumber(inputValue);
      }
    }
  };
  const handleNewLeadClose = () => {
    setLeadFirstName("");
    setLeadLastName("");
    setPhonenumber("");
    setEmailError(false);
    setLeadEmail("");
    setLeadCity("");
    setLeadArea("");
    setLeadAddress("");
    setWorkScope("");
    setLeadSource("");
    setSourceName("");
    setAssignedTo("");
    setErrorPin(false);
    setLeadArea("");
    setLeadCityCode("");
    setBudget("");
    setFirstNameError(false);
    setLastNameError(false);
    setDiscardOpen(false);
    dispatch(setAddLeadModalOpen(false));
  };
  const handlePinCodeChange = (event) => {
    const inputValue = event.target.value.replace(/[^0-9]/g, "");
    if (event.target.value?.length <= 6) {
      setLeadCityCode(inputValue);

      if (
        /^\d+$/.test(inputValue) &&
        inputValue.length >= 6 &&
        inputValue.length <= 6
      ) {
        setLeadCityCode(inputValue);
        setErrorPin(false);
      } else {
        setErrorPin(true);
        setLeadCityCode(inputValue);
      }
    }
  };
  const handleEmailChange = (event) => {
    const inputValue = event.target.value;
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i;

    if (inputValue.length < 64 && emailPattern.test(inputValue)) {
      setLeadEmail(inputValue);
      setEmailError(false);
    } else {
      if (inputValue?.length == 0) {
        setEmailError(false);
      } else {
        setEmailError(true);
      }
      if (inputValue.length < 64) {
        setLeadEmail(inputValue);
      }
    }
  };

  const handleBudget = (event) => {
    const inputValue = event.target.value.replace(/[^0-9]/g, "");
    if (event.target.value?.length <= 10) {
      setBudget(inputValue);
      if (/^\d+$/.test(inputValue) && inputValue.length <= 9) {
        setBudget(inputValue);
      }
    }
  };

  function handleLeadArea(event) {
    const inputValue = event.target.value.replace(/[^0-9]/g, "");
    if (event.target.value?.length <= 6) {
      setLeadArea(inputValue);
      if (/^\d+$/.test(inputValue) && inputValue.length <= 6) {
        setLeadArea(inputValue);
      }
    }
  }

  const handleSubmit = () => {
    if (
      addLeadFormTemplate?.fields[0]?.isFirstNameMandatory &&
      leadFirstName == ""
    ) {
      alert("Please enter First Name.");
    } else if (
      addLeadFormTemplate?.fields[0]?.isLastNameMandatory &&
      leadLastName == ""
    ) {
      alert("Please enter Last Name.");
    } else if (
      addLeadFormTemplate?.fields[0]?.isContactNumberMandatory &&
      (phoneError || phoneNumber == "")
    ) {
      alert("Please enter Contact Number.");
    } else if (
      addLeadFormTemplate?.fields[0]?.isEmailMandatory &&
      (emailError || leadEmail == "")
    ) {
      alert("Please enter email.");
    } else if (
      addLeadFormTemplate?.fields[0]?.isAddressMandatory &&
      leadAddress == ""
    ) {
      alert("Please enter address.");
    } else if (
      addLeadFormTemplate?.fields[0]?.isCityMandatory &&
      leadCity == ""
    ) {
      alert("Please enter city.");
    } else if (
      addLeadFormTemplate?.fields[0]?.isPostalCodeMandatory &&
      (errorPin || leadCityCode == "")
    ) {
      alert("Please enter postal code.");
    } else if (
      addLeadFormTemplate?.fields[0]?.isWorkScopeMandatory &&
      workScope == ""
    ) {
      alert("Please enter work scope.");
    } else if (
      addLeadFormTemplate?.fields[0]?.isBudgetMandatory &&
      budget == ""
    ) {
      alert("Please enter Budget.");
    } else if (
      addLeadFormTemplate?.fields[0]?.isAreaMandatory &&
      leadArea == ""
    ) {
      alert("Please enter Area.");
    } else if (
      addLeadFormTemplate?.fields[0]?.isLeadSourceMandatory &&
      selectedLeadSource == ""
    ) {
      alert("Please enter Lead source.");
    } else if (
      addLeadFormTemplate?.fields[0]?.isSouceNameMandatory &&
      selectedSourceName == ""
    ) {
      alert("Please enter Source name");
    } else if (
      addLeadFormTemplate?.fields[0]?.isAssignToMandatory &&
      assignedTo == ""
    ) {
      alert("Please enter Assign to");
    } else {
      handleFormSubmit({
        firstName: leadFirstName,
        lastName: leadLastName ? leadLastName : "",
        email: leadEmail,
        address: leadAddress,
        city: leadCity,
        pinCode: leadCityCode,
        phoneNumber: phoneNumber,
        budget: budget,
        requirements: workScope,
        leadSource: selectedLeadSource,
        sourceName: selectedSourceName,
        assignedTo: assignedTo,
        area: leadArea,
        areaUnit: areaUnit,
        templateId: addLeadFormTemplate._id,
      });
    }
  };
  const getUserbyId = async (id) => {
    const res = await getReq(`${constants.CRM_API}/user/getUserById?userId=${id}`);
    if (res && !res.error) {
      setUserData(res?.data?.data);
    } else {
      console.log(res.error);
    }
  };

  const showError = (error) => {
    // console.log(error)
    if (error.response && error.response.data.message) {
      // toast.error(error.response.data.message);
      toast.error(error.response.data.message, {
        // position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      // clg
      // alert(error.response.data.message)
      return;
    } else {
      window.location.reload();
    }
  };
  // const addLeadNotificationType = allNotificationsObjects?.filter(
  //   (notificationObject) => notificationObject.CTA === "Add Lead"
  // );
  // console.log("addLeadNotificationType", addLeadNotificationType);

  const handleFormSubmit = async (data) => {
    setButtonClickedOnce(true);
    // console.log({...data});
    try {
      // await setProgress(30);
      for (const k in data) {
        const e = data[k];
        if (e?.trim() === "") {
          delete data[k];
        }
      }
      // if (data?.assignedTo == "UnAssigned") {
      //   delete data["assignedTo"];
      //   data["assignedTo"] = null;
      // }
      // console.log(data);

      const res = await postReq(constants.CRM_API + "/user/addEditLead", {
        ...data,
        architectDetails: sourceNameArchitectDetails,
      });
      if (res.error) {
        showError(res.error);
      }

      if (res && !res.error) {
        setButtonClickedOnce(false);
        dispatch(setAddLeadModalOpen(false));
        apiFetch(tab);
        getLeadsCount(tab);
        notification.success({
          message: res?.message || "Lead Created Successfully",
          duration: 2,
        });

        console.log("responseof creatinglead", res);
        const assignedToObjectReceivers =
          assignedToObject == "" ? haveAssigneToAccess : [assignedToObject];
        console.log("afterpushinarray", assignedToObjectReceivers);
        handleNewLeadClose();
        sendEmailPushBellNotifications(
          "Add Lead",
          {
            "@leadName": `${leadFirstName} ${leadLastName}`,
            "@leadName": `${leadFirstName} ${leadLastName}`,
            "@leadCompany": profileData.CompanySettingDetails.companyName,
            "@Lead Email": leadEmail,
            "@leadPhone": phoneNumber,
            "@leadSource": leadSource,
            "@SalesRep": assignedToName,
          },
          [...assignedToObjectReceivers]
        );

        //   const usersToReceiveMails = [];
        //   if (callingNotificationServiceType.superAdmin) {
        //     usersToReceiveMails.push(profileData.superAdmins);
        //   }
        //   if (callingNotificationServiceType.admins) {
        //     usersToReceiveMails.push(profileData.admins);
        //   }
        //   const removingDublicateUsers = usersToReceiveMails.filter(
        //     (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
        //   )[0];
        //   if (callingNotificationServiceType.type == 1) {

        //     const stringToReplaceInEmailString = {
        //       // "[Client Name]": `${leadFirstName}`,
        //       "[Lead Name]": `${leadFirstName} ${leadLastName}`,
        //       "[Lead Company]": profileData.CompanySettingDetails.companyName,
        //       "[Lead Email]": leadEmail,
        //       "[Lead Phone]": phoneNumber,
        //       "[Lead Source]": leadSource,
        //       "[Assigned Sales Rep]": assignedToName,
        //     };
        //     // const subject = callingNotificationServiceType.subject;
        //     let emailString = callingNotificationServiceType.emailString;
        //     const replacedSubject = callingNotificationServiceType.subject.replace(
        //       "[Lead Name]",
        //       `${leadFirstName} ${leadLastName}`
        //     );
        //     for (let key in stringToReplaceInEmailString) {
        //       emailString = emailString.replace(
        //         key,
        //         stringToReplaceInEmailString[key]
        //       );
        //     }

        //     console.log("emailstring: ", emailString);
        //     // const replacedEmailString = emailString.replace('[Client Name]',`${leadFirstName} ${leadLastName}`)
        //     removingDublicateUsers.map(async (Mailuser) => {
        //       const updatedClientEmailBody = emailString.replace(
        //         "[Client Name]",
        //         Mailuser.fullName.split(" ")[0]
        //       );
        //       const res = await postReq(
        //         constants.notify + "/api/notifications/send-service?type=1",
        //         {
        //           companyName: profileData.CompanySettingDetails.companyName,
        //           recieverEmails: ['uttam@idesign.market'],
        //           subject: replacedSubject,
        //           htmlContent: updatedClientEmailBody,
        //         }
        //       );
        //       if (res.error) {
        //         console.log(res.error);
        //       }
        //     });
        //   } else if (callingNotificationServiceType.type == 2) {
        //     const updatedNotificationString =
        //       callingNotificationServiceType.notificationString.replace(
        //         "[leadname]",
        //         `${leadFirstName} ${leadLastName}`
        //       );
        //     const res = await postReq(
        //       constants.notify + "/api/notifications/send-service?type=2",
        //       {
        //         userId: localStorage.getItem("userId"),
        //         // receiverId: 6518f9cedc770dc818ee8898,
        //         notification: updatedNotificationString,
        //         featureName: "LMS",
        //         featureLink: `${constants. BASE_URL}`,
        //         // extraUserIds: [64802123035f491df0ea5756,64880a71fcc15d20a419b83d],//optional
        //         // clientId: 64880ca6b08bde3ab8f78a97,//optional
        //         // shareWithClient: true,//optional
        //         // forAdmins:[64802123035f491df0ea5756,64880a71fcc15d20a419b83d],//optional
        //       }
        //     );
        //     if (res.error) {
        //       console.log(res.error);
        //     }
        //   } else if (callingNotificationServiceType.type == 3) {
        //     const updatedNotificationString =
        //       callingNotificationServiceType.notificationString.replace(
        //         "[leadname]",
        //         `${leadFirstName} ${leadLastName}`
        //       );
        //       console.log('removingDublicateUsers', removingDublicateUsers)
        //     const userIds = removingDublicateUsers.map((user) => user._id);
        //     console.log('userIdsss', userIds)

        //     const res = await postReq(
        //       constants.notify + "/api/notifications/send-service?type=3",
        //       {
        //         recieverIds: userIds,
        //         notification: updatedNotificationString,
        //         projectName: '-'
        //       }
        //     );
        //     if (res.error) {
        //       console.log(res.error);
        //     }
        //   }
        // });
      }
      await setProgress(100);
    } catch (error) {
      setButtonClickedOnce(false);
      // console.log(error)
      // dispatch(setAddLeadModalOpen(false))
      // showError(error);
    }
  };
  const getTeamLeads = async () => {
    const res = await getReq(
      `${
        constants.CRM_API
      }/api/enterprise/get-team-leaders?userId=${localStorage.getItem(
        "userId"
      )}&access=1`
    );
    if (res && !res.error) {
      setTeamLeadArr(res.data.data);
    } else {
      console.log(res.error);
    }
  };

  const getLeadSource = async () => {
    const res = await getReq(
      `${constants.CRM_API}/user/get-lead-source?userId=${localStorage.getItem(
        "userId"
      )}`
    );
    if (res && !res.error) {
      // console.log('getting lead source', res.data.data)
      setLeadSources(res.data.data);
    } else {
      console.log(res.error);
    }
  };
  const getWorkTypes = async () => {
    const res = await getReq(
      `${constants.CRM_API}/user/get-work-types?userId=${localStorage.getItem(
        "userId"
      )}`
    );
    if (res && !res.error) {
      setLeadWorkTypes(res.data.data);
    } else {
      console.log(res.error);
    }
  };
  const getSourceName = async () => {
    const res = await getReq(
      `${constants.CRM_API}/user/get-source-name?userId=${localStorage.getItem(
        "userId"
      )}`
    );
    if (res && !res.error) {
      // console.log('getting lead source', res.data.data)
      setSourceNames(res.data.data);
    } else {
      console.log(res.error);
    }
  };
  const handleLeadSource = (e) => {
    // const tempLeadSource = leadSources.find(
    //   (leadSource) => leadSource.id === e.target.value
    // );
    // setSelectedLeadSource(tempLeadSource.leadSource);
    // setLeadSource(e.target.value);
    setSelectedLeadSource(e.target.value);
  };
  const handleSourceName = (sourceName1) => {
    // setSourceName(e.target.value);
    const tempSourceName = sourceNames.find(
      (sourceName) => sourceName.sourceName === sourceName1
    );
    setSelectedSourceName(tempSourceName.sourceName);
    if (tempSourceName?.architectDetails) {
      setSourceNameArchitectDetails(tempSourceName?.architectDetails);
    }
  };
  useEffect(() => {
    getLeadSource();
    getSourceName();
    getTeamLeads();
    getWorkTypes();
    getUserbyId(localStorage.getItem("userId"));
  }, []);
  const [discardOpen, setDiscardOpen] = useState(false);

  const handleOnFocus = (fieldName, errorStateName) => {
    if (fieldName?.length === 0) {
      errorStateName(true);
    }
  };

  <script type="text/javascript"></script>;
  return (
    <>
      <Modal
        title={false}
        show={discardOpen}
        centered
        width={200}
        bodyStyle={{ height: 60 }}
        closable={false}
      >
        <div
          style={{
            border: "1px solid black",
            borderRadius: "5px",
            margin: "5px",
          }}
        >
          <h6 style={{ padding: "3%" }}>Do you want to discard the lead ?</h6>
          <Modal.Footer>
            <div className={styles.discardCloseBtn}>
              <button
                className={styles.createModalSave}
                onClick={handleNewLeadClose}
              >
                <span
                  style={{
                    color: "#FFF",
                    fontWeight: "600",
                    fontSize: "1rem",
                  }}
                >
                  Yes{" "}
                </span>
              </button>
              <button
                className={styles.createModalClose}
                onClick={() => setDiscardOpen(false)}
              >
                <span
                  style={{
                    color: "#C9C9C9",
                    fontWeight: "600",
                    fontSize: "1rem",
                  }}
                >
                  No{" "}
                </span>
              </button>
            </div>
          </Modal.Footer>
        </div>
      </Modal>
      <Modal
        show={addLeadModalOpen}
        onHide={() => handleNewLeadClose()}
        backdrop="static"
        keyboard={false}
        centered
        width={300}
        // size="lg"
        // width='1000px'
      >
        <Modal.Header
          // closeButton
          style={{
            borderBottom: "none",
            backgroundColor: "#0084ef",
            color: "#ffffff",
          }}
        >
          <Modal.Title>Add New Lead</Modal.Title>
          <button
            type="button"
            onClick={() => setDiscardOpen(true)}
            class="btn-close"
            aria-label="Close"
          ></button>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "65vh", overflow: "scroll" }}>
          <div className={styles.formName}>
            {addLeadFormTemplate?.fields[0]?.firstName && (
              <TextField
                style={{ width: "14rem" }}
                id="outlined-basic"
                label={
                  addLeadFormTemplate?.fields[0]?.isFirstNameMandatory
                    ? "First Name*"
                    : "First Name"
                }
                size="small"
                helperText={
                  firstNameError && leadFirstName.length == 0
                    ? "Please enter first name"
                    : firstNameError && "Limit of 24 characters is reached"
                }
                variant="outlined"
                onFocus={() => handleOnFocus(leadFirstName, setFirstNameError)}
                isRequired={true}
                error={firstNameError}
                value={leadFirstName}
                InputProps={{
                  inputMode: "text",
                }}
                onChange={(e) => {
                  leadFirstNameHandle(e);
                }}
              />
            )}
            {addLeadFormTemplate?.fields[0]?.lastName && (
              <TextField
                style={{ width: "14rem" }}
                id="outlined-basic"
                label={
                  addLeadFormTemplate?.fields[0]?.isLastNameMandatory
                    ? "Last Name*"
                    : "Last Name"
                }
                variant="outlined"
                size="small"
                helperText={
                  lastNameError && leadLastName.length == 0
                    ? "Please enter last name"
                    : lastNameError && "Limit of 24 characters is reached"
                }
                error={lastNameError}
                onFocus={() => handleOnFocus(leadLastName, setLastNameError)}
                isRequired={true}
                value={leadLastName}
                InputProps={{
                  inputMode: "text",
                }}
                onChange={(e) => leadLastNameHandle(e)}
              />
            )}
          </div>
          <div className={styles.formName}>
            {addLeadFormTemplate?.fields[0]?.contactNumber && (
              <TextField
                style={{ width: "14rem" }}
                id="outlined-basic"
                label="Contact No*"
                size="small"
                variant="outlined"
                // isRequired={true}
                helperText={
                  phoneNumber.length > 0
                    ? phoneNumber.length < 10
                      ? "Please enter valid number"
                      : ""
                    : ""
                }
                value={phoneNumber}
                onChange={(e) => handlePhoneNumberChange(e)}
                error={phoneNumber.length > 0 && phoneNumber.length < 10}
                InputProps={{
                  inputMode: "numeric",
                  startAdornment: (
                    <InputAdornment position="start">
                      {profileData?.CompanySettingDetails?.countryCode}
                    </InputAdornment>
                  ),
                }}
              />
            )}
            {addLeadFormTemplate?.fields[0]?.email && (
              <TextField
                style={{ width: "14rem" }}
                id="outlined-basic"
                label="Email Address*"
                variant="outlined"
                isRequired={true}
                value={leadEmail}
                size="small"
                onKeyDown={(e) =>
                  exceptThisSymbols2.includes(e.key) && e.preventDefault()
                }
                error={emailError}
                helperText={
                  emailError
                    ? leadEmail.length == 0
                      ? "Please input email"
                      : "Invalid email address"
                    : ""
                }
                onChange={(e) => handleEmailChange(e)}
              />
            )}
          </div>
          {userData?.rolesAssigned?.crm?.canAssign &&
              addLeadFormTemplate?.fields[0]?.assignTo && (<div className={styles.formName}>
           
                <FormControl style={{ width: "100%" }} size="small">
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={teamLeadArr?.map((teamLead) => {
                      return {
                        label: teamLead.fullName,
                        _id: teamLead._id,
                        email: teamLead.email,
                      };
                    })}
                    onChange={(object, value) => (
                      setAssignedTo(value?._id),
                      setAssignedToName(value?.label),
                      setAssignedToObject({
                        email: value?.email,
                        fullName: value?.label,
                        _id: value?._id,
                      })
                    )}
                    // sx={{ width: 225 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        required={
                          addLeadFormTemplate?.fields[0]?.isAssignToMandatory
                        }
                        label={
                          addLeadFormTemplate?.fields[0]?.isAssignToMandatory
                            ? "Assign To"
                            : "Assign To"
                        }
                      />
                    )}
                    // MenuProps={{ PaperProps: { sx: { maxHeight: 100 } } }}
                  />
                </FormControl>
             
          </div> )}
          {addLeadFormTemplate?.fields[0]?.address && (
            <div className={styles.formName}>
              <TextField
                style={{ width: "30rem" }}
                id="outlined-basic"
                label={
                  addLeadFormTemplate?.fields[0]?.isAddressMandatory
                    ? "Address*"
                    : "Address"
                }
                variant="outlined"
                size="small"
                isRequired={addLeadFormTemplate?.fields[0]?.isAddressMandatory}
                value={leadAddress}
                onChange={(e) => setLeadAddress(e.target.value)}
              />
            </div>
          )}
          {addLeadFormTemplate?.fields[0]?.city && (<div className={styles.formName}>
            
              <TextField
                style={{ width: "100%" }}
                id="outlined-basic"
                label={
                  addLeadFormTemplate?.fields[0]?.isCityMandatory
                    ? "City*"
                    : "City"
                }
                size="small"
                variant="outlined"
                isRequired={addLeadFormTemplate?.fields[0]?.isCityMandatory}
                value={leadCity}
                onChange={(e) => leadCityHandle(e)}
              />
          
          </div>  )}
          {addLeadFormTemplate?.fields[0]?.postalCode && (<div className={styles.formName}>
            
              <TextField
                style={{ width: "100%" }}
                id="outlined-basic"
                label={
                  addLeadFormTemplate?.fields[0]?.isPostalCodeMandatory
                    ? "Zip/Postal code*"
                    : "Zip/Postal code"
                }
                variant="outlined"
                size="small"
                isRequired={
                  addLeadFormTemplate?.fields[0]?.isPostalCodeMandatory
                }
                value={leadCityCode}
                error={errorPin}
                helperText={
                  errorPin
                    ? leadCityCode.length == 0
                      ? "Please enter city code"
                      : "Invalid Pin Code"
                    : ""
                }
                onChange={(e) => handlePinCodeChange(e)}
                InputProps={{
                  inputMode: "numeric",
                }}
              />
          
          </div>  )}
        
            {!addLeadFormTemplate?.fields[0]?.workScopeShortAnswer ? (
              addLeadFormTemplate?.fields[0]?.workScope.length > 0 && (
                <div className={styles.formName}>
                <FormControl style={{ width: "100%" }} size="small">
                  <InputLabel id="demo-select-large-label">
                    {addLeadFormTemplate?.fields[0]?.isWorkScopeMandatory
                      ? "Work Scope*"
                      : "Work Scope"}
                  </InputLabel>
                  <Select
                    labelId="demo-select-large-label"
                    id="demo-select-large"
                    value={workScope}
                    isRequired={
                      addLeadFormTemplate?.fields[0]?.isWorkScopeMandatory
                    }
                    label={
                      addLeadFormTemplate?.fields[0]?.isWorkScopeMandatory
                        ? "Work Scope*"
                        : "Work Scope"
                    }
                    onChange={(e) => setWorkScope(e.target.value)}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: 150,
                          "&.MuiPaper-root": {
                            left:
                              window.screen.width < 1400
                                ? "32.8vw !important"
                                : "35vw !important",
                          },
                        },
                      },
                    }}
                  >
                    {addLeadFormTemplate?.fields[0]?.workScope?.map(
                      (leadWorkType) => {
                        return (
                          <MenuItem value={leadWorkType}>
                            {leadWorkType}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl></div>
              )
            ) : (
              <div className={styles.formName}>
              <TextField
                style={{ width: "100%" }}
                id="outlined-basic"
                label={
                  addLeadFormTemplate?.fields[0]?.isWorkScopeMandatory
                    ? "Work Scope*"
                    : "Work Scope"
                }
                size="small"
                variant="outlined"
                isRequired={
                  addLeadFormTemplate?.fields[0]?.isWorkScopeMandatory
                }
                value={workScope}
                onChange={(e) => setWorkScope(e.target.value)}
              /> </div>
            )}
         {" "}
          
            {addLeadFormTemplate?.fields[0]?.budgetShortAmswer ? (<div className={styles.formName}>
              <TextField
                style={{ width: "100%" }}
                id="outlined-basic"
                label={
                  addLeadFormTemplate?.fields[0]?.isBudgetMandatory
                    ? "Budget*"
                    : "Budget"
                }
                size="small"
                variant="outlined"
                isRequired={addLeadFormTemplate?.fields[0]?.isBudgetMandatory}
                value={budget}
                onChange={(e) => handleBudget(e)}
                InputProps={{
                  inputMode: "numeric",
                  startAdornment: (
                    <InputAdornment position="start">
                      {profileData?.CompanySettingDetails?.currencySymbol}
                    </InputAdornment>
                  ),
                }}
              /></div>
            ) : (
              addLeadFormTemplate?.fields[0]?.budget.length > 0 && (
                <div className={styles.formName}>
                <FormControl style={{ width: "14rem" }} size="small">
                  <InputLabel id="demo-select-large-label">
                    {addLeadFormTemplate?.fields[0]?.isBudgetMandatory
                      ? "Budget*"
                      : "Budget"}
                  </InputLabel>
                  <Select
                    labelId="demo-select-large-label"
                    id="demo-select-large"
                    value={budget}
                    label="Budget"
                    required={addLeadFormTemplate?.fields[0]?.isBudgetMandatory}
                    onChange={(e) => setBudget(e.target.value)}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: 150,
                          "&.MuiPaper-root": {
                            left:
                              window.screen.width < 1400
                                ? "32.8vw !important"
                                : "35vw !important",
                          },
                        },
                      },
                    }}
                  >
                    {addLeadFormTemplate?.fields[0]?.budget?.map((bud) => {
                      return <MenuItem value={bud}>{bud}</MenuItem>;
                    })}
                  </Select>
                </FormControl>  </div>
              )
            )}
        
         
            {addLeadFormTemplate?.fields[0]?.areaShortAnswer ? ( <div className={styles.formName}>
              <TextField
                style={{ width: "100%" }}
                id="outlined-basic"
                label={
                  addLeadFormTemplate?.fields[0]?.isAreaMandatory
                    ? "Area*"
                    : "Area"
                }
                size="small"
                variant="outlined"
                isRequired={addLeadFormTemplate?.fields[0]?.isAreaMandatory}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Select
                        variant="standard"
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={areaUnit}
                        renderValue={() => areaUnit}
                        label="Unit"
                        // onChange={handleChange}
                        InputProps={{
                          disableUnderline: true, // <== added this
                        }}
                        disableUnderline 
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              maxHeight: 150,
                              "&.MuiPaper-root": {
                                left:
                                  window.screen.width < 1400
                                    ? "48.5vw !important"
                                    : "52.5vw !important",
                              },
                            },
                          },
                        }}
                      >
                        <MenuItem
                          sx={{ minWidth: 200 }}
                          onClick={() => setAreaUnit("sqft")}
                          value={"sqft"}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className={styles.areaUnit}>Sqft</div>
                          <div className={styles.areaDescription}>
                            Square feet
                          </div>
                        </MenuItem>
                        <MenuItem
                          sx={{ minWidth: 200 }}
                          onClick={() => setAreaUnit("sqm")}
                          value={"sqm"}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className={styles.areaUnit}>Sqm</div>
                          <div className={styles.areaDescription}>
                            Square meter
                          </div>
                        </MenuItem>
                      </Select>
                    </InputAdornment>
                  ),
                }}
                value={leadArea}
                onChange={handleLeadArea}
              /></div>
            ) : (
              addLeadFormTemplate?.fields[0]?.area?.length > 0 && (
                <div className={styles.formName}>
                <FormControl style={{ width: "100%" }} size="small">
                  <InputLabel id="demo-select-large-label">
                    {addLeadFormTemplate?.fields[0]?.isAreaMandatory
                      ? "Area*"
                      : "Area"}
                  </InputLabel>
                  <Select
                    labelId="demo-select-large-label"
                    id="demo-select-large"
                    value={leadArea}
                    label="Area"
                    onChange={(e) => setLeadArea(e.target.value)}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: 150,
                          "&.MuiPaper-root": {
                            left:
                              window.screen.width < 1400
                                ? "32.8vw !important"
                                : "35vw !important",
                          },
                        },
                      },
                    }}
                  >
                    {addLeadFormTemplate?.fields[0]?.area?.map((ar) => {
                      return <MenuItem value={ar}>{ar}</MenuItem>;
                    })}
                  </Select>
                </FormControl></div>
              )
            )}
        
            {!addLeadFormTemplate?.fields[0]?.leadSourceShortAnswer ? (
              addLeadFormTemplate?.fields[0]?.leadSource.length > 0 && (
                <div className={styles.formName}>
                <FormControl style={{ width: "100%" }} size="small">
                  <InputLabel id="demo-select-large-label">
                    {addLeadFormTemplate?.fields[0]?.isLeadSourceMandatory
                      ? "Lead Source*"
                      : "Lead Source"}
                  </InputLabel>
                  <Select
                    labelId="demo-select-large-label"
                    id="demo-select-large"
                    value={selectedLeadSource}
                    isRequired={
                      addLeadFormTemplate?.fields[0]?.isLeadSourceMandatory
                    }
                    label="Lead Source"
                    onChange={(e) => handleLeadSource(e)}
                    MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
                  >
                    {addLeadFormTemplate?.fields[0]?.leadSource?.map(
                      (ldSource) => {
                        return <MenuItem value={ldSource}>{ldSource}</MenuItem>;
                      }
                    )}
                  </Select>
                </FormControl></div>
              )
            ) : (  <div className={styles.formName}>
              <TextField
                style={{ width: "100%" }}
                id="outlined-basic"
                label={
                  addLeadFormTemplate?.fields[0]?.isLeadSourceMandatory
                    ? "Lead Source*"
                    : "Lead Source"
                }
                size="small"
                variant="outlined"
                isRequired={
                  addLeadFormTemplate?.fields[0]?.isLeadSourceMandatory
                }
                value={selectedLeadSource}
                onChange={(e) => setSelectedLeadSource(e.target.value)}
                // InputProps={{
                //   inputMode: "numeric",
                //   startAdornment: (
                //     <InputAdornment position="start">
                //       {profileData?.CompanySettingDetails?.currencySymbol}
                //     </InputAdornment>
                //   ),
                // }}
              /></div>
            )}
          
            {!addLeadFormTemplate?.fields[0]?.sourceNameShortAnswer ? (
              addLeadFormTemplate?.fields[0]?.sourceName.length > 0 && (
                <div className={styles.formName}>
                <FormControl style={{ width: "100%" }} size="small">
                  <InputLabel id="demo-select-large-label">
                    {addLeadFormTemplate?.fields[0]?.isSouceNameMandatory
                      ? "Source name*"
                      : "Source name"}
                  </InputLabel>
                  <Select
                    labelId="demo-select-large-label"
                    id="demo-select-large"
                    value={selectedSourceName}
                    label="Source name"
                    required={
                      addLeadFormTemplate?.fields[0]?.isSouceNameMandatory
                    }
                    // error={leadSourceClicked && !selectedLeadSource}
                    // helperText="Please select a Lead source"
                    // onClick={() => setLeadSourceClicked(true)}
                    // onBlur={() => setLeadSourceClicked(false)}
                    onChange={(e) => setSelectedSourceName(e.target.value)}
                    MenuProps={{
                      PaperProps: {
                        sx: { maxHeight: 150 },
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                      },
                    }}
                  >
                    {addLeadFormTemplate?.fields[0]?.sourceName?.map(
                      (srceName) => {
                        return <MenuItem value={srceName}>{srceName}</MenuItem>;
                      }
                    )}
                  </Select>
                  {/* {!selectedLeadSource && leadSourceClicked && (
                    <FormHelperText>
                      <span style={{ color: "#d32f2f" }}>
                        Please select lead source
                      </span>
                    </FormHelperText>
                  )} */}
                </FormControl></div>
              )
            ) : (
              <div className={styles.formName}>
              <TextField
                style={{ width: "100%" }}
                id="outlined-basic"
                label={
                  addLeadFormTemplate?.fields[0]?.isSouceNameMandatory
                    ? "Source name*"
                    : "Source name"
                }
                size="small"
                variant="outlined"
                isRequired={
                  addLeadFormTemplate?.fields[0]?.isSouceNameMandatory
                }
                value={selectedSourceName}
                onChange={(e) => setSelectedSourceName(e.target.value)}
                // InputProps={{
                //   inputMode: "numeric",
                //   startAdornment: (
                //     <InputAdornment position="start">
                //       {profileData?.CompanySettingDetails?.currencySymbol}
                //     </InputAdornment>
                //   ),
                // }}
              /></div>
            )}
        </Modal.Body>
        <Modal.Footer>
          <div className={styles.closeBtn}>
            <button
              className={styles.createModalClose}
              onClick={() => setDiscardOpen(true)}
            >
              <span
                style={{
                  color: "#C9C9C9",
                }}
              >
                Cancel{" "}
              </span>
            </button>
            <button
              disabled={buttonClickedOnce}
              className={styles.createModalSave}
              onClick={handleSubmit}
            >
              <span
                style={{
                  color: "#FFF",
                }}
              >
                {!buttonClickedOnce ? (
                  "Add Lead"
                ) : (
                  <Spinner
                    animation="border"
                    role="status"
                    style={{ height: "1.25rem", width: "1.25rem" }}
                  >
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                )}
              </span>
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddLeadModalNewForFormTemplate;
