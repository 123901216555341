import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    fields: [],
}

const leadManagementSettingsSilce = createSlice({
    name: "leadManagementSettings",
    initialState,
    reducers: {
        addAllFields(state, action) {
            state.fields = action.payload;
        }
    }
});

export const leadManagementSettingsActions = leadManagementSettingsSilce.actions;
export default leadManagementSettingsSilce.reducer;