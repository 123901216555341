export const apiEndpoints = {
  sendOtp: "/send",
  verifyOtp: "/api/verify",
  updateProfile:"/user/updateProfile",
  profile:"/user/profile",
  Register:"/user/register",
  updatePassword:"/api/updatePassword",
  createPassword:"/api/updatePassword",
  companyDetails:"/api/addCompany",
  teamOnboard:"/user/add-company-teamMembers",
  getDesignation:"/api/enterprise/get-designations",
  createDesignation:"/api/enterprise/create-designation",
  login:"/user/login",
};

export const navRoutes = {
  homepage: "/",
};
