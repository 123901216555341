import React, { useEffect, useState } from "react";
import bgmain from "../../Assets/bg-main.svg";
import { useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import { useSelector } from "react-redux";
import styles from "../Signup/signup.module.css";
import { postReq, getReq } from "../../piservices/apis";
import { apiEndpoints } from "../../piservices/enums";
import { PiEyeLight, PiEyeClosedLight } from "react-icons/pi";
import LoginHeader from "../loginHeader/LoginHeader";
import { multipleValidationFunction, toasterControl } from "../../piservices/commonService";
import expf, { constants} from "../../piservices/constants";
import axios from "axios";

const LoginPage = () => {
  const navigateTo = useNavigate();
  const { otpEmail, userType, companyId } = useSelector((state) => state.persist);
  const [inputPassword, setinputPassword] = useState({ password: "",
    confirmPassword: "",
  });
const iDesignWebsite ="https://www.idesign.market";
const signUpValidationObject = {
  password: {
    required: true,
    minLength: 8,
    maxLength: 15,
    upperCasePattern: /[A-Z]/,
    specialCharacterPattern: /[!@#$%^&*()_+{}[\]:;<>,.?~|/-]/,
  },
};
   
  const [confirmErr, setConfirmErr] = useState("");

  const [passedChecks, setPassedChecks] = useState([]);

  const [inputType, setInputType] = useState("password");
  const [confirmInputType, setConfirmInputType] = useState("password");

  const handlePassword = (event) => {
    const { name, value } = event.target;

    if (name === "password") {
      let valArr = multipleValidationFunction(signUpValidationObject[name], value);
      setPassedChecks(valArr);
    }

    if (name === "confirmPassword") {
      if (inputPassword.password === value) {
        setConfirmErr("");
      } else {
        setConfirmErr("Passwords do not match");
      }
    }

    if(value.length<=15){
      setinputPassword((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    }
  };

  const handleLogin = async () => {
    await axios
      .post(
        `${
          constants.CRM_API
        }/user/edit-user-details?userId=${localStorage.getItem("userId")}`,
        {
          isFirstTimeLogin: false,
        }
      )
      .then((res) => {
        // localStorage.removeItem("emailId")
        console.log("api req success", res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // create password
  const createPass = async () => {
    const res = await postReq(`${constants.CRM_API}${apiEndpoints.createPassword}`, {
      email: localStorage.getItem("emailId").toString(),
      password: inputPassword.confirmPassword,
    });
    if (res && !res.error) {
      console.log(res);
      // toasterControl(true, "Password created successfully.", "success");
    //   navigateTo("/");
    // window.location.assign(`${constants. BASE_URL}/admin/`);
    const profRes = await getReq(`${constants.CRM_API}/user/profile`, {
      headers: {
        Authorization: `Bearer${localStorage.getItem("token")}`,
      },
    });
    if (profRes && !profRes.error) {
       handleLogin();
     if(profRes?.data?.data?.isOnboardingDone==false && passedChecks.length===0){
      // if (profRes?.data?.data?.isAdmin==true || profRes?.data?.data?.isSuperAdmin==true ){
        if (profRes?.data?.data?.isSuperAdmin==true ){
        // navigateTo("/personal-data");
        navigateTo("/company-details")
        toasterControl(true, "Password created successfully.", "success");
        localStorage.removeItem("emailId")
      } else {
        navigateTo("/personal-details");
        toasterControl(true, "Password created successfully.", "success");
        localStorage.removeItem("emailId")
      }
     }
    } else {
      console.error(profRes.error);
    }
} else {
      console.log(res.error.response.data.message);
      toasterControl(true, res.error.response.data.message, "error");
    }
  };

  // toggle password
  const toggleConfirmIcon = () => {
    if (confirmInputType === "password") {
      setConfirmInputType("text");
    } else {
      setConfirmInputType("password");
    }
  };

  const togglePasswordIcon = () => {
    if (inputType === "password") {
      setInputType("text");
    } else {
      setInputType("password");
    }
  };

  useEffect(()=>{
localStorage.setItem("fromLoginPage", "yes")
  },[])

  return (
    <>
      <LoginHeader />
      <div
        style={{
          backgroundImage: `url(${bgmain})`,
        }}
        className={styles.signInContainer}
      >
        <div className={styles.boxContainer}>
          <div className={styles.welcomeHead}>Set up a new Password</div>
          <div className={styles.allTextfield}>
            <div style={{ borderRadius: "12px", width: "100%", display: "flex", flexDirection: "column" }}>
              <TextField
                label="Email"
                variant="outlined"
                className="saas-form mb-3"
                type="email"
                name="email"
                value={localStorage.getItem("emailId")}
                onChange={handlePassword}
                style={{ borderRadius: "12px", width: "100%" }}
                InputProps={{ style: { borderRadius: "12px" } }}
                disabled
              />
              <div className={styles.passwordInputContainer}>
                <TextField
                  error={passedChecks.length > 0}
                  label="Enter New Password"
                  variant="outlined"
                  className="saas-form mb-3"
                  type={inputType}
                  name="password"
                  value={inputPassword.password}
                  onChange={handlePassword}
                  style={{ borderRadius: "12px", width: "100%" }}
                  InputProps={{ style: { borderRadius: "12px" } }}
                />
                <div className={styles.showPasswordIcon} onClick={togglePasswordIcon}>
                  {inputType === "password" ? <PiEyeLight fontSize={22} color="#4d4d4d" /> : <PiEyeClosedLight fontSize={22} color="#4d4d4d" />}
                </div>
              </div>

              {inputPassword.password.length > 0 && (
                <div className="mb-3">
                  <div className={styles.errorHead}>Your password must contain</div>
                  {/* <div style={{color:""}} className={passedChecks.includes("minlen") ? styles.success : styles.errorSubHead}>
                    <li>At least 8 characters.</li>
                  </div> */}
                  <div>
                    <li className={passedChecks.includes("nospecial") ? styles.success : styles.errorSubHead}>At least one special character (@).</li>
                  </div>
                  <div>
                    <li className={passedChecks.includes("noupper") ? styles.success : styles.errorSubHead}>At least one capital letter (P)</li>
                  </div>
                  <div>
                    <li className={passedChecks.includes("minlen")  ? styles.success : styles.errorSubHead}>Minimum 8 characters and maximum 15 characters</li>
                  </div>
                </div>
              )}

              <div className={styles.passwordInputContainer}>
                <TextField
                  error={confirmErr}
                  label="Confirm Password"
                  variant="outlined"
                  className="saas-form"
                  type={confirmInputType}
                  name="confirmPassword"
                  value={inputPassword.confirmPassword}
                  onChange={handlePassword}
                  style={{ borderRadius: "12px", width: "100%" }}
                  InputProps={{ style: { borderRadius: "12px" } }}
                />
                <div className={styles.showPasswordIcon} onClick={toggleConfirmIcon}>
                  {confirmInputType === "password" ? <PiEyeLight fontSize={22} color="#4d4d4d" /> : <PiEyeClosedLight fontSize={22} color="#4d4d4d" />}
                </div>
              </div>
              {confirmErr && <div className={styles.error}>{confirmErr}</div>}
            </div>

            <div style={{ width: "100%" }}>
              <button
                className={styles.createBtn}
                onClick={() => {
                  inputPassword.password === inputPassword.confirmPassword && createPass()
                  //  && handleLogin();
                }}
              >
                Create
              </button>
            </div>
           
          </div>
          <div style={{ borderTop: "1px solid var(--Neutral-200, #E0E3E6)", width: "100%" }}>
            <div className={styles.footerPara}>
            <div>
                  On signing you agree to our{" "}
                  <span
                    style={{
                      textDecoration: "underline",
                      textUnderlineOffset: "0.2em",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      window.open(`${iDesignWebsite}/terms`, "_blank")
                    }
                    className={styles.underlineInteraction}
                  >
                    Terms of Use{" "}
                  </span>
                  and{" "}
                  <span
                    onClick={() =>
                      window.open(
                        `${iDesignWebsite}/privacy-policy`,
                        "_blank"
                      )
                    }
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      textUnderlineOffset: "0.2em",
                    }}
                    className={styles.underlineInteraction}
                  >
                    Privacy Policy
                  </span>
                  . <br />
                </div>
            </div>
            </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
